import { Expose } from 'class-transformer'

export class FormatSelect {
  @Expose({ name: 'value' })
  value: string | undefined

  @Expose({ name: 'label' })
  label: string | undefined

  @Expose({ name: 'email' })
  email: number | undefined

  @Expose({ name: 'usertype' })
  usertype: number | undefined
}
