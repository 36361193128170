/* eslint-disable import/no-anonymous-default-export */
import api from './Api'
import Swal from 'sweetalert2'
import options from '../data/selectOptions'

const UpdatePatientInfo = async (obj: any) => {
  let user: any = localStorage.getItem('user')
  // console.log(`obj: \n${JSON.stringify(obj, null, 4)}`);

  let {
    entryDate,
    clinicalMedicalNH,
    firstname,
    middlename,
    surname,
    second_surname,
    birthday,
    isMobilePhone,
    phone,
    extPhone,
    countryOrigin,
    gender,
    statusSex,
    bloodtype,
    maritalStatus,
    nursingHomeId,
    nursingHomeSourceId,
    socialSecurityId,
    email,
    insuranceCarrier,
    insuranceCarrierII,
    tytocareId,
    preferenceHospital,
    id,
    otherSource,
  }: any = obj

  birthday = new Date(birthday).toISOString().substring(0, 10) //yyyy-mm-dd
  nursingHomeSourceId =
    nursingHomeSourceId === options.preOptionsHome[0]
      ? options.preOptionsHome[0]
      : nursingHomeSourceId

  nursingHomeSourceId = otherSource
    ? { value: `other;${otherSource}`, label: null }
    : nursingHomeSourceId

  const data = await api.instanceUsers
    .post(
      `/user/update-resident/`,
      {
        entryDate,
        clinicalMedicalNH,
        firstname,
        middlename,
        surname,
        second_surname,
        birthday,
        isMobilePhone: isMobilePhone ? isMobilePhone : true,
        phone,
        extPhone,
        countryOrigin,
        gender,
        statusSex,
        bloodtype,
        maritalStatus,
        nursingHomeId,
        nursingHomeSourceId,
        socialSecurityId,
        email,
        insuranceCarrier,
        insuranceCarrierII,
        tytocareId,
        preferenceHospital,
        id,
      },
      {
        headers: {
          Authorization: `${JSON.parse(user).TokenType} ${
            JSON.parse(user).IdToken
          }`,
          USER_ENTITY_ID: `${localStorage.getItem('role')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    )
    .then((response) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmación',
        text: 'Se realizó actualización con éxito!',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return response.data
    })
    .catch((error) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return error
    })
  return data
}

export default {
  UpdatePatientInfo,
}
