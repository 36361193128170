/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import useGetListPatient from '../../hooks/useGetListPatient'
import PatientItem from './PatientItem'
import Select, { components, OptionProps } from 'react-select'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { customStyles } from '../customStyleNewLabelCircle'
import Resident from '../../assets/svg/residenteAzul.svg'
import { rolesAccess } from '../../utils/Interfaces/accessRoles'
import logicalRolSidebar from '../../utils/MenuAccess/logicalRolSidebar'

const CustomOption = ({ children, data, label, ...props }: any) => {
  // console.log('Select: ', { label, children, data, ...props });
  return (
    <>
      <components.Option {...props}>
        <div className="flex flex-col">
          <p>
            <b>Nombre</b> {data.label}
          </p>
          <p>
            <b>Fecha de nacimeinto</b> {data.Profile_birthday}
          </p>
          <p>
            <b>Seguro</b> {data.Profile_insurance_carrier}
          </p>
          <p>
            <b>Record Medico</b> {data.clinicalmedicalrecord || 'N/A'}
          </p>
          <p>
            <b>Record Nursing Home</b> {data.clinicalmedicalnh || 'N/A'}
          </p>
        </div>
      </components.Option>
    </>
  )
}

const filterOption = (candidate: any, input: any) => {
  if (
    candidate?.data?.Profile_insurance_carrier?.toLowerCase().includes(
      input?.toLowerCase(),
    ) ||
    candidate?.data?.insurancecarrierii
      ?.toLowerCase()
      .includes(input?.toLowerCase()) ||
    candidate?.data?.clinicalmedicalrecord
      ?.toLowerCase()
      .includes(input?.toLowerCase()) ||
    candidate?.data?.clinicalmedicalnh
      ?.toLowerCase()
      .includes(input?.toLowerCase()) ||
    candidate?.data?.Profile_birthday?.toLowerCase().includes(
      input?.toLowerCase(),
    ) ||
    candidate?.label?.toLowerCase().includes(input?.toLowerCase())
  ) {
    return candidate
  }
}

const PatientList = ({ ...props }: any) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [dataList, setDataList] = useState<any>([])
  const [user, setUser] = useState<any>()
  const { data, isLoading }: any = useGetListPatient()

  let access: rolesAccess = logicalRolSidebar(user)

  useEffect(() => {
    setDataList(data)
  }, [data])

  useEffect(() => {
    setSearchTerm('')
  }, [])

  const { control } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    setUser(props?.state?.currentUser)
  })

  const searchPatient = (value: any, onchange: any) => {
    if (value?.label) {
      setSearchTerm(value.label)
    } else {
      setSearchTerm('')
    }
    return onchange
  }

  return (
    <div className="w-full h-screen flex flex-col gap-5">
      <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
        <div className="flex flex-col md:flex-row gap-1">
          <div className="flex flex-row">
            <img src={Resident} className={`w-20`} alt="" />
            <h1 className="w-full self-center text-[#294fa9] text-4xl font-extrabold ">
              Residentes
            </h1>
          </div>
        </div>
        <div>
          <h1 className="w-full self-center text-[#aaaaaa] text-2xl md:text-4xl font-light ">
            / Buscar
          </h1>
        </div>
      </div>
      <div className="w-full px-5">
        <form className="w-full grid grid-cols-1  gap-2 ">
          <div className="w-full hidden lg:block col-span-3">
            <Controller
              control={control}
              name="patientSelect"
              rules={{
                required: {
                  value: false,
                  message: 'Nursing Home es requerida',
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }: any) => {
                return (
                  <Select
                    className="w-full"
                    placeholder={`${
                      isLoading
                        ? 'Cargando Residentes...'
                        : 'Busque un residente por nombre, registro medico, fecha de nacimiento'
                    }`}
                    ref={ref}
                    isClearable
                    backspaceRemovesValue
                    styles={customStyles}
                    onBlur={onBlur}
                    onChange={searchPatient(value, onChange)}
                    options={dataList}
                    value={value}
                    isDisabled={isLoading ? true : false}
                    components={{ Option: CustomOption }}
                    isSearchable
                    filterOption={filterOption}
                  />
                )
              }}
            />
          </div>
          <div className="w-full lg:hidden">
            <Controller
              control={control}
              name="patientSelect"
              rules={{
                required: {
                  value: false,
                  message: 'Nursing Home es requerida',
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }: any) => {
                return (
                  <Select
                    className="w-full"
                    placeholder={`${
                      isLoading ? 'Cargando Residentes...' : 'Ingrese un nombre'
                    }`}
                    ref={ref}
                    isClearable
                    backspaceRemovesValue
                    styles={customStyles}
                    onBlur={onBlur}
                    onChange={searchPatient(value, onChange)}
                    options={dataList}
                    value={value}
                    isDisabled={isLoading ? true : false}
                    components={{ Option: CustomOption }}
                    isSearchable
                    filterOption={filterOption}
                  />
                )
              }}
            />
          </div>
        </form>
      </div>

      <div className="p-5 flex flex-col gap-5 overflow-scroll h-full ">
        <div
          className={
            dataList?.length > 0
              ? `grid lg:grid-cols-2 gap-x-4 gap-y-4 mx-auto w-fit`
              : `flex flex-col mx-auto w-fit`
          }
        >
          {isLoading ? (
            <div>Cargando...</div>
          ) : (
            <React.Fragment>
              {dataList?.length > 0 ? (
                dataList
                  ?.filter((val: any) => {
                    if (
                      searchTerm === '' ||
                      searchTerm === null ||
                      searchTerm === undefined
                    ) {
                      return val
                    } else if (
                      val?.label
                        ?.toLowerCase()
                        ?.includes(searchTerm?.toLowerCase())
                    ) {
                      return val
                    }
                  })
                  ?.map((patients: any, idx: any) => {
                    return (
                      <PatientItem {...patients} access={access} key={idx} />
                    )
                  })
              ) : (
                <>
                  <div className="justify-center text-center text-xl font-semibold">
                    Su usuario no tiene ninguna entidad vinculada para
                    visualizar residentes
                  </div>
                </>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => {
  return {
    state: {
      currentUser: state.currentUser,
    },
  }
}

export default connect(mapStateToProps, null)(PatientList)
