import { deserialize } from 'class-transformer'
import { Calendar } from './FormatSelect'

export const dataTransformer = (data: any): any => {
  if (data?.length > 0) {
    let dataArray = data.map((item: any, idx: number) => {
      return {
        idx,
        ...item.detail,
      }
    })

    let processData = deserialize(Calendar, JSON.stringify(dataArray))

    return processData
  } else {
    return []
  }
}
