/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BanIcon,
  LinkIcon,
  OfficeBuildingIcon,
  PencilIcon,
  SearchIcon,
} from '@heroicons/react/outline'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select, { components, OptionProps } from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import { customStyles } from '../customStyleNewLabel'
import UnLinkConfirmation from '../Modals/UnLinkConfirmation'
import useGetUserEntity from '../../hooks/useGetUserEntity'
import options from '../../data/selectOptions'
import Entities from '../../assets/svg/entidades.svg'
import { ROLEID } from '../../utils/enums/Roles'
import Swal from 'sweetalert2'
import UpdateUserLinkageStatus from '../../services/UpdateUserLinkageStatus'
const ManageUser = ({ ...props }: any) => {
  const [showModal, setShowModal] = useState<any>(false)
  const [userEntityList, setUserEntityList] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState<any>()
  const [formData, setFormData] = useState<any>(null)
  const [isLoad, setIsLoading] = useState<any>(null)

  const { listData, error, isLoading } = useGetUserEntity({
    clinic: props?.state?.updateEntityForm?.clinic_id,
    home: props?.state?.updateEntityForm?.nursing_home_id,
  })

  useEffect(() => {
    if (userEntityList === null) {
      setTimeout(() => {
        setUserEntityList(listData)
      }, 1000)
    }
  })

  const navigate = useNavigate()

  const { control } = useForm({
    mode: 'onChange',
  })

  const filterUserRelationSelected = useCallback((candidate, input) => {
    if (
      candidate?.data?.label?.toLowerCase().includes(input?.toLowerCase()) ||
      candidate?.data?.email?.toLowerCase().includes(input?.toLowerCase())
    ) {
      return candidate
    }
  }, [])

  const onChangeUserRelationSelected = useCallback((value) => {
    if (value?.label) {
      setSearchTerm(value.label)
    } else {
      setSearchTerm('')
    }
  }, [])

  const desvincular = async (item: any) => {
    let data: any = {
      clinicId: props?.state?.updateEntityForm?.clinic_id
        ? props?.state?.updateEntityForm?.clinic_id
        : null,
      nursingHomeId: props?.state?.updateEntityForm?.nursing_home_id
        ? props?.state?.updateEntityForm?.nursing_home_id
        : null,
      userId: item,
    }
    setFormData(data)
    setShowModal(true)
  }

  const unlinkUser = async () => {
    setIsLoading(false)
    // formData.userId = location?.state?.patient
    let result: any = await UpdateUserLinkageStatus(formData)

    if (result?.code >= 200 && result?.code < 300) {
      // reset(myInitialData)

      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Confirmación',
        text: `¡Ha sido eliminado correctamente!`,
        icon: 'success',
        // icon: "error",
        //cancelButtonText: 'Volver al formulario',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
        confirmButtonColor: 'rgb(14 165 233)',
        cancelButtonColor: 'rgb(14 165 233)',
      })
      navigate('/entities/profile')
    } else {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      setIsLoading(true)
    }
  }



  return (
    <div className="w-full h-screen">
      <div className="w-full h-fit shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
        <img src={Entities} className={`w-20`} alt="" />
        <div className="">
          <h1 className="my-5 justify-start md:text-4xl text-2xl font-semibold text-[#96c952]">
            {props?.state?.updateEntityForm?.clinic_comercialName ||
              props?.state?.updateEntityForm?.nursing_home_comercialName}
          </h1>
        </div>
      </div>

      <div className="flex flex-col gap-5 p-3 sm:p-7">
        <div className="w-full flex flex-col gap-5">
          <Controller
            control={control}
            name="patientSelect"
            rules={{
              required: {
                value: false,
                message: 'Nursing Home es requerida',
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }: any) => {
              return (
                <Select
                  className="w-full"
                  placeholder={`Buscar uno de los usuarios asociados a su entidad`}
                  ref={ref}
                  isClearable
                  backspaceRemovesValue
                  styles={customStyles}
                  onBlur={onBlur}
                  onChange={onChangeUserRelationSelected}
                  options={userEntityList}
                  value={value}
                  isSearchable
                  filterOption={filterUserRelationSelected}
                />
              )
            }}
          />
          {props?.state?.currentUser?.roles[0]?.roleId === ROLEID.ADMIN ||
          props?.state?.currentUser?.roles[0]?.roleId ===
            ROLEID.PROJECT_MANAGER ||
          props?.state?.currentUser?.roles[0]?.roleId ===
            ROLEID.PROJECT_ASSISTANT ||
          props?.state?.currentUser?.roles[0]?.roleId ===
            ROLEID.PROJECT_EVALUATOR ? (
            <div className="w-full text-center">
              <button
                className={`w-fit px-4 py-2 border-[1px] rounded-xl bg-[#F6A01E] shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 duration-300`}
                onClick={() => {
                  navigate('/user/linkuser')
                }}
              >
                Vincular nuevos usuarios
              </button>
            </div>
          ) : null}
        </div>
        <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-3">
          {/*Grupo entidades*/}

          {!isLoading ? (
            <div>Cargando...</div>
          ) : (
            <>
              {userEntityList
                ?.filter((val: any) => {
                  if (
                    searchTerm === '' ||
                    searchTerm === null ||
                    searchTerm === undefined
                  ) {
                    return val
                  } else if (
                    val?.label
                      ?.toLowerCase()
                      ?.includes(searchTerm?.toLowerCase())
                  ) {
                    return val
                  }
                })
                ?.sort((a: any, b: any) => {
                  if (a.label > b.label) {
                    return 1
                  }
                  if (a.label < b.label) {
                    return -1
                  }
                  return 0
                })
                ?.map((item: any) => {
                  return (
                    <div
                      key={item?.value}
                      className="bg-white flex flex-col gap-3 rounded-md border px-5 py-4 shadow-lg"
                    >
                      <div className="flex flex-col">
                        <div className="flex flex-row gap-x-1">
                          <b>Rol:</b>
                          <p>
                            {options.roleList
                              .filter((data: any) => {
                                if (data?.value === item?.usertype) {
                                  return data
                                }
                              })
                              ?.map((data: any) => {
                                return data.label
                              })}
                          </p>
                        </div>
                        <div className="flex flex-row gap-x-1">
                          <b>Nombre:</b>
                          <p>{item?.label}</p>
                        </div>
                        <div className="flex flex-row gap-x-1">
                          <b>correo:</b>
                          <p>{`${item?.email ? item?.email : 'N/A'}`}</p>
                        </div>
                      </div>
                      <div className="flex flex-row-reverse gap-3">
                        <button
                          className="px-4 py-2 rounded-xl border-[1px] font-bold bg-cyan-500 shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 duration-300"
                          onClick={() => {
                            navigate('/profile/user/', {
                              state: {
                                userId: item?.value,
                                return: '/entities/manage/users',
                                entityUser: true,
                              },
                            })
                          }}
                        >
                          Ver Perfil
                        </button>
                        <button
                          className="px-4 py-2 rounded-xl border-[1px] font-bold bg-[#f6501e] shadow-sm hover:cursor-pointer hover:bg-red-400 text-white hover:border-red-700 duration-300"
                          onClick={() => {
                            desvincular(item?.value)
                          }}
                        >
                          Desvincular
                        </button>
                      </div>
                    </div>
                  )
                })}
            </>
          )}
        </div>
      </div>
      <div className="w-full text-center">
        <button
          className={`w-fit px-4 py-2 rounded-xl border-[1px] font-bold bg-[#F6A01E] shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 duration-300`}
          onClick={() => {
            navigate('/entities/profile')
          }}
        >
          Volver
        </button>
      </div>
      {showModal && (
        <UnLinkConfirmation
          setShowModal={setShowModal}
          formData={formData}
          unlinkUser={unlinkUser}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    state: {
      updateEntityForm: state.updateEntityForm,
      currentUser: state.currentUser,
    },
  }
}

const mapDispatchToProps = {}

// export default Sidebar;
export default connect(mapStateToProps, mapDispatchToProps)(ManageUser)
