import React from 'react'
import { customStyles } from '../customStyleNewLabel'
import options from '../../data/selectOptions'
import InformationLaboral from '../../assets/svg/PatientItems/informacionLaboral.svg'
import InformationPersonal from '../../assets/svg/PatientItems/informacionPersonal.svg'
import { phoneLengthVerification } from '../../utils/phoneLengthVerification'
import { clearSpace, onlyPhone } from '../../utils/regexExpresion'

const StepSix = ({
  Controller,
  NumberFormat,
  Select,
  control,
  errors,
  register,
  specialtyDoctorFields,
  specialtyDoctorRemove,
  specialtyDoctorAppend,
  CustomValueContainer,
  mode,
  watch,
}: any) => {
  return (
    <React.Fragment>
      {specialtyDoctorFields.map((field: any, index: any) => {
        return (
          <div
            className="w-full place-items-center flex flex-col space-y-5 border-b-2 pb-5"
            key={field.id}
          >
            <div className="my-1 flex w-full flex-col lg:col-span-2 lg:block xl:col-span-3  text-slate-500">
              <p>Registro de los doctores que tratan al residente.</p>
            </div>
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">
              <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
                <img src={InformationPersonal} className={`w-12`} alt="" />
                <h1 className="text-xl">{`Información Personal`}</h1>
              </div>
              <div className="my-1 flex w-full flex-col lg:col-span-2 lg:block xl:col-span-3  text-slate-500">
                <p>Información básica y de contacto del doctor/a.</p>
              </div>
              
              <div className="w-full lg:w-64 h-full relative">
                <Controller
                  control={control}
                  name={`specialtyDoctor.${index}.doctorSpecialty`}
                  rules={{
                    required: {
                      value: true,
                      message: 'Especialidad es requerido',
                    },
                  }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                  }: any) => (
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      className="w-full"
                      placeholder="Especialidad*"
                      styles={customStyles}
                      options={options.specialtyOptions}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      isClearable
                    />
                  )}
                />
                {errors.specialtyDoctor?.[index]?.doctorSpecialty && (
                  <p className="text-sm text-red-600 mt-2">
                    {errors.specialtyDoctor?.[index]?.doctorSpecialty.message}
                  </p>
                )}
              </div>

              <div className="w-full lg:w-64 h-full relative">
                <input
                  id={`specialtyDoctor.${index}.doctorFirstname`}
                  name={`specialtyDoctor.${index}.doctorFirstname`}
                  type="text"
                  placeholder="Nombre"
                  className=" input-style peer"
                  {...register(`specialtyDoctor.${index}.doctorFirstname`, {
                    required: {
                      value: true,
                      message: 'Nombre es requerido',
                    },
                  })}
                />
                <label
                  htmlFor={`specialtyDoctor.${index}.doctorFirstname`}
                  className="input-style-label"
                >
                  Nombre*
                </label>
                {errors.specialtyDoctor?.[index]?.doctorFirstname && (
                  <p className="text-sm text-red-600 mt-2">
                    {errors.specialtyDoctor?.[index]?.doctorFirstname.message}
                  </p>
                )}
              </div>

              <div className="w-full lg:w-64 h-full relative">
                <input
                  id={`specialtyDoctor.${index}.doctorSurname`}
                  name={`specialtyDoctor.${index}.doctorSurname`}
                  type="text"
                  placeholder="Apellido"
                  className="input-style peer"
                  {...register(`specialtyDoctor.${index}.doctorSurname`, {
                    required: {
                      value: true,
                      message: 'Apellido es requerido',
                    },
                  })}
                />
                <label
                  htmlFor={`specialtyDoctor.${index}.doctorSurname`}
                  className="input-style-label"
                >
                  Apellido*
                </label>
                {errors.specialtyDoctor?.[index]?.doctorSurname && (
                  <p className="text-sm text-red-600 mt-2">
                    {errors.specialtyDoctor?.[index]?.doctorSurname.message}
                  </p>
                )}
              </div>

              <div className="w-full lg:w-64 h-full relative">
                <Controller
                  control={control}
                  name={`specialtyDoctor.${index}.doctorPhone`}
                  rules={{
                    pattern: {
                      value: onlyPhone,
                      message: `¡Error, el numero debe ser de 10 dígitos!`,
                    },
                  }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                  }: any) => (
                    <NumberFormat
                      className="input-style peer"
                      id={`specialtyDoctor.${index}.doctorPhone`}
                      name={`specialtyDoctor.${index}.doctorPhone`}
                      thousandSeparator={true}
                      onChange={onChange}
                      onBlur={onBlur}
                      placeholder="Número Celular"
                      displayType={'input'}
                      format="### ### ####"
                      value={value}
                    />
                  )}
                />
                <label
                  htmlFor={`specialtyDoctor.${index}.doctorPhone`}
                  className="input-style-label"
                >
                  Número Celular
                </label>
                {errors.specialtyDoctor?.[index]?.doctorPhone && (
                  <p className="text-sm text-red-600 mt-2">
                    {errors.specialtyDoctor?.[index]?.doctorPhone?.message}
                  </p>
                )}
              </div>

              <div className="w-full lg:w-64 h-full relative">
                <input
                  id={`specialtyDoctor.${index}.doctorEmail`}
                  name={`specialtyDoctor.${index}.doctorEmail`}
                  type="text"
                  placeholder="Correo electrónico"
                  className="input-style peer"
                  {...register(`specialtyDoctor.${index}.doctorEmail`, {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9._.-]+\.[A-Z]{2,5}$/i,
                      message: 'Correo electrónico Invalido',
                    },
                    required: {
                      value: true,
                      message: 'Nombre es requerido',
                    },
                  })}
                />
                <label
                  htmlFor={`specialtyDoctor.${index}.doctorEmail`}
                  className="input-style-label"
                >
                  Correo electrónico*
                </label>
                {errors.specialtyDoctor?.[index]?.doctorEmail && (
                  <p className="text-sm text-red-600 mt-2">
                    {errors.specialtyDoctor?.[index]?.doctorEmail?.message}
                  </p>
                )}
              </div>

              {/* <div className="hidden lg:block lg:col-span-2 xl:col-span-3 my-1"></div> */}
              <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
                <img src={InformationLaboral} className={`w-12`} alt="" />
                <h1 className="text-xl first-line:capitalize">
                  {`Información de la Clínica`}
                </h1>
              </div>
              <div className="my-1 flex w-full flex-col lg:col-span-2 lg:block xl:col-span-3  text-slate-500">
                <p>Información de la clínica donde labora el doctor/a.</p>
              </div>

              <div className="w-full lg:w-64 h-full relative">
                <input
                  id={`specialtyDoctor.${index}.clinicalMedicalRecord`}
                  name={`specialtyDoctor.${index}.clinicalMedicalRecord`}
                  className="input-style peer"
                  placeholder="Record Médico de Paciente"
                  {...register(
                    `specialtyDoctor.${index}.clinicalMedicalRecord`,
                  )}
                />

                <label
                  htmlFor={`specialtyDoctor.${index}.clinicalMedicalRecord`}
                  className="input-style-label"
                >
                  Record Médico de Paciente
                </label>
                {/* {errors.specialtyDoctor?.[index]?.clinicalMedicalRecord && (
									<p className="text-sm text-red-600 mt-2">
										{
											errors.specialtyDoctor?.[index]?.clinicalMedicalRecord
												?.message
										}
									</p>
								)} */}
              </div>
              <div className="w-full lg:w-64 h-full relative">
                <input
                  id={`specialtyDoctor.${index}.clinicalName`}
                  name={`specialtyDoctor.${index}.clinicalName`}
                  type="text"
                  placeholder="Nombre de Clínica"
                  className="input-style peer"
                  {...register(`specialtyDoctor.${index}.clinicalName`)}
                />
                <label
                  htmlFor={`specialtyDoctor.${index}.clinicalName`}
                  className="input-style-label"
                >
                  Nombre de Clínica
                </label>
              </div>

              <div className="w-full lg:w-64 h-full relative">
                <Controller
                  control={control}
                  name={`specialtyDoctor.${index}.doctorLocalPhone`}
                  rules={{
                    pattern: {
                      value: onlyPhone,
                      message: `¡Error, el numero debe ser de 10 dígitos!`,
                    },
                  }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                  }: any) => (
                    <NumberFormat
                      id={`specialtyDoctor.${index}.doctorLocalPhone`}
                      name={`specialtyDoctor.${index}.doctorLocalPhone`}
                      className="input-style peer"
                      thousandSeparator={true}
                      onChange={onChange}
                      onBlur={onBlur}
                      placeholder="Teléfono Fijo"
                      displayType={'input'}
                      format="### ### ####"
                      value={value}
                    />
                  )}
                />
                <label
                  htmlFor={`specialtyDoctor.${index}.doctorLocalPhone`}
                  className="input-style-label"
                >
                  Teléfono Fijo
                </label>
                {errors.specialtyDoctor?.[index]?.doctorLocalPhone && (
                  <p className="text-sm text-red-600 mt-2">
                    {errors.specialtyDoctor?.[index]?.doctorLocalPhone?.message}
                  </p>
                )}
              </div>

              {/* <div className="w-full lg:w-64 h-full relative">
								<input
									type="text"
									id={`specialtyDoctor.${index}.extDoctorLocalPhone`}
									name={`specialtyDoctor.${index}.extDoctorLocalPhone`}
									className="input-style peer"
									placeholder="Extensión Teléfono Fijo"
									format="####"
									{...register(`specialtyDoctor.${index}.extDoctorLocalPhone`)}
								/>
								<label
									htmlFor={`specialtyDoctor.${index}.extDoctorLocalPhone`}
									className="input-style-label">
									Extensión Teléfono Fijo
								</label>
							</div> */}
              <div className="w-full lg:w-64 h-full relative">
                <Controller
                  control={control}
                  name={`specialtyDoctor.${index}.extDoctorLocalPhone`}
                  render={({
                    field: { onChange, onBlur, value, ref },
                  }: any) => (
                    <NumberFormat
                      id={`specialtyDoctor.${index}.extDoctorLocalPhone`}
                      name={`specialtyDoctor.${index}.extDoctorLocalPhone`}
                      className="input-style peer"
                      thousandSeparator={true}
                      onChange={onChange}
                      onBlur={onBlur}
                      placeholder="Extensión Teléfono Fijo"
                      displayType={'input'}
                      format="#####"
                      value={value}
                    />
                  )}
                />
                <label
                  htmlFor={`specialtyDoctor.${index}.extDoctorLocalPhone`}
                  className="input-style-label"
                >
                  Extensión Teléfono Fijo
                </label>
                {/* {errors.specialtyDoctor?.[index]?.doctorZipCode && (
									<p className="text-sm text-red-600 mt-2">
										{errors.specialtyDoctor?.[index]?.doctorZipCode?.message}
									</p>
								)} */}
              </div>

              <div className="w-full lg:w-64 h-full relative">
                <input
                  id={`specialtyDoctor.${index}.doctorJobAddress`}
                  name={`specialtyDoctor.${index}.doctorJobAddress`}
                  type="text"
                  placeholder="Direción Linea 1"
                  className="input-style peer"
                  {...register(`specialtyDoctor.${index}.doctorJobAddress`)}
                />
                <label
                  htmlFor={`specialtyDoctor.${index}.doctorJobAddress`}
                  className="input-style-label"
                >
                  Direción Linea 1
                </label>
              </div>

              <div className="w-full lg:w-64 h-full relative">
                <input
                  id={`specialtyDoctor.${index}.doctorJobAddressAlt`}
                  name={`specialtyDoctor.${index}.doctorJobAddressAlt`}
                  type="text"
                  placeholder="Direción Linea 2"
                  className="input-style peer"
                  {...register(`specialtyDoctor.${index}.doctorJobAddressAlt`)}
                />
                <label
                  htmlFor={`specialtyDoctor.${index}.doctorJobAddressAlt`}
                  className="input-style-label"
                >
                  Direción Linea 2
                </label>
              </div>
              <div className="w-full lg:w-64 h-full relative">
                <Controller
                  control={control}
                  name={`specialtyDoctor.${index}.doctorJobState`}
                  render={({
                    field: { onChange, onBlur, value, ref },
                  }: any) => (
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      className="w-full"
                      placeholder="Pueblo/Municipio..."
                      isClearable
                      styles={customStyles}
                      onBlur={onBlur}
                      onChange={onChange}
                      options={options.city}
                      value={value}
                    />
                  )}
                />
              </div>
              {/* <div className="w-11/12 lg:w-64 h-full">
								<label
									className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
									htmlFor={`specialtyDoctor.${index}.doctorJobCity`}
								>
									<input
										type="text"
										id={`specialtyDoctor.${index}.doctorJobCity`}
										name={`specialtyDoctor.${index}.doctorJobCity`}
										className="w-full h-10 focus:outline-none bg-transparent"
										placeholder="Ciudad..."
										{...register(`specialtyDoctor.${index}.doctorJobCity`, {
											required: {
												value: false,
												message: 'Ciudad es requerido',
											},
										})}
									/>
								</label>
								{errors.specialtyDoctor?.[index]?.doctorJobCity && (
									<p className="text-sm text-red-600 mt-2">
										{errors.specialtyDoctor?.[index]?.doctorJobCity.message}
									</p>
								)}
							</div> */}

              <div className="w-full lg:w-64 h-full relative">
                <Controller
                  control={control}
                  name={`specialtyDoctor.${index}.doctorZipCode`}
                  render={({
                    field: { onChange, onBlur, value, ref },
                  }: any) => (
                    <NumberFormat
                      id={`specialtyDoctor.${index}.doctorZipCode`}
                      name={`specialtyDoctor.${index}.doctorZipCode`}
                      className="input-style peer"
                      thousandSeparator={true}
                      onChange={onChange}
                      onBlur={onBlur}
                      placeholder="Código Postal"
                      displayType={'input'}
                      format="#####"
                      value={value}
                    />
                  )}
                />
                <label
                  htmlFor={`specialtyDoctor.${index}.doctorZipCode`}
                  className="input-style-label"
                >
                  Código Postal
                </label>
                {/* {errors.specialtyDoctor?.[index]?.doctorZipCode && (
									<p className="text-sm text-red-600 mt-2">
										{errors.specialtyDoctor?.[index]?.doctorZipCode?.message}
									</p>
								)} */}
              </div>

              <div className="w-full lg:w-64 h-full relative">
                <Controller
                  control={control}
                  name={`specialtyDoctor.${index}.doctorJobCountry`}
                  render={({
                    field: { onChange, onBlur, value, ref },
                  }: any) => (
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      className="w-full"
                      placeholder="País..."
                      isClearable
                      styles={customStyles}
                      onBlur={onBlur}
                      onChange={onChange}
                      options={options.state}
                      value={value}
                    />
                  )}
                />
              </div>

              <div className="hidden lg:block lg:col-span-2 xl:col-span-3 my-1"></div>

              {/* <div className="w-11/12 lg:w-64 h-full">
								<Controller
									control={control}
									name={`specialtyDoctor.${index}.isMobileDoctorPhone`}
									render={({
										field: { onChange, onBlur, value = false, ref },
									}: any) => (
										<label
											className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px] cursor-pointer"
											htmlFor={`specialtyDoctor.${index}.isMobileDoctorPhone`}
										>
											<Switch
												id={`specialtyDoctor.${index}.isMobileDoctorPhone`}
												name={`specialtyDoctor.${index}.isMobileDoctorPhone`}
												onChange={onChange}
												height={16}
												width={30}
												checked={value}
												checkedIcon={false}
												uncheckedIcon={false}
												className={`mr-5`}
											/>
											<span>Es Celular</span>
										</label>
									)}
								/>
							</div> */}

              <input
                type="button"
                className={`${
                  mode === 'update' || mode === 'add' ? 'hidden' : ''
                }  w-full h-10 bg-red-500 rounded-lg text-white`}
                onClick={() => specialtyDoctorRemove(index)}
                value="Eliminar"
              />
            </div>
          </div>
        )
      })}
      <div className="w-full text-center">
        <input
          className={`${
            mode === 'update' || mode === 'add' ? 'hidden' : ''
          } mx-auto w-11/12 lg:w-fit rounded-lg px-4 py-2 bg-lime-600 shadow-md duration-300 font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700`}
          type="button"
          value={`Adicionar Médico`}
          onClick={() =>
            specialtyDoctorAppend({
              doctorSpecialty: '',
              doctorFirstname: '',
              doctorSurname: '',
              clinicalName: '',
              doctorJobAddress: '',
              doctorJobAddressAlt: '',
              // doctorJobCity: "",
              doctorJobState: '',
              doctorJobCountry: '',
              doctorEmail: '',
            })
          }
        />
      </div>
    </React.Fragment>
  )
}

export default StepSix
