const entityType = [
  { value: 0, label: 'Nursing Home' },
  { value: 1, label: 'Clínica' },
]

const appointmentType = [
  { value: 0, label: 'Cita' },
  { value: 1, label: 'Turno' },
]

const roleList = [
  { value: 7, label: 'Administrador/a del hogar' },
  { value: 8, label: 'Administrador/a de la clínica' },
  { value: 10, label: 'Asistente del proyecto' },
  { value: 6, label: 'Cuidador/a' },
  { value: 9, label: 'Director/a del proyecto' },
  { value: 1, label: 'Doctor/a' },
  { value: 3, label: 'Enfermero/a' },
  { value: 11, label: 'Evaluador del proyecto' },
  { value: 2, label: 'Secretario/a' },
  { value: 5, label: 'Super Administrador' },
]

const specialtyOptions = [
  { value: 17, label: 'Medicina física y rehabilitación' },
  { value: 18, label: 'Medicina forense' },
  { value: 19, label: 'Medicina intensiva' },
  { value: 20, label: 'Medicina interna' },
  { value: 21, label: 'Medicina preventiva y salud pública' },
  { value: 22, label: 'Medicina del trabajo' },
  { value: 23, label: 'Nefrología' },
  { value: 24, label: 'Neumología' },
  { value: 25, label: 'Neurología' },
  { value: 26, label: 'Nutriología' },
  { value: 27, label: 'Oncología médica' },
  { value: 28, label: 'Oncología radioterápica' },
  { value: 29, label: 'Pediatría' },
  { value: 30, label: 'Psiquiatría' },
  { value: 31, label: 'Reumatología' },
  { value: 32, label: 'Toxicología' },
  { value: 34, label: 'Alergología' },
  { value: 33, label: 'Anestesiología' },
  { value: 35, label: 'Angiología' },
  { value: 36, label: 'Cardiología' },
  { value: 37, label: 'Endocrinología' },
  { value: 38, label: 'Estomatología' },
  { value: 39, label: 'Farmacología Clínica' },
  { value: 40, label: 'Gastroenterología' },
  { value: 41, label: 'Genética' },
  { value: 42, label: 'Geriatría' },
  { value: 43, label: 'Hematología' },
  { value: 44, label: 'Hepatología' },
  { value: 45, label: 'Infectología' },
  { value: 46, label: 'Medicina aeroespacial' },
  { value: 47, label: 'Medicina del deporte' },
  { value: 48, label: 'Medicina familiar y comunitaria' },
]

const relationshipOptions = [
  { value: 'son', label: 'Hijo' },
  { value: 'stepson', label: 'Hijastro' },
  { value: 'daughter', label: 'Hija' },
  { value: 'stepdaughter', label: 'Hijastra' },
  { value: 'father', label: 'Padre' },
  { value: 'stepfather', label: 'Padrastro' },
  { value: 'mother', label: 'Madre' },
  { value: 'stepmother', label: 'Madrastra' },
  { value: 'cousin', label: 'Primo' },
  { value: 'uncle', label: 'Tio' },
  { value: 'aunt', label: 'Tia' },
  { value: 'grandfather', label: 'Abuelo' },
  { value: 'grandmother', label: 'Abuela' },
  { value: 'husband', label: 'Esposo' },
  { value: 'wife', label: 'Esposa' },
  { value: 'brother', label: 'Hermano' },
  { value: 'sister', label: 'Hermana' },
  { value: 'sibling', label: 'Sobrino' },
  { value: 'nephew', label: 'Suegro' },
  { value: 'niece', label: 'Suegra' },
  { value: 'grandson', label: 'Nieto' },
  { value: 'granddaughter', label: 'Nieta' },
  { value: 'friend', label: 'Amigo' },
  { value: 'neighbor', label: 'Vecino' },
]

const bloodOptions = [
  { value: 'A+', label: 'A+' },
  { value: 'A-', label: 'A-' },
  { value: 'B+', label: 'B+' },
  { value: 'B-', label: 'B-' },
  { value: 'AB+', label: 'AB+' },
  { value: 'AB-', label: 'AB-' },
  { value: 'O+', label: 'O+' },
  { value: 'O-', label: 'O-' },
]

const maritalOptions = [
  { value: '7', label: 'Casado/a' },
  { value: '8', label: 'Soltero/a' },
  { value: '9', label: 'Viudo/a' },
  { value: '10', label: 'Divorciado/a' },
  { value: '11', label: 'Separado/a' },
  { value: '12', label: 'Conviviente' },
]

const genderOptions = [
  { value: 5, label: 'Femenino' },
  { value: 4, label: 'Masculino' },
  { value: 6, label: 'Intersexual' },
]

const sexOptions = [
  {
    value: 13,
    label: 'Se identifica como Hombre',
    lavel: 'Identifies as Male',
  },
  {
    value: 14,
    label: 'Se identifica como Mujer',
    lavel: 'Identifies as Female',
  },
  {
    value: 15,
    label: 'Mujer a Hombre /Hombre Transgénero/Hombre Trans',
    lavel: 'Female-to-Male (FTM)/Transgender Male/Trans Man',
  },
  {
    value: 16,
    label: 'Hombre a Mujer /Mujer Transgénero/Mujer Trans',
    lavel: 'Male-to-Female (MTF)/Transgender Female/Trans Woman',
  },
]

const measurementUnitOptions = [
  { value: 'mg', label: 'Miligramo (mg)' },
  { value: 'ml', label: 'Mililitro (ml)' },
  { value: 'cc', label: 'Centímetro cúbico (cc)' },
]

const country = [
  { value: 1, label: 'Puerto Rico' },
  { value: 2, label: 'Estados Unidos' },
]

const state = country

const city = [
  {
    value: 1,
    label: 'Adjuntas',
    country: 1,
  },
  {
    value: 2,
    label: 'Aguada',
    country: 1,
  },
  {
    value: 3,
    label: 'Aguadilla',
    country: 1,
  },
  {
    value: 4,
    label: 'Aguas Buenas',
    country: 1,
  },
  {
    value: 5,
    label: 'Aibonito',
    country: 1,
  },
  {
    value: 6,
    label: 'Añasco',
    country: 1,
  },
  {
    value: 7,
    label: 'Arecibo',
    country: 1,
  },
  {
    value: 8,
    label: 'Arroyo',
    country: 1,
  },
  {
    value: 9,
    label: 'Barceloneta',
    country: 1,
  },
  {
    value: 10,
    label: 'Barranquitas',
    country: 1,
  },
  {
    value: 11,
    label: 'Bayamón',
    country: 1,
  },
  {
    value: 12,
    label: 'Cabo Rojo',
    country: 1,
  },
  {
    value: 13,
    label: 'Caguas',
    country: 1,
  },
  {
    value: 14,
    label: 'Camuy',
    country: 1,
  },
  {
    value: 15,
    label: 'Canóvanas',
    country: 1,
  },
  {
    value: 16,
    label: 'Carolina',
    country: 1,
  },
  {
    value: 17,
    label: 'Cataño',
    country: 1,
  },
  {
    value: 18,
    label: 'Cayey',
    country: 1,
  },
  {
    value: 19,
    label: 'Ceiba',
    country: 1,
  },
  {
    value: 20,
    label: 'Ciales',
    country: 1,
  },
  {
    value: 21,
    label: 'Cidra',
    country: 1,
  },
  {
    value: 22,
    label: 'Coamo',
    country: 1,
  },
  {
    value: 23,
    label: 'Comerío',
    country: 1,
  },
  {
    value: 24,
    label: 'Corozal',
    country: 1,
  },
  {
    value: 25,
    label: 'Culebra',
    country: 1,
  },
  {
    value: 26,
    label: 'Dorado',
    country: 1,
  },
  {
    value: 27,
    label: 'Fajardo',
    country: 1,
  },
  {
    value: 28,
    label: 'Florida',
    country: 1,
  },
  {
    value: 29,
    label: 'Guánica',
    country: 1,
  },
  {
    value: 30,
    label: 'Guayama',
    country: 1,
  },
  {
    value: 31,
    label: 'Guayanilla',
    country: 1,
  },
  {
    value: 32,
    label: 'Guaynabo',
    country: 1,
  },
  {
    value: 33,
    label: 'Gurabo',
    country: 1,
  },
  {
    value: 34,
    label: 'Hatillo',
    country: 1,
  },
  {
    value: 35,
    label: 'Hormigueros',
    country: 1,
  },
  {
    value: 36,
    label: 'Humacao',
    country: 1,
  },
  {
    value: 37,
    label: 'Isabela',
    country: 1,
  },
  {
    value: 38,
    label: 'Jayuya',
    country: 1,
  },
  {
    value: 39,
    label: 'Juana Díaz',
    country: 1,
  },
  {
    value: 40,
    label: 'Juncos',
    country: 1,
  },
  {
    value: 41,
    label: 'Lajas',
    country: 1,
  },
  {
    value: 42,
    label: 'Lares',
    country: 1,
  },
  {
    value: 43,
    label: 'Las Marías',
    country: 1,
  },
  {
    value: 44,
    label: 'Las Piedras',
    country: 1,
  },
  {
    value: 45,
    label: 'Loíza',
    country: 1,
  },
  {
    value: 46,
    label: 'Luquillo',
    country: 1,
  },
  {
    value: 47,
    label: 'Manatí',
    country: 1,
  },
  {
    value: 48,
    label: 'Maricao',
    country: 1,
  },
  {
    value: 49,
    label: 'Maunabo',
    country: 1,
  },
  {
    value: 50,
    label: 'Mayagüez',
    country: 1,
  },
  {
    value: 51,
    label: 'Moca',
    country: 1,
  },
  {
    value: 52,
    label: 'Morovis',
    country: 1,
  },
  {
    value: 53,
    label: 'Naguabo',
    country: 1,
  },
  {
    value: 54,
    label: 'Naranjito',
    country: 1,
  },
  {
    value: 55,
    label: 'Orocovis',
    country: 1,
  },
  {
    value: 56,
    label: 'Patillas',
    country: 1,
  },
  {
    value: 57,
    label: 'Peñuelas',
    country: 1,
  },
  {
    value: 58,
    label: 'Ponce',
    country: 1,
  },
  {
    value: 59,
    label: 'Quebradillas',
    country: 1,
  },
  {
    value: 60,
    label: 'Rincón',
    country: 1,
  },
  {
    value: 61,
    label: 'Río Grande',
    country: 1,
  },
  {
    value: 62,
    label: 'Sabana Grande',
    country: 1,
  },
  {
    value: 63,
    label: 'Salinas',
    country: 1,
  },
  {
    value: 64,
    label: 'San Germán',
    country: 1,
  },
  {
    value: 65,
    label: 'San Juan (capital)',
    country: 1,
  },
  {
    value: 66,
    label: 'San Lorenzo',
    country: 1,
  },
  {
    value: 67,
    label: 'San Sebastián',
    country: 1,
  },
  {
    value: 68,
    label: 'Santa Isabel',
    country: 1,
  },
  {
    value: 69,
    label: 'Toa Alta',
    country: 1,
  },
  {
    value: 70,
    label: 'Toa Baja',
    country: 1,
  },
  {
    value: 71,
    label: 'Trujillo Alto',
    country: 1,
  },
  {
    value: 72,
    label: 'Utuado',
    country: 1,
  },
  {
    value: 73,
    label: 'Vega Alta',
    country: 1,
  },
  {
    value: 74,
    label: 'Vega Baja',
    country: 1,
  },
  {
    value: 75,
    label: 'Vieques',
    country: 1,
  },
  {
    value: 76,
    label: 'Villalba',
    country: 1,
  },
  {
    value: 77,
    label: 'Yabucoa',
    country: 1,
  },
  {
    value: 78,
    label: 'Yauco',
    country: 1,
  },
  //USA
  {
    value: 79,
    label: 'Alabama',
    country: 2,
  },
  {
    value: 80,
    label: 'Alaska',
    country: 2,
  },
  {
    value: 81,
    label: 'Arizona',
    country: 2,
  },
  {
    value: 82,
    label: 'Arkansas',
    country: 2,
  },
  {
    value: 83,
    label: 'California',
    country: 2,
  },
  {
    value: 84,
    label: 'Colorado',
    country: 2,
  },
  {
    value: 85,
    label: 'Connecticut',
    country: 2,
  },
  {
    value: 86,
    label: 'Delaware',
    country: 2,
  },
  {
    value: 87,
    label: 'District of Columbia',
    country: 2,
  },
  {
    value: 88,
    label: 'Florida',
    country: 2,
  },
  {
    value: 89,
    label: 'Georgia',
    country: 2,
  },
  {
    value: 90,
    label: 'Hawaii',
    country: 2,
  },
  {
    value: 91,
    label: 'Idaho',
    country: 2,
  },
  {
    value: 92,
    label: 'Illinois',
    country: 2,
  },
  {
    value: 93,
    label: 'Indiana',
    country: 2,
  },
  {
    value: 94,
    label: 'Iowa',
    country: 2,
  },
  {
    value: 95,
    label: 'Kansas',
    country: 2,
  },
  {
    value: 96,
    label: 'Kentucky',
    country: 2,
  },
  {
    value: 97,
    label: 'Louisiana',
    country: 2,
  },
  {
    value: 98,
    label: 'Maine',
    country: 2,
  },
  {
    value: 99,
    label: 'Montana',
    country: 2,
  },
  {
    value: 100,
    label: 'Nebraska',
    country: 2,
  },
  {
    value: 101,
    label: 'Nevada',
    country: 2,
  },
  {
    value: 102,
    label: 'New Hampshire',
    country: 2,
  },
  {
    value: 103,
    label: 'New Jersey',
    country: 2,
  },
  {
    value: 104,
    label: 'New Mexico',
    country: 2,
  },
  {
    value: 105,
    label: 'New York',
    country: 2,
  },
  {
    value: 106,
    label: 'North Carolina',
    country: 2,
  },
  {
    value: 107,
    label: 'North Dakota',
    country: 2,
  },
  {
    value: 108,
    label: 'Ohio',
    country: 2,
  },
  {
    value: 109,
    label: 'Oklahoma',
    country: 2,
  },
  {
    value: 110,
    label: 'Oregon',
    country: 2,
  },
  {
    value: 111,
    label: 'Maryland',
    country: 2,
  },
  {
    value: 112,
    label: 'Massachusetts',
    country: 2,
  },
  {
    value: 113,
    label: 'Michigan',
    country: 2,
  },
  {
    value: 114,
    label: 'Minnesota',
    country: 2,
  },
  {
    value: 115,
    label: 'Mississippi',
    country: 2,
  },
  {
    value: 116,
    label: 'Missouri',
    country: 2,
  },
  {
    value: 117,
    label: 'Pennsylvania',
    country: 2,
  },
  {
    value: 118,
    label: 'Rhode Island',
    country: 2,
  },
  {
    value: 119,
    label: 'South Carolina',
    country: 2,
  },
  {
    value: 120,
    label: 'South Dakota',
    country: 2,
  },
  {
    value: 121,
    label: 'Tennessee',
    country: 2,
  },
  {
    value: 122,
    label: 'Texas',
    country: 2,
  },
  {
    value: 123,
    label: 'Utah',
    country: 2,
  },
  {
    value: 124,
    label: 'Vermont',
    country: 2,
  },
  {
    value: 125,
    label: 'Virginia',
    country: 2,
  },
  {
    value: 126,
    label: 'Washington',
    country: 2,
  },
  {
    value: 127,
    label: 'West Virginia',
    country: 2,
  },
  {
    value: 128,
    label: 'Wisconsin',
    country: 2,
  },
  {
    value: 129,
    label: 'Wyoming',
    country: 2,
  },
]

//Selects for create events
const priorities = [
  { value: 1, label: 'Alta' },
  { value: 2, label: 'Media' },
  { value: 3, label: 'Baja' },
]

const status = [
  { value: 4, label: 'Confirmado' },
  { value: 5, label: 'Cancelada' },
  { value: 6, label: 'Pendiente' },
  { value: 7, label: 'Reagendada' },
  { value: 8, label: 'Completada' },
]

const assistance = [
  { value: 9, label: 'En el hogar' },
  { value: 10, label: 'En el consultorio' },
  { value: 11, label: 'Llamada' },
  { value: 12, label: 'Telemedicina TytoCare' },
]

const preOptionsHome = [
  { value: '0', label: 'Ninguna' },
  { value: 'other', label: 'Otro...' },
]

const options = {
  appointmentType,
  relationshipOptions,
  bloodOptions,
  maritalOptions,
  genderOptions,
  sexOptions,
  measurementUnitOptions,
  country,
  state,
  city,
  priorities,
  status,
  assistance,
  specialtyOptions,
  roleList,
  entityType,
  preOptionsHome,
}

export default options
