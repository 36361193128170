import { deserialize } from 'class-transformer'
import { FormatSelect } from './FormatSelect'

export const dataTransformer = (data: any) => {
  let counter: number = 0
  let dataArray = data.map((item: any) => {
    let firstname: string = item?.middlename
      ? `${item?.firstname} ${item?.middlename}`
      : item?.firstname

    let surname: string = item?.secondsurname
      ? `${item?.surname} ${item?.secondsurname}`
      : item?.surname
    return {
      value: item?.id,
      label: `${firstname} ${surname}`,
      email: item?.email,
      usertype: item?.usertype,
    }
  })

  let processData = deserialize(FormatSelect, JSON.stringify(dataArray))

  return processData
}
