import { Expose, Transform, Type } from 'class-transformer'
import 'reflect-metadata'

class Event {
  @Expose({ name: 'beginAt' })
  @Transform(({ value }: any) => new Date(value))
  start: Date

  @Expose({ name: 'endAt' })
  @Transform(({ value }: any) => new Date(value))
  end: Date
}

export class Calendar {
  @Expose({ name: 'dateBeginAt' })
  @Transform(({ value }: any) => new Date(value))
  dateBeginAt: Date

  @Expose({ name: 'dateFinishAt' })
  @Transform(({ value }: any) => new Date(value))
  dateFinishAt: Date

  @Expose({ name: 'event' })
  @Type(() => Event)
  event: Event[]
}
