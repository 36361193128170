import InformationLaboral from '../../assets/svg/PatientItems/informacionLaboral.svg'
import InformationPersonal from '../../assets/svg/PatientItems/informacionPersonal.svg'
const SumaryTutorRegisterData = ({
  setModalOn,
  setChoice,
  registerUser,
  setIsLoading,
  formData,

  actionBotton,
  deleteAction,
}: any) => {
  const handleOKClick = () => {
    if (actionBotton !== 'delete') {
      registerUser()
    } else {
      deleteAction()
    }
    setChoice(true)
    setModalOn(false)
  }

  const handleCancelClick = () => {
    if (actionBotton !== 'delete') {
      setIsLoading(true)
    }
    setChoice(false)
    setModalOn(false)
  }

  return (
    <div className=" opacity-100 fixed inset-0 z-50">
      <div className="flex h-screen justify-center items-center ">
        <div className="flex-col justify-center rounded-md bg-white py-10 px-16 grid gap-5 border border-gray-200">
          {actionBotton !== 'delete' ?
          (<div className="flex flex-col text-lg text-zinc-600 max-w-xl max-h-[30rem] space-y-4 overflow-y-auto">
            <p className="font-bold text-2xl text-center">
              ¿Esta seguro de registrar este Responsables?
            </p>
            <div className="flex flex-col">
              <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
                <img src={InformationPersonal} className={`w-7`} alt="" />
                <h1 className="text-xl">{`Información personal`}</h1>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Primer nombre: </b>
                <p>{formData?.tutores[0]?.tutorFirstname}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Segundo nombre: </b>
                <p>{formData?.tutores[0]?.tutorMiddlemame}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Primer apellido: </b>
                <p>{formData?.tutores[0]?.tutorSurname}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Segundo apellido: </b>
                <p>{formData?.tutores[0]?.tutorSecondSurname}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Estado civil: </b>
                <p>{formData?.tutores[0]?.tutorMaritalStatus?.label}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Relación: </b>
                <p>{formData?.tutores[0]?.tutorMaritalStatus?.label}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Profesión: </b>
                <p>{formData?.tutores[0]?.tutorProfession}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Número Celular: </b>
                <p>{formData?.tutores[0]?.tutorPhone}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Correo electrónico: </b>
                <p>{formData?.tutores[0]?.tutorEmail}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Teléfono Fijo: </b>
                <p>{formData?.tutores[0]?.tutorOptionalPhone}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Extensión Teléfono Fijo: </b>
                <p>{formData?.tutores[0]?.tutorOptionalExt}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Dirección Linea 1: </b>
                <p>{formData?.tutores[0]?.tutorHomeAddressLineOne}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Dirección Linea 2: </b>
                <p>{formData?.tutores[0]?.tutorHomeAddressLineTwo}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Pueblo: </b>
                <p>{formData?.tutores[0]?.tutorHomeState?.label}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Código postal: </b>
                <p>{formData?.tutores[0]?.tutorZipCode}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">País: </b>
                <p>{formData?.tutores[0]?.tutorHomeCountry?.label}</p>
              </div>

              <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
                <img src={InformationLaboral} className={`w-7`} alt="" />
                <h1 className="text-xl">{`Información laboral`}</h1>
              </div>

              <div className="flex flex-row gap-1">
                <b className="font-bold">Lugar de trabajo: </b>
                <p>{formData?.tutores[0]?.workPlace}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Número Celular: </b>
                <p>{formData?.tutores[0]?.tutorLaborPhone}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Teléfono Fijo: </b>
                <p>{formData?.tutores[0]?.jopOptPhone}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Extensión Teléfono Fijo: </b>
                <p>{formData?.tutores[0]?.jopOptPhoneExt}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Dirección Laboral Linea 1: </b>
                <p>{formData?.tutores[0]?.tutorJobAddressLineOne}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Dirección Laboral Linea 2: </b>
                <p>{formData?.tutores[0]?.tutorJobAddressLineTwo}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Pueblo: </b>
                <p>{formData?.tutores[0]?.tutorJobState?.label}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Código postal: </b>
                <p>{formData?.tutores[0]?.tutorJobZipCode}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">País: </b>
                <p>{formData?.tutores[0]?.tutorJobCountry?.label}</p>
              </div>
            </div>
          </div>) : (
            <div className="flex flex-col text-lg text-zinc-600 max-w-xl max-h-[30rem] space-y-4 overflow-y-auto">
              <p className="font-bold text-2xl text-center">
                ¿Esta seguro de eliminar esta Responsables?
              </p>
            </div>
          )}
          <div className="flex flex-row justify-center">
            <button
              onClick={handleOKClick}
              className=" rounded px-4 py-2 text-white bg-green-400 "
            >
              Si
            </button>
            <button
              onClick={handleCancelClick}
              className="rounded px-4 py-2 ml-4 text-white bg-blue-500 "
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SumaryTutorRegisterData
