/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import { useState, useEffect } from 'react'
import { dataTransformer } from '../utils/DataTransformer/listUserType/dataTransformer'
import api from '../services/Api'
import RefreshToken from '../services/RefreshToken'

const useGetListPatient = () => {
  const [data, setData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchdata = async () => {
      let user: any = localStorage.getItem('user')
      setIsLoading(true)

      let arraydata: any[] = []

      let relationClinicForHome: any = async () => {
        return await api.instanceEntities.post(
          `/nursing_home_clinic/clinic-for-home/`,
          { clinicId: `${localStorage.getItem('clinicId')}` },
          {
            headers: {
              Authorization: `${JSON.parse(user).TokenType} ${
                JSON.parse(user).IdToken
              }`,
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          },
        )
      }

      if (
        localStorage.getItem('nursing_home_id') === 'null' &&
        localStorage.getItem('clinicId') !== 'null'
      ) {
        let values = await relationClinicForHome()
        values = values.data.data[0]

        values.map((keys: any) => {
          arraydata.push(keys.nursingHomeId)
        })
      } else if (localStorage.getItem('nursing_home_id') !== 'null') {
        arraydata = [localStorage.getItem('nursing_home_id')]
      } else {
        arraydata = []
      }

      await api.instanceUsers
        .post(
          `/user/getListPatient/`,
          // { nursingHomeId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
          { nursingHomeId: arraydata },
          {
            headers: {
              Authorization: `${JSON.parse(user).TokenType} ${
                JSON.parse(user).IdToken
              }`,
              'Content-Type': 'application/json',
              USER_ENTITY_ID: `${localStorage.getItem('role')}`,
            },
            withCredentials: true,
          },
        )
        .then((response: any) => {
          setData(dataTransformer(response.data.data))
        })
        .catch((err) => {
          setData(null)
          //console.log(err.response);
          //console.log(err?.response?.status);
          if ([401].includes(err?.response?.status) && data === null) {
            fetchdata()
          }
        })

      setIsLoading(false)
    }
    if (data === null) {
      fetchdata()
    }
  }, [data])
  return { data, isLoading }
}

export default useGetListPatient
