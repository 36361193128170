/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react'
import * as DTOClinic from '../utils/DataTransformer/limitList/clinics/dataTransformer'
import * as DTONursingHome from '../utils/DataTransformer/limitList/homes/dataTransformer'
import api from '../services/Api'

const useGetCurrentUSer = (string: any) => {
  const [data, setData] = useState<any>(null)
  const [dataClinic, setDataClinic] = useState<any>(null)
  const [dataHome, setDataHome] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [clinicIDList, setClinicIDList] = useState<any>(null)
  const [homeIDList, setHomeIDList] = useState<any>(null)
  const [listSelectClinic, setListSelectClinic] = useState<any>(null)
  const [listSelectNursingHome, setListSelectNursingHome] = useState<any>(null)

  useEffect(() => {
    if (string === 'get') {
      setIsLoading(true)
      const fetchdata = async () => {
        let user: any = localStorage.getItem('user')
        let clinicIds: any[] = []
        let homeIds: any[] = []

        await api.instanceUsers
          .get(`/user/basic-info/`, {
            headers: {
              Authorization: `${JSON.parse(user).TokenType} ${
                JSON.parse(user).IdToken
              }`,
              'Content-Type': 'application/json',
            },
          })
          .then(async (response: any) => {
            localStorage.setItem('userId', response.data.data[0].id)
            localStorage.setItem(
              'clinicId',
              response.data.data[0].roles[0].clinicId,
            )
            localStorage.setItem(
              'nursing_home_id',
              JSON.stringify(response.data.data[0].roles[0].nursingHomeId),
            )
            localStorage.setItem(
              'role',
              JSON.stringify(response.data.data[0].roles[0].id),
              // JSON.stringify(response.data.data[0].roles[0].roleId),
            )

            setData(response.data.data[0])

            response.data.data[0].roles.map((item: any) => {
              if (item.clinicId !== null) {
                if (!clinicIds.includes(item.clinicId)) {
                  clinicIds.push(item.clinicId)
                }
              }
              if (item.nursingHomeId !== null) {
                if (!homeIds.includes(item.nursingHomeId)) {
                  homeIds.push(item.nursingHomeId)
                }
              }
            })
            //console.log(`homeIds:`,homeIds);

            if (homeIds?.length > 0) {
              await api.instanceEntities
                .post(
                  '/nursing_home/limitList',
                  {
                    nursingHomeIds: homeIds,
                  },
                  {
                    headers: {
                      Authorization: `${JSON.parse(user).TokenType} ${
                        JSON.parse(user).IdToken
                      }`,
                      'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                  },
                )
                .then((response: any) => {
                  setListSelectNursingHome(
                    DTONursingHome.dataTransformer(response.data.data[0]),
                  )
                })
            }
            if (clinicIds?.length > 0) {
              await api.instanceEntities
                .post(
                  '/clinic/limitList',
                  {
                    clinicIds: clinicIds,
                  },
                  {
                    headers: {
                      Authorization: `${JSON.parse(user).TokenType} ${
                        JSON.parse(user).IdToken
                      }`,
                      'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                  },
                )
                .then((response: any) => {
                  setListSelectClinic(
                    DTOClinic.dataTransformer(response.data.data[0]),
                  )
                })
            }

            setClinicIDList(clinicIds)
            setHomeIDList(homeIds)

            if (
              response?.data?.data[0]?.roles[0]?.clinicId !== null &&
              response?.data?.data[0]?.roles[0]?.clinicId !== 0
            ) {
              await api.instanceEntities
                .post(
                  `/clinic/get-profile-clinic-by-id`,
                  {
                    clinicId: response?.data?.data[0]?.roles[0]?.clinicId,
                  },
                  {
                    headers: {
                      Authorization: `${JSON.parse(user).TokenType} ${
                        JSON.parse(user).IdToken
                      }`,
                      'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                  },
                )
                .then((response: any) => {
                  setDataClinic(response.data.data[0][0])
                })
            }
            //console.log(`response?.data?.data[0]?.roles[0]?.nursingHomeId`,response?.data?.data[0]?.roles[0]?.nursingHomeId);

            if (
              response?.data?.data[0]?.roles[0]?.nursingHomeId !== null &&
              response?.data?.data[0]?.roles[0]?.nursingHomeId !== 0
            ) {
              //console.log('entre al if ====> ');

              await api.instanceEntities
                .post(
                  `/nursing_home/get-profile-home-by-id`,
                  {
                    nursingHomeId:
                      response?.data?.data[0]?.roles[0]?.nursingHomeId,
                  },
                  {
                    headers: {
                      Authorization: `${JSON.parse(user).TokenType} ${
                        JSON.parse(user).IdToken
                      }`,
                      'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                  },
                )
                .then((response: any) => {
                  //console.log('setDataHome: ',response.data.data);
                  setDataHome(response.data.data)
                })
            }

            setIsLoading(false)
          })
          .catch((err) => {
            console.log(err.response);
            if ([401].includes(err.response.status)) {
              setData(null)
            }
            // console.log(err.response.status);
            if ([401].includes(err?.response?.status) && data === null) {
              fetchdata()
            }
            setIsLoading(false)
          })
        // console.log(response.data.data[0]);
      }

      fetchdata()
    }

    return () => {
      const res = api.endThread.cancel()
    }
  }, [])
  // console.log(`\nlistSelectClinic:\n`, listSelectClinic)
  // console.log(`\nlistSelectNursingHome:\n`, listSelectNursingHome)

  return {
    data,
    dataClinic,
    dataHome,
    isLoading,
    clinicIDList,
    homeIDList,
    listSelectClinic,
    listSelectNursingHome,
  }
}

export default useGetCurrentUSer
