import { TrashIcon } from '@heroicons/react/outline'
import PencilAltIcon from '@heroicons/react/outline/PencilAltIcon'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { setUpdatePatientVaccineForm } from '../../actions'
import Vaccines from '../../assets/svg/PatientItems/Vaccines.svg'
import UpdatePatientVaccine from '../../services/UpdatePatientVaccine'
import SumaryVaccineRegister from '../Modals/SumaryVaccineRegister'

const VaccinesPatient = (props: any) => {
  const {
    vaccineName,
    dosage,
    doseDate,
    frequency,
    pharmaceuticalCompany,
    patient,
    id,
  } = props

  const navigate = useNavigate()

  const [actionBotton, setActionBotton] = useState<string>('')
  const [modalOn, setModalOn] = useState<any>(false)
  const [formData, setFormData] = useState<any>(null)
  const [choice, setChoice] = useState<any>(false)
  //console.log(`props`, props)

  const trashSubmit = async () => {
    setActionBotton('delete')
    setModalOn((prev: any) => {
      //console.log(`prev`, prev)
      return !prev
    })
  }

  const deleteAction = async () => {
    navigate(`/patientprofile/${props?.patient}/`)

    let data: any = {
      id: props?.id,
    }
    const dataUpdate: any = await UpdatePatientVaccine.DeletePatientVaccine(
      data,
    )
    console.log(`dataUpdate: `, dataUpdate);

    if (dataUpdate?.code >= 200 && dataUpdate?.code < 300) {
      console.log(`entre if (dataUpdate)`)
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Confirmación',
        text: `¡Ha sido eliminado con éxito!`,
        icon: 'success',
        // icon: "error",
        //cancelButtonText: 'Volver al formulario',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
        confirmButtonColor: 'rgb(14 165 233)',
        cancelButtonColor: 'rgb(14 165 233)',
      }).then((res) => {
        // console.log(`res`, res)
        // console.log(`dataUpdate?.data`,dataUpdate?.data);
        // console.log(`props?.patient`,props?.patient);
        navigate(`/patientprofile/${props?.patient}`)
      })
    } else {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        confirmButtonText: 'Aceptar',
      }).then((res) => {
        console.log(`res`, res)
        navigate(`/patientprofile/${props?.patient}`)
      })
    }
    navigate(`/patients`)
    navigate(`/patientprofile/${props?.patient}`)
  }

  return (
    <>
      {modalOn && (
        <SumaryVaccineRegister
          setChoice={setChoice}
          setModalOn={setModalOn}
          actionBotton={actionBotton}
          deleteAction={deleteAction}
        />
      )}
      <div className="w-full text-left md:px-20 space-y-4 text-lg py-2 border-y-2 ">
        <div className="flex flex-row items-center justify-start gap-1.5 border-b-2">
          <img src={Vaccines} className="w-9 h-9" alt="" />

          <h1 className="text-xl font-bold">
            <p className="text-black font-bold first-line:capitalize  ">
              {vaccineName ? vaccineName : 'N/A'}
            </p>
            <p></p>
          </h1>
        </div>
        <div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5  ">
          <div className="w-fit md:ml-24 min-w-sm	 ">
            <div className="md:gap-1.5 md:flex md:flex-row">
              <p className="text-black font-bold">Dosis:</p>
              <p>{dosage ? dosage : 'N/A'}</p>
            </div>
            <div className="md:gap-1.5 md:flex md:flex-row">
              <p className="text-black font-bold">Fecha Dosis:</p>
              <p>{doseDate ? doseDate : 'N/A'}</p>
            </div>
            <div className="md:gap-1.5 md:flex md:flex-row">
              <p className="text-black font-bold">Frecuencia:</p>
              <p>{frequency ? frequency : 'N/A'}</p>
            </div>
            <div className="md:gap-1.5 md:flex md:flex-row">
              <p className="text-black font-bold">Farmaceutica:</p>
              <p>{pharmaceuticalCompany ? pharmaceuticalCompany : 'N/A'}</p>
            </div>
          </div>
        </div>

        <div className="flex flex-row ">
          <div className={`lg:flex-1 flex flex-row`} />
          <div
            className="w-full sm:w-fit flex items-center gap-1 bg-[#f61e1e]  p-2 text-white rounded-lg cursor-pointer m-2"
            onClick={() => trashSubmit()}
          >
            <TrashIcon strokeWidth={1.3} className="w-4 border-b-2" />
            Eliminar
          </div>
          <div
            className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
            onClick={() => {
              navigate('/vaccine/update')
              props.setUpdatePatientVaccineForm({
                vaccineName,
                dosage,
                doseDate,
                frequency,
                pharmaceuticalCompany,
                patient,
                id,
              })
            }}
          >
            <PencilAltIcon className="w-8 h-8 " />
            <div>Editar datos</div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapDispatchToProps = {
  setUpdatePatientVaccineForm,
}
export default connect(null, mapDispatchToProps)(VaccinesPatient)
