/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BanIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  UsersIcon,
} from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import Select from 'react-select'
import { customStyles } from './customStyleNewLabel'
import { ROLEID } from '../utils/enums/Roles'
import CustomValueContainer from './ReactSelectComponents/CustomValueContainer'
import useListUserType from '../hooks/useListUserType'
import { addUserEntity } from '../services/addUserEntity'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

const LinkUsers = ({ ...props }: any) => {
  const [showData, setShowData] = useState(true)
  const [doctorDataList, setDoctorDataList] = useState()
  const [secretaryDataList, setSecretaryDataList] = useState()
  const [nurseDataList, setNurseDataList] = useState()
  const [isLoadin, setIsloadin] = useState(true)

  const { doctorList, secretaryList, nurseList, isLoading } = useListUserType()
  const navigate = useNavigate()

  let result: any
  const entitySelect: any = [
    {
      value:
        props?.state?.updateEntityForm?.nursing_home_id ||
        props?.state?.updateEntityForm?.clinic_id,
      label:
        props?.state?.updateEntityForm?.nursing_home_comercialName ||
        props?.state?.updateEntityForm?.clinic_comercialName,
      type: props?.state?.updateEntityForm?.type,
    },
  ]
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
    control,
  } = useForm({ mode: 'onChange' })

  const onSubmit = async (data: any) => {
    setIsloadin(false)
    data.entitySelected = entitySelect[0]
    await addUserEntity(data)
      .then((res: any) => {
        Swal.fire({
          customClass: {
            confirmButton: 'bg-red-500 text-white',
          },
          icon: 'success',
          title: 'Confirmación',
          text: 'La vinculación se realizó  con éxito!',
          // icon: "error",
          confirmButtonText: 'Aceptar',
        })
        navigate(`/entities/manage/users`)
        setIsloadin(true)
      })
      .catch((err: any) => {
        Swal.fire({
          customClass: {
            confirmButton: 'bg-red-500 text-white',
          },
          title: 'Error Interno!',
          text: 'Vuelva a intertarlo nuevamente',
          // icon: "error",
          confirmButtonText: 'Aceptar',
        })
        setIsloadin(true)
      })
  }

  return (
    <div className="w-full h-full ">
      <div className="flex flex-col gap-5">
        <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
          {/* <img src={Usuario} className={`w-20`} alt="" /> */}
          <UsersIcon color="#294fa9" className="h-20 w-20" />
          <h1 className="text-center sm:text-4xl font-extrabold text-xl text-[#96c952]">
            Vincular usuarios
          </h1>
        </div>
        {/*React hook form maneja un select mas dinamico y personalizable este es un ejemplo*/}
        <div className="flex flex-col gap-2">
          <form
            className={`flex flex-col gap-2 px-4`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <p>Entidad actual</p>
            <div className="w-full h-full">
              <Controller
                control={control}
                name="entitySelected"
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <Select
                    ref={ref}
                    className="w-full"
                    defaultValue={entitySelect[0]}
                    styles={customStyles}
                    onBlur={onBlur}
                    isDisabled
                    onChange={onChange}
                    options={entitySelect}
                    value={value}
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                  />
                )}
              />
            </div>
            <p>Seleccione el usuario a vincular</p>
            <div className="w-full h-full">
              <Controller
                control={control}
                name="user_selected"
                rules={{
                  required: {
                    value: true,
                    message: 'Seleccione el usuario que vinculara es obligatorio',
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <Select
                    ref={ref}
                    id="user_selected"
                    name="user_selected"
                    className="w-full"
                    placeholder={
                      isLoading ? `Cargando usuarios...` : `Seleccione usuario*`
                    }
                    styles={customStyles}
                    onBlur={onBlur}
                    isClearable
                    isDisabled={isLoading}
                    onChange={onChange}
                    options={doctorList}
                    value={value}
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                  />
                )}
              />
              {errors.user_selected && (
                <p className="text-sm text-red-600 mt-2">
                  {errors.user_selected.message}
                </p>
              )}
            </div>
            <div className="items-center justify-center place-items-center pt-4 flex flex-row gap-2">
              {isLoadin ? (
                <div id="next" className="has-tooltip w-fit h-fit">
                  <input
                    type={'button'}
                    value="Volver"
                    className="w-fit mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                    onClick={() => {
                      navigate(`/entities/manage/users`)
                    }}
                  />
                  {!isValid ? (
                    <span className="tooltip rounded shadow-lg p-1 bg-gray-800 text-white -mt-12">
                      Para continuar, debe completar los campos requeridos
                      señalados con *
                    </span>
                  ) : null}
                  <input
                    type="submit"
                    disabled={!isValid}
                    value="Vincular"
                    className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  />
                </div>
              ) : (
                <input
                  type="submit"
                  disabled
                  value="Cargando..."
                  className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                />
              )}
            </div>

            {/* <p>Tipo el tipo de usuario que va a añadir a la entidad</p>
            <div className="flex flex-row gap-2 w-full h-full items-center">
              <Controller
                control={control}
                name="user_type"
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <Select
                    ref={ref}
                    id="user_type"
                    name="user_type"
                    className="w-full"
                    placeholder="Tipo de usuario.."
                    styles={customStyles}
                    onBlur={onBlur}
                    isClearable
                    isDisabled={isLoading}
                    onChange={onChange}
                    options={[
                      { value: ROLEID.DOCTOR, label: 'Doctor/a' },
                      { value: ROLEID.SECRETARY, label: 'Secretario/a' },
                      { value: ROLEID.NURSE, label: 'Enfermero/a' },
                    ]}
                    value={value}
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                  />
                )}
              />
              <input
                type="submit"
                value="Mostrar Lista"
                className="md:w-auto h-10 w-full rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
                disabled={!(watch('user_type')?.label.length > 0)}
              />
            </div> */}
            {/*Habra un evento cuando se seleccione el tipo de usuario dira en variable si es doctor secretario enfermero*/}
            {/* <form className={`space-y-2 ${showData ? `block` : `hidden`}`}> */}
          </form>

          <div className="flex flex-col gap-2 px-4">
            <p>
              {watch('user_type')?.label.length > 0
                ? `Lista de ${watch('user_type')?.label} Disponibles`
                : ''}
            </p>
            {watch('user_type')?.label.length > 0 && (
              <>
                {/* <div className="w-full h-full">
								<label
									className="h-10 rounded-md border-[1px] px-1 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
									htmlFor="user">
									<Controller
										control={control}
										name="user"
										rules={{
											required: {
												value: false,
												message: 'Tipo de usuario es requerido',
											},
										}}
										render={({
											field: { onChange, onBlur, value, ref },
										}: any) => (
											<Select
												ref={ref}
												className="w-full"
												placeholder="Tipo de usuario.."
												styles={customStyles}
												onBlur={onBlur}
												onChange={onChange}
												options={[
													{ value: 1, label: 'nombre 1' },
													{ value: 2, label: 'Nombre 2' },
													{ value: 3, label: 'Nombre 3' },
												]}
												value={value}
											/>
										)}
									/>
								</label>
								{errors.user && (
									<p className="text-sm text-red-600 mt-2">
										{errors.user.message}
									</p>
								)}
							</div> */}
                <div>
                  <div className="grid w-full gap-3 rounded-md border-[1px] px-3 py-2 shadow-sm bg-white">
                    <label className="flex w-full flex-row gap-2">
                      <input type="checkbox" name="uno" id="uno" />
                      <p className="w-full">Nombre apellido</p>
                      <BanIcon className="h-6 w-6" />
                    </label>
                    <label className="flex w-full flex-row gap-2">
                      <input type="checkbox" name="dos" id="dos" />
                      <p className="w-full">Nombre apellido</p>
                      <CheckCircleIcon className="h-6 w-6" />
                    </label>
                    <label className="flex w-full flex-row gap-2">
                      <input type="checkbox" name="tres" id="tres" />
                      <p className="w-full">Nombre apellido</p>
                      <ExclamationCircleIcon className="h-6 w-6" />
                    </label>
                    <label className="flex w-full flex-row gap-2">
                      <input type="checkbox" name="cuatro" id="cuatro" />
                      <p className="w-full">Nombre apellido</p>
                      <ExclamationCircleIcon className="h-6 w-6" />
                    </label>
                  </div>
                  <div className="my-5 w-full text-center">
                    <input
                      type="submit"
                      defaultValue="Guardar Cambios"
                      className="center mb-5 rounded-lg border-[1px] px-4 py-2 font-medium shadow-md duration-300 hover:cursor-pointer"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    state: {
      updateEntityForm: state.updateEntityForm,
    },
  }
}
const mapDispatchToProps = {}

// export default Sidebar;
export default connect(mapStateToProps, null)(LinkUsers)
