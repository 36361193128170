import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setUpdatePatientDoctorForm } from '../../actions'
import { connect } from 'react-redux'
import Physicians from '../../assets/svg/PatientItems/Physicians.svg'
import SumaryDoctorRegister from '../Modals/SumaryDoctorRegister'
import Swal from 'sweetalert2'
import UpdatePatientDoctor from '../../services/updatePatientDoctor'


const PhysicianPatient = (props: any) => {
  const {
    firstname,
    surname,
    specialty,
    jobAddress,
    zipCode,
    email,
    clinicalMedicalRecord,
    clinicalName,
    npi,
    JobAddressAlt,
    JobState,
    JobCountry,
    phone,
    id,
    patient,
    clinicPhone,
    doctorLocalPhone,
  } = props
  const navigate = useNavigate()

  const [actionBotton, setActionBotton] = useState<string>('')
  const [modalOn, setModalOn] = useState<any>(false)
  const [formData, setFormData] = useState<any>(null)
  const [choice, setChoice] = useState<any>(false)
  //console.log(`props`, props)

  const trashSubmit = async () => {
    setActionBotton('delete')
    setModalOn((prev: any) => {
      //console.log(`prev`, prev)
      return !prev
    })
  }

  const deleteAction = async () => {
    navigate(`/patientprofile/${props?.patient}/`)
    let data: any = {
      id: props?.id,
    }
    const dataUpdate: any = await UpdatePatientDoctor.DeletePatientDoctor(data)
    console.log(`dataupdate: `,dataUpdate);
    
    if (dataUpdate?.code >= 200 && dataUpdate?.code < 300) {
      console.log(`entre if (dataUpdate)`)
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Confirmación',
        text: `¡Ha sido eliminado con éxito!`,
        icon: 'success',
        // icon: "error",
        //cancelButtonText: 'Volver al formulario',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
        confirmButtonColor: 'rgb(14 165 233)',
        cancelButtonColor: 'rgb(14 165 233)',
      }).then((res) => {
        // console.log(`res`, res)
        // console.log(`dataUpdate?.data`,dataUpdate?.data);
        // console.log(`props?.patient`,props?.patient);
        navigate(`/patientprofile/${props?.patient}`)
      })
    } else {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        confirmButtonText: 'Aceptar',
      }).then((res) => {
        console.log(`res`, res)
        navigate(`/patientprofile/${props?.patient}`)
      })
    }
    navigate(`/patients`)
    navigate(`/patientprofile/${props?.patient}`)
  }

  return (
    <>
      {modalOn && (
        <SumaryDoctorRegister
          formData={formData}
          setChoice={setChoice}
          setModalOn={setModalOn}
          actionBotton={actionBotton}
          deleteAction={deleteAction}
        />
      )}
      <div className="w-full text-left md:px-20 space-y-4 text-lg pb-4 border-y-2 ">
        <div className="md:flex md:flex-row md:items-center md:justify-start border-b-2 ">
          <img src={Physicians} className={`w-12`} alt="" />
          <h1 className="text-2xl font-bold">
            <p className="text-black font-bold first-line:capitalize">
              {`${surname}` ? `${firstname} ${surname}` : `${firstname}`}
            </p>
          </h1>
        </div>
        <div className="w-full md:text-right space-y-4 text-lg m-0">
          {/* <p className="text-black font-bold ">
					{`Record Clínico: ${clinicalMedicalRecord}`}
				</p> */}
          <p>
            <b className="text-black font-bold ">Record Clínico:</b>
            {clinicalMedicalRecord ? ` ${clinicalMedicalRecord}` : 'N/A'}
          </p>
        </div>
        <div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5  ">
          <div className="w-fit md:ml-24 min-w-sm	 ">
            {/* <div className="md:gap-1.5 md:flex md:flex-row ">
						<p className="text-black font-bold">Record Clínico: </p>
						<p>
							{clinicalMedicalRecord}
						</p>
					</div> */}
            {/* <div className="md:gap-1.5 md:flex md:flex-row ">
						<p className="text-black font-bold">Nombre: </p>
						<p>
							{`${surname}` ? `${firstname} ${surname}` : `${firstname}`}
						</p>
					</div> */}
            <div className="md:gap-1.5 md:flex md:flex-row">
              <p className="text-black font-bold">Especialidad: </p>
              <p>{specialty ? specialty : 'N/A'}</p>
            </div>
            <div className="md:gap-1.5 md:flex md:flex-row">
              <p className="text-black font-bold">Número Celular: </p>
              <p>{phone?.number ? phone?.number : 'N/A'}</p>
            </div>
            <div className="md:gap-1.5 md:flex md:flex-row whitespace-normal ">
              <p className="text-black font-bold">Correo electrónico: </p>
              <p>{email ? email : 'N/A'}</p>
            </div>
            <div className="md:gap-1.5 md:flex md:flex-row">
              <p className="text-black font-bold">Clínica: </p>
              <p>{clinicalName ? clinicalName : 'N/A'}</p>
            </div>
            <div className="md:gap-1.5 md:flex md:flex-row">
              <p className="text-black font-bold">Teléfono Fijo de Clínica: </p>
              <p>
                {`${
                  doctorLocalPhone?.number ? doctorLocalPhone?.number : 'N/A'
                } `}
                <b className="text-black font-bold ">Ext:</b>
                {doctorLocalPhone?.extension
                  ? ` ${doctorLocalPhone.extension}`
                  : 'N/A'}
              </p>
            </div>
            <div className="md:gap-1.5 md:flex md:flex-row">
              <p className="text-black font-bold">Dirección 1: </p>
              <p>{`${jobAddress ? jobAddress : 'N/A'}`}</p>
            </div>
            <div className="md:gap-1.5 md:flex md:flex-row">
              <p className="text-black font-bold">Dirección 2: </p>
              <p>{`${JobAddressAlt ? JobAddressAlt : 'N/A'}`}</p>
            </div>
            <div className="md:gap-1.5 md:flex md:flex-row">
              <p className="text-black font-bold">Municipio/Estado: </p>
              <p>{`${JobState ? JobState : 'N/A'}`}</p>
            </div>
            <div className="md:gap-1.5 md:flex md:flex-row">
              <p className="text-black font-bold">Código postal: </p>
              <p>{zipCode ? zipCode : 'N/A'}</p>
            </div>
            <div className="md:gap-1.5 md:flex md:flex-row">
              <p className="text-black font-bold">País: </p>
              <p>{`${JobCountry ? JobCountry : 'N/A'}`}</p>
            </div>
          </div>
        </div>
        <div className="lg:col-span-2 xl:col-span-3 my-5">
          <div className="flex flex-row gap-2">
            <div className={`sm:flex-1 flex flex-row`} />
            <div
              className="w-full sm:w-fit flex items-center gap-1 bg-[#f61e1e]  p-2 text-white rounded-lg cursor-pointer my-2"
              onClick={() => trashSubmit()}
            >
              <TrashIcon strokeWidth={1.3} className="w-4 border-b-2" />
              Eliminar
            </div>
            <div
              className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
              onClick={() => {
                navigate('/doctorupdate')
                props.setUpdatePatientDoctorForm({
                  firstname,
                  surname,
                  specialty,
                  jobAddress,
                  zipCode,
                  email,
                  clinicalMedicalRecord,
                  clinicalName,
                  npi,
                  JobAddressAlt,
                  JobState,
                  JobCountry,
                  phone,
                  id,
                  patient,
                  clinicPhone,
                  doctorLocalPhone,
                })
              }}
            >
              <PencilAltIcon className="w-8 h-8 " />
              <div>Editar datos</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapDispatchToProps = {
  setUpdatePatientDoctorForm,
}

export default connect(null, mapDispatchToProps)(PhysicianPatient)
