/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select, { components, OptionProps } from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import { customStyles } from '../customStyleNewLabel'
import useGetEntityRelations from '../../hooks/useGetEntityRelations'
import UnLinkConfirmation from '../Modals/UnLinkConfirmation'
import { rolesAccess } from '../../utils/Interfaces/accessRoles'
import logicalRolSidebar from '../../utils/MenuAccess/logicalRolSidebar'
import Entities from '../../assets/svg/entidades.svg'
import { PencilAltIcon } from '@heroicons/react/outline'

const ManageEntity = ({ ...props }: any) => {
  const [showModal, setShowModal] = useState<any>(false)
  const [entityListdata, setEntityListdata] = useState<any>()
  const [user, setUser] = useState<any>(null)
  const navigate = useNavigate()
  const { control } = useForm({
    mode: 'onChange',
  })

  const { entityList } = useGetEntityRelations({
    nursingHomeId: props?.state?.updateEntityForm?.nursing_home_id,
    clinicId: props?.state?.updateEntityForm?.clinic_id,
  })
  // const { dataClinics } = ListClinicFromHome(1);

  useEffect(() => {
    setEntityListdata(entityList)
  }, [entityList])

  useEffect(() => {
    if (user === null) {
      setTimeout(() => {
        setUser(props?.state?.currentUser)
      }, 500)
    }
  })

  let access: rolesAccess = logicalRolSidebar(user)

  return (
    <div className="flex flex-col w-full">
      <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
        <img src={Entities} className={`w-20`} alt="" />
        <div className="">
          <h1 className="my-5 justify-start md:text-4xl text-2xl  font-semibold text-[#96c952]">
            {`Gestion de ${
              props?.state?.updateEntityForm?.clinic_comercialName ||
              props?.state?.updateEntityForm?.nursing_home_comercialName
            }`}
          </h1>
        </div>
      </div>
      <div className="flex flex-col gap-5 p-3 sm:p-7">
        <div className="w-full flex flex-col gap-5">
          <Controller
            control={control}
            name="patientSelect"
            rules={{
              required: {
                value: false,
                message: 'Nursing Home es requerida',
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }: any) => {
              return (
                <Select
                  className="w-full"
                  placeholder={`Buscar uno de las entidades asoiadas a su entidad`}
                  ref={ref}
                  isClearable
                  backspaceRemovesValue
                  styles={customStyles}
                  onBlur={onBlur}
                  onChange={onChange}
                  options={entityListdata}
                  value={value}
                  isSearchable
                />
              )
            }}
          />
          {access?.linkEntity && (
            <div className="w-full text-center">
              <button
                className={`w-fit px-4 py-2 border-[1px] rounded-xl bg-[#F6A01E] shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 duration-300`}
                onClick={() => {
                  navigate('/entity/create-relation')
                }}
              >
                Vincular nuevas entidades
              </button>
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          {entityListdata?.map((item: any) => {
            return (
              <div className="flex flex-col gap-3 rounded-md border px-5 py-4 shadow-lg bg-white">
                <div className="flex flex-col">
                  <div className="flex flex-row gap-x-1">
                    <b>Tipo:</b>
                    <p>{item?.cliniclegalname ? 'Clinica' : 'Nursing Home'}</p>
                  </div>
                  <div className="flex flex-row gap-x-1">
                    <b>Nombre Legal:</b>
                    <p>{item?.cliniclegalname || item?.nhlegalname}</p>
                  </div>
                  <div className="flex flex-row gap-x-1">
                    <b>Dirección:</b>
                    <p>{`${item?.firstLine}${
                      item?.secondLine ? ` ${item?.secondLine}` : ''
                    }`}</p>
                  </div>
                  <div className="flex flex-row gap-x-1">
                    <b>Pueblo:</b>
                    <p>{item?.state ? item?.state : 'N/A'}</p>
                  </div>
                </div>
                <div className="flex flex-row-reverse gap-3">
                  {/* <button
                    className="cursor-pointer rounded-xl border px-4 py-2 hover:bg-slate-400"
                    onClick={() => {
                      navigate('/update/entity')
                    }}
                  >
                    Editar
                  </button> */}
                  <div
                    className="h-12 flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer"
                    onClick={() => {
                      navigate('/update/entity')
                    }}
                  >
                    <PencilAltIcon className="w-8 h-8 " />
                    <div>Editar datos</div>
                  </div>
                  <button
                    // className="cursor-pointer rounded-xl border px-4 py-2 hover:bg-slate-400"
                    className="px-4 py-2 rounded-xl border-[1px] font-bold bg-[#f6501e] shadow-sm hover:cursor-pointer hover:bg-red-400 text-white hover:border-red-700 duration-300"
                    onClick={() => {
                      setShowModal(true)
                    }}
                  >
                    Desvincular
                  </button>
                </div>
              </div>
            )
          })}
        </div>
        <div className="w-full text-center">
          <button
            className={`w-fit px-4 py-2 rounded-xl border-[1px] font-bold bg-[#F6A01E] shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 duration-300`}
            onClick={() => {
              navigate('/entities/profile')
            }}
          >
            Volver
          </button>
        </div>
      </div>
      {showModal && <UnLinkConfirmation setShowModal={setShowModal} />}
    </div>
  )
}
const mapStateToProps = (state: any) => {
  return {
    state: {
      currentUser: state.currentUser,
      updateEntityForm: state.updateEntityForm,
    },
  }
}

const mapDispatchToProps = {}

// export default Sidebar;
export default connect(mapStateToProps, null)(ManageEntity)
