/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { FilterDataACS } from '../utils/listACS/FilterDataACS'
import api from '../services/Api'

const useGetAllergyConditionSurgery = () => {
  const [listData, setListData] = useState<any>(null)
  const [error, setError] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchdata = async () => {
      let user: any = localStorage.getItem('user')
      setIsLoading(true)

      await api.instanceUsers
        .get(`/conditionAllergyMedicine/get-condition-allergy/`, {
          headers: {
            Authorization: `${JSON.parse(user).TokenType} ${
              JSON.parse(user).IdToken
            }`,
            'Content-Type': 'application/json',
            USER_ENTITY_ID: `${localStorage.getItem('role')}`,
          },
          withCredentials: true,
        })
        .then((response) => {
          setListData(FilterDataACS(response.data.data[0].conditionAllergy))
        })
        .catch((err) => {
          setListData(null)
          //console.log(err.response);
          //console.log(err?.response?.status);
          if ([401].includes(err?.response?.status) && listData === null) {
            fetchdata()
          }
        })

      setIsLoading(false)
    }

    fetchdata()
  }, [])
  return { listData, error, isLoading }
}

export default useGetAllergyConditionSurgery

/*
 */
