import {
  IdentificationIcon,
  PhoneIcon,
  CameraIcon,
} from '@heroicons/react/outline'
import React from 'react'
import InformationPersonal from '../../assets/svg/PatientItems/informacionPersonal.svg'
import { clearSpace, onlyNumbers, onlyPhone } from '../../utils/regexExpresion'
import IconoContacto from '../../assets/svg/iconoContacto.svg'
import UserIcon from '../../assets/svg/UserSVGBlue.svg'
import { ROLEID } from '../../utils/enums/Roles'

const FormUser = ({
  optionsRole,
  Controller,
  DatePicker,
  CalendarIcon,
  Select,
  customStyles,
  options,
  NumberFormat,
  emailValidation,
  npiValidation,
  Switch,
  userTypeSelected,
  register,
  handleSubmit,
  errors,
  control,
  isRequiredNPI,
  onlySpecialty,
  withoutInstitution,
  onSubmit,
  CustomValueContainer,
  watch,
  onlyClinic,
  onlyHome,
  mode,
  homeList,
  clinicList,
  loading,
  photoURL,
  setPhotoURL,
  currentHome,
}: any) => {
  let isDisabled = mode === 'update' ? true : false
  // console.log(`localStorage: `, localStorage)

  return (
    <>
      <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
        <img src={IconoContacto} className={`w-11`} alt="" />
        <h1 className="text-xl">{`Información personal`}</h1>
      </div>
      <div className="w-11/12 lg:w-64 h-full">
        <Controller
          control={control}
          name="user_type"
          rules={{
            required: {
              value: true,
              message: 'Tipo de usuario es requerido',
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => {
            //console.log(`value Form: `,value);

            return (
              <Select
                id="user_type"
                name="user_type"
                ref={ref}
                components={{
                  ValueContainer: CustomValueContainer,
                }}
                className="w-full"
                placeholder="Tipo de usuario...*"
                isClearable
                isDisabled={isDisabled}
                styles={customStyles}
                onBlur={onBlur}
                onChange={onChange}
                options={optionsRole}
                value={userTypeSelected(value)}
              />
            )
          }}
        />
        {errors.user_type && (
          <p className="text-sm text-red-600 mt-2">
            {errors.user_type.message}
          </p>
        )}
      </div>

      <div
        className={`w-11/12 lg:w-64 h-full relative ${
          watch('user_type')?.value === 1 ? '' : 'hidden'
        }`}
      >
        <Controller
          control={control}
          name={`specialty`}
          rules={{
            required: {
              value: watch('user_type')?.value === 1 ? true : false,
              message: 'Especialidad es requerido',
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Select
              components={{ ValueContainer: CustomValueContainer }}
              className="w-full"
              placeholder="Especialidad*"
              styles={customStyles}
              options={options.specialtyOptions?.sort((a: any, b: any) => {
                if (a.label > b.label) {
                  return 1
                }
                if (a.label < b.label) {
                  return -1
                }
                return 0
              })}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              isClearable
            />
          )}
        />
        {errors.specialty && (
          <p className="text-sm text-red-600 mt-2">
            {errors.specialty.message}
          </p>
        )}
      </div>

      {/*
			<div
				className={`w-11/12 lg:w-64 h-full relative ${
					watch('user_type')?.value === 1 ? '' : 'hidden'
				}`}>
				<input
					type="text"
					id="specialty"
					className="input-style peer"
					placeholder="Especialidad*"
					{...register('specialty', {
						// required: {
						// 	value: true,
						// 	message: 'Especialidad',
						// },
					})}
				/>
				<label className="input-style-label" htmlFor="specialty">
					Especialidad
				</label>
				{errors.specialty && (
					<p className="text-sm text-red-600 mt-2">
						{errors.specialty.message}
					</p>
				)}
			</div>
			*/}

      <div className="w-11/12 lg:w-64 h-full relative">
        <input
          type="text"
          id="firstname"
          className="input-style peer"
          placeholder="Primer Nombre*"
          {...register('firstname', {
            required: {
              value: true,
              message: 'Primer Nombre es requerido',
            },
          })}
        />
        <label className="input-style-label" htmlFor="firstname">
          Primer Nombre*
        </label>
        {errors.firstname && (
          <p className="text-sm text-red-600 mt-2">
            {errors.firstname.message}
          </p>
        )}
      </div>

      <div className="w-11/12 lg:w-64 h-full relative">
        <input
          type="text"
          id="middlename"
          className="input-style peer"
          placeholder="Segundo Nombre"
          {...register('middlename')}
        />
        <label className="input-style-label" htmlFor="middlename">
          Segundo Nombre
        </label>
      </div>

      <div className="w-11/12 lg:w-64 h-full relative">
        <input
          type="text"
          id="surname"
          className="input-style peer"
          placeholder="Primer Apellido*"
          {...register('surname', {
            required: {
              value: true,
              message: 'Primer apellido es requerido',
            },
          })}
        />
        <label className="input-style-label" htmlFor="surname">
          Primer Apellido*
        </label>
        {errors.surname && (
          <p className="text-sm text-red-600 mt-2">{errors.surname.message}</p>
        )}
      </div>

      <div className="w-11/12 lg:w-64 h-full relative">
        <input
          type="text"
          id="second_surname"
          className="input-style peer"
          placeholder="Segundo Apellido"
          {...register('second_surname')}
        />
        <label className="input-style-label" htmlFor="second_surname">
          Segundo Apellido
        </label>
        {errors.second_surname && (
          <p className="text-sm text-red-600 mt-2">
            {errors.second_surname.message}
          </p>
        )}
      </div>

      <div className="w-11/12 lg:w-64 h-full relative">
        <label
          className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm border-[1] flex flex-row items-center bg-white focus:border-sky-500 focus:ring-sky-500 focus:ring-[1px]"
          htmlFor="birthday"
        >
          <Controller
            control={control}
            name="birthday"
            rules={{
              required: {
                value: true,
                message: 'Fecha de nacimiento es requerida',
              },
              validate: {
                validateDate: (date: Date) => {
                  return date > new Date()
                    ? 'Fecha invalida, no debe ser superior a la actual'
                    : null
                },
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }: any) => (
              <DatePicker
                ref={ref}
                id={`birthday`}
                name={`birthday`}
                className="w-full focus:outline-none bg-transparent"
                dateFormat="MM/dd/yyyy"
                placeholderText="Fecha de nacimiento*"
                autoComplete="off"
                onBlur={onBlur}
                selected={value}
                onChange={onChange}
                showYearDropdown
                yearDropdownItemNumber={120}
                scrollableYearDropdown
              />
            )}
          />
          <CalendarIcon className="h-7" />
        </label>
        {errors.birthday && (
          <p className="text-sm text-red-600 mt-2">{errors.birthday.message}</p>
        )}
      </div>

      <div className="w-11/12 lg:w-64 h-full">
        <Controller
          control={control}
          name="gender"
          rules={{
            required: {
              value: true,
              message: 'Sexo es requerido',
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Select
              id="gender"
              name="gender"
              ref={ref}
              className="w-full"
              placeholder="Sexo...*"
              isClearable
              components={{
                ValueContainer: CustomValueContainer,
              }}
              styles={customStyles}
              onBlur={onBlur}
              onChange={onChange}
              options={options.genderOptions}
              value={value}
            />
          )}
        />
        {errors.gender && (
          <p className="text-sm text-red-600 mt-2">{errors.gender.message}</p>
        )}
      </div>

      <div className="w-11/12 lg:w-64 h-full">
        <Controller
          control={control}
          name="countryOrigin"
          id="countryOrigin"
          rules={{
            required: {
              value: true,
              message: 'País es requerido',
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Select
              className="w-full"
              ref={ref}
              placeholder="País...*"
              isClearable
              components={{
                ValueContainer: CustomValueContainer,
              }}
              styles={customStyles}
              onBlur={onBlur}
              onChange={onChange}
              options={options.country}
              value={value}
            />
          )}
        />
        {errors.countryOrigin && (
          <p className="text-sm text-red-600 mt-2">
            {errors.countryOrigin.message}
          </p>
        )}
      </div>

      <div className="w-11/12 lg:w-64 h-full">
        <Controller
          control={control}
          name="municipalState"
          rules={{
            required: {
              value: true,
              message: 'Pueblo/estado es requerido',
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Select
              className="w-full"
              placeholder="Pueblo/Estado...*"
              isClearable
              ref={ref}
              components={{
                ValueContainer: CustomValueContainer,
              }}
              styles={customStyles}
              onBlur={onBlur}
              onChange={onChange}
              options={options.city
                .filter((item: any) => {
                  if (item?.country === watch('countryOrigin')?.value) {
                    return item
                  }
                })
                ?.map((item: any) => {
                  return item
                })}
              value={value}
            />
          )}
        />
        {errors.municipalState && (
          <p className="text-sm text-red-600 mt-2">
            {errors.municipalState.message}
          </p>
        )}
      </div>

      {/*
			<div className="hidden lg:block lg:col-span-2 xl:col-span-3 my-1"></div>

			 <div className="w-11/12 lg:w-64 h-full">
				<Controller
					control={control}
					name="isMobilePatient"
					render={({
						field: { onChange, onBlur, value = false, ref },
					}: any) => (
						<label
							className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
							htmlFor="isMobilePatient">
							<Switch
								id="isMobilePatient"
								name="isMobilePatient"
								onChange={onChange}
								height={16}
								width={30}
								checked={value}
								checkedIcon={false}
								uncheckedIcon={false}
								className={`mr-5`}
							/>
							<span>Es Celular</span>
						</label>
					)}
				/>
			</div> 
			*/}
      <div className="hidden lg:block lg:col-span-2 xl:col-span-3 my-1"></div>

      <div
        className={`  ${
          isRequiredNPI ? 'block' : 'hidden'
        }  w-11/12 lg:w-64 h-full relative`}
      >
        <Controller
          control={control}
          name="npi"
          rules={{
            // pattern: {
            // 	value: npiValidation,
            // 	message: 'El formato del NPI es incorrecto',
            // },
            required: {
              value: isRequiredNPI ? true : false,
              message: `${
                watch('user_type')?.label === 'Enfermero/a'
                  ? 'Licencia de enfermería es requerido*'
                  : 'NPI es requerido*'
              }`,
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <NumberFormat
              id="npi"
              name="npi"
              className="input-style peer"
              thousandSeparator={true}
              onChange={onChange}
              onBlur={onBlur}
              placeholder={`${
                watch('user_type')?.label === 'Enfermero/a'
                  ? 'Licencia de enfermería*'
                  : 'NPI*'
              }`}
              displayType={'input'}
              format="##########"
              value={value}
            />
          )}
        />
        <label className={`input-style-label`} htmlFor="npi">
          {`${
            watch('user_type')?.label === 'Enfermero/a'
              ? 'Licencia de enfermería*'
              : 'NPI*'
          }`}
        </label>
        {errors.npi && (
          <p className="text-sm text-red-600 mt-2">{errors.npi.message}</p>
        )}
      </div>

      {/* <div
				className={`${
					onlySpecialty ? 'block' : 'hidden'
				} w-11/12 lg:w-64 h-full`}
			>
				<label
					className="h-10 rounded-md border-[1px] px-1 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
					htmlFor="specialty"
				>
					<Controller
						control={control}
						name="specialty"
						rules={{
							required: {
								value: onlySpecialty ? true : false,
								message: 'Especialidad es requerido',
							},
						}}
						render={({ field: { onChange, onBlur, value, ref } }: any) => (
							<Select
								className="w-full"
								placeholder="Especialidad..."
								styles={customStyles}
								onBlur={onBlur}
								onChange={onChange}
								options={options.specialtyOptions}
								value={value}
							/>
						)}
					/>
				</label>
				{errors.specialty && (
					<p className="text-sm text-red-600 mt-2">
						{errors.specialty.message}
					</p>
				)}
			</div> */}

      <div className={`w-11/12 lg:w-64 h-full ${onlyHome ? '' : 'hidden'}`}>
        <Controller
          control={control}
          name="nursinghome_id"
          rules={{
            required: {
              value: onlyHome ? true : false,
              message: 'Clínica es requerido',
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Select
              id="nursinghome_id"
              name="nursinghome_id"
              className="w-full"
              placeholder="Seleccione Hogar...*"
              isClearable
              isDisabled={loading ? true : false}
              components={{
                ValueContainer: CustomValueContainer,
              }}
              styles={customStyles}
              onBlur={onBlur}
              onChange={onChange}
              ref={ref}
              options={
                currentHome !== null && currentHome !== undefined
                  ? homeList
                      ?.filter((item: any) => {
                        if (homeList?.includes(item.value)) {
                          return item
                        } else if (currentHome === item.value) {
                          return item
                        }
                      })
                      ?.sort((a: any, b: any) => {
                        if (a.label > b.label) {
                          return 1
                        }
                        if (a.label < b.label) {
                          return -1
                        }
                        return 0
                      })
                  : homeList
              }
              value={value}
            />
          )}
        />
        {errors.nursinghome_id && (
          <p className="text-sm text-red-600 mt-2">
            {errors.nursinghome_id.message}
          </p>
        )}
      </div>

      <div className={`w-11/12 lg:w-64 h-full ${onlyClinic ? '' : 'hidden'}`}>
        <Controller
          control={control}
          name="clinic_id"
          rules={{
            required: {
              value: onlyClinic ? true : false,
              message: 'Clínica es requerido',
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Select
              id="clinic_id"
              name="clinic_id"
              className="w-full"
              placeholder="Seleccione Clínica...*"
              isClearable
              components={{
                ValueContainer: CustomValueContainer,
              }}
              styles={customStyles}
              onBlur={onBlur}
              onChange={onChange}
              isDisabled={loading ? true : false}
              ref={ref}
              options={clinicList}
              value={value}
            />
          )}
        />
        {errors.clinic_id && (
          <p className="text-sm text-red-600 mt-2">
            {errors.clinic_id.message}
          </p>
        )}
      </div>

      <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
        <PhoneIcon strokeWidth={1.3} color="#294fa9" className={`w-11 `} />
        <h1 className="text-black text-xl ">{'Información de contacto'}</h1>
      </div>

      {/* {mode !== 'update' ? (
      ) : null} */}
      <div className="w-11/12 lg:w-64 h-full relative ">
        <input
          type="email"
          id="email"
          className="input-style peer disabled:bg-[#f2f2f2] disabled:text-[#cecece]"
          placeholder="Correo electrónico*"
          disabled={isDisabled}
          {...register('email', {
            pattern: {
              value: emailValidation,
              message: 'El formato del correo es incorrecto',
            },
            required: {
              value: true,
              message: 'Correo es requerido',
            },
          })}
        />
        <label className="input-style-label" htmlFor="email">
          Correo electrónico*
        </label>
        {errors.email && (
          <p className="text-sm text-red-600 mt-2">{errors.email.message}</p> // jhonathan
        )}
      </div>

      <div className="w-11/12 lg:w-64 h-full relative">
        <Controller
          control={control}
          name="phoneMobile"
          rules={{
            // required: {
            //   value: true,
            //   message: 'Número celular es requerido',
            // },
            // validate: {
            //   validateLength: (data: string) => {
            //     return data.replace(clearSpace, '').replace(' ', '').length < 10
            //       ? `¡Error, solo hay ${
            //           data.replace(clearSpace, '').replace(' ', '').length
            //         } de 10 dígitos!`
            //       : null
            //   },
            // },
            pattern: {
              value: onlyPhone,
              message: `¡Error, el numero debe ser de 10 dígitos!`,
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <NumberFormat
              id="phoneMobile"
              name="phoneMobile"
              className="input-style peer"
              thousandSeparator={true}
              onChange={onChange}
              onBlur={onBlur}
              placeholder="Número Celular*"
              displayType={'input'}
              ref={ref}
              format="### ### ####"
              value={value}
            />
          )}
        />
        <label className="input-style-label" htmlFor="phoneMobile">
          Número Celular*
        </label>
        {errors.phoneMobile && (
          <p className="text-sm text-red-600 mt-2">
            {errors.phoneMobile.message}
          </p>
        )}
      </div>

      <div className="w-11/12 lg:w-64 h-full relative">
        <Controller
          control={control}
          name="phone"
          rules={{
            // 	required: false,
            // 	validate: {
            // 		validateLength: (data: string) => {
            // 			return data.replace(clearSpace, '').length < 10
            // 				? `¡Error, solo hay ${
            // 						data.replace(clearSpace, '').length
            // 				  } de 10 dígitos!`
            // 				: null;
            // 		},
            // 	},
            pattern: {
              value: onlyPhone,
              message: `¡Error, el numero debe ser de 10 dígitos!`,
            },
          }}
          ren
          render={({ field: { onChange, onBlur, value, ref } }: any) => {
            return (
              <NumberFormat
                id="phone"
                name="phone"
                className="input-style peer"
                thousandSeparator={true}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                placeholder="Teléfono Fijo"
                displayType={'input'}
                format="### ### ####"
                value={value}
              />
            )
          }}
        />
        <label className="input-style-label" htmlFor="phone">
          Teléfono Fijo
        </label>
        {errors.phone && (
          <p className="text-sm text-red-600 mt-2">{errors.phone.message}</p>
        )}
      </div>

      <div className="w-11/12 lg:w-64 h-full relative">
        <Controller
          control={control}
          name="phoneExt"
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <NumberFormat
              id="phoneExt"
              name="phoneExt"
              className="input-style peer"
              thousandSeparator={true}
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
              autoComplete="off"
              placeholder="Extensión"
              displayType={'input'}
              format="####"
              value={value}
            />
          )}
        />
        <label className="input-style-label" htmlFor="phoneExt">
          Extensión
        </label>
      </div>

      <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
        <CameraIcon strokeWidth={1.3} color="#294fa9" className={`w-11 `} />
        <h1 className="text-black text-xl ">Foto</h1>
      </div>
      <div className="w-full col-span-1 lg:col-span-2 xl:col-span-3 flex flex-col justify-items-start gap-3">
        <div>
          <p>Haga click en el icono podra subir una foto a su perfil</p>
          {photoURL ? (
            <label htmlFor="photoUserURL" className="cursor-pointer">
              <img src={photoURL} className={`w-48`} alt={`Foto de perfil`} />
            </label>
          ) : (
            <label htmlFor="photoUserURL" className="cursor-pointer">
              {/* <PlusCircleIcon className="h-16 w-16" /> */}
              <img src={UserIcon} className="h-60" alt="" />
            </label>
          )}
          <input
            className="hidden"
            type="file"
            id="photoUserURL"
            {...register('photoUserURL', {
              validate: {
                showPhoto: (files: any) => {
                  if (Object.entries(files).length === 0) {
                    setPhotoURL(``)
                  } else {
                    const reader = new FileReader()
                    reader.onloadend = () => {
                      setPhotoURL(`${reader.result}`)
                    }
                    reader.readAsDataURL(files[0])
                  }
                },
              },
            })}
          />
          {!watch('photoUserURL') || watch('photoUserURL').length === 0 ? (
            ''
          ) : (
            <strong>Nombre de archivo: {watch('photoUserURL')[0].name}</strong>
          )}
          {errors.photoUserURL && (
            <p className="text-sm text-red-600 mt-2">
              {errors.photoUserURL.message}
            </p>
          )}
        </div>
      </div>
    </>
  )
}

export default FormUser
