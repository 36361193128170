import { PencilAltIcon, PhoneIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import Usuario from '../../../assets/svg/usuarioSearch.svg'
import useGetUserProfile from '../../../hooks/useGetUserProfile'
import IconoContacto from '../../../assets/svg/iconoContacto.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import options from '../../../data/selectOptions'
import { ROLEID } from '../../../utils/enums/Roles'
import { connect } from 'react-redux'
import { setUpdateProfileUserForm } from '../../../actions'

const ShowProfile = ({ ...props }: any) => {
  const [dataProfile, setDataProfile] = useState<any>(null)
  const location: any = useLocation()
  const navigate = useNavigate()

  const { userProfile, isLoading } = useGetUserProfile(location?.state?.userId)
  console.log('Perfil: ', userProfile)
  console.log('location?.state: ', location?.state)
  console.log('ShowProfile props: ', props)

  useEffect(() => {
    if (dataProfile === null) {
      setTimeout(() => {
        setDataProfile(userProfile)
      }, 500)
    }
  }, [dataProfile, userProfile])
  // console.log(
  //   'userProfile?.Info?.user_type_id :',
  //   userProfile?.Info?.user_type_id,
  // )

  console.log(`\nreturn`, location?.state?.return)
  console.log(`\nentityUser`, location?.state?.entityUser)

  return (
    <div className="w-full h-full ">
      <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
        <img
          //src={Usuario}
          src={Usuario}
          className={`w-20 p-1 self-center rounded-full bg-slate-500`}
          alt=""
        />
        <div className="">
          <h1 className="text-2xl text-[#96c952] font-semibold my-5 justify-start">
            {userProfile?.Info?.user_firstname
              ? `${userProfile?.Info?.user_firstname} ${userProfile?.Info?.user_middlename} ${userProfile?.Info?.user_surname} ${userProfile?.Info?.user_second_surname}`
              : 'Cargando...'}
          </h1>
        </div>
      </div>
      <div className="w-full text-left md:px-20 space-y-2 text-lg py-2 border-y-2 p-3	">
        {isLoading ? (
          <div>Cargando...</div>
        ) : (
          <div className="">
            <div className="flex flex-row items-center justify-start gap-1.5 border-b-2">
              {/* <img src={Allergy} className={`w-12`} alt="" /> */}
              <img src={IconoContacto} className={`w-11`} alt="" />
              <h1 className="text-black text-xl font-bold first-line:capitalize">
                {'Información Personal'}
              </h1>
            </div>

            <div className="w-full text-left md:px-20 space-y-2 text-lg py-2 p-3	">
              <div className="flex flex-col">
                {/* <p>{userProfile?.Info?.user_id}</p> */}

                {userProfile?.Info?.user_type_id && (
                  <div className="flex flex-row gap-1">
                    <b className="text-black font-bold">Tipo usuario: </b>
                    <p>
                      {
                        options?.roleList
                          ?.filter((value: any) => {
                            let val
                            if (
                              userProfile?.Info?.user_type_id === value?.value
                            ) {
                              val = value
                            }
                            return val
                          })
                          .map((item: any) => item)[0].label
                      }
                    </p>
                  </div>
                )}
                <div className="flex flex-row gap-1">
                  <b className="text-black font-bold">Primer nombre: </b>
                  <p>{userProfile?.Info?.user_firstname}</p>
                </div>
                {userProfile?.Info?.user_middlename && (
                  <div className="flex flex-row gap-1">
                    <b className="text-black font-bold">Segundo nombre:</b>
                    <p>{userProfile?.Info?.user_middlename}</p>
                  </div>
                )}
                <div className="flex flex-row gap-1">
                  <b className="text-black font-bold">Primer apellido:</b>
                  <p>{userProfile?.Info?.user_surname}</p>
                </div>
                {userProfile?.Info?.user_second_surname && (
                  <div className="flex flex-row gap-1">
                    <b className="text-black font-bold">Segundo apellido:</b>
                    <p>{userProfile?.Info?.user_second_surname}</p>
                  </div>
                )}
                <div className="flex flex-row gap-1">
                  <b className="text-black font-bold">Fecha de nacimiento: </b>
                  <p>
                    {userProfile?.Info?.Profile_birthday
                      ? new Date(userProfile?.Info?.Profile_birthday)
                          .toISOString()
                          .substring(0, 10)
                      : null}
                  </p>
                </div>
                {/* <div className="flex flex-row gap-1">
							<b>:</b>
							<p>{userProfile?.Info?.UserEntity_clinic_id}</p>
						</div> */}
                {/* <div className="flex flex-row gap-1">
							<b>:</b>
							<p>{userProfile?.Info?.UserEntity_nursing_home_id}</p>
						</div> */}
                {userProfile?.Info?.Profile_legal_identifier && (
                  <div className="flex flex-row gap-1">
                    <b className="text-black font-bold">
                      {userProfile?.Info?.user_type_id === ROLEID.NURSE
                        ? 'Licencia de enfermería:'
                        : 'NPI:'}
                    </b>
                    <p>{userProfile?.Info?.Profile_legal_identifier}</p>
                  </div>
                )}
                {userProfile?.Info?.Profile_specialty && (
                  <div className="flex flex-row gap-1">
                    <b className="text-black font-bold">Especialidad:</b>
                    <p>{userProfile?.Info?.Profile_specialty}</p>
                  </div>
                )}

                {/* <div className="flex flex-row gap-1">
							<b>:</b>
							<p>{userProfile?.Info?.status_gender_id}</p>
						</div> */}
                <div className="flex flex-row gap-1">
                  <b className="text-black font-bold">Genero: </b>
                  <p>{userProfile?.Info?.gender}</p>
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-start gap-1.5 border-b-2">
              <PhoneIcon
                strokeWidth={1.3}
                color="#294fa9"
                className={`w-11 `}
              />
              <h1 className="text-black text-xl font-bold ">
                {'Información de contacto'}
              </h1>
            </div>

            <div className="w-full text-left md:px-20 space-y-2 text-lg py-2  p-3	">
              <div className="flex flex-col">
                <div className="flex flex-row gap-1">
                  <b className="text-black font-bold">Correo electrónico:</b>
                  <p>{userProfile?.Info?.user_email}</p>
                </div>
              </div>
              {dataProfile?.Phones && (
                <div className="md:gap-1.5 md:flex md:flex-row">
                  <p className="text-black font-bold">Número Celular:</p>
                  <p>
                    {dataProfile?.Phones?.map((item: any) => {
                      if (item?.is_mobile === true) {
                        return <p key={item.id}>{item?.number}</p>
                      } else {
                        return ''
                      }
                    })}
                  </p>
                </div>
              )}

              {dataProfile?.Phones && (
                <div className="md:gap-1.5 md:flex md:flex-row">
                  <p className="text-black font-bold">Teléfono Fijo: </p>
                  <p>
                    {dataProfile?.Phones?.map((item: any) => {
                      if (item?.is_mobile === false) {
                        return (
                          <p key={item.id}>
                            {`${item?.number} `}
                            <b className="text-black font-bold ">Ext: </b>
                            {item?.extension ? ` ${item?.extension}` : 'N/A'}
                          </p>
                        )
                      } else {
                        return ''
                      }
                    })}
                  </p>
                </div>
              )}
            </div>

            <div className="items-center gap-2 justify-center lg:col-span-2 xl:col-span-3 my-5 flex flex-row">
              <div>
                {location?.state?.searchUser === undefined ||
                location?.state?.searchUser === null ? (
                  <input
                    className="m-auto px-4 py-2 rounded-xl border-[1px] font-bold bg-[#F6A01E] shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 duration-300"
                    type="button"
                    onClick={() => {
                      navigate(
                        location?.state?.return
                          ? location?.state?.return
                          : //: '/entities/profile',
                            '/entities/manage/users',
                      )
                    }}
                    value="Volver"
                  />
                ) : (
                  <input
                    className="m-auto px-4 py-2 rounded-xl border-[1px] font-bold bg-[#F6A01E] shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 duration-300"
                    type="button"
                    onClick={() => {
                      navigate(
                        location?.state?.return
                          ? location?.state?.return
                          : //: '/entities/profile',
                            '/users',
                      )
                    }}
                    value="Volver"
                  />
                )}
              </div>

              <div className="flex flex-row gap-10">
                <div
                  className="h-12 flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer"
                  onClick={() => {
                    props.setUpdateProfileUserForm({
                      entity: true,
                      return: '/profile/user/',
                      searchUser: location?.state?.searchUser,
                      entityUser: location?.state?.entityUser,
                      firstname: userProfile?.Info?.user_firstname,
                      middlename: userProfile?.Info?.user_middlename,
                      surname: userProfile?.Info?.user_surname,
                      secondSurname: userProfile?.Info?.user_second_surname,
                      email: userProfile?.Info?.user_email,
                      id: location?.state?.userId,
                      roles: [
                        {
                          //id: location?.state?.userId,
                          id: userProfile?.Info?.UserEntity_id,
                          roleId: userProfile?.Info?.user_type_id,
                          nursingHomeId:
                            userProfile?.Info?.UserEntity_nursing_home_id,
                          clinicId: userProfile?.Info?.UserEntity_clinic_id,
                        },
                      ],
                      npi: userProfile?.Info?.Profile_legal_identifier,
                      birthday: userProfile?.Info?.Profile_birthday,
                      genderId: userProfile?.Info?.status_gender_id,
                      phones: userProfile?.Phones,
                      city: userProfile?.Info?.city_id,
                      country: userProfile?.Info?.country,
                    })
                    navigate('/profile/update')
                  }}
                >
                  <PencilAltIcon className="w-8 h-8 " />
                  <div>Editar datos</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  setUpdateProfileUserForm,
}

export default connect(null, mapDispatchToProps)(ShowProfile)
