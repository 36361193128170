/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios'
import TokenService from './Token'
import axiosRetry from 'axios-retry'

// const API_URL_USERS = 'http://localhost:3002'
// const API_URL_EVENTS = 'http://localhost:3003'
// const API_URL_ENTITY = 'http://localhost:3004'

//trust
// export const API_URL_USERS =
// 	'https://deci1d9kbb.execute-api.us-east-1.amazonaws.com/staging';
// export const API_URL_EVENTS =
// 	'https://zd3z1lvd8l.execute-api.us-east-1.amazonaws.com/staging';
// export const API_URL_ENTITY =
// 	'https://4qdx0igf4l.execute-api.us-east-1.amazonaws.com/staging';

//Applivio
export const API_URL_USERS =
  'https://awvkf3vjx3.execute-api.us-east-1.amazonaws.com/staging'
export const API_URL_EVENTS =
  'https://lg9pv0y758.execute-api.us-east-1.amazonaws.com/staging'
export const API_URL_ENTITY =
  'https://qsxeb2psi8.execute-api.us-east-1.amazonaws.com/staging'

const user: any = localStorage.getItem('user')

const endThread = axios.CancelToken.source()

const instanceUsers = axios.create({
  baseURL: API_URL_USERS,
  headers: {
    'Content-Type': 'application/json',
  },
})

const instanceEvents = axios.create({
  baseURL: API_URL_EVENTS,
  headers: {
    'Content-Type': 'application/json',
  },
})

const instanceEntities = axios.create({
  baseURL: API_URL_ENTITY,
  headers: {
    'Content-Type': 'application/json',
  },
})

instanceUsers.interceptors.response.use(
  async (res) => {
    console.log('Resultado de la instancia User', res)

    return res
  },
  async (_err) => {
    return await error(_err)
  },
)

instanceEvents.interceptors.response.use(
  async (res) => {
    console.log('Resultado de la instancia Eventos', res)

    return res
  },
  async (_err) => {
    return await error(_err)
  },
)

instanceEntities.interceptors.response.use(
  async (res) => {
    console.log('Resultado de la instancia Entidades', res)

    return res
  },
  async (_err) => {
    return await error(_err)
  },
)

const error = async (err: any) => {
  const originalConfig = err.config

  if (originalConfig.url !== '/user/login' && err.response) {
    // Access Token was expired
    if (
      [401 /*, 403, 404, 500, 504, 502*/].includes(err?.response?.status) &&
      !originalConfig._retry
    ) {
      originalConfig._retry = true

      try {
        const rs = await instanceUsers.post(
          '/user/refresh-token',
          { refresh_token: TokenService.getLocalRefreshToken() },
          {
            headers: {
              Authorization: `${JSON.parse(user).TokenType} ${
                JSON.parse(localStorage.getItem('user')).IdToken
              }`,
              USER_ENTITY_ID: `${localStorage.getItem('role')}`,
              'Content-Type': 'application/json',
            },
          },
        )

        const { IdToken, AccessToken } = rs.data.data[0]
        TokenService.updateLocalAccessToken(IdToken, AccessToken)

        return instanceUsers(originalConfig)
      } catch (_error) {
        return Promise.reject(_error)
      }
    }
  }

  return Promise.reject(err)
}

axiosRetry(instanceUsers, {
  retries: 1,
  retryDelay: () => 3000,
  retryCondition: () => true,
})

axiosRetry(instanceEvents, {
  retries: 1,
  retryDelay: () => 3000,
  retryCondition: () => true,
})

axiosRetry(instanceEntities, {
  retries: 1,
  retryDelay: () => 3000,
  retryCondition: () => true,
})
export default { instanceUsers, instanceEvents, instanceEntities, endThread }
