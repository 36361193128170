/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import api from '../services/Api'
import { dataTransformer } from '../utils/DataTransformer/listNursing/dataTransformer'

const useGetListPatient = (value: any) => {
  const [dataNursing, setDataNursing] = useState<any>([])
  const [error, setError] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchdata = async () => {
      let user: any = localStorage.getItem('user')

      setIsLoading(true)

      await api.instanceEntities
        .get(`/nursing_home/list/`, {
          headers: {
            Authorization: `${JSON.parse(user).TokenType} ${
              JSON.parse(user).IdToken
            }`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        })
        .then((responseNursing: any) => {
          setDataNursing(dataTransformer(responseNursing?.data?.data[0]))
        })
        .catch((err) => {
          //console.log(err.response);
          //console.log(err?.response?.status);
          if ([401].includes(err?.response?.status)) {
            fetchdata()
          }
        })

      setIsLoading(false)
    }

    fetchdata()
  }, [])
  return { dataNursing, error, isLoading }
}

export default useGetListPatient

/**
 */
