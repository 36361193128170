export const FinishSectionButton: React.FC<{
  onClick: () => void
  isDisabled: boolean
  text?: string
  id?: string
}> = ({ onClick, isDisabled, children, text, id }) => {
  return (
    <div id="next" className="has-tooltip w-fit h-fit">
      {isDisabled ? (
        <span className="tooltip rounded shadow-lg p-1 bg-gray-800 text-white -mt-12">
          {text
            ? text
            : 'Para continuar, debe completar los campos requeridos señalados con *'}
        </span>
      ) : null}
      <button
        data-tip
        data-for="next"
        onClick={onClick}
        disabled={isDisabled}
        type="button"
        className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
      >
        {children}
      </button>
    </div>
  )
}
