/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import api from '../services/Api'
import { dataTransformer as homesLimitList } from '../utils/DataTransformer/limitList/homes/dataTransformer'
import { dataTransformer as clinicLimitList } from '../utils/DataTransformer/limitList/clinics/dataTransformer'

const useListLimitEntity = (dataEntitiesId: any) => {
  const [dataHomes, setDataHomes] = useState<unknown>()
  const [dataClinics, setDataClinics] = useState<unknown>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // console.log(dataEntitiesId?.nursingHomes?.length > 0);
    // console.log(dataEntitiesId?.clinics?.length > 0);
    const fetchdata = async () => {
      let user: any = localStorage.getItem('user')

      if (dataEntitiesId?.nursingHomes?.length > 0) {
        await api.instanceEntities
          .post(
            '/nursing_home/limitList',
            {
              nursingHomeIds: dataEntitiesId.nursingHomes,
            },
            {
              headers: {
                Authorization: `${JSON.parse(user).TokenType} ${
                  JSON.parse(user).IdToken
                }`,
                'Content-Type': 'application/json',
                USER_ENTITY_ID: `${localStorage.getItem('role')}`,
              },
              withCredentials: true,
            },
          )
          .then((response) => {
            const data = Array.isArray(response.data.data[0])
              ? response.data.data[0]
              : [response.data.data[0]]
            setDataHomes(homesLimitList(data))
          })
          .catch((err) => {
            // console.log(err.response);
            // console.log(err?.response?.status);
            if ([401].includes(err?.response?.status) && dataHomes === null) {
              fetchdata()
            }
          })
      }
      if (dataEntitiesId?.clinics?.length > 0) {
        await api.instanceEntities
          .post(
            '/clinic/limitList',
            {
              clinicIds: dataEntitiesId.clinics,
            },
            {
              headers: {
                Authorization: `${JSON.parse(user).TokenType} ${
                  JSON.parse(user).IdToken
                }`,
                'Content-Type': 'application/json',
                USER_ENTITY_ID: `${localStorage.getItem('role')}`,
              },
              withCredentials: true,
            },
          )
          .then((response) => {
            const data = Array.isArray(response.data.data[0])
              ? response.data.data[0]
              : [response.data.data[0]]
            setDataClinics(clinicLimitList(data))
          })
          .catch((err) => {
            // console.log(err.response);
            // console.log(err?.response?.status);
            if ([401].includes(err?.response?.status) && dataClinics === null) {
              fetchdata()
            }
          })
      }
    }
    fetchdata()
  }, [])

  return { dataHomes, dataClinics, isLoading }
}

export default useListLimitEntity
