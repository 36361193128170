import api from './Api'
import Swal from 'sweetalert2'
import { object } from 'yup'

const UpdatePatientTutor = async (obj: any) => {
  let user: any = localStorage.getItem('user')
  // console.log(`obj: \n${JSON.stringify(obj, null, 4)}`);

  const {
    tutorFirstname,
    tutorMiddlemame,
    tutorSurname,
    tutorSecondSurname,
    tutorProfession,
    tutorEmail,
    tutorPhone,
    isMobilePersonalTutor,
    tutorExt,
    tutorHomeAddressLineOne,
    tutorHomeAddressLineTwo,
    tutorZipCode,
    tutorOptionalPhone,
    isMobileHomeTutor,
    tutorOptionalExt,
    tutorJobAddressLineOne,
    tutorJobAddressLineTwo,
    tutorJobZipCode,
    tutorLaborPhone,
    isMobileLaborTutor,
    tutorLaborExt,
    tutorMaritalStatus,
    tutorRelationship,
    tutorHomeCountry,
    tutorHomeState,
    tutorJobState,
    tutorJobCountry,
    tutorId,
    id,
    workPlace,
    jopOptPhone,
    jopOptPhoneExt,
  } = obj

  const data = await api.instanceUsers
    .post(
      `/user/update-tutor/`,
      {
        tutorFirstname,
        tutorMiddlemame,
        tutorSurname,
        tutorSecondSurname,
        tutorProfession,
        tutorEmail,
        tutorPhone,
        isMobilePersonalTutor,
        tutorExt,
        tutorHomeAddressLineOne,
        tutorHomeAddressLineTwo,
        tutorZipCode,
        tutorOptionalPhone,
        isMobileHomeTutor,
        tutorOptionalExt,
        tutorJobAddressLineOne,
        tutorJobAddressLineTwo,
        tutorJobZipCode,
        tutorLaborPhone,
        isMobileLaborTutor,
        tutorLaborExt,
        tutorMaritalStatus,
        tutorRelationship,
        tutorHomeCountry,
        tutorHomeState,
        tutorJobState,
        tutorJobCountry,
        tutorId,
        id,
        workPlace,
        jopOptPhone,
        jopOptPhoneExt,
      },
      {
        headers: {
          Authorization: `${JSON.parse(user).TokenType} ${
            JSON.parse(user).IdToken
          }`,
          USER_ENTITY_ID: `${localStorage.getItem('role')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    )
    .then((response) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmación',
        text: 'Se realizó actualización con éxito!',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return response.data
    })
    .catch((error) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return error
    })
  return data
}

const DeletePatientTutor = async (obj: any) => {
  let user: any = localStorage.getItem('user')
  // console.log(`obj obejota: \n${JSON.stringify(obj, null, 4)}`);

  const { id } = obj

  const data = await api.instanceUsers
    .post(
      `/user/delete-responsable-resident/`,
      {
        userId: id,
      },
      {
        headers: {
          Authorization: `${JSON.parse(user).TokenType} ${
            JSON.parse(user).IdToken
          }`,
          USER_ENTITY_ID: `${localStorage.getItem('role')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    )
    .then((response) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmacion',
        text: 'Se realizó actualización con éxito!',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return response.data
    })
    .catch((error) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return error
    })
  return data
}
const addPatientTutor = async (obj: any) => {
  let user: any = localStorage.getItem('user')
  // console.log(`obj: \n${JSON.stringify(obj, null, 4)}`)

  const data = await api.instanceUsers
    .post(`/user/create-tutor/`, obj, {
      headers: {
        Authorization: `${JSON.parse(user).TokenType} ${
          JSON.parse(user).IdToken
        }`,
        USER_ENTITY_ID: `${localStorage.getItem('role')}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    .then((response) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmación',
        text: 'Se realizó actualización con éxito!',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return response.data
    })
    .catch((error) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return error
    })
  //console.log(`dataService: `,data);
  return data
}
const addPatientDoctor = async (obj: any) => {
  let user: any = localStorage.getItem('user')
  // console.log(`obj: \n${JSON.stringify(obj, null, 4)}`)

  const data = await api.instanceUsers
    .post(`/user/create-doctor/`, obj, {
      headers: {
        Authorization: `${JSON.parse(user).TokenType} ${
          JSON.parse(user).IdToken
        }`,
        USER_ENTITY_ID: `${localStorage.getItem('role')}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    .then((response) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmación',
        text: 'Se realizó actualización con éxito!',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return response.data
    })
    .catch((error) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return error
    })
  //console.log(`dataService: `,data);
  return data
}
const addPatientConAllSur = async (obj: any) => {
  let user: any = localStorage.getItem('user')
  // console.log(`obj: \n${JSON.stringify(obj, null, 4)}`)

  const data = await api.instanceUsers
    .post(`/user/create-afections/`, obj, {
      headers: {
        Authorization: `${JSON.parse(user).TokenType} ${
          JSON.parse(user).IdToken
        }`,
        USER_ENTITY_ID: `${localStorage.getItem('role')}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    .then((response) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmación',
        text: 'Se realizó actualización con éxito!',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return response.data
    })
    .catch((error) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return error
    })
  //console.log(`dataService: `,data);
  return data
}
const addPatientMedicine = async (obj: any) => {
  let user: any = localStorage.getItem('user')
  // console.log(`obj: \n${JSON.stringify(obj, null, 4)}`)

  const data = await api.instanceUsers
    .post(`/user/create-medicine/`, obj, {
      headers: {
        Authorization: `${JSON.parse(user).TokenType} ${
          JSON.parse(user).IdToken
        }`,
        USER_ENTITY_ID: `${localStorage.getItem('role')}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    .then((response) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmación',
        text: 'Se realizó actualización con éxito!',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return response.data
    })
    .catch((error) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return error
    })
  //console.log(`dataService: `,data);
  return data
}
const addPatientVaccine = async (obj: any) => {
  let user: any = localStorage.getItem('user')
  // console.log(`obj: \n${JSON.stringify(obj, null, 4)}`)

  const data = await api.instanceUsers
    .post(`/user/create-vaccine/`, obj, {
      headers: {
        Authorization: `${JSON.parse(user).TokenType} ${
          JSON.parse(user).IdToken
        }`,
        USER_ENTITY_ID: `${localStorage.getItem('role')}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    .then((response) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmación',
        text: 'Se realizó actualización con éxito!',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return response.data
    })
    .catch((error) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return error
    })
  //console.log(`dataService: `,data);
  return data
}
const UpdateTutor = {
  UpdatePatientTutor,
  DeletePatientTutor,
  addPatientTutor,
  addPatientDoctor,
  addPatientConAllSur,
  addPatientMedicine,
  addPatientVaccine,
}

export default UpdateTutor
