import Surgery from '../../assets/svg/PatientItems/Surgery.svg'
import PencilAltIcon from '@heroicons/react/outline/PencilAltIcon'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setUpdatePatientAllerProfCondForm } from '../../actions'
import Swal from 'sweetalert2'
import updatePatientASC from '../../services/updatePatientASC'
import SumarySurgeryRegister from '../Modals/SumarySurgeryRegister'
import { TrashIcon } from '@heroicons/react/outline'


const SurgeryPatient = (props: any) => {
  const { mane, type, description, id, pcmid, patient } = props

  const navigate = useNavigate()

  const [actionBotton, setActionBotton] = useState<string>('')
  const [modalOn, setModalOn] = useState<any>(false)
  const [formData, setFormData] = useState<any>(null)
  const [choice, setChoice] = useState<any>(false)
  //console.log(`props`, props)
  
  const trashSubmit = async () => {
    setActionBotton('delete');
    setModalOn((prev: any) => {
      //console.log(`prev`, prev)
      return !prev
    })
  }

  const deleteAction = async () => {
    navigate(`/patientprofile/${props?.patient}/`,);
    let data: any = {
      pcmid: props?.pcmid,
    }
    const dataUpdate: any = await updatePatientASC.DeletePatientASC(data)
    if (dataUpdate?.data?.code >= 200 && dataUpdate?.data?.code < 300) {
      console.log(`entre if (dataUpdate)`)
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Confirmación',
        text: `¡Ha sido eliminado con éxito!`,
        icon: 'success',
        // icon: "error",
        //cancelButtonText: 'Volver al formulario',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
        confirmButtonColor: 'rgb(14 165 233)',
        cancelButtonColor: 'rgb(14 165 233)',
      }).then((res) => {
        // console.log(`res`, res)
        // console.log(`dataUpdate?.data`,dataUpdate?.data);
        // console.log(`props?.patient`,props?.patient);
        navigate(`/patientprofile/${props?.patient}`,);
      })
    } else {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        confirmButtonText: 'Aceptar',
      }).then((res) => {
        console.log(`res`, res)
        navigate(`/patientprofile/${props?.patient}`,)
      })
    }
    navigate(`/patients`,);
    navigate(`/patientprofile/${props?.patient}`)
  }
  
  return (
    <>
      {modalOn && (
        <SumarySurgeryRegister
          setChoice={setChoice}
          setModalOn={setModalOn}
          actionBotton={actionBotton}
          deleteAction={deleteAction}
        />
      )}
      <div className="w-full text-left md:px-20 space-y-2 text-lg py-2 border-y-2 ">
        <div className="flex flex-row items-center justify-start gap-1.5 border-b-2">
          <img src={Surgery} className={`w-12`} alt="" />
          <p className="text-black font-bold first-line:capitalize">{`${
            mane ? mane : 'N/A'
          }`}</p>
        </div>
        <div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5  ">
          <div className="w-fit md:ml-24 min-w-sm	 ">
            {/* <div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-black font-bold">Cirugía:</p>
						<p>{mane}</p>
					</div> */}
            <div className="md:gap-1.5 md:flex md:flex-row max-w-md">
              <p className="text-black font-bold">Descripcción:</p>
              <p>{description ? description : 'N/A'}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-row ">
          <div className={`lg:flex-1 flex flex-row`} />
          <div
            // className="w-full sm:w-fit flex items-center md:w-auto h-10  rounded-lg px-4 p-2 my-2 py-2 bg-[#f61e1e] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-slate-600 text-white hover:border-red-700"
            className="w-full sm:w-fit flex items-center gap-1 bg-[#f61e1e]  p-2 text-white rounded-lg cursor-pointer m-2"
            onClick={() => trashSubmit()}
          >
            <TrashIcon strokeWidth={1.3} className="w-4 border-b-2" />
            Eliminar
          </div>
          <div
            className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
            onClick={() => {
              navigate('/surgery/update')
              props.setUpdatePatientAllerProfCondForm({
                mane,
                type,
                description,
                id,
                pcmid,
                patient,
              })
            }}
          >
            <PencilAltIcon className="w-8 h-8 " />
            <div>Editar datos</div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = {
  setUpdatePatientAllerProfCondForm,
}

export default connect(null, mapDispatchToProps)(SurgeryPatient)
