/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { dataTransformer } from '../utils/DataTransformer/filterPatients/dataTransformer'
import api from '../services/Api'

const useGetUserTypeI = (userType: any) => {
  const [data, setData] = useState<any>(null)
  const [error, setError] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchdata = async () => {
      let user: any = localStorage.getItem('user')

      setIsLoading(true)

      await api.instanceUsers
        .post(
          `/user/get-user-type/`,
          { id: userType },
          {
            headers: {
              Authorization: `${JSON.parse(user).TokenType} ${
                JSON.parse(user).IdToken
              }`,
              'Content-Type': 'application/json',
              USER_ENTITY_ID: `${localStorage.getItem('role')}`,
            },
            withCredentials: true,
          },
        )
        .then((response: any) => {
          setData(dataTransformer(response.data.data[0]))
        })
        .catch((err) => {
          // console.log(err.response);
          // console.log(err?.response?.status);
          if ([401].includes(err?.response?.status) && data === null) {
            fetchdata()
          }
        })

      setIsLoading(false)
    }
    fetchdata()
  }, [])
  return { data, error, isLoading }
}

export default useGetUserTypeI
