/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import Conditions from '../../../assets/svg/PatientItems/Conditions.svg'

const ConditionForm = (props: any) => {
  const {
    Controller,
    control,
    Select,
    register,
    conditionFields,
    conditionRemove,
    conditionAppend,
    acsError,
    acsIsLoading,
    customStyles,
    acsListData,
    mode,
    CustomValueContainer,
  } = props

  return (
    <div className="w-full h-full space-y-5 border-b-2">
      {mode !== 'update' ? (
        // <h2 className="text-center text-2xl my-5">Condiciones</h2>
        <>
          <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
            <img src={Conditions} className={`w-12`} alt="" />
            <h2 className="text-xl">{`Condiciones`}</h2>
          </div>
          <div className="my-1 flex w-full flex-col lg:col-span-2 lg:block xl:col-span-3  text-slate-500">
            <p>Lista de condiciones médicas presente en el residente.</p>
          </div>
        </>
      ) : null}
      {conditionFields.map((field: any, index: any) => {
        return (
          <div
            className="w-full mx-auto flex flex-col space-y-5"
            key={field.id}
          >
            <div
              className={`w-full grid gap-2 grid-cols-1 lg:grid-cols-2  ${
                mode !== 'update' ? 'xl:grid-cols-3' : 'xl:grid-cols-2'
              }`}
            >
              <div className="w-full lg:w-64 h-full ">
                <Controller
                  control={control}
                  name={`condition.${index}.item`}
                  rules={{
                    required: {
                      value: true,
                      message: 'Seleccione una condicion',
                    },
                  }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                  }: any) => (
                    <Select
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      className="w-full"
                      placeholder="Condición"
                      styles={customStyles}
                      options={acsListData?.conditions}
                      onBlur={onBlur}
                      isClearable
                      onChange={onChange}
                      value={value}
                      isDisabled={mode === 'update' ? true : false}
                    />
                  )}
                />
              </div>
              <div className="w-full lg:w-64 h-full relative">
                <textarea
                  id={`condition.${index}.description`}
                  className="input-style peer"
                  placeholder="Detalles"
                  {...register(`condition.${index}.description`)}
                />
                <label
                  className="input-style-label"
                  htmlFor={`condition.${index}.description`}
                >
                  Detalles
                </label>
              </div>
              <input
                type="button"
                className={`${
                  mode === 'update' || mode === 'add' ? 'hidden' : ''
                } lg:w-64 h-10 bg-red-500 rounded-lg text-white`}
                onClick={() => conditionRemove(index)}
                value="Eliminar"
              />
            </div>
          </div>
        )
      })}
      <div className="w-full text-center">
        <input
          className={`${
            mode === 'update' || mode === 'add' ? 'hidden' : ''
          } mx-auto w-11/12 lg:w-64 rounded-lg px-4 mb-5 py-2 bg-lime-600 shadow-md duration-300 font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700`}
          type="button"
          value={`Adicionar Condición`}
          onClick={() => conditionAppend({})}
        />
      </div>
    </div>
  )
}

export default ConditionForm
