import React from 'react'

const ItemNote = (props: any) => {
  const { date_create_note, note_text, user_create_name } = props
  return (
    <div className="w-full rounded-md border-[1px] bg-transparent p-4 shadow-sm">
      <h1 className="font-bold">Nota</h1>
      <p>
        <b>Fecha: </b>
        {`${new Date(date_create_note).toISOString().substring(0, 10)}`}
      </p>
      <p>
        <b>Hora: </b>
        {`${new Date(date_create_note).toISOString().substring(11, 19)}`}
      </p>
      <p>
        <b>Detalles: </b>
        {note_text}
      </p>
      <p>
        <b>Creado por: </b>
        {user_create_name}
      </p>
      {/* <div className="flex w-full flex-row-reverse">
        <input
          type="submit"
          value="Mas Detalles"
          className="rounded-lg border-[1px] px-4 py-2 font-medium shadow-md duration-300 hover:cursor-pointer"
        />
      </div> */}
    </div>
  )
}

export default ItemNote
