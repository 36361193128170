import React from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { MenuIcon, ChevronRightIcon } from '@heroicons/react/outline'
// import Switch from "react-switch";

const Navbar = ({
  collapsed,
  handleToggleSidebar,
  handleCollapsedChange,
  isNavigate,
}: any) => {
  // const { isHome, isProfilePl, isProfilePa } = props;
  return (
    <nav
      id="menu-profile"
      className="w-full h-14 bg-[#294fa9] flex flex-col md:hidden"
    >
      {/* <button
          onClick={() => handleToggleSidebar(true) && collapsed(false)}
          onChange={handleCollapsedChange}
        >
          
        </button> */}
      <button
        onClick={() => handleToggleSidebar(true)}
        className="md:hidden w-fit"
      >
        <MenuIcon className="w-8 text-white m-3" />
      </button>

      <button onClick={() => handleToggleSidebar(true)} className="hidden">
        <MenuIcon className="w-8 text-white m-3" />
      </button>
      {/* <Switch
          height={16}
          width={30}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={handleCollapsedChange}
          checked={collapsed}
          onColor="#219de9"
          offColor="#bbbbbb"
        /> */}
    </nav>
  )
}

export default Navbar
