/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import api from '../services/Api'
import { dataTransformer } from '../utils/DataTransformer/listRelations/dataTransformer'

const useGetEntityRelations = (values: any) => {
  const [entityList, setEntityList] = useState<any>(null)
  const [listClinic, setListClinic] = useState<any>(null)
  const [listHome, setListHome] = useState<any>(null)
  const [isloading, setisloading] = useState<any>(true)

  useEffect(() => {
    const fetchdata = async () => {
      let user: any = localStorage.getItem('user')

      setisloading(true)
      let cliniclisId: any[] = []
      let homeListId: any[] = []
      //Home <==> Clinic

      if (values?.clinicId === undefined) {
        await api.instanceEntities
          .post(
            `/nursing_home_clinic/home-for-clinic/`,
            { nursingHomeId: values?.nursingHomeId },
            {
              headers: {
                Authorization: `${JSON.parse(user).TokenType} ${
                  JSON.parse(user).IdToken
                }`,
                'Content-Type': 'application/json',
              },
              withCredentials: true,
            },
          )
          .then((response: any) => {
            setEntityList(dataTransformer(response?.data.data[0]))
            console.log('Home for clinic', response?.data.data[0])
            response?.data.data[0].map((item: any) => {
              console.log(item)

              if (item.clinicId !== null) {
                if (!homeListId.includes(item.clinicId)) {
                  homeListId.push(item.clinicId)
                }
              }
            })
            setListHome(homeListId)
          })
          .catch((err) => {
            setEntityList(null)
            if ([401].includes(err?.response?.status) && entityList === null) {
              fetchdata()
            }
          })
      }
      if (values?.nursingHomeId === undefined) {
        //Clinic <==> Home
        await api.instanceEntities
          .post(
            `/nursing_home_clinic/clinic-for-home/`,
            { clinicId: values?.clinicId },
            {
              headers: {
                Authorization: `${JSON.parse(user).TokenType} ${
                  JSON.parse(user).IdToken
                }`,
                'Content-Type': 'application/json',
              },
              withCredentials: true,
            },
          )
          .then((response: any) => {
            setEntityList(dataTransformer(response?.data.data[0]))
            response?.data.data[0].map((item: any) => {
              if (item.nursingHomeId !== null) {
                if (!cliniclisId.includes(item.nursingHomeId)) {
                  cliniclisId.push(item.nursingHomeId)
                }
              }
            })
            setListClinic(cliniclisId)
          })
          .catch((err) => {
            setEntityList(null)
            if ([401].includes(err?.response?.status) && entityList === null) {
              fetchdata()
            }
          })
      }
      setisloading(false)
    }

    fetchdata()
  }, [])
  return { entityList, listClinic, listHome, isloading }
}

export default useGetEntityRelations
