/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import options from '../../data/selectOptions'
import NumberFormat from 'react-number-format'
import { useLocation, useNavigate } from 'react-router-dom'
import PersonalInfo from '../RegisterPatient/PersonalInfo'
import { CalendarIcon } from '@heroicons/react/outline'
import DatePicker from 'react-datepicker'
import Select, { components } from 'react-select'
import { clearUpdatePatientInfoForm } from '../../actions'
import { connect } from 'react-redux'
import { formatDate, formatDateMDY } from '../../utils/formatDate'
import useGetListNursing from '../../hooks/useGetListNursing'
import { dataTransformer } from '../../utils/DataTransformer/listNursing/dataTransformer'
import UpdatePatientInfo from '../../services/UpdatePatientInfo'
import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline'
import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer'
import Swal from 'sweetalert2'
import GenericModalUser from '../Modals/GenericModalUser'

const UpdatePatientForm = (props: any) => {
  const [stateData, setStateData] = useState<any>()
  const location: any = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const [user, initUser] = useState<any>(null)

  const [modalOn, setModalOn] = useState<any>(false)
  const [formData, setFormData] = useState<any>(null)
  const [choice, setChoice] = useState<any>(false)

  const navigate = useNavigate()

  const {
    register,
    watch,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid, isDirty, isSubmitSuccessful },
  } = useForm({
    mode: 'onChange',
  })

  //console.log(`props?.updatePatientInfoForm`,props?.updatePatientInfoForm);
  //console.log(`options.preOptionsHome value`,options.preOptionsHome[1].value);
  //console.log(`props nursing`,props?.updatePatientInfoForm?.dataNursing.concat(options?.preOptionsHome));

  useEffect(() => {
    if (user === null) {
      setTimeout(() => {
        initUser({
          entryDate: formatDateMDY(props?.updatePatientInfoForm?.entryDate),
          clinicalMedicalNH: props?.updatePatientInfoForm?.clinicalMedicalNH,

          firstname: props?.updatePatientInfoForm?.name,
          middlename: props?.updatePatientInfoForm?.middle,
          surname: props?.updatePatientInfoForm?.surname,
          second_surname: props?.updatePatientInfoForm?.secondSurname,

          birthday: formatDateMDY(props?.updatePatientInfoForm?.birthday),

          isMobilePhone: props?.updatePatientInfoForm?.phones?.isMobile,
          phone: props?.updatePatientInfoForm?.phones?.number,
          extPhone: props?.updatePatientInfoForm?.phones?.extension,

          countryOrigin: options.state
            .filter((item: any) => {
              if (item?.label === props?.updatePatientInfoForm?.countryOrigin) {
                return item
              }
            })
            .map((item: any) => {
              return item
            })[0],

          gender: options.genderOptions
            .filter((item: any) => {
              if (item?.label === props?.updatePatientInfoForm?.gender) {
                return item
              }
            })
            .map((item: any) => {
              return item
            })[0],

          statusSex: options.sexOptions
            .filter((item: any) => {
              if (
                item?.label
                  ?.toLowerCase()
                  ?.includes(
                    props?.updatePatientInfoForm?.statusSex?.toLowerCase(),
                  ) ||
                item?.lavel
                  ?.toLowerCase()
                  ?.includes(
                    props?.updatePatientInfoForm?.statusSex?.toLowerCase(),
                  )
              ) {
                return item
              }
            })
            .map((item: any) => {
              return item
            })[0],

          bloodtype: options.bloodOptions
            .filter((item: any) => {
              if (item?.label === props?.updatePatientInfoForm?.bloodtype) {
                return item
              }
            })
            .map((item: any) => {
              return item
            })[0],

          maritalStatus: options.maritalOptions
            .filter((item: any) => {
              if (item?.label === props?.updatePatientInfoForm?.maritalStatus) {
                return item
              }
            })
            .map((item: any) => {
              return item
            })[0],
          nursingHomeId: props?.updatePatientInfoForm?.dataNursing
            .filter((item: any) => {
              if (
                item?.value === props?.updatePatientInfoForm?.nursingHomeId ||
                item?.value === options.preOptionsHome[1].value
              ) {
                return item
              }
            })
            .map((item: any) => {
              return item
            }),

          nursingHomeSourceId: props?.updatePatientInfoForm?.dataNursing
            .concat(options?.preOptionsHome)
            .filter((item: any) => {
              if (
                parseInt(item?.value) ===
                parseInt(props?.updatePatientInfoForm?.nursingHomeSourceId)
              ) {
                //console.log(`item1:\n`,item,true);
                return item
              }
              if (
                props?.updatePatientInfoForm?.nursingHomeSourceId.includes(';')
              ) {
                if (
                  item?.value ===
                  props?.updatePatientInfoForm?.nursingHomeSourceId.split(
                    ';',
                  )[0]
                ) {
                  //console.log(`item split:\n`,props?.updatePatientInfoForm?.nursingHomeSourceId?.split(';')[1],true);
                  item.label =
                    parseInt(
                      props?.updatePatientInfoForm?.nursingHomeSourceId,
                    ) === 0
                      ? 'Ninguna'
                      : 'Otro...'
                  return item
                }
              }
            })
            .map((item: any) => {
              // console.log(`nursingItem\n`,item);
              return item
            })[0],

          otherSource:
            props?.updatePatientInfoForm?.nursingHomeSourceId.split(';')[1],
          socialSecurityId: props?.updatePatientInfoForm?.socialSecurityId,
          email: props?.updatePatientInfoForm?.email,
          insuranceCarrier: props?.updatePatientInfoForm?.insuranceCarrier,
          insuranceCarrierII: props?.updatePatientInfoForm?.insuranceCarrierII,
          tytocareId: props?.updatePatientInfoForm?.tytocareId,
          preferenceHospital: props?.updatePatientInfoForm?.preferenceHospital,
        })
      }, 1000)
    }
  })

  useEffect(() => {
    reset(user)
  }, [user])

  const registerUser = async () => {
    setIsLoading(!isLoading)
    formData.id = props?.updatePatientInfoForm?.id
    //console.log(`valores data: \n ${JSON.stringify(formData, null, 4)}`);
    const dataUpdate: any = await UpdatePatientInfo.UpdatePatientInfo(formData)
    if (dataUpdate.code >= 200 && dataUpdate.code < 300) {
      //alert(JSON.stringify(formData, null, 4));
      clearUpdatePatientInfoForm(null)
      reset({
        entryDate: '',
        clinicalMedicalNH: '',
        firstname: '',
        middlename: '',
        surname: '',
        second_surname: '',
        birthday: '',
        isMobilePhone: false,
        phone: '',
        extPhone: '',
        socialSecurityId: '',
        email: '',
        insuranceCarrier: '',
        insuranceCarrierII: '',
        tytocareId: '',
        preferenceHospital: '',
        nursingHomeId: {},
        countryOrigin: {},
        gender: {},
        statusSex: {},
        bloodtype: {},
        maritalStatus: {},
      })
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Confirmación',
        text: `¡Ha sido registrado correctamente!`,
        icon: 'success',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
        confirmButtonColor: 'rgb(14 165 233)',
        cancelButtonColor: 'rgb(14 165 233)',
      }).then((res) => {
        setIsLoading(true)
        if (res.isConfirmed) {
          // console.log(result?.data[0])
          navigate(`/patientprofile/${props?.updatePatientInfoForm?.id}/`)
        } else {
          setIsLoading(true)
        }
      })
      initUser({})
      navigate(`/patientprofile/${props?.updatePatientInfoForm?.id}/`)
    } else {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        confirmButtonText: 'Aceptar',
      })
      setIsLoading(true)
    }
    setIsLoading(true)
  }
  const onSubmit = async (data: any) => {
    // console.log(`onSubmit`, data);
    setModalOn(true)
    setFormData(data)
    setIsLoading(false)
  }
  return (
    <>
      {modalOn && (
        <GenericModalUser
          formData={formData}
          setChoice={setChoice}
          setModalOn={setModalOn}
          registerUser={registerUser}
          setIsLoading={setIsLoading}
        />
      )}
      <div className="w-full space-y-4 ">
        <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
          <PencilAltIcon strokeWidth={1.3} color="#294fa9" className="w-20" />
          <h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
            Editando información del residente
          </h2>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
          <div className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-3 place-items-center flex flex-col">
            <PersonalInfo
              Controller={Controller}
              Select={Select}
              DatePicker={DatePicker}
              control={control}
              register={register}
              errors={errors}
              NumberFormat={NumberFormat}
              CalendarIcon={CalendarIcon}
              genderOptions={options.genderOptions}
              sexOptions={options.sexOptions}
              bloodOptions={options.bloodOptions}
              maritalOptions={options.maritalOptions}
              components={components}
              ListNursing={props?.updatePatientInfoForm?.dataNursing}
              CustomValueContainer={CustomValueContainer}
              nursingHomeId={stateData?.nursingHomeId}
              watch={watch}
              mode={'update'}
            />
            <div className="lg:col-span-2 xl:col-span-3 my-5">
              {isLoading ? (
                <>
                  <input
                    type={'button'}
                    value="Volver"
                    className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                    onClick={() => {
                      navigate(
                        `/patientprofile/${props?.updatePatientInfoForm?.id}/`,
                      )
                    }}
                  />
                  <input
                    type="submit"
                    value="Guardar"
                    className="mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  />
                </>
              ) : (
                <>
                  <input
                    type="submit"
                    value="Cargando..."
                    disabled
                    className="mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
                  />
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
const mapStateToProps = (state: any) => {
  return {
    updatePatientInfoForm: state.updatePatientInfoForm,
  }
}
const mapDispatchToProps = {
  clearUpdatePatientInfoForm,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePatientForm)
