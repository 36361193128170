/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate } from 'react-router-dom'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { clearUpdatePatientVaccineForm } from '../../actions'
import { connect } from 'react-redux'
import VaccineInfo from '../RegisterPatient/VaccineInfo'
import { CalendarIcon } from '@heroicons/react/outline'
import DatePicker from 'react-datepicker'
import UpdatePatientVaccine from '../../services/UpdatePatientVaccine'
import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline'
import Swal from 'sweetalert2'
import SumaryVaccineRegister from '../Modals/SumaryVaccineRegister'

const UpdateVaccineForm = (props: any) => {
  const [user, initUser] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(true)

  const [modalOn, setModalOn] = useState<any>(false)
  const [formData, setFormData] = useState<any>(null)
  const [choice, setChoice] = useState<any>(false)

  const navigate = useNavigate()
  ////console.log(`perico state:\n${JSON.stringify(location, null, 4)}`);
  const {
    register,
    watch,
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty, isSubmitSuccessful },
  } = useForm({
    mode: 'onChange',
  })

  const {
    fields: vaccineFields,
    append: vaccineAppend,
    remove: vaccineRemove,
  } = useFieldArray({
    name: 'vaccines',
    control,
  })
  //console.log(`props:\n${JSON.stringify(props, null, 4)}`);

  useEffect(() => {
    if (user === null) {
      setTimeout(() => {
        initUser({
          vaccineName: props?.updatePatientVaccineForm?.vaccineName,
          dosage: props?.updatePatientVaccineForm?.dosage,
          doseDate: new Date(props?.updatePatientVaccineForm?.doseDate),
          frequency: props?.updatePatientVaccineForm?.frequency,
          pharmaceuticalCompany:
            props?.updatePatientVaccineForm?.pharmaceuticalCompany,
        })
      }, 1000)
    }
  })

  //console.log(`user: \n${JSON.stringify(user)}`);
  useEffect(() => {
    reset({ vaccines: [user] })
  }, [user])

  const registerUser = async () => {
    setIsLoading(false)
    formData.vaccines[0].id = props?.updatePatientVaccineForm?.patient
    formData.vaccines[0].vaccineId = props?.updatePatientVaccineForm?.id

    //console.log(`valores data: \n ${JSON.stringify(formData.vaccines[0], null, 4)}`);
    const dataUpdate: any = await UpdatePatientVaccine.UpdatePatientVaccine(
      formData.vaccines[0],
    )
    if (dataUpdate.code === 200) {
      //alert(JSON.stringify(formData, null, 4));
      clearUpdatePatientVaccineForm(null)
      reset({
        vaccineName: '',
        dosage: '',
        doseDate: '',
        frequency: '',
        pharmaceuticalCompany: '',
      })
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Confirmación',
        text: `¡Ha sido registrado correctamente!`,
        icon: 'success',
        // icon: "error",
        //cancelButtonText: 'Volver al formulario',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
        confirmButtonColor: 'rgb(14 165 233)',
        cancelButtonColor: 'rgb(14 165 233)',
      }).then((res) => {
        setIsLoading(true)
        if (res.isConfirmed) {
          // console.log(result?.data[0])
          navigate(`/patientprofile/${props?.updatePatientVaccineForm?.patient}/`)
        } else {
          setIsLoading(true)
        }
      })
      initUser({})
      navigate(`/patientprofile/${props?.updatePatientVaccineForm?.patient}/`)
    } else {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      setIsLoading(true)
    }
    setIsLoading(true)
  }
  
  const onSubmit = async (data: any) => {
    // console.log(`onSubmit`, data);
    setModalOn(true)
    setFormData(data)
    setIsLoading(false)
  }
  return (
    <>
      {modalOn && (
        <SumaryVaccineRegister
          formData={formData}
          setChoice={setChoice}
          setModalOn={setModalOn}
          registerUser={registerUser}
          setIsLoading={setIsLoading}
        />
      )}
      <div className="w-full space-y-4 ">
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
          <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
            <PencilAltIcon strokeWidth={1.3} color="#294fa9" className="w-20" />
            <h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
              Editando Vacuna
            </h2>
          </div>

          <div className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-3 place-items-center flex flex-col p-7">
            <VaccineInfo
              Controller={Controller}
              DatePicker={DatePicker}
              control={control}
              register={register}
              vaccineFields={vaccineFields}
              vaccineRemove={vaccineRemove}
              vaccineAppend={vaccineAppend}
              CalendarIcon={CalendarIcon}
              errors={errors}
              mode={'update'}
            />

            <div className="lg:col-span-2 w-full xl:col-span-3 justify-center place-items-center center flex md:flex-row flex-col gap-6">
              {isLoading ? (
                <>
                  <input
                    type={'button'}
                    value="Volver"
                    className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                    onClick={() => {
                      navigate(
                        `/patientprofile/${props?.updatePatientVaccineForm?.patient}/`,
                      )
                    }}
                  />
                  <input
                    type="submit"
                    value="Guardar"
                    className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  />
                </>
              ) : (
                <>
                  <input
                    type="submit"
                    disabled
                    value="Cargando..."
                    className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
                  />
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
const mapStateToProps = (state: any) => {
  return {
    updatePatientVaccineForm: state.updatePatientVaccineForm,
  }
}
const mapDispatchToProps = {
  clearUpdatePatientVaccineForm,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateVaccineForm)
