import React, { useState } from 'react'
import InformationTutor from '../../assets/svg/PatientItems/informacionRegistro.svg'
import InformationPersonal from '../../assets/svg/PatientItems/informacionPersonal.svg'
import InformationLaboral from '../../assets/svg/PatientItems/informacionLaboral.svg'
import { useNavigate } from 'react-router-dom'
import { setUpdatePatientTutorForm } from '../../actions'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import UpdatePatientTutor from '../../services/updatePatientTutor'
import SumaryTutorRegister from '../Modals/SumaryTutorRegister'



const TutorPatient = (props: any) => {
  const {
    firstname,
    middlemame,
    surname,
    secondSurname,
    relationship,
    email,
    profession,
    jobCountry,
    jobAddress,
    jobAddressII,
    jobState,
    jobCity,
    jobZipCode,
    homeCountry,
    homeAddress,
    homeAddressII,
    homeState,
    homeCity,
    zipCode,
    maritalStatus,
    statusType,
    phone,
    optionalPhone,
    optionalPhoneII,
    id,
    patient,
    workPlace,
    tutorLaborPhone,
  } = props

  const navigate = useNavigate()
  console.log(`props`, props)

  const [actionBotton, setActionBotton] = useState<string>('')
  const [modalOn, setModalOn] = useState<any>(false)
  const [formData, setFormData] = useState<any>(null)
  const [choice, setChoice] = useState<any>(false)
  //console.log(`props`, props)
  
  const trashSubmit = async () => {
    setActionBotton('delete');
    setModalOn((prev: any) => {
      //console.log(`prev`, prev)
      return !prev
    })
  }

  const deleteAction = async () => {
    navigate(`/patientprofile/${props?.patient}/`,);
    let data: any = {
      id: props?.id,
    }
    const dataUpdate: any = await UpdatePatientTutor.DeletePatientTutor(data)
    console.log(`dataUpdate: `,dataUpdate);
    
    if (dataUpdate?.code >= 200 && dataUpdate?.code < 300) {
      console.log(`entre if (dataUpdate)`)
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Confirmación',
        text: `¡Ha sido eliminado con éxito!`,
        icon: 'success',
        // icon: "error",
        //cancelButtonText: 'Volver al formulario',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
        confirmButtonColor: 'rgb(14 165 233)',
        cancelButtonColor: 'rgb(14 165 233)',
      }).then((res) => {
        // console.log(`res`, res)
        // console.log(`dataUpdate?.data`,dataUpdate?.data);
        // console.log(`props?.patient`,props?.patient);
        navigate(`/patientprofile/${props?.patient}`,);
      })
    } else {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        confirmButtonText: 'Aceptar',
      }).then((res) => {
        console.log(`res`, res)
        navigate(`/patientprofile/${props?.patient}`,)
      })
    }
    navigate(`/patients`,);
    navigate(`/patientprofile/${props?.patient}`)
  }
  
  return (
    <>
    {modalOn && (
        <SumaryTutorRegister
          formData={formData}
          setChoice={setChoice}
          setModalOn={setModalOn}
          actionBotton={actionBotton}
          deleteAction={deleteAction}

        />
      )}
    <div className="w-full text-left md:px-20 space-y-4 text-lg py-4 border-y-2 ">
      <div className="flex flex-row items-center justify-start border-b-2 gap-1.5 ">
        {/* <IdentificationIcon className="w-8 h-8 " /> */}
        <img src={InformationTutor} className={`w-12`} alt="" />
        <h1 className="text-2xl font-bold">
          <p className="text-black font-bold first-line:capitalize justify-items-center ">
            {middlemame !== null
              ? `${firstname} ${middlemame}`
              : `${firstname}`}{' '}
            {secondSurname !== null
              ? `${surname} ${secondSurname}`
              : `${surname}`}
          </p>
        </h1>
      </div>
      <div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5 ">
        {/* <div className="w-fit md:mx-auto"> */}
        <div className="w-fit md:ml-24 min-w-sm	 ">
          {/* 
					<div className="md:gap-1.5 md:flex md:flex-row">

						<p className="text-black font-bold">Nombre: </p>
						<p>
							{`${middlemame}` ? `${firstname} ${middlemame}` : `${firstname}`} ' ' {`${secondSurname}` ? `${surname} ${secondSurname}` : `${surname}`}
						</p>
					</div>
					<div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-black font-bold">Apellido: </p>
						<p>
							{secondSurname ? `${surname} ${secondSurname}` : `${surname}`}
						</p>
					</div> */}
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Relación: </p>
            <p>{relationship ? relationship : 'N/A'}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Estado marital: </p>
            <p>{maritalStatus?.value ? maritalStatus?.value : 'N/A'}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Profesión: </p>
            <p>{profession ? profession : 'N/A'}</p>
          </div>
        </div>
      </div>

      <div className="flex flex-row items-center justify-start border-b-2 gap-1.5 ">
        <img src={InformationPersonal} className={`w-12`} alt="" />
        <h1 className="text-black text-xl font-bold">Información personal</h1>
      </div>
      <div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5 ">
        <div className="w-fit md:ml-24 min-w-sm	 ">
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Número Celular: </p>
            <p>{phone?.number ? phone?.number : 'N/A'}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold w-[164.906px] break-keep">
              Correo electrónico:{' '}
            </p>
            <p>{email ? email : 'N/A'}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Teléfono Fijo: </p>
            <p>
              {`${optionalPhone?.number ? optionalPhone?.number : 'N/A'} `}
              <b className="text-black font-bold ">Ext:</b>
              {optionalPhone?.extension ? ` ${optionalPhone.extension}` : 'N/A'}
            </p>
          </div>

          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Dirección 1:</p>
            <p>{homeAddress ? homeAddress : 'N/A'}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Dirección 2:</p>
            <p>{homeAddressII ? homeAddressII : 'N/A'}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Municipio/Estado:</p>
            <p>{homeState ? homeState : 'N/A'}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Código postal:</p>
            <p>{zipCode ? zipCode : 'N/A'}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">País:</p>
            <p>{homeCountry ? homeCountry : 'N/A'}</p>
          </div>
          {/* <p>
					<b>Ciudad:</b> {homeCity}
				</p> */}
        </div>
      </div>

      <div className="flex flex-row items-center justify-start border-b-2 gap-1.5 ">
        <img src={InformationLaboral} className={`w-12`} alt="" />
        <h1 className="text-black text-xl font-bold">Información laboral</h1>
      </div>

      <div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5 ">
        <div className="w-fit md:ml-24 min-w-sm	 ">
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Lugar de Trabajo:</p>
            <p>{workPlace ? workPlace : 'N/A'}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Número Celular:</p>
            <p>{optionalPhoneII?.number ? optionalPhoneII.number : 'N/A'}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Teléfono Fijo:</p>

            <p>
              {`${tutorLaborPhone?.number ? tutorLaborPhone?.number : 'N/A'} `}
              <b className="text-black font-bold ">Ext:</b>
              {tutorLaborPhone?.extension
                ? ` ${tutorLaborPhone.extension}`
                : 'N/A'}
            </p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Dirección 1:</p>
            <p>{jobAddress}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Dirección 2:</p>
            <p>{jobAddressII}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Municipio/Estado:</p>
            <p>{jobState}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Código postal:</p>
            <p>{jobZipCode}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">País:</p>
            <p>{jobCountry ? jobCountry : 'N/A'}</p>
          </div>
          {/* <p>
					<b>Ciudad:</b> {jobCity}
				</p> */}
        </div>
      </div>
      <div className="lg:col-span-2 xl:col-span-3 my-5">
        <div className="flex flex-row gap-2">
          <div className={`sm:flex-1 flex flex-row`} />
          <div
            // className="w-full sm:w-fit flex items-center md:w-auto h-10  rounded-lg px-4 p-2 my-2 py-2 bg-[#f61e1e] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-slate-600 text-white hover:border-red-700"
            className="w-full sm:w-fit flex items-center gap-1 bg-[#f61e1e]  p-2 text-white rounded-lg cursor-pointer m-2"
            onClick={() => trashSubmit()}
          >
            <TrashIcon strokeWidth={1.3} className="w-4 border-b-2" />
            Eliminar
          </div>
          <div
            className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
            onClick={() => {
              navigate('/tutorupdate')
              props.setUpdatePatientTutorForm({
                firstname,
                middlemame,
                surname,
                secondSurname,
                relationship,
                email,
                profession,
                jobCountry,
                jobAddress,
                jobAddressII,
                jobState,
                jobCity,
                jobZipCode,
                homeCountry,
                homeAddress,
                homeAddressII,
                homeState,
                homeCity,
                zipCode,
                maritalStatus,
                statusType,
                phone,
                optionalPhone,
                optionalPhoneII,
                id,
                patient,
                workPlace,
                tutorLaborPhone,
              })
            }}
          >
            <PencilAltIcon className="w-8 h-8 " />
            <div>Editar datos</div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
const mapDispatchToProps = {
  setUpdatePatientTutorForm,
}
export default connect(null, mapDispatchToProps)(TutorPatient)
