/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  emailValidation,
  npiValidation,
  websiteValidation,
} from '../../utils/regexExpresion'
import { Controller, useForm } from 'react-hook-form'
import options from '../../data/selectOptions'
import NumberFormat from 'react-number-format'
import { customStyles } from '../customStyleNewLabel'
import { useLocation, useNavigate } from 'react-router-dom'
import Select, { components } from 'react-select'
import Switch from 'react-switch'
import { useEffect, useState } from 'react'
import EntityForm from './FormEntity'
import updateEntity from '../../services/UpdateEntity'
import { connect } from 'react-redux'
import { clearUpdateEntityForm } from '../../actions'
import React from 'react'
import Entities from '../../assets/svg/entidades.svg'
import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer'
import Swal from 'sweetalert2'
import SumaryEntityRegisterData from '../Modals/SumaryEntityRegisterData'
import List from '../../services/ListEntity'
import { setUpdateEntityForm } from '../../actions'

const UpdateEntity = ({ ...props }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isRequired, setIsRequired] = useState(true)
  const [user, initUser] = useState<any>(null)

  const [choice, setChoice] = useState<any>(false)
  const [modalOn, setModalOn] = useState<any>(false)
  const [formData, setFormdata] = useState<any>(null)

  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
  })
  // console.log(`state.updateEntityForm,`,props);

  useEffect(() => {
    if (user === null) {
      setTimeout(() => {
        initUser({
          entityType: options.entityType
            .filter((item: any) => {
              if (props?.updateEntityForm?.type === 'C') {
                if (item.value === 1) {
                  return item
                }
              } else if (props?.updateEntityForm?.type === 'HN') {
                if (item.value === 0) {
                  return item
                }
              }
            })
            ?.map((item: any) => {
              return item
            }),
          phoneTwo: props?.updateEntityForm?.mobile
            ? props?.updateEntityForm?.mobile
            : null,

          phone: props?.updateEntityForm?.localphone
            ? props?.updateEntityForm?.localphone
            : null,
          firstLline: props?.updateEntityForm?.Address_firstLine,
          secondLline: props?.updateEntityForm?.Address_secondLine,
          pobox: props?.updateEntityForm?.Address_pobox,
          country: options.state
            .filter((item: any) => {
              if (item?.value === props?.updateEntityForm?.countryId) {
                return item
              }
            })
            ?.map((item: any) => {
              return item
            })[0],
          state: options.city
            .filter((item: any) => {
              if (item?.label === props?.updateEntityForm?.Address_state) {
                return item
              }
            })
            ?.map((item: any) => {
              return item
            })[0],
          zipCode: props?.updateEntityForm?.Address_zipCode,
          legalName:
            props?.updateEntityForm?.type === 'C'
              ? props?.updateEntityForm?.clinic_legalName
              : props?.updateEntityForm?.nursing_home_legalName,
          comercialName:
            props?.updateEntityForm?.type === 'C'
              ? props?.updateEntityForm?.clinic_comercialName
              : props?.updateEntityForm?.nursing_home_comercialName,
          webPage:
            props?.updateEntityForm?.type === 'C'
              ? props?.updateEntityForm?.clinic_webPage
              : props?.updateEntityForm?.nursing_home_webPage,
          email:
            props?.updateEntityForm?.type === 'C'
              ? props?.updateEntityForm?.clinic_email
              : props?.updateEntityForm?.nursing_home_email,
          npi:
            props?.updateEntityForm?.type === 'C'
              ? props?.updateEntityForm?.clinic_npi
              : props?.updateEntityForm?.nursing_home_npi,
          ein:
            props?.updateEntityForm?.type === 'C'
              ? props?.updateEntityForm?.clinic_ein
              : props?.updateEntityForm?.nursing_home_ein,
          isMobilePatient: false,
          phoneExt: props?.updateEntityForm?.extension
            ? props?.updateEntityForm?.extension
            : null,
          nursingHomeId: props?.updateEntityForm?.nursing_home_id,
          poboxState: options.city
            .filter((item: any) => {
              if (item?.label === props?.updateEntityForm?.Address_statePoBox) {
                return item
              }
            })
            ?.map((item: any) => {
              return item
            })[0],
          poboxZipCode: props?.updateEntityForm?.Address_zipCodePoBox
            ? props?.updateEntityForm?.Address_zipCodePoBox
            : null,
          poboxCountry: options.state
            .filter((item: any) => {
              if (item?.value === props?.updateEntityForm?.countryPoBoxId) {
                return item
              }
            })
            ?.map((item: any) => {
              return item
            })[0],
        })
      }, 1000)
    }
  })

  useEffect(() => {
    reset(user)
  }, [user])

  const onSubmit = async () => {
    setIsLoading(false)

    // console.log(formData)

    let selectedEntity: any = formData?.entityType[0]?.label
    let data: any
    let dataUpdate: any = null
    //data.nursingHomeId = props?.updateEntityForm?.nursing_home_id;
    formData.nursingHomeId = props?.updateEntityForm?.nursing_home_id
    //data.clinic_id = props?.updateEntityForm?.clinic_id;
    formData.clinic_id = props?.updateEntityForm?.clinic_id

    // console.log(`data on submit:\n${JSON.stringify(data, null, 4)}`);
    if (props?.updateEntityForm?.type === 'HN') {
      //dataUpdate = await updateEntity.nursingHomeUpdate(data);
      dataUpdate = await updateEntity.nursingHomeUpdate(formData)
      data = await List.home({
        id: dataUpdate?.data[0]?.nursing_home?.id,
      })
      props.setUpdateEntityForm(data)
    } else {
      //dataUpdate = await updateEntity.clinicUpdate(data);
      dataUpdate = await updateEntity.clinicUpdate(formData)
      data = await List.clinic({
        id: dataUpdate?.data[0]?.clinicUpdate?.id,
      })
      props.setUpdateEntityForm(data)
    }
    /////console.log(`dataUpdate:\n${JSON.stringify(dataUpdate, null, 4)}`);

    if (dataUpdate.code >= 200 && dataUpdate.code <= 299) {
      setIsLoading(false)
      clearUpdateEntityForm(null)
      reset({
        legalName: null,
        comercialName: null,
        webPage: null,
        email: null,
        firstLline: null,
        secondLline: null,
        entityType: null,
        ein: '',
        npi: '',
        phoneTwo: '',
        phone: '',
        phoneExt: '',
        state: null,
        zipCode: '',
        country: null,
        pobox: '',
        poboxState: null,
        poboxZipCode: '',
        poboxCountry: null,
      })
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Confirmación',
        text: '¡Entidad actualizada con éxito!',
        icon: 'success',
        // icon: "error",
        //cancelButtonText: 'Volver al formulario',
        confirmButtonText: 'Ver perfil',
        showCancelButton: false,
        confirmButtonColor: 'rgb(14 165 233)',
        cancelButtonColor: 'rgb(14 165 233)',
      }).then(async (result) => {
        // console.log('result:', result);
        if (result.isConfirmed) {
          // console.log(`formData?.entityType: `,formData?.entityType?.label);
          // console.log(`response?.data[0]?.id`,dataUpdate?.data[0]);
          // console.log(formData?.entityType?.label === 'Clínica')

          if (selectedEntity === 'Clínica') {
            data.type = 'C'
            navigate('/entities/profile')
            // navigator('/update/entity');
          } else if (selectedEntity === 'Nursing Home') {
            data.type = 'HN'
            navigate('/entities/profile')
            // navigator('/update/entity');
          }
          //navigate(`/entities/profile/information`);
        } else {
          setIsLoading(true)
          reset({})
        }
      })
      setIsLoading(true)
      //initUser({});
      //navigate(`/entities/profile/information`);
    } else {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      //setIsLoading(true);
    }
    setIsLoading(true)
  }

  const beforeRegisterEntity = (data: any) => {
    setModalOn(true)
    setFormdata(data)
    setIsLoading(true)
  }

  return (
    <>
      {modalOn && (
        <SumaryEntityRegisterData
          formData={formData}
          setChoice={setChoice}
          setModalOn={setModalOn}
          onSubmit={onSubmit}
          setIsLoading={setIsLoading}
        />
      )}
      <div className="w-full space-y-7 ">
        <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
          <div className="flex flex-col md:flex-row gap-1">
            <div className="flex flex-row">
              <img src={Entities} className={`w-20`} alt="" />
              <h1 className="w-full self-center text-[#96c952] sm:text-4xl font-extrabold text-2xl ">
                Actualización de Entidad
              </h1>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(beforeRegisterEntity)} onReset={reset}>
          <section className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-3 place-items-center flex flex-col py-4">
            <EntityForm
              Controller={Controller}
              Select={Select}
              options={options}
              customStyles={customStyles}
              NumberFormat={NumberFormat}
              emailValidation={emailValidation}
              websiteValidation={websiteValidation}
              Switch={Switch}
              register={register}
              errors={errors}
              isValid={isValid}
              isDirty={isDirty}
              control={control}
              isRequired={isRequired}
              setIsRequired={setIsRequired}
              CustomValueContainer={CustomValueContainer}
              mode={''}
              watch={watch}
            />
            <div className="lg:col-span-2 xl:col-span-3 my-5">
              {isLoading ? (
                <div id="next" className="has-tooltip w-fit h-fit">
                  <input
                    type={'button'}
                    value="Volver"
                    className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                    onClick={() => {
                      navigate(`/entities/profile`)
                    }}
                  />
                  {!isValid ? (
                    <span className="tooltip rounded shadow-lg p-1 bg-gray-800 text-white -mt-12">
                      Para continuar, debe completar los campos requeridos
                      señalados con *
                    </span>
                  ) : null}
                  <input
                    type="submit"
                    disabled={!isValid}
                    value="Editar Entidad"
                    className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  />
                </div>
              ) : (
                <input
                  type="submit"
                  disabled
                  value="Cargando..."
                  className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                />
              )}
              {/* {isLoading ? (
								<>
									<input
										type={'button'}
										value="Volver"
										className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
										onClick={() => {
											navigate(`/entities/profile`);
										}}
									/>
									<input
										type="submit"
										value="Actualizar"
										disabled={!isValid}
										className="rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
									/>
								</>
							) : (
								<input
									type="submit"
									value="Cargando..."
									disabled
									className="rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
								/>
							)} */}
            </div>
          </section>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    updateEntityForm: state.updateEntityForm,
  }
}

const mapDispatchToProps = {
  clearUpdateEntityForm,
  setUpdateEntityForm,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEntity)
// export default UpdateEntity;
