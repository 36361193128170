import React from 'react'
import AppointmentForm from './AppointmentForm'
import CustomValueContainer from '../../ReactSelectComponents/CustomValueContainer'
import { customStyles } from '../../customStyleNewLabel'
import { connect } from 'react-redux'
import { ROLEID } from '../../../utils/enums/Roles'
import useGetUserTypeI from '../../../hooks/useGetUserTypeI'

const UpdateAppointmentCalendar = ({ ...props }) => {
  const {
    returnViewTwo,
    Controller,
    control,
    Select,
    errors,
    DatePicker,
    register,
    watch,
    handleSubmit,
    reset,
    updateEvent,
    calendars,
    isLoadingUserType,
    errorUserType,
    dataUserType,
    options,
    updateMode,
  } = props
  let obj: any

  return (
    <div className="w-full space-y-7 ">
      <div className="h-full w-full space-y-7 px-7">
        <form onSubmit={handleSubmit(updateEvent)} onReset={reset}>
          <div className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-4 place-items-center flex flex-col">
            <AppointmentForm
              Controller={Controller}
              control={control}
              Select={Select}
              CustomValueContainer={CustomValueContainer}
              customStyles={customStyles}
              errors={errors}
              isLoadingUser={isLoadingUserType}
              data={dataUserType}
              DatePicker={DatePicker}
              register={register}
              options={options}
              obj={obj}
              isObject={[{}]}
              watch={watch}
              doctorId={props?.state?.currentUser?.id}
              doctorRol={props?.state?.currentUser?.roles[0].roleId}
              ROLEID={ROLEID.DOCTOR}
              calendars={calendars}
              title={'Actualización de cita'}
              updateMode={updateMode}
            />
            <div className="lg:col-span-2 xl:col-span-3 my-5 space-x-4">
              <input
                type="button"
                onClick={returnViewTwo}
                value="Volver"
                className="lg:col-span-2 xl:col-span-3 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:text-black disabled:cursor-not-allowed"
              />
              <input
                type="submit"
                value="Actualizar"
                className="lg:col-span-2 xl:col-span-3 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:text-black disabled:cursor-not-allowed"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    state: {
      currentUser: state.currentUser,
    },
  }
}

export default connect(mapStateToProps, null)(UpdateAppointmentCalendar)
