import InformationLaboral from '../../assets/svg/PatientItems/informacionLaboral.svg'
import InformationPersonal from '../../assets/svg/PatientItems/informacionPersonal.svg'
const SumaryDoctorRegister = ({
  setModalOn,
  setChoice,
  registerUser,
  setIsLoading,
  formData,

  actionBotton,
  deleteAction,
}: any) => {
  const handleOKClick = () => {
    if (actionBotton !== 'delete') {
      registerUser()
    } else {
      deleteAction()
    }
    setChoice(true)
    setModalOn(false)
  }

  const handleCancelClick = () => {
    if (actionBotton !== 'delete') {
      setIsLoading(true)
    }
    setChoice(false)
    setModalOn(false)
  }
  // console.log(`formData`, formData)

  return (
    <div className=" opacity-100 fixed inset-0 z-50">
      <div className="flex h-screen justify-center items-center ">
        <div className="flex-col justify-center rounded-md bg-white py-10 px-16 grid gap-5 border border-gray-200">
          {actionBotton !== 'delete' ? (
            <div className="flex flex-col text-lg text-zinc-600 max-w-xl max-h-[30rem] space-y-4 overflow-y-auto">
              <p className="font-bold text-2xl text-center">
                ¿Esta seguro de registrar este Doctor?
              </p>
              <div className="flex flex-col">
                <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
                  <img src={InformationPersonal} className={`w-7`} alt="" />

                  <h1 className="text-xl">{`Información Personal`}</h1>
                </div>
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Especialidad: </b>
                  <p>{formData?.specialtyDoctor[0]?.doctorSpecialty?.label}</p>
                </div>
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Primer nombre: </b>
                  <p>{formData?.specialtyDoctor[0]?.doctorFirstname}</p>
                </div>
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Primer apellido: </b>
                  <p>{formData?.specialtyDoctor[0]?.doctorSurname}</p>
                </div>
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Número Celular: </b>
                  <p>{formData?.specialtyDoctor[0]?.doctorPhone}</p>
                </div>
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Correo electrónico: </b>
                  <p>{formData?.specialtyDoctor[0]?.doctorEmail}</p>
                </div>
                <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
                  <img src={InformationLaboral} className={`w-7`} alt="" />
                  <h1 className="text-xl first-line:capitalize">
                    {`Información de la Clínica`}
                  </h1>
                </div>
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Record Médico de Paciente: </b>
                  <p>{formData?.specialtyDoctor[0]?.clinicalMedicalRecord}</p>
                </div>
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Nombre de Clínica: </b>
                  <p>{formData?.specialtyDoctor[0]?.clinicalName}</p>
                </div>
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Teléfono Fijo: </b>
                  <p>{formData?.specialtyDoctor[0]?.doctorLocalPhone}</p>
                </div>
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Extensión Teléfono Fijo: </b>
                  <p>{formData?.specialtyDoctor[0]?.extDoctorLocalPhone}</p>
                </div>
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Dirección Linea 1: </b>
                  <p>{formData?.specialtyDoctor[0]?.doctorJobAddress}</p>
                </div>
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Dirección Linea 2: </b>
                  <p>{formData?.specialtyDoctor[0]?.doctorJobAddressAlt}</p>
                </div>
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Pueblo: </b>
                  <p>{formData?.specialtyDoctor[0]?.doctorJobState?.label}</p>
                </div>
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Código postal: </b>
                  <p>{formData?.specialtyDoctor[0]?.doctorZipCode}</p>
                </div>
                <div className="flex flex-row gap-1">
                  <b className="font-bold">País: </b>
                  <p>{formData?.specialtyDoctor[0]?.doctorJobCountry?.label}</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col text-lg text-zinc-600 max-w-xl max-h-[30rem] space-y-4 overflow-y-auto">
              <p className="font-bold text-2xl text-center">
                ¿Esta seguro de eliminar esta Doctor?
              </p>
            </div>
          )}
          <div className="flex flex-row justify-center">
            <button
              onClick={handleOKClick}
              className=" rounded px-4 py-2 text-white bg-green-400 "
            >
              Si
            </button>
            <button
              onClick={handleCancelClick}
              className="rounded px-4 py-2 ml-4 text-white bg-blue-500 "
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SumaryDoctorRegister
