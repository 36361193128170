/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { connect } from 'react-redux'
import { clearUpdatePatientAllerProfCondForm } from '../../actions'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import Select, { components } from 'react-select'
import React, { useEffect, useState } from 'react'
import useGetAllergyConditionSurgery from '../../hooks/useGetAllergyConditionSurgery'
import { customStyles } from '../customStyleNewLabel'
import updatePatientASC from '../../services/updatePatientASC'
import AllergyForm from '../RegisterPatient/ConditionAllergySurgery/Allergy'
import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline'
import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer'
import Swal from 'sweetalert2'
import SumaryAllergyRegister from '../Modals/SumaryAllergyRegister'

const UpdateAllergyForm = (props: any) => {
  const [user, initUser] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(true)

  const [modalOn, setModalOn] = useState<any>(false)
  const [formData, setFormData] = useState<any>(null)
  const [choice, setChoice] = useState<any>(false)

  const navigate = useNavigate()
  //console.log(`perico state:\n${JSON.stringify(location, null, 4)}`);
  const {
    register,
    watch,
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty, isSubmitSuccessful },
  } = useForm({
    // resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      allergy: [{}],
    },
  })
  let {
    listData: acsListData,
    error: acsError,
    isLoading: acsIsLoading,
  }: any = useGetAllergyConditionSurgery()

  const {
    fields: allergyFields,
    append: allergyAppend,
    remove: allergyRemove,
  } = useFieldArray({
    name: 'allergy',
    control,
  })

  //console.log(`props:\n${JSON.stringify(props, null, 4)}`);
  //console.log(`acsListData:\n${JSON.stringify(acsListData, null, 4)}`);

  useEffect(() => {
    if (user === null) {
      setTimeout(() => {
        initUser({
          description: props?.updatePatientAllerProfCondForm?.description,
          item: acsListData.allergies
            .filter((item: any) => {
              if (item?.value === props?.updatePatientAllerProfCondForm?.id) {
                return item
              }
            })
            .map((item: any) => {
              return item
            })[0],
        })
      }, 1000)
    }
  })

  //console.log(`user: \n${JSON.stringify(user)}`);
  useEffect(() => {
    reset({ allergy: [user] })
  }, [user])

  const registerUser = async () => {
    setIsLoading(false)
    formData.allergy[0].id = props?.updatePatientAllerProfCondForm?.patient
    formData.allergy[0].afectionId = props?.updatePatientAllerProfCondForm?.pcmid
    const dataUpdate: any = await updatePatientASC.UpdatePatientASC(
      formData.allergy[0],
    )
    if (dataUpdate?.code >= 200 && dataUpdate?.code < 300) {
      //alert(JSON.stringify(values, null, 4));
      clearUpdatePatientAllerProfCondForm(null)
      reset({
        allergy: [
          {
            description: '',
            item: {},
          },
        ],
      })
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Confirmación',
        text: `¡Ha sido registrado correctamente!`,
        icon: 'success',
        // icon: "error",
        //cancelButtonText: 'Volver al formulario',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
        confirmButtonColor: 'rgb(14 165 233)',
        cancelButtonColor: 'rgb(14 165 233)',
      }).then((res) => {
        setIsLoading(true)
        if (res.isConfirmed) {
          console.log(dataUpdate?.data[0])
          navigate(
            `/patientprofile/${props?.updatePatientAllerProfCondForm?.patient}/`,
          )
        } else {
          setIsLoading(true)
        }
      })
      //initUser({})
      navigate(
        `/patientprofile/${props?.updatePatientAllerProfCondForm?.patient}/`,
      )
    } else {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      setIsLoading(true)
    }
    setIsLoading(true)
  }
  // const trashSubmit = async (values: any) => {
  //   setActionBotton('delete');
  //   //console.log(`values ${JSON.stringify(values, null, 4)}`);
  //   const dataUpdate: any = await updatePatientASC.DeletePatientASC(values)
  //   if (dataUpdate.code === 200) {
  //     navigate(
  //       `/patientprofile/${props?.updatePatientAllerProfCondForm?.patient}/`,
  //     )
  //   } else {
  //     navigate(
  //       `/patientprofile/${props?.updatePatientAllerProfCondForm?.patient}/`,
  //     )
  //   }
  // }
  
  const onSubmit = async (data: any) => {
    // console.log(`onSubmit`, data);
    setModalOn(true)
    setFormData(data)
    setIsLoading(false)
  }

  return (
    <>
      {modalOn && (
        <SumaryAllergyRegister
          formData={formData}
          setChoice={setChoice}
          setModalOn={setModalOn}
          registerUser={registerUser}
          setIsLoading={setIsLoading}
        />
      )}
      <div className="w-full space-y-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
            <PencilAltIcon strokeWidth={1.3} color="#294fa9" className="w-20" />
            <h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
              Editando Alergia
            </h2>
          </div>
          <div className="lg:w-fit mx-auto place-items-center flex flex-col space-y-7  p-7">
            <AllergyForm
              Controller={Controller}
              control={control}
              Select={Select}
              register={register}
              allergyFields={allergyFields}
              allergyAppend={allergyAppend}
              allergyRemove={allergyRemove}
              acsListData={acsListData}
              acsError={acsError}
              acsIsLoading={acsIsLoading}
              customStyles={customStyles}
              CustomValueContainer={CustomValueContainer}
              mode={'update'}
              errors={'errors'}
            />
            {isLoading ? (
              <>
                <input
                  type="button"
                  value="Volver"
                  className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  onClick={() => {
                    navigate(
                      `/patientprofile/${props?.updatePatientAllerProfCondForm?.patient}/`,
                    )
                  }}
                />
                <input
                  type="submit"
                  value="Guardar"
                  className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                />
                {/* <div
                  className="md:w-auto h-10 flex flex-row w-full gap-1 place-items-center justify-center rounded-lg px-4 py-2 bg-[#f61e1e] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-slate-600 text-white hover:border-red-700"
                  onClick={() =>
                    trashSubmit(props?.updatePatientAllerProfCondForm)
                  }
                >
                  <TrashIcon strokeWidth={1.3} className="w-4 border-b-2" />
                  Eliminar
                </div> */}
              </>
            ) : (
              <>
                <input
                  type="submit"
                  disabled
                  value="Cargando..."
                  className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
                />
              </>
            )}
            <div className="lg:col-span-2 w-full xl:col-span-3 justify-center place-items-center center flex md:flex-row flex-col gap-6"></div>
          </div>
        </form>
      </div>
    </>
  )
}
const mapStateToProps = (state: any) => {
  return {
    updatePatientAllerProfCondForm: state.updatePatientAllerProfCondForm,
  }
}
const mapDispatchToProps = {
  clearUpdatePatientAllerProfCondForm,
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateAllergyForm)
