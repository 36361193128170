/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useProfilePatient from '../../hooks/useProfilePatient'
import {
  ChevronRightIcon,
  UserGroupIcon,
  PhotographIcon,
  FolderOpenIcon,
  PlusIcon,
} from '@heroicons/react/outline'
import Information from '../../assets/svg/Tutor.svg'

import Conditions from '../../assets/svg/PatientItems/Conditions.svg'
import Vaccines from '../../assets/svg/PatientItems/Vaccines.svg'
import Medicine from '../../assets/svg/PatientItems/Medicine.svg'
import Surgery from '../../assets/svg/PatientItems/Surgery.svg'
import Physicians from '../../assets/svg/PatientItems/Physicians.svg'
import PatientInfoSVG from '../../assets/svg/PatientItems/PatientInfo.svg'
import Allergy from '../../assets/svg/PatientItems/alergia.svg'
import Resident from '../../assets/svg/PatientItems/residentes.svg'
import Tutor from '../../assets/svg/PatientItems/tutores.svg'
import PatientInfo from './PatientInfo'
import TutorPatient from './TutorPatient'
import PhysicianPatient from './PhysicianPatient'
import ConditionPatient from './ConditionPatient'
import MedicationPatient from './MedicationPatient'
import SurgeryPatient from './SurgeryPatient'
import VaccinesPatient from './VaccinesPatient'
import AllergyPatient from './AllergyPatient'
import Usuario from '../../assets/svg/usuarioSearch.svg'
import PatientFiles from './PatientFiles'
import useGetAllergyConditionSurgery from '../../hooks/useGetAllergyConditionSurgery'

//import useResidentPhotos from '../../hooks/useResidentPhotos';
const PatientProfile = () => {
  const [guiLayout, setGuiLayout] = React.useState(false)
  const [buttonType, setButtonType] = React.useState('')
  const navigate = useNavigate()
  const { id } = useParams()

  //const { dataImage, isLoadingImage }: any = useResidentPhotos(id);
  const { data, error, isLoading }: any = useProfilePatient(id)
  let {
    listData: acsListData,
    // error: acsError,
    // isLoading: acsIsLoading,
  }: any = useGetAllergyConditionSurgery()
  //console.log(`acsListDataProfilePatient`, acsListData)
  //console.log(`data?.profile`,data?.profile);
  //console.log(`dataImage: ${JSON.stringify(dataImage)}`);

  const profileLayout = (textButton: string) => {
    setGuiLayout(!guiLayout)
    setButtonType(textButton)
  }

  return (
    <div className="w-full h-full">
      <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
        <img
          //src={Usuario}
          src={data?.photoURL ? data?.photoURL : Usuario}
          className={`w-20 p-1 self-center rounded-full bg-slate-500`}
          alt=""
        />
        <div className="">
          {isLoading ? (
            <div>Cargando...</div>
          ) : (
            // <h1 className="text-center text-xl text-[#96c952] font-semibold my-5">
            <h1 className=" text-2xl text-[#96c952] font-semibold my-5 justify-start">
              {`${data?.profile?.middle}`
                ? `${data?.profile?.name} ${data?.profile?.middle}`
                : `${data?.profile?.name}`}{' '}
              {`${data?.profile?.secondSurname}`
                ? `${data?.profile?.surname} ${data?.profile?.secondSurname}`
                : `${data?.profile?.surname}`}
            </h1>
          )}
        </div>
      </div>

      {isLoading ? (
        <React.Fragment>
          <div>Cargando opciones...</div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="w-full h-full flex flex-col text-center items-center p-4 overflow-scroll">
            <div
              onClick={() => profileLayout('profile')}
              className={`w-full flex flex-row place-items-center px-2 py-3 border-[1px] shadow-sm rounded-md shadow-slate-400 border-slate-300 md:w-80 cursor-pointer ${
                guiLayout ? 'hidden' : ' my-3'
              }`}
            >
              <img src={Resident} className="w-9 h-9 " alt="" />
              <p className="text-[#044382] flex-1 w-full text-center">
                Datos del Residente
              </p>
              <ChevronRightIcon className="w-9 h-9" />
            </div>

            <div
              className={`w-full px-2 flex flex-col ${
                guiLayout && buttonType === `profile` ? '' : 'hidden'
              } `}
            >
              <h1 className=" my-3 text-xl font-bold">
                Información de Residente
              </h1>

              <PatientInfo data={data?.profile} patientId={id} />
              <div className="w-full text-center items-center py-4">
                <input
                  type={'button'}
                  className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  onClick={() => {
                    profileLayout('')
                  }}
                  value="Volver"
                />
              </div>
            </div>

            <div
              onClick={() => profileLayout('tutores')}
              className={`w-full flex flex-row place-items-center px-2 py-3 border-[1px] shadow-sm rounded-md shadow-slate-400 border-slate-300 md:w-80 cursor-pointer ${
                guiLayout ? 'hidden' : ' my-3'
              }`}
            >
              <img src={Tutor} className="w-9 h-9 color" alt="" />
              <p className="text-[#044382] flex-1 w-full text-center">
                Tutores
              </p>
              <ChevronRightIcon className="w-9 h-9" />
            </div>

            <div
              className={`w-full px-2 flex flex-col ${
                guiLayout && buttonType === `tutores` ? '' : 'hidden'
              } `}
            >
              <h1 className="my-3 text-xl font-extrabold">
                Lista de Responsables
              </h1>
              <div className="flex flex-row gap-10">
                <div className={`sm:flex-1 flex flex-row`} />
                <div
                  className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
                  onClick={() => {
                    navigate('/add/tutor/', {
                      state: { patient: data?.profile?.id },
                    })
                  }}
                >
                  <PlusIcon className="w-8 h-8 " />
                  <div>Agregar Responsables</div>
                </div>
              </div>
              {data?.profile?.tutores?.map((tutor: any) => {
                return (
                  <TutorPatient
                    key={tutor.id}
                    {...tutor}
                    patient={data?.profile?.id}
                  />
                )
              })}
              <div className="w-full text-center items-center py-4">
                <input
                  type={'button'}
                  className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  onClick={() => {
                    profileLayout('')
                  }}
                  value="Volver"
                />
              </div>
            </div>

            <div
              onClick={() => profileLayout('physician')}
              className={`w-full flex flex-row place-items-center px-2 py-3 border-[1px] shadow-sm rounded-md shadow-slate-400 border-slate-300 md:w-80 cursor-pointer ${
                guiLayout ? 'hidden' : ' my-3'
              }`}
            >
              <img src={Physicians} className="w-9 h-9" alt="" />
              <p className="text-[#044382] flex-1 w-full text-center">
                Medicos
              </p>
              <ChevronRightIcon className="w-9 h-9" />
            </div>

            <div
              className={`w-full px-2 flex flex-col ${
                guiLayout && buttonType === `physician` ? '' : 'hidden'
              } `}
            >
              <h1 className="my-3 text-xl font-extrabold">
                Listado de Doctores
              </h1>
              <div className="flex flex-row gap-10">
                <div className={`sm:flex-1 flex flex-row`} />
                <div
                  className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
                  onClick={() => {
                    navigate('/add/doctor/', {
                      state: { patient: data?.profile?.id },
                    })
                  }}
                >
                  <PlusIcon className="w-8 h-8 " />
                  <div>Agregar Doctor</div>
                </div>
              </div>
              {data?.profile?.doctors?.map((doctors: any) => {
                return (
                  <PhysicianPatient
                    key={doctors.id}
                    {...doctors}
                    patient={data?.profile?.id}
                  />
                )
              })}
              <div className="w-full text-center items-center py-4">
                <input
                  type={'button'}
                  className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  onClick={() => {
                    profileLayout('')
                  }}
                  value="Volver"
                />
              </div>
            </div>

            <div
              onClick={() => profileLayout('condition')}
              className={`w-full flex flex-row place-items-center px-2 py-3 border-[1px] shadow-sm rounded-md shadow-slate-400 border-slate-300 md:w-80 cursor-pointer ${
                guiLayout ? 'hidden' : ' my-3'
              }`}
            >
              <img src={Conditions} className="w-9 h-9" alt="" />
              <p className="text-[#044382] flex-1 w-full text-center">
                Condiciones
              </p>
              <ChevronRightIcon className="w-9 h-9" />
            </div>

            <div
              className={`w-full px-2 flex flex-col ${
                guiLayout && buttonType === `condition` ? '' : 'hidden'
              } `}
            >
              <h1 className="my-3 text-xl font-bold">Listado de Condiciones</h1>
              <div className="flex flex-row gap-10">
                <div className={`sm:flex-1 flex flex-row`} />
                <div
                  className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
                  onClick={() => {
                    navigate('/add/condition/', {
                      state: { patient: data?.profile?.id },
                    })
                  }}
                >
                  <PlusIcon className="w-8 h-8 " />
                  <div>Agregar Condicion</div>
                </div>
              </div>
              {data?.profile?.conditions?.map((conditions: any) => {
                return (
                  <ConditionPatient
                    key={conditions.pcmid}
                    {...conditions}
                    patient={data?.profile?.id}
                  />
                )
              })}
              <div className="w-full text-center items-center py-4">
                <input
                  type={'button'}
                  className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  onClick={() => {
                    profileLayout('')
                  }}
                  value="Volver"
                />
              </div>
            </div>

            <div
              onClick={() => profileLayout('allergy')}
              className={`w-full flex flex-row place-items-center px-2 py-3 border-[1px] shadow-sm rounded-md shadow-slate-400 border-slate-300 md:w-80 cursor-pointer ${
                guiLayout ? 'hidden' : ' my-3'
              }`}
            >
              <img src={Allergy} className="w-9 h-9" alt="" />
              <p className="text-[#044382] flex-1 w-full text-center">
                Alergias
              </p>
              <ChevronRightIcon className="w-9 h-9" />
            </div>

            <div
              className={`w-full px-2 flex flex-col ${
                guiLayout && buttonType === `allergy` ? '' : 'hidden'
              } `}
            >
              <h1 className="my-3 text-xl font-bold">Listado de Alergias</h1>
              <div className="flex flex-row gap-10">
                <div className={`sm:flex-1 flex flex-row`} />
                <div
                  className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
                  onClick={() => {
                    navigate('/add/allergy/', {
                      state: { patient: data?.profile?.id ,acsListData},
                    })
                  }}
                >
                  <PlusIcon className="w-8 h-8 " />
                  <div>Agregar Alergia</div>
                </div>
              </div>
              {data?.profile?.allergy?.map((allergies: any) => {
                return (
                  <AllergyPatient
                    key={allergies.pcmid}
                    {...allergies}
                    patient={data?.profile?.id}
                    acsListData={acsListData}
                  />
                )
              })}
              <div className="w-full text-center items-center py-4">
                <input
                  type={'button'}
                  className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  onClick={() => {
                    profileLayout('')
                  }}
                  value="Volver"
                />
              </div>
            </div>

            <div
              onClick={() => profileLayout('surgery')}
              className={`w-full flex flex-row place-items-center px-2 py-3 border-[1px] shadow-sm rounded-md shadow-slate-400 border-slate-300 md:w-80 cursor-pointer ${
                guiLayout ? 'hidden' : ' my-3'
              }`}
            >
              <img src={Surgery} className="w-9 h-9" alt="" />
              <p className="text-[#044382] flex-1 w-full text-center">
                Cirugías
              </p>
              <ChevronRightIcon className="w-9 h-9" />
            </div>

            <div
              className={`w-full px-2 flex flex-col ${
                guiLayout && buttonType === `surgery` ? '' : 'hidden'
              } `}
            >
              <h1 className="my-3 text-xl">Listado de Cirugías</h1>
              <div className="flex flex-row gap-10">
                <div className={`sm:flex-1 flex flex-row`} />
                <div
                  className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
                  onClick={() => {
                    navigate('/add/surgery/', {
                      state: { patient: data?.profile?.id },
                    })
                  }}
                >
                  <PlusIcon className="w-8 h-8 " />
                  <div>Agregar Cirugía</div>
                </div>
              </div>
              {data?.profile?.surgery?.map((surgery: any) => {
                return (
                  <SurgeryPatient
                    key={surgery.pcmid}
                    {...surgery}
                    patient={data?.profile?.id}
                  />
                )
              })}
              <div className="w-full text-center items-center py-4">
                <input
                  type={'button'}
                  className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  onClick={() => {
                    profileLayout('')
                  }}
                  value="Volver"
                />
              </div>
            </div>

            <div
              onClick={() => profileLayout('medication')}
              className={`w-full flex flex-row place-items-center px-2 py-3 border-[1px] shadow-sm rounded-md shadow-slate-400 border-slate-300 md:w-80 cursor-pointer ${
                guiLayout ? 'hidden' : ' my-3'
              }`}
            >
              <img src={Medicine} className="w-9 h-9" alt="" />
              <p className="text-[#044382] flex-1 w-full text-center">
                Medicamento
              </p>
              <ChevronRightIcon className="w-9 h-9" />
            </div>

            <div
              className={`w-full px-2 flex flex-col ${
                guiLayout && buttonType === `medication` ? '' : 'hidden'
              } `}
            >
              <h1 className="my-3 text-xl">Listado de Medicamentos</h1>
              <div className="flex flex-row gap-10">
                <div className={`sm:flex-1 flex flex-row`} />
                <div
                  className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
                  onClick={() => {
                    navigate('/add/medicine/', {
                      state: { patient: data?.profile?.id },
                    })
                  }}
                >
                  <PlusIcon className="w-8 h-8 " />
                  <div>Agregar Medicamento</div>
                </div>
              </div>
              {data?.profile?.medicine?.map((medicine: any) => (
                <MedicationPatient
                  key={medicine.id}
                  {...medicine}
                  patient={data?.profile?.id}
                />
              ))}
              <div className="w-full text-center items-center py-4">
                <input
                  type={'button'}
                  className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  onClick={() => {
                    profileLayout('')
                  }}
                  value="Volver"
                />
              </div>
            </div>

            <div
              onClick={() => profileLayout('vaccine')}
              className={`w-full flex flex-row place-items-center px-2 py-3 border-[1px] shadow-sm rounded-md shadow-slate-400 border-slate-300 md:w-80 cursor-pointer ${
                guiLayout ? 'hidden' : ' my-3'
              }`}
            >
              <img src={Vaccines} className="w-9 h-9" alt="" />
              <p className="text-[#044382] flex-1 w-full text-center">
                Vacunas
              </p>
              <ChevronRightIcon className="w-9 h-9" />
            </div>

            <div
              className={`w-full px-2 flex flex-col ${
                guiLayout && buttonType === `vaccine` ? '' : 'hidden'
              } `}
            >
              <h1 className="my-3 text-xl">Listado de Vacunas</h1>
              <div className="flex flex-row gap-10">
                <div className={`sm:flex-1 flex flex-row`} />
                <div
                  className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
                  onClick={() => {
                    navigate('/add/vaccine/', {
                      state: { patient: data?.profile?.id },
                    })
                  }}
                >
                  <PlusIcon className="w-8 h-8 " />
                  <div>Agregar Vacuna</div>
                </div>
              </div>
              {data?.profile?.vaccines?.map((vaccines: any) => (
                <VaccinesPatient
                  key={vaccines.id}
                  {...vaccines}
                  patient={data?.profile?.id}
                />
              ))}
              <div className="w-full text-center items-center py-4">
                <input
                  type={'button'}
                  className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  onClick={() => {
                    profileLayout('')
                  }}
                  value="Volver"
                />
              </div>
            </div>
            {/* archivos del residente */}
            <div
              onClick={() => profileLayout('residentFiles')}
              className={`w-full flex flex-row place-items-center px-2 py-3 border-[1px] shadow-sm rounded-md shadow-slate-400 border-slate-300 md:w-80 cursor-pointer ${
                guiLayout ? 'hidden' : ' my-3'
              }`}
            >
              <FolderOpenIcon
                strokeWidth={0.9}
                color="#294fa9"
                className="w-9 h-9"
              />
              <p className="text-[#044382] flex-1 w-full text-center">
                Archivos del Residente
              </p>
              <ChevronRightIcon className="w-9 h-9" />
            </div>

            <div
              className={`w-full px-2 flex flex-col ${
                guiLayout && buttonType === `residentFiles` ? '' : 'hidden'
              } `}
            >
              <h1 className="my-3 text-xl font-semibold">Archivos Cargados</h1>

              <PatientFiles
                photoURL={data?.photoURL}
                driverLicenseURL={data?.driverLicenseURL}
                socialSecurityCardURL={data?.socialSecurityCardURL}
                insuranceCardURL={data?.insuranceCardURL}
                patientId={id}
              />

              <div className="w-full text-center items-center py-4">
                <input
                  type={'button'}
                  className="w-28 mx-1 sm:mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  onClick={() => {
                    profileLayout('')
                  }}
                  value="Volver"
                />
              </div>
            </div>

            <div className="w-full text-center">
              <input
                type={'button'}
                //
                className={`w-fit px-4 py-2 rounded-lg border-[1px] font-bold bg-[#F6A01E] shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 duration-300 ${
                  guiLayout ? 'hidden' : ' my-3'
                }`}
                onClick={() => {
                  navigate('/patients')
                }}
                value="Volver"
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default PatientProfile
