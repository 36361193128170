import { createStore, compose } from 'redux'
import reducer from '../reducer'

const initialState = {}

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, initialState, composeEnhancers())
// console.log('Estore: ', store);

export default store
