import api from './Api'
import Swal from 'sweetalert2'
import TokenService from './Token'

export const UpdateCalendar = async (params: any) => {
  let {
    statusAssistanceTypeIdId,
    statusAssistanceTypeId,
    patientUserEntityId,
    doctorUserEntityId,
    statusPriorityId,
    insuranceCarrier,
    statusStatusIdId,
    appointmentType,
    orderAttention,
    description,
    tytocareId,
    created_by,
    calendarId,
    beginAt,
    endAt,
    id,
  } = params
  console.log('rawData', params)
  const user = TokenService.getUser()

  statusAssistanceTypeId = statusAssistanceTypeId[0]?.value
  patientUserEntityId = patientUserEntityId[0]?.value
  doctorUserEntityId = doctorUserEntityId.value
  statusPriorityId = statusPriorityId[0]?.value
  calendarId = calendarId?.value
  appointmentType = appointmentType[0]?.value
  beginAt = beginAt.toISOString()
  endAt = endAt.toISOString()

  console.log('formatedData', {
    statusAssistanceTypeIdId,
    statusAssistanceTypeId,
    patientUserEntityId,
    doctorUserEntityId,
    statusPriorityId,
    insuranceCarrier,
    statusStatusIdId,
    appointmentType,
    orderAttention,
    description,
    tytocareId,
    created_by,
    calendarId,
    beginAt,
    endAt,
    id,
  })
  return await api.instanceEvents
    .post(
      '/event/update-Date',
      {
        statusAssistanceTypeIdId,
        statusAssistanceTypeId,
        patientUserEntityId,
        doctorUserEntityId,
        statusPriorityId,
        insuranceCarrier,
        statusStatusIdId,
        appointmentType,
        orderAttention,
        description,
        tytocareId,
        created_by,
        calendarId,
        beginAt,
        endAt,
        id,
      },
      {
        headers: {
          Authorization: `${user.TokenType} ${user.IdToken}`,
          USER_ENTITY_ID: `${TokenService.getRole()}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    )
    .then((res) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmación',
        text: 'El evento fue actualizado correctamente',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return res
    })
    .catch((err) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Vuelva a intertarlo nuevamente',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return err
    })
}
