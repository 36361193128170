/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { dataTransformer } from '../utils/DataTransformer/filterPatients/dataTransformer'
import api from '../services/Api'
import { ROLEID } from '../utils/enums/Roles'

const useListUserType = () => {
  const [doctorList, setDoctorList] = useState<any>()
  const [secretaryList, setSecretaryList] = useState<any>()
  const [nurseList, setNurseList] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchdata = async () => {
      let user: any = localStorage.getItem('user')

      await api.instanceUsers
        .post(
          `/user/get-user-type/`,
          { id: [
            ROLEID.DOCTOR
            ,ROLEID.SECRETARY
            ,ROLEID.NURSE
            ,ROLEID.PATIENT
            ,ROLEID.ADMIN
            ,ROLEID.CARER
            ,ROLEID.ADMIN_NURSING_HOME
            ,ROLEID.ADMIN_CLINIC
            ,ROLEID.PROJECT_MANAGER
            ,ROLEID.PROJECT_ASSISTANT
            ,ROLEID.PROJECT_EVALUATOR
          ] },
          {
            headers: {
              Authorization: `${JSON.parse(user).TokenType} ${
                JSON.parse(user).IdToken
              }`,
              'Content-Type': 'application/json',
              USER_ENTITY_ID: `${localStorage.getItem('role')}`,
            },
            withCredentials: true,
          },
        )
        .then((response: any) => {
          setDoctorList(dataTransformer(response.data.data[0]))
        })
        .catch((err) => {
          setDoctorList(null)
          // console.log(err.response);
          // console.log(err?.response?.status);
          if ([401].includes(err?.response?.status) && doctorList === null) {
            fetchdata()
          }
        })

      // await api.instanceUsers
      //   .post(
      //     `/user/get-user-type/`,
      //     { id: ROLEID.SECRETARY },
      //     {
      //       headers: {
      //         Authorization: `${JSON.parse(user).TokenType} ${
      //           JSON.parse(user).IdToken
      //         }`,
      //         'Content-Type': 'application/json',
      //         USER_ENTITY_ID: `${localStorage.getItem('role')}`,
      //       },
      //       withCredentials: true,
      //     },
      //   )
      //   .then((response: any) => {
      //     setSecretaryList(dataTransformer(response.data.data[0]))
      //   })
      //   .catch((err) => {
      //     setSecretaryList(null)
      //     // console.log(err.response);
      //     // console.log(err?.response?.status);
      //     if ([401].includes(err?.response?.status) && secretaryList === null) {
      //       fetchdata()
      //     }
      //   })

      // await api.instanceUsers
      //   .post(
      //     `/user/get-user-type/`,
      //     { id: ROLEID.NURSE },
      //     {
      //       headers: {
      //         Authorization: `${JSON.parse(user).TokenType} ${
      //           JSON.parse(user).IdToken
      //         }`,
      //         'Content-Type': 'application/json',
      //         USER_ENTITY_ID: `${localStorage.getItem('role')}`,
      //       },
      //       withCredentials: true,
      //     },
      //   )
      //   .then((response: any) => {
      //     setNurseList(dataTransformer(response.data.data[0]))
      //   })
      //   .catch((err) => {
      //     setNurseList(null)
      //     // console.log(err.response);
      //     // console.log(err?.response?.status);
      //     if ([401].includes(err?.response?.status) && nurseList === null) {
      //       fetchdata()
      //     }
      //   })

      setIsLoading(false)
    }
    fetchdata()
  }, [])
  return { doctorList, secretaryList, nurseList, isLoading }
}

export default useListUserType
