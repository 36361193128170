import { ROLES } from '../../utils/enums/Roles'
import { rolesAccess } from '../../utils/Interfaces/accessRoles'

let ctryp: boolean = false

const logicalRolSidebar = (data: any): rolesAccess => {
  const residentActions: {
    createNote: boolean
    createAppoinment: boolean
    watchProfile: boolean
  } = {
    createNote: [
      ROLES.ADMIN,
      ROLES.NURSE,
      ROLES.DOCTOR,
      ROLES.SECRETARY,
    ]?.includes(data?.roles[0]?.roleName)
      ? true
      : ctryp,
    createAppoinment: [
      ROLES.ADMIN,
      ROLES.NURSE,
      ROLES.DOCTOR,
      ROLES.SECRETARY,
    ]?.includes(data?.roles[0]?.roleName)
      ? true
      : ctryp,
    watchProfile: [
      ROLES.ADMIN,
      ROLES.NURSE,
      ROLES.DOCTOR,
      ROLES.SECRETARY,
      ROLES.CARER,
      ROLES.ADMIN_NURSING_HOME,
      ROLES.ADMIN_CLINIC,
    ]?.includes(data?.roles[0]?.roleName)
      ? true
      : ctryp,
  }

  const entityActionClinic: boolean = [
    ROLES.ADMIN,
    ROLES.ADMIN_CLINIC,
    ROLES.PROJECT_MANAGER,
    ROLES.PROJECT_EVALUATOR,
    ROLES.PROJECT_ASSISTANT,
  ]?.includes(data?.roles[0]?.roleName)
    ? true
    : ctryp

  const entityActionHome: boolean = [
    ROLES.ADMIN,
    ROLES.PROJECT_MANAGER,
    ROLES.PROJECT_ASSISTANT,
    ROLES.PROJECT_EVALUATOR,
    ROLES.ADMIN_NURSING_HOME,
    ROLES.NURSE,
    ROLES.CARER,
    ROLES.DOCTOR,
  ]?.includes(data?.roles[0]?.roleName)
    ? true
    : ctryp

  const statics: boolean = [
    ROLES.ADMIN,
    ROLES.PROJECT_MANAGER,
    ROLES.PROJECT_ASSISTANT,
    ROLES.PROJECT_EVALUATOR,
    ROLES.ADMIN_NURSING_HOME,
    ROLES.ADMIN_CLINIC,
    ROLES.NURSE,
    ROLES.DOCTOR,
  ].includes(data?.roles[0]?.roleName)
    ? true
    : ctryp

  const calendarView: boolean = [
    ROLES.ADMIN_CLINIC,
    ROLES.NURSE,
    ROLES.ADMIN_NURSING_HOME,
    ROLES.SECRETARY,
    ROLES.DOCTOR,
  ].includes(data?.roles[0]?.roleName)
    ? true
    : ctryp

  const linkEntity: boolean = [ROLES.ADMIN, ROLES.ADMIN_CLINIC].includes(
    data?.roles[0]?.roleName,
  )
    ? true
    : ctryp
  const calendarOption: boolean = [
    ROLES.ADMIN,
    ROLES.ADMIN_NURSING_HOME,
    ROLES.NURSE,
    ROLES.SECRETARY,
  ].includes(data?.roles[0]?.roleName)
    ? true
    : ctryp

  const entityOption: boolean = [
    ROLES.ADMIN_NURSING_HOME,
    ROLES.NURSE,
    ROLES.CARER,
    ROLES.ADMIN_CLINIC,
    ROLES.DOCTOR,
  ].includes(data?.roles[0]?.roleName)
    ? true
    : ctryp

  const searchOption: { resident: boolean; entity: boolean } = {
    resident: [
      ROLES.ADMIN,
      ROLES.ADMIN_NURSING_HOME,
      ROLES.NURSE,
      ROLES.CARER,
      ROLES.ADMIN_CLINIC,
      ROLES.DOCTOR,
    ].includes(data?.roles[0]?.roleName)
      ? true
      : ctryp,
    entity: [
      ROLES.ADMIN,
      ROLES.PROJECT_MANAGER,
      ROLES.PROJECT_EVALUATOR,
      ROLES.PROJECT_ASSISTANT,
    ].includes(data?.roles[0]?.roleName)
      ? true
      : ctryp,
  }

  const registerOption: {
    resident: boolean
    entity: boolean
    user: boolean
  } = {
    resident: [ROLES.ADMIN_NURSING_HOME, ROLES.NURSE].includes(
      data?.roles[0]?.roleName,
    )
      ? true
      : ctryp,
    entity:
      [ROLES.ADMIN, ROLES.PROJECT_MANAGER, ROLES.PROJECT_ASSISTANT].includes(
        data?.roles[0]?.roleName,
      ) === true
        ? true
        : false,
    user:
      [
        ROLES.ADMIN,
        ROLES.ADMIN_NURSING_HOME,
        ROLES.PROJECT_MANAGER,
        ROLES.PROJECT_ASSISTANT,
        ROLES.ADMIN_CLINIC,
        ROLES.DOCTOR,
      ].includes(data?.roles[0]?.roleName) === true
        ? true
        : ctryp,
  }

  const changeEntity: boolean =
    [
      ROLES.ADMIN,
      ROLES.NURSE,
      ROLES.CARER,
      ROLES.DOCTOR,
      ROLES.SECRETARY,
      ROLES.ADMIN_NURSING_HOME,
    ].includes(data?.roles[0]?.roleName) === true
      ? true
      : false

  const profileAcceseModifyData: boolean =
    [ROLES.ADMIN].includes(data?.roles[0]?.roleName) === true ? ctryp : true
  return {
    profileAcceseModifyData,
    statics,
    calendarView,
    entityOption,
    searchOption,
    registerOption,
    calendarOption,
    linkEntity,
    entityActionClinic,
    entityActionHome,
    changeEntity,
    residentActions,
  }
}

export default logicalRolSidebar
