/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import DatePicker from 'react-datepicker'
import Select, { components } from 'react-select'
import Switch from 'react-switch'
import { CalendarIcon, ClockIcon } from '@heroicons/react/outline'
import { customStyles } from '../customStyleNewLabel'
import useGetListPatient from '../../hooks/useGetListNursing'
import { CreateCalendar } from '../../services/CreateCalendar'
import moment from 'moment-timezone'
import useGetUserTypeI from '../../hooks/useGetUserTypeI'
import Calendario from '../../assets/svg/calendario.svg'
import { connect } from 'react-redux'
import BloqueTiempo from '../../assets/svg/reservationCalendar/bloque-de-tiempo.svg'
import DiasHabiles from '../../assets/svg/reservationCalendar/dias-habiles.svg'
import InformacionGeneral from '../../assets/svg/reservationCalendar/informacion-general.svg'
import LlimitesTurno from '../../assets/svg/reservationCalendar/limites-de-turno.svg'
import RangoFecha from '../../assets/svg/reservationCalendar/rango-de-fecha.svg'
import { ROLEID } from '../../utils/enums/Roles'
const { ValueContainer, Placeholder } = components

const CustomValueContainer = ({ children, ...props }: any) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null,
      )}
    </ValueContainer>
  )
}

const daysArray: any[] = [
  {
    label: 'Domingo',
    rhf: 'isSunday',
  },
  {
    label: 'Lunes',
    rhf: 'isMonday',
  },
  {
    label: 'Martes',
    rhf: 'isTuesday',
  },
  {
    label: 'Miercoles',
    rhf: 'isWednesday',
  },
  {
    label: 'Jueves',
    rhf: 'isThursday',
  },
  {
    label: 'Viernes',
    rhf: 'isFriday',
  },
  {
    label: 'Sabado',
    rhf: 'isSaturday',
  },
]

const CreateReservation = ({ ...props }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty, isSubmitSuccessful },
    control,
  } = useForm({})

  //renombrar nombre del servicio porque es traer la lista de nursing home
  const { dataNursing, isLoading: homeIsLoading } = useGetListPatient(null)
  const { data: datalist, isLoading: doctorIsLoading } = useGetUserTypeI([1])

  const onSubmit = async (data: any) => {
    data.created_by = props?.state?.currentUser?.id
    console.log('Reserva', data)

    await CreateCalendar(data)
  }

  return (
    <div className="w-full">
      <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
        <img src={Calendario} className={`w-20`} alt="" />
        <h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#294fa9] first-line:capitalize">
          Reserva de citas
        </h2>
      </div>
      <div className="w-full p-7">
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-auto flex flex-col place-items-center gap-3 lg:grid lg:w-fit lg:grid-cols-2 xl:grid-cols-3">
            {/* <div className="my-1 w-full font-black text-black lg:col-span-2 lg:block xl:col-span-3">
              <p>Información general del calendario</p>
            </div> */}
            <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 gap-4">
              <img src={InformacionGeneral} className={`w-12`} alt="" />
              <h1 className="text-black text-xl first-line:capitalize ">
                Información general del calendario
              </h1>
            </div>
            <div className="w-full lg:w-72 h-full">
              <Controller
                control={control}
                name={`nursingHomeId`}
                rules={{
                  required: {
                    value: false,
                    message: 'Tipo de cita es requerido',
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <Select
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                    placeholder={
                      homeIsLoading ? 'Cargando...' : 'Seleccione un Hogar...'
                    }
                    isClearable
                    styles={customStyles}
                    ref={ref}
                    isDisabled={homeIsLoading}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    options={dataNursing}
                  />
                )}
              />
              {errors.nursingHomeId && (
                <p className="text-sm text-red-600 mt-2">
                  {errors.nursingHomeId.message}
                </p>
              )}
            </div>

            <div className="w-full lg:w-72 h-full">
              <Controller
                control={control}
                name={`statusAssistanceType`}
                rules={{
                  required: {
                    value: false,
                    message: 'Tipo de cita es requerido',
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <Select
                    id="statusAssistanceType"
                    name="statusAssistanceType"
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                    placeholder="Tipo de Asistencia..."
                    isClearable
                    styles={customStyles}
                    ref={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    options={[
                      { value: 9, label: 'En el hogar' },
                      { value: 10, label: 'En el consultorio' },
                      { value: 11, label: 'Llamada' },
                      { value: 12, label: 'Telemedicina "TytoCare"' },
                    ]}
                  />
                )}
              />
              {errors.statusAssistanceType && (
                <p className="text-sm text-red-600 mt-2">
                  {errors.statusAssistanceType.message}
                </p>
              )}
            </div>

            <div className="w-full lg:w-72 h-full">
              <Controller
                control={control}
                name={`statusCalendarType`}
                rules={{
                  required: {
                    value: false,
                    message: 'Tipo de cita es requerido',
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <Select
                    id="statusCalendarType"
                    name="statusCalendarType"
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                    placeholder="Tipo de Reserva..."
                    isClearable
                    styles={customStyles}
                    ref={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    options={[
                      { value: 20, label: 'Cita' },
                      { value: 21, label: 'Turno' },
                    ]}
                  />
                )}
              />
              {errors.statusCalendarType && (
                <p className="text-sm text-red-600 mt-2">
                  {errors.statusCalendarType.message}
                </p>
              )}
            </div>

            <div className="w-full lg:w-72 h-full">
              <Controller
                control={control}
                name={`doctorId`}
                rules={{
                  required: {
                    value: false,
                    message: 'Tipo de cita es requerido',
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <Select
                    id="doctorId"
                    name="doctorId"
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                    placeholder="Doctor"
                    isClearable
                    styles={customStyles}
                    ref={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    options={datalist
                      ?.filter((item: any) => {
                        if (
                          item?.type ===
                            props?.state?.currentUser?.roles[0]?.roleId &&
                          item?.value === props?.state?.currentUser?.id
                        ) {
                          return item
                        } else if (
                          item?.type === ROLEID.DOCTOR &&
                          props?.state?.currentUser?.roles[0]?.roleId !==
                            ROLEID.DOCTOR
                        ) {
                          return item
                        }
                      })
                      ?.map((item: any) => {
                        return item
                      })}
                    // options={dataPhysician}
                    isDisabled={false}
                    // isDisabled={doctorIsLoading}
                  />
                )}
              />
              {errors.doctorId && (
                <p className="text-sm text-red-600 mt-2">
                  {errors.doctorId.message}
                </p>
              )}
            </div>
            {/* <div className="my-1 flex w-full flex-col lg:col-span-2 lg:block xl:col-span-3">
              <p className="font-black text-black">Limites para turnos</p>
              <p>
                Estos limites permiten especificar la duración aproximada de un
                horario especificado y cuantos residentes pueden estar en el
                rango de tiempo.
              </p>
            </div> */}
            <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 gap-4">
              <img src={LlimitesTurno} className={`w-12`} alt="" />
              <h1 className="text-black text-xl first-line:capitalize">
                Turnos
              </h1>
            </div>
            <div className="my-1 flex w-full flex-col lg:col-span-2 lg:block xl:col-span-3  text-slate-500">
              <p>
                Especificar la duración aproximada y cuantos residentes pueden
                estar en el rango de tiempo.
              </p>
            </div>

            <div className="w-full lg:w-72 h-full relative">
              <Controller
                control={control}
                name="expectedDuration"
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <NumberFormat
                    id="expectedDuration"
                    name="expectedDuration"
                    ref={ref}
                    className="input-style peer"
                    thousandSeparator={true}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={`Tiempo estimado`}
                    displayType={'input'}
                    format="##"
                    value={value}
                  />
                )}
              />
              <label className="input-style-label" htmlFor="expectedDuration">
                Tiempo estimado (Min)
              </label>
            </div>

            <div className="w-full lg:w-72 h-full relative">
              <Controller
                control={control}
                name="maxCapacity"
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <NumberFormat
                    id="maxCapacity"
                    name="maxCapacity"
                    ref={ref}
                    className="input-style peer"
                    thousandSeparator={true}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={`Capacidad maxima`}
                    displayType={'input'}
                    format="##"
                    value={value}
                  />
                )}
              />
              <label className="input-style-label" htmlFor="maxCapacity">
                Capacidad maxima
              </label>
            </div>

            {/* <div className="my-1 flex w-full flex-col lg:col-span-2 lg:block xl:col-span-3">
              <p className="font-black text-black">
                Rango de fechas para el calendario
              </p>
              <p>
                El rango de fechas en el que estara habilitado el calendario
                para el hogar
              </p>
            </div> */}

            <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 gap-4">
              <img src={RangoFecha} className={`w-12`} alt="" />
              <h1 className="text-black text-xl first-line:capitalize">
                Fechas para el calendario
              </h1>
            </div>
            <div className="my-1 flex w-full flex-col lg:col-span-2 lg:block xl:col-span-3 text-slate-500">
              <p>
                Asignar el rango de fechas en el que estará habilitado el
                calendario para el hogar
              </p>
            </div>
            <div className="w-full lg:w-72 h-full">
              <Controller
                control={control}
                {...register('dateBeginAt', {
                  required: {
                    value: false,
                    message: 'Dia inicial es requerido',
                  },
                  // , maxLength: { value: 15, message: "Primer nombre tiene como maximo 15 caracteres" }
                })}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label
                    className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm border-[1] flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
                    htmlFor="dateBeginAt"
                  >
                    <CalendarIcon className="w-6 h-6 mr-2" />
                    <DatePicker
                      id="dateBeginAt"
                      name="dateBeginAt"
                      placeholderText="Dia inicial"
                      className="w-full focus:outline-none bg-transparent"
                      selected={value}
                      autoComplete="off"
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      dateFormat="MMMM d, yyyy"
                    />
                  </label>
                )}
              />
              {errors.dateBeginAt && (
                <p className="text-sm text-red-600 mt-2">
                  {errors.dateBeginAt.message}
                </p>
              )}
            </div>

            <div className="w-full lg:w-72 h-full">
              <Controller
                control={control}
                {...register('dateFinishAt', {
                  required: {
                    value: false,
                    message: 'Dia final es requerido',
                  },
                  // , maxLength: { value: 15, message: "Primer nombre tiene como maximo 15 caracteres" }
                })}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label
                    className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm border-[1] flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
                    htmlFor="dateFinishAt"
                  >
                    <CalendarIcon className="w-6 h-6 mr-2" />
                    <DatePicker
                      id="dateFinishAt"
                      name="dateFinishAt"
                      placeholderText="Dia final"
                      autoComplete="off"
                      className="w-full focus:outline-none bg-transparent"
                      selected={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      dateFormat="MMMM d, yyyy"
                    />
                  </label>
                )}
              />
              {errors.dateFinishAt && (
                <p className="text-sm text-red-600 mt-2">
                  {errors.dateFinishAt.message}
                </p>
              )}
            </div>

            {/* <div className="my-1 flex w-full flex-col lg:col-span-2 lg:block xl:col-span-3">
              <p className="font-black text-black">
                Bloque de tiempo del calendario
              </p>
              <p>
                El rango de horas en el dia que estara habilitado el calendario
                para el hogar
              </p>
            </div> */}
            <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 gap-4">
              <img src={BloqueTiempo} className={`w-12`} alt="" />
              <h1 className="text-black text-xl  first-line:capitalize">
                Horario laboral del calendario
              </h1>
            </div>
            <div className="my-1 flex w-full flex-col lg:col-span-2 lg:block xl:col-span-3  text-slate-500">
              <p>
                El rango de horas en el día que estará habilitado el calendario
                para el hogar
              </p>
            </div>
            <div className="w-full lg:w-72 h-full">
              <Controller
                control={control}
                {...register('timeBeginAt', {
                  required: {
                    value: false,
                    message: 'Hora inicial es requerido',
                  },
                  // , maxLength: { value: 15, message: "Primer nombre tiene como maximo 15 caracteres" }
                })}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label
                    className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm border-[1] flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
                    htmlFor="timeBeginAt"
                  >
                    <ClockIcon className="w-6 h-6 mr-2" />
                    <DatePicker
                      id="timeBeginAt"
                      placeholderText="Hora inicial"
                      className="w-full focus:outline-none bg-transparent"
                      selected={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      autoComplete="off"
                      ref={ref}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </label>
                )}
              />
              {errors.timeBeginAt && (
                <p className="text-sm text-red-600 mt-2">
                  {errors.timeBeginAt.message}
                </p>
              )}
            </div>

            <div className="w-full lg:w-72 h-full">
              <Controller
                control={control}
                {...register('timeEndAt', {
                  required: {
                    value: false,
                    message: 'Hora final es requerido',
                  },
                  // , maxLength: { value: 15, message: "Primer nombre tiene como maximo 15 caracteres" }
                })}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label
                    className="h-10 rounded-md border-[1px] px-3 py-4 shadow-sm border-[1] flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
                    htmlFor="timeEndAt"
                  >
                    <ClockIcon className="w-6 h-6 mr-2" />
                    <DatePicker
                      id="timeEndAt"
                      placeholderText="Hora final"
                      className="w-full focus:outline-none bg-transparent"
                      selected={value}
                      autoComplete="off"
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </label>
                )}
              />
              {errors.timeEndAt && (
                <p className="text-sm text-red-600 mt-2">
                  {errors.timeEndAt.message}
                </p>
              )}
            </div>

            {/* <div className="my-1 flex w-full flex-col lg:col-span-2 lg:block xl:col-span-3">
              <p className="font-black text-black">
                Días hábiles del calendario
              </p>
              <p>Seleccione los dias de disponibilidad para su calendario</p>
            </div> */}
            <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 gap-4">
              <img src={DiasHabiles} className={`w-12`} alt="" />
              <h1 className="text-black text-xl first-line:capitalize">
                Días hábiles del calendario
              </h1>
            </div>
            <div className="my-1 flex w-full flex-col lg:col-span-2 lg:block xl:col-span-3  text-slate-500">
              <p>Seleccione los días de disponibilidad para su calendario</p>
            </div>
            {daysArray?.map((day: any) => {
              return (
                <div className="w-full lg:w-72 h-full">
                  <label
                    className="w-full h-full input-style flex flex-row gap-2 px-3 text-center items-center content-center bg-white"
                    htmlFor={day.rhf}
                  >
                    <input
                      id={day.rhf}
                      type="checkbox"
                      {...register(day.rhf, {})}
                    />
                    <span>{day.label}</span>
                  </label>
                </div>
              )
            })}

            <div className="hidden lg:block lg:col-span-2 xl:col-span-3 my-1"></div>
            <input
              type="submit"
              value="Crear Reserva"
              className="lg:col-span-2 xl:col-span-3 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
            />
          </div>
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    state: {
      currentUser: state.currentUser,
    },
  }
}
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(CreateReservation)
// export default CreateReservation
