/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import TokenService from './Token'
import api from './Api'

const clinic = async (data: any) => {
  let user: any = localStorage.getItem('user')
  const { id, label, value } = data

  const response = await api.instanceEntities.post(
    `/clinic/get-profile-clinic-by-id`,
    {
      clinicId: id,
    },
    {
      timeout: 10000,
      headers: {
        Authorization: `${JSON.parse(user).TokenType} ${
          JSON.parse(user).IdToken
        }`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    },
  )

  // console.log(
  // 	`response.data.data[0][0] ${JSON.stringify(
  // 		response.data.data[0][0],
  // 		null,
  // 		4,
  // 	)}`,
  // );

  return response.data.data[0][0]
}

const home = async (data: any) => {
  let user: any = localStorage.getItem('user')
  const { id, label, value } = data

  const response = await api.instanceEntities.post(
    `/nursing_home/get-profile-home-by-id`,
    {
      nursingHomeId: id,
    },
    {
      headers: {
        Authorization: `${JSON.parse(user).TokenType} ${
          JSON.parse(user).IdToken
        }`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    },
  )
  // console.log(
  // 	`response.data.data ${JSON.stringify(response.data.data, null, 4)}`,
  // );

  return response.data.data
}

export default {
  clinic,
  home,
}
