/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  IdentificationIcon,
  PhoneIcon,
  LocationMarkerIcon,
  MapIcon,
  InboxInIcon,
  PencilAltIcon,
} from '@heroicons/react/outline'
import { connect } from 'react-redux'
import options from '../../data/selectOptions'
import { useNavigate } from 'react-router-dom'
import Entities from '../../assets/svg/entidades.svg'
const ProfileEntityInfo = (props: any) => {
  const navigate = useNavigate()

  const {
    clinic_legalName,
    clinic_comercialName,
    clinic_npi,
    clinic_ein,
    clinic_webPage,
    clinic_email,
    nursing_home_legalName,
    nursing_home_comercialName,
    nursing_home_webPage,
    nursing_home_email,
    nursing_home_npi,
    nursing_home_ein,
    Address_state,
    Address_firstLine,
    Address_secondLine,
    Address_zipCode,
    phone,
    extension,
    type,

    Address_pobox,
    Address_statePoBox,
    Address_zipCodePoBox,
    countryId,
    countryPoBoxId,
    mobile,
    localphone,
  } = props?.updateEntityForm

  return (
    <div className="w-full ">
      <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
        <div className="flex flex-col md:flex-row gap-1.5">
          <div className="flex flex-row">
            <img src={Entities} className={`w-20`} alt="" />
            <h1 className="w-full self-center text-[#96c952] sm:text-4xl text-2xl font-extrabold ">
              {nursing_home_comercialName || clinic_comercialName}
            </h1>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 p-7">
        <div className="flex flex-col gap-5">
          <div className="flex flex-row items-center justify-start border-b-2 gap-1.5 ">
            <img src={Entities} className={`w-12`} alt="" />
            <h1 className="font-bold text-xl">Información Comercial</h1>
          </div>
          <div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5 ">
            <div className="w-fit md:ml-24 min-w-sm	 ">
              <div className="flex flex-row gap-1.5">
                <b>Tipo de entidad:</b>
                <p>{type === 'c' ? 'Clínica' : 'Nursing Home'}</p>
              </div>
              <div className="flex flex-row gap-1.5">
                <b>Nombre Legal:</b>
                <p>{clinic_legalName || nursing_home_legalName}</p>
              </div>
              <div className="flex flex-row gap-1.5">
                <b>Nombre Comercial:</b>
                <p>{nursing_home_comercialName || clinic_comercialName}</p>
              </div>
              <div className="flex flex-row gap-1.5">
                <b>EIN:</b>
                <p>{clinic_ein || nursing_home_ein || 'N/A'}</p>
              </div>
              <div className="flex flex-row gap-1.5">
                <b>NPI:</b>
                <p>{clinic_npi || nursing_home_npi || 'N/A'}</p>
              </div>
              <div className="flex flex-row gap-1.5">
                <b>Sitio Web:</b>
                <p>{clinic_webPage || nursing_home_webPage || 'N/A'}</p>
              </div>
              <div className="flex flex-row gap-1.5">
                <b>Correo electrónico:</b>
                <p>{clinic_email || nursing_home_email}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start border-b-2 gap-1.5 ">
            <PhoneIcon strokeWidth={1.3} color="#294fa9" className={`w-10`} />
            <h1 className="font-bold text-xl">Contacto</h1>
          </div>
          <div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5 ">
            <div className="w-fit md:ml-24 min-w-sm	 ">
              <div className="flex flex-row gap-1.5">
                <b>Numero Celular:</b>
                <p>{mobile ? mobile : 'N/A'}</p>
              </div>
              <div className="flex flex-row gap-1.5 ">
                <b>Teléfono Fijo:</b>
                <p>{localphone ? localphone : 'N/A'}</p>
                <b>Ext:</b>
                <p>{extension ? extension : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start border-b-2 gap-1.5 ">
            <MapIcon color="#294fa9" strokeWidth={1.3} className={`w-12`} />
            <h1 className="font-bold text-xl">Ubicacion</h1>
          </div>
          <div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5 ">
            <div className="w-fit md:ml-24 min-w-sm	 ">
              <div className="flex flex-row gap-1.5">
                <b>Dirección:</b>
                <p>{`${Address_firstLine} ${Address_secondLine}`}</p>
              </div>
              <div className="flex flex-row gap-1.5">
                <b>Pueblo/Municipio:</b>
                <p>{Address_state}</p>
              </div>
              <div className="flex flex-row gap-1.5">
                <b>Código Postal:</b>
                <p>{Address_zipCode || 'N/A'}</p>
              </div>
              <div className="flex flex-row gap-1.5">
                <b>País:</b>
                <p>
                  {
                    options.country
                      .filter((item: any) => {
                        if (item.value === countryId) {
                          return item
                        }
                      })
                      ?.map((item: any) => {
                        return item.label
                      })[0]
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start border-b-2 gap-1.5 ">
            <InboxInIcon color="#294fa9" strokeWidth={1.3} className={`w-12`} />
            <h1 className="font-bold text-xl">P.O. Box</h1>
          </div>
          <div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5 ">
            <div className="w-fit md:ml-24 min-w-sm	 ">
              {/* <div className="flex flex-row items-center justify-start border-b-2 gap-1.5 ">
                <h1 className="font-bold text-lg"></h1>
              </div> */}
              <div className="pl-3 flex flex-row gap-1.5 ">
                <b>Número:</b>
                <p>{`${Address_pobox ? Address_pobox : 'N/A'}`}</p>
              </div>
              <div className="pl-3 flex flex-row gap-1.5">
                <b>Pueblo/Municipio:</b>
                <p>{Address_statePoBox ? Address_statePoBox : 'N/A'}</p>
              </div>
              <div className="pl-3 flex flex-row gap-1.5">
                <b>Código Postal:</b>
                <p>{Address_zipCodePoBox ? Address_zipCodePoBox : 'N/A'}</p>
              </div>
              <div className="pl-3 flex flex-row gap-1.5">
                <b>País:</b>
                <p>
                  {
                    options.country
                      .filter((item: any) => {
                        if (item.value === countryPoBoxId) {
                          return item
                        }
                      })
                      ?.map((item: any) => {
                        return item.label
                      })[0]
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-3 justify-center w-full">
          <button
            className={`w-fit px-4 py-2 rounded-xl border-[1px] font-bold bg-[#F6A01E] shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 duration-300`}
            onClick={() => {
              navigate('/entities/profile')
            }}
          >
            Volver
          </button>
          <div
            className="h-12 flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer"
            onClick={() => {
              navigate('/update/entity')
            }}
          >
            <PencilAltIcon className="w-8 h-8 " />
            <div>Editar datos</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    updateEntityForm: state.updateEntityForm,
  }
}

export default connect(mapStateToProps, null)(ProfileEntityInfo)
