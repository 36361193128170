import api from './Api'
import Swal from 'sweetalert2'

const UpdatePatientASC = async (obj: any) => {
  let user: any = localStorage.getItem('user')
  // console.log(`obj: \n${JSON.stringify(obj, null, 4)}`);

  const { description, id, afectionId } = obj

  const data = await api.instanceUsers
    .post(
      `/user/update-condition-allergy-surgery/`,
      {
        afectiionDescription: description,
        id,
        afectionId,
      },
      {
        headers: {
          Authorization: `${JSON.parse(user).TokenType} ${
            JSON.parse(user).IdToken
          }`,
          USER_ENTITY_ID: `${localStorage.getItem('role')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    )
    .then((response) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmación',
        text: 'Se realizó actualización con éxito!',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return response.data
    })
    .catch((error) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return error
    })
  return data
}
const DeletePatientASC = async (obj: any) => {
  let user: any = localStorage.getItem('user')
  // console.log(`obj: \n${JSON.stringify(obj, null, 4)}`);

  const { pcmid } = obj

  const data = await api.instanceUsers
    .post(
      `/user/delete-afections-resident/`,
      {
        pcmId: pcmid,
      },
      {
        headers: {
          Authorization: `${JSON.parse(user).TokenType} ${
            JSON.parse(user).IdToken
          }`,
          USER_ENTITY_ID: `${localStorage.getItem('role')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    )
    // .then((response) => {
    //   Swal.fire({
    //     customClass: {
    //       confirmButton: 'bg-red-500 text-white',
    //     },
    //     icon: 'success',
    //     title: 'Confirmación',
    //     text: 'Se realizó actualización con éxito!',
    //     // icon: "error",
    //     confirmButtonText: 'Aceptar',
    //   })
    //   return response.data
    // })
    // .catch((error) => {
    //   Swal.fire({
    //     customClass: {
    //       confirmButton: 'bg-red-500 text-white',
    //     },
    //     title: 'Error Interno!',
    //     text: 'Se ha presentado un error interno',
    //     // icon: "error",
    //     confirmButtonText: 'Aceptar',
    //   })
    //   return error
    // })
  return data
}

const UpdateASC = {
  UpdatePatientASC,
  DeletePatientASC,
}

export default UpdateASC
