import api from './Api'
import TokenService from './Token'

export const addUserEntity = async (values: any) => {
  console.log('Servicio ', values)

  const { entitySelected, user_type, user_selected } = values
  const user = TokenService.getUser()
  // console.log('capturado ', id);

  const response = await api.instanceUsers.put(
    `/user/add-user-entity/`,
    {
      id: user_selected?.value,
      typeId: user_type?.value || 1,
      clinicId:
        entitySelected?.type === 'C' ? entitySelected?.value : undefined,
      nursingHomeId:
        entitySelected?.type === 'HN' ? entitySelected?.value : undefined,
    },
    {
      headers: {
        Authorization: `${user.TokenType} ${user.IdToken}`,
        USER_ENTITY_ID: `${TokenService.getRole()}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    },
  )

  return response
}
