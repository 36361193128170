import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setUpdatePatientPhotoForm } from '../../actions'
import ModalPhotoViewer from '../Modals/ModalPhotoViewer'
import UserIcon from '../../assets/svg/UserSVGBlue.svg'
import Aseguradora from '../../assets/svg/PatientItems/PhotosIcon/icono-aseguradora.svg'
import Licence from '../../assets/svg/PatientItems/PhotosIcon/icono-licenciadeconducir.svg'
import Salud from '../../assets/svg/PatientItems/PhotosIcon/icono-salud.svg'

const PatientFiles = (props: any) => {
  const [showPhotoViewer, setShowPhotoViewer] = useState<boolean>(false)
  const [tempPhoto, setTempPhoto] = useState<any>(null)
  const {
    photoURL,
    driverLicenseURL,
    socialSecurityCardURL,
    insuranceCardURL,
    patientId,
  } = props

  const navigate = useNavigate()

  return (
    <div className="w-full h-full">
      {showPhotoViewer && (
        <ModalPhotoViewer
          showPhotoViewer={showPhotoViewer}
          setShowPhotoViewer={setShowPhotoViewer}
          tempPhoto={tempPhoto}
        />
      )}
      {/* <div className="grid xl:grid-cols-2 gap-5  place-items-center sm:px-24 "> */}
      <div className="grid lg:grid-cols-2 gap-x-6 gap-y-6 mx-auto w-fit">
        <div className="gap-5 text-center flex max-w-md flex-col items-center format-div-photo">
          <p>Foto residente</p>
          <img src={photoURL ? photoURL : UserIcon} className=" h-60" alt="" />
          {/* <div className="lg:col-span-2 w-full xl:col-span-3 justify-center place-items-center center flex md:flex-row flex-col gap-6">
						<div
							className="md:w-28 h-10 flex flex-row w-full gap-1 place-items-center justify-center rounded-lg px-4 py-2 bg-[#679230] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-slate-600 text-white hover:border-blue-700"
							onClick={() => {}}
						>
							<PencilAltIcon strokeWidth={1.3} className="w-4 border-b-2" />
							Editar
						</div>
						<div
							className="md:w-28 h-10 flex flex-row w-full gap-1 place-items-center justify-center rounded-lg px-4 py-2 bg-[#f61e1e] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-slate-600 text-white hover:border-red-700"
							onClick={() => {}}
						>
							<TrashIcon strokeWidth={1.3} className="w-4 border-b-2" />
							Eliminar
						</div>
					</div> */}
        </div>
        <div className="gap-5 text-center flex  max-w-md flex-col items-center format-div-photo">
          <p>Foto licencia de conducir</p>
          <img
            src={driverLicenseURL ? driverLicenseURL : Licence}
            className="h-60"
            alt=""
          />
          {/* <div className="lg:col-span-2 w-full xl:col-span-3 justify-center place-items-center center flex md:flex-row flex-col gap-6">
						<div
							className="md:w-28 h-10 flex flex-row w-full gap-1 place-items-center justify-center rounded-lg px-4 py-2 bg-[#679230] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-slate-600 text-white hover:border-blue-700"
							onClick={() => {}}
						>
							<PencilAltIcon strokeWidth={1.3} className="w-4 border-b-2" />
							Editar
						</div>
						<div
							className="md:w-28 h-10 flex flex-row w-full gap-1 place-items-center justify-center rounded-lg px-4 py-2 bg-[#f61e1e] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-slate-600 text-white hover:border-red-700"
							onClick={() => {}}
						>
							<TrashIcon strokeWidth={1.3} className="w-4 border-b-2" />
							Eliminar
						</div>
					</div> */}
        </div>
        <div className="gap-5 text-center flex max-w-md flex-col items-center format-div-photo">
          <p>Foto seguro social</p>
          <img
            src={socialSecurityCardURL ? socialSecurityCardURL : Aseguradora}
            className="h-60"
            alt=""
          />
          {/* <div className="lg:col-span-2 w-full xl:col-span-3 justify-center place-items-center center flex md:flex-row flex-col gap-6">
						<div
							className="md:w-28 h-10 flex flex-row w-full gap-1 place-items-center justify-center rounded-lg px-4 py-2 bg-[#679230] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-slate-600 text-white hover:border-blue-700"
							onClick={() => {}}
						>
							<PencilAltIcon strokeWidth={1.3} className="w-4 border-b-2" />
							Editar
						</div>
						<div
							className="md:w-28 h-10 flex flex-row w-full gap-1 place-items-center justify-center rounded-lg px-4 py-2 bg-[#f61e1e] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-slate-600 text-white hover:border-red-700"
							onClick={() => {}}
						>
							<TrashIcon strokeWidth={1.3} className="w-4 border-b-2" />
							Eliminar
						</div>
					</div> */}
        </div>
        <div className="gap-5 text-center flex max-w-md 	  flex-col items-center format-div-photo">
          <p>Foto Aseguradora</p>
          <img
            src={insuranceCardURL ? insuranceCardURL : Salud}
            className="h-60 "
            alt=""
          />
          {/* <div className="lg:col-span-2 w-full xl:col-span-3 justify-center place-items-center center flex md:flex-row flex-col gap-6">
						<div
							className="md:w-28 h-10 flex flex-row w-full gap-1 place-items-center justify-center rounded-lg px-4 py-2 bg-[#679230] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-slate-600 text-white hover:border-blue-700"
							onClick={() => {}}
						>
							<PencilAltIcon strokeWidth={1.3} className="w-4 border-b-2" />
							Editar
						</div>
						<div
							className="md:w-28 h-10 flex flex-row w-full gap-1 place-items-center justify-center rounded-lg px-4 py-2 bg-[#f61e1e] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-slate-600 text-white hover:border-red-700"
							onClick={() => {}}
						>
							<TrashIcon strokeWidth={1.3} className="w-4 border-b-2" />
							Eliminar
						</div>
					</div> */}
        </div>
      </div>
    </div>
  )
}
const mapDispatchToProps = {
  setUpdatePatientPhotoForm,
}
//export default PatientFiles;
export default connect(null, mapDispatchToProps)(PatientFiles)
