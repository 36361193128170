/* eslint-disable @typescript-eslint/no-unused-vars */
const ModalShowEvent = ({
  currentEventUpdateSelected,
  showDataEventModal,
  setShowDataEventModal,
  rescheduleEventAction,
  updateEventAction,
}: any) => {
  // const { setModalOn, setChoice, eventToShow, setEventToShow }: any = props
  // const handleOKClick = () => {
  //   setChoice(true)
  //   setModalOn(false)
  //   setEventToShow({})
  // }
  const handleCancelClick = () => {
    setShowDataEventModal(!showDataEventModal)
    //   setChoice(false)
    //   setModalOn(false)
    //   setEventToShow({})
  }

  return (
    <div className="fixed inset-0 z-50">
      <div className="flex h-screen items-center justify-center">
        <div className="border border-slate-600 max-w-2xl flex flex-col justify-center rounded-xl bg-white py-10 px-16 gap-5">
          <div className=" flex flex-col gap-2 text-lg text-zinc-600 opacity-100">
            <div className="flex flex-row gap-1">
              <b>Medico:</b> <p>{currentEventUpdateSelected?.doctorName}</p>
            </div>
            <div className="flex flex-row gap-1">
              <b>Residente:</b> <p>{currentEventUpdateSelected?.patientName}</p>
            </div>
            <div className="flex flex-row gap-1">
              <b>Hora de cita:</b>{' '}
              <p>{new Date(currentEventUpdateSelected?.start).toISOString()}</p>
            </div>
            <div className="flex flex-row gap-1">
              <b>Tytocare:</b> <p>{currentEventUpdateSelected?.tytocareId}</p>
            </div>
            <div className="flex flex-row gap-1">
              <b>Aseguradora:</b>
              <p>{currentEventUpdateSelected?.insuranceCarrier}</p>
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex-1" />
            <button
              onClick={handleCancelClick}
              className="rounded bg-[#F6A01E] px-4 py-2 text-white"
            >
              Volver
            </button>
            <button
              onClick={rescheduleEventAction}
              className="rounded bg-fuchsia-500 px-4 py-2 text-white"
            >
              Reprogramar
            </button>
            <button
              onClick={updateEventAction}
              className="rounded bg-blue-400 px-4 py-2 text-white"
            >
              Editar
            </button>
            <button
              // onClick={handleCancelClick}
              className="rounded bg-red-500 px-4 py-2 text-white"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalShowEvent
