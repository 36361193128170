/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useState } from 'react'
import Select, { components, OptionProps } from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import { customStyles } from '../customStyleNewLabelCircle'
import useListUserType from '../../hooks/useListUserType'
import { ROLEID, ROLES } from '../../utils/enums/Roles'
import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer'
import { useNavigate } from 'react-router-dom'
import { UserIcon } from '@heroicons/react/outline'
import useGetUserEntity from '../../hooks/useGetUserEntity'
import options from '../../data/selectOptions'
import { connect } from 'react-redux'

const CustomOption = ({ children, data, label, ...props }: any) => {
  // console.log('Select: ', { label, children, data, ...props });
  return (
    <>
      <components.Option {...props}>
        <div className="flex flex-col">
          <p>
            <b>Nombre</b> {data.label}
          </p>
          <p>
            <b>Fecha de nacimeinto</b> {data.Profile_birthday}
          </p>
          <p>
            <b>Seguro</b> {data.Profile_insurance_carrier}
          </p>
          <p>
            <b>Record Medico</b> {data.clinicalmedicalrecord || 'N/A'}
          </p>
          <p>
            <b>Record Nursing Home</b> {data.clinicalmedicalnh || 'N/A'}
          </p>
        </div>
      </components.Option>
    </>
  )
}

const filterOption = (candidate: any, input: any) => {
  if (
    candidate?.data?.Profile_insurance_carrier?.toLowerCase().includes(
      input?.toLowerCase(),
    ) ||
    candidate?.data?.insurancecarrierii
      ?.toLowerCase()
      .includes(input?.toLowerCase()) ||
    candidate?.data?.clinicalmedicalrecord
      ?.toLowerCase()
      .includes(input?.toLowerCase()) ||
    candidate?.data?.clinicalmedicalnh
      ?.toLowerCase()
      .includes(input?.toLowerCase()) ||
    candidate?.data?.Profile_birthday?.toLowerCase().includes(
      input?.toLowerCase(),
    ) ||
    candidate?.label?.toLowerCase().includes(input?.toLowerCase())
  ) {
    return candidate
  }
}

const ListUser = ({ ...props }: any) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [dataList, setDataList] = useState<any>([])
  const [listSelect, setListSelect] = useState([
    { value: 0, label: 'Todo' },
    { value: ROLEID.DOCTOR, label: ROLES.DOCTOR },
    { value: ROLEID.NURSE, label: ROLES.NURSE },
    { value: ROLEID.ADMIN_CLINIC, label: ROLES.ADMIN_CLINIC },
    { value: ROLEID.ADMIN_NURSING_HOME, label: ROLES.ADMIN_NURSING_HOME },
  ])
  const navigate = useNavigate()
  const doctorList: any = null
  //const { doctorList, secretaryList, nurseList, isLoading } = useListUserType()

  const [userEntityList, setUserEntityList] = useState<any>(null)
  if (userEntityList === null) {
    setTimeout(() => {
      setUserEntityList(listData)
    }, 1000)
  }

  console.log(`currentUser: `, props?.state?.currentUser)
  console.log(`accessRole: `, props?.state?.currentUser?.roles)
  console.log(`currentEntitySelected: `, props?.state?.currentEntitySelected)
  
  let homeId: any =props?.state?.currentEntitySelected?.nursing_home_id;
  let clinicId: any = null;
    
  const { listData, error, isLoading } = useGetUserEntity({
    clinic: clinicId ? clinicId : null,
      // localStorage.getItem('clinicId') === 'null'
      //   ? null
      //   : localStorage.getItem('clinicId'),
    home: homeId ? homeId : null
      // localStorage.getItem('nursing_home_id') === 'null'
      //   ? null
      //   : localStorage.getItem('nursing_home_id'),
  })
  console.log(`listData consult`, listData)

  const { control } = useForm({
    mode: 'onChange',
  })

  const searchInput = useCallback(
    (prev) => {
      setDataList([])
      if (
        prev?.value === 0 ||
        prev?.value === null ||
        prev?.value === undefined ||
        prev?.value === ''
      ) {
        // setDataList(dataHomeList?.concat(dataClinicList))
      }

      if (prev?.value === 1) {
        // setDataList(dataClinicList)
      }

      if (prev?.value === 2) {
        // setDataList(dataHomeList)
      }
    },
    [
      // , dataHomeList
    ],
  )

  const userSelected = useCallback((value) => {
    if (value?.label) {
      setSearchTerm(value.label)
    } else {
      setSearchTerm('')
    }
  }, [])

  const filterEntitySelected = useCallback((candidate, input) => {
    if (
      candidate?.data?.label?.toLowerCase().includes(input?.toLowerCase()) ||
      candidate?.data?.ein?.toLowerCase().includes(input?.toLowerCase()) ||
      candidate?.data?.npi?.toLowerCase().includes(input?.toLowerCase())
    ) {
      return candidate
    }
  }, [])

  const onSubmitButton = async (data: any) => {}

  return (
    <div className="w-full h-screen flex flex-col gap-5">
      <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
        <div className="flex flex-col md:flex-row gap-1">
          <UserIcon strokeWidth={1.3} color="#294fa9" className="h-20 w-20" />
          <div className="flex flex-row">
            <h1 className="w-full self-center text-[#294fa9] text-4xl font-extrabold ">
              Usuarios
            </h1>
          </div>
        </div>
        <div>
          <h1 className="w-full self-center text-[#aaaaaa] text-2xl md:text-4xl font-light ">
            / Buscar
          </h1>
        </div>
      </div>
      <div className="w-full px-5">
        <form className='"w-full flex flex-row gap-2'>
          {/* <Controller
            control={control}
            name="entityType"
            render={({ field: { onChange, onBlur, value, ref } }: any) => {
              return (
                <Select
                  id="entityType"
                  name="entityType"
                  placeholder={`Tipo de entidad`}
                  ref={ref}
                  className="w-72"
                  defaultValue={listSelect[0]}
                  styles={customStyles}
                  onBlur={onBlur}
                  isDisabled={isLoading}
                  onChange={searchInput}
                  options={listSelect}
                  value={value}
                  components={{
                    ValueContainer: CustomValueContainer,
                  }}
                  isSearchable
                  // filterOption={filterOption}
                />
              )
            }}
          /> */}
          {console.log(`isLoading`, isLoading)}
          <div className="w-full hidden lg:block col-span-3">
            <Controller
              control={control}
              name="listEntity"
              render={({ field: { onChange, onBlur, value, ref } }: any) => {
                return (
                  <Select
                    id="listEntity"
                    name="listEntity"
                    placeholder={`Busque un usuario por nombre o rol`}
                    ref={ref}
                    className="w-full"
                    isClearable
                    styles={customStyles}
                    onBlur={onBlur}
                    onChange={userSelected}
                    options={
                      listData !== undefined && listData !== null
                        ? listData?.sort((a: any, b: any) => {
                            if (
                              a.label?.toLowerCase() > b.label?.toLowerCase()
                            ) {
                              return 1
                            }
                            if (
                              a.label?.toLowerCase() < b.label?.toLowerCase()
                            ) {
                              return -1
                            }
                            return 0
                          })
                        : null
                    }
                    isDisabled={!isLoading}
                    value={value}
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                    isSearchable
                    filterOption={filterEntitySelected}
                  />
                )
              }}
            />
          </div>
          <div className="w-full lg:hidden">
            <Controller
              control={control}
              name="listEntity"
              render={({ field: { onChange, onBlur, value, ref } }: any) => {
                return (
                  <Select
                    id="listEntity"
                    name="listEntity"
                    placeholder={`Ingrese un nombre o rol`}
                    ref={ref}
                    className="w-full"
                    isClearable
                    styles={customStyles}
                    onBlur={onBlur}
                    onChange={userSelected}
                    options={
                      listData !== undefined && listData !== null
                        ? listData?.sort((a: any, b: any) => {
                            if (
                              a.label?.toLowerCase() > b.label?.toLowerCase()
                            ) {
                              return 1
                            }
                            if (
                              a.label?.toLowerCase() < b.label?.toLowerCase()
                            ) {
                              return -1
                            }
                            return 0
                          })
                        : null
                    }
                    isDisabled={!isLoading}
                    value={value}
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                    isSearchable
                    filterOption={filterEntitySelected}
                  />
                )
              }}
            />
          </div>
        </form>
      </div>

      <div className="p-5 flex flex-col gap-5 overflow-scroll h-full ">
        <div
          className={
            userEntityList?.length > 0
              ? `grid lg:grid-cols-2 gap-x-4 gap-y-4 mx-auto w-fit`
              : `flex flex-col mx-auto w-fit`
          }
        >
          {/*Grupo entidades*/}

          {!isLoading ? (
            <div>Cargando...</div>
          ) : (
            <React.Fragment>
              {userEntityList?.length > 0 ? (
                userEntityList
                  ?.filter((val: any) => {
                    if (
                      searchTerm === '' ||
                      searchTerm === null ||
                      searchTerm === undefined
                    ) {
                      return val
                    } else if (
                      val?.label
                        ?.toLowerCase()
                        ?.includes(searchTerm?.toLowerCase())
                    ) {
                      return val
                    }
                  })
                  ?.sort((a: any, b: any) => {
                    if (a.label > b.label) {
                      return 1
                    }
                    if (a.label < b.label) {
                      return -1
                    }
                    return 0
                  })
                  ?.map((item: any) => {
                    return (
                      <div
                        key={item?.value}
                        className="bg-white flex flex-col gap-3 rounded-md border px-5 py-4 shadow-lg"
                      >
                        <div className="flex flex-col">
                          <div className="flex flex-row gap-x-1">
                            <b>Rol:</b>
                            <p>
                              {options.roleList
                                .filter((data: any) => {
                                  if (data?.value === item?.usertype) {
                                    return data
                                  }
                                })
                                ?.map((data: any) => {
                                  return data.label
                                })}
                            </p>
                          </div>
                          <div className="flex flex-row gap-x-1">
                            <b>Nombre:</b>
                            <p>{item?.label}</p>
                          </div>
                          <div className="flex flex-row gap-x-1">
                            <b>correo:</b>
                            <p>{`${item?.email ? item?.email : 'N/A'}`}</p>
                          </div>
                        </div>
                        <div className="flex flex-row-reverse gap-3">
                          <button
                            className="px-4 py-2 rounded-xl border-[1px] font-bold bg-cyan-500 shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 duration-300"
                            onClick={() => {
                              navigate('/profile/user/', {
                                state: {
                                  userId: item?.value,
                                  return: '/users',
                                  searchUser: true,
                                },
                              })
                            }}
                          >
                            Ver Perfil
                          </button>
                          {/*  <button
                          className="px-4 py-2 rounded-xl border-[1px] font-bold bg-[#f6501e] shadow-sm hover:cursor-pointer hover:bg-red-400 text-white hover:border-red-700 duration-300"
                          onClick={() => {
                            // setShowModal(true);
                          }}
                        >
                          Desvincular
                        </button>*/}
                        </div>
                      </div>
                    )
                  })
              ) : (
                <>
                  <div className="justify-center text-center text-xl items-center font-semibold">
                    Su usuario no tiene ninguna entidad vinculada, no podrá
                    visualizar Usuarios
                  </div>
                </>
              )}
            </React.Fragment>
          )}
        </div>
        {/* <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">
          {isLoading ? (
            <div>Cargando</div>
          ) : (
            doctorList
              ?.filter((val: any) => {
                if (
                  searchTerm === '' ||
                  searchTerm === null ||
                  searchTerm === undefined
                ) {
                  return val
                } else if (
                  val?.label?.toLowerCase()?.includes(searchTerm?.toLowerCase())
                ) {
                  return val
                }
              })
              ?.map((item: any) => {
                return (
                  <div
                    key={item?.ein || item?.npi}
                    className="rounded-md border px-5 py-4 shadow-lg flex flex-col gap-3 bg-white"
                  >
                    <div className="flex flex-col">
                      <div className="flex flex-row gap-x-1">
                        <b>Nombre:</b>
                        <p>{item?.label ? item?.label : 'N/A'}</p>
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="flex-1" />
                      <button
                        //w-full
                        className="sm:w-fit rounded-lg border-[1px] border-[#044382] bg-[#1E93C6] px-4 py-2 font-semibold text-white shadow-md duration-300 hover:cursor-pointer hover:border-[#044382] hover:bg-[#0561A0]"
                        onClick={() => {
                          navigate('/profile/user/', {
                            state: {
                              userId: item?.value,
                              return: '/users',
                            },
                          })
                        }}
                      >
                        Ver perfil
                      </button>
                    </div>
                  </div>
                )
              })
          )}
        </div> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    state: {
      currentUser: state.currentUser,
      accessRole: state.accessRole,
      currentEntitySelected: state.currentEntitySelected,
    },
  }
}

// const mapDispatchToProps = {
//   setUpdateEntityForm,
// }

export default connect(mapStateToProps, null)(ListUser)

//export default ListUser
