import api from './Api'
import Swal from 'sweetalert2'
import TokenService from './Token'

export const CreateCalendar = async (params: any) => {
  const user = TokenService.getUser()
  // const userid = TokenService.getUserIdAction()

  let {
    timeBeginAt,
    timeEndAt,
    dateBeginAt,
    dateFinishAt,
    expectedDuration,
    maxCapacity,
    created_by,
    doctorId,
    statusCalendarType,
    statusAssistanceType,
    nursingHomeId,
    isSunday,
    isMonday,
    isTuesday,
    isWednesday,
    isThursday,
    isFriday,
    isSaturday,
  } = params

  console.log(`Raw `, params)

  // dateBeginAt = new Date(dateBeginAt).toISOString().substring(0, 10);
  // dateFinishAt = new Date(dateFinishAt).toISOString().substring(0, 10);
  // timeBeginAt = new Date(timeBeginAt).toISOString().substring(11, 16);
  // timeEndAt = new Date(timeEndAt).toISOString().substring(11, 16);

  dateBeginAt = new Date(dateBeginAt).toISOString()
  dateFinishAt = new Date(dateFinishAt).toISOString()
  timeBeginAt = new Date(timeBeginAt).toISOString().substring(11, 16)
  timeEndAt = new Date(timeEndAt).toISOString().substring(11, 16)
  nursingHomeId = nursingHomeId?.value
  statusAssistanceType = statusAssistanceType.value
  statusCalendarType = statusCalendarType.value
  doctorId = doctorId.value
  maxCapacity = parseInt(maxCapacity)

  let days: Object[] = []

  if (isSunday === true) {
    days.push({
      day: 'Domingo',
    })
  }
  if (isMonday === true) {
    days.push({
      day: 'Lunes',
    })
  }
  if (isTuesday === true) {
    days.push({
      day: 'Martes',
    })
  }
  if (isWednesday === true) {
    days.push({
      day: 'Miércoles',
    })
  }
  if (isThursday === true) {
    days.push({
      day: 'Jueves',
    })
  }
  if (isFriday === true) {
    days.push({
      day: 'Viernes',
    })
  }
  if (isSaturday === true) {
    days.push({
      day: 'Sabado',
    })
  }

  let formatedData = {
    created_by,
    timeBeginAt,
    timeEndAt,
    dateBeginAt,
    dateFinishAt,
    expectedDuration,
    maxCapacity,
    doctorId,
    statusCalendarType,
    statusAssistanceType,
    nursingHomeId,
    days,
  }

  console.log(`Formated Data `, formatedData)

  return await api.instanceEvents
    .post('/calendar/createCalendar', formatedData, {
      headers: {
        Authorization: `${user.TokenType} ${user.IdToken}`,
        USER_ENTITY_ID: `${TokenService.getRole()}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    .then((res) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmación',
        text: 'El calendario fue creado correctamente',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return res
    })
    .catch((err) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Vuelva a intertarlo nuevamente',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return err
    })
}

export const CreateRestriction = async (params: any) => {
  const user = TokenService.getUser()

  let {
    calendarId,
    timeBeginAt,
    timeEndAt,
    dateBeginAt,
    dateFinishAt,
    expectedDuration,
    maxCapacity,
    doctorId,
    statusCalendarType,
    statusAssistanceType,
    created_by,
    nursingHomeId,
  } = params

  let obejota = {
    calendarId,
    timeBeginAt,
    timeEndAt,
    dateBeginAt,
    dateFinishAt,
    expectedDuration,
    maxCapacity,
    doctorId,
    statusCalendarType,
    statusAssistanceType,
    created_by,
    nursingHomeId,
  }

  return await api.instanceEvents
    .post('/restriction/create', obejota, {
      headers: {
        Authorization: `${user.TokenType} ${user.IdToken}`,
        USER_ENTITY_ID: `${TokenService.getRole()}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    .then(() => {})
    .catch(() => {})
}

export const UpdateRestriction = async (params: any) => {
  const user = TokenService.getUser()

  let {
    restrictionId,
    calendarId,
    timeBeginAt,
    timeEndAt,
    dateBeginAt,
    dateFinishAt,
    expectedDuration,
    maxCapacity,
    doctorId,
    statusCalendarType,
    statusAssistanceType,
    created_by,
    nursingHomeId,
  } = params

  let obejota = {
    restrictionId,
    calendarId,
    timeBeginAt,
    timeEndAt,
    dateBeginAt,
    dateFinishAt,
    expectedDuration,
    maxCapacity,
    doctorId,
    statusCalendarType,
    statusAssistanceType,
    created_by,
    nursingHomeId,
  }
  return await api.instanceEvents
    .post('/restriction/update', obejota, {
      headers: {
        Authorization: `${user.TokenType} ${user.IdToken}`,
        USER_ENTITY_ID: `${TokenService.getRole()}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    .then(() => {})
    .catch(() => {})
}

export const CreateEventCalendar = async (params: any) => {
  const user = TokenService.getUser()
  console.log(user)

  console.log('Raw data', params)
  let { beginAt, endAt, title, details } = params

  let jsonData: any = {
    beginAt: beginAt.toISOString(),
    endAt: endAt.toISOString(),
    statusAssistanceTypeId: details?.statusAssistanceTypeId?.value,
    patientUserEntityId: details?.patientUserEntityId?.value,
    doctorUserEntityId: details?.doctorUserEntityId?.value,
    insuranceCarrier: details?.insuranceCarrier,
    statusPriorityId: details?.statusPriorityId?.value,
    orderAttention: parseInt(details?.orderAttention) || undefined,
    tytocareId: details?.tytocareId,
    description: details?.description,
    created_by: details?.created_by,
    calendarId: details?.calendarId,
    address: '',
    expectedDuration: 0,
    chiefComplaint: '',
    statusStatusId: 1,
  }

  console.log('Formated Data', jsonData)

  return await api.instanceEvents
    .post('/event/createDate', jsonData, {
      headers: {
        Authorization: `${user.TokenType} ${user.IdToken}`,
        USER_ENTITY_ID: `${TokenService.getRole()}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    .then((res) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmación',
        text: 'La cita ha sido creada correctamente',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return res
    })
    .catch((err) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Vuelva a intertarlo nuevamente',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return err
    })
}
