import React from 'react'
import { customStyles } from '../customStyleNewLabel'
import options from '../../data/selectOptions'
import Medicine from '../../assets/svg/PatientItems/Medicine.svg'

const StepFive = ({
  Controller,
  Select,
  control,
  register,
  medicineFields,
  medicineRemove,
  medicineAppend,
  CustomValueContainer,
  errors,
  mode,
}: any) => {
  return (
    <React.Fragment>
      <div className="w-11/12 lg:w-full h-full p-2 lg:col-span-2 xl:col-span-3">
        {medicineFields.map((field: any, index: any) => {
          return (
            <div className="flex flex-col my-3" key={field.id}>
              <div className="w-full grid lg:grid-cols-2 xl:grid-cols-3 gap-3">
                <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
                  <img src={Medicine} className={`w-12`} alt="" />
                  <h1 className="text-xl">{`Información de Medicamento`}</h1>
                </div>
                <div className="w-full lg:w-64 h-full relative">
                  <input
                    id={`medicine.${index}.nameMedicine`}
                    type="text"
                    placeholder="Nombre*"
                    className="input-style peer"
                    {...register(`medicine.${index}.nameMedicine`, {
                      required: {
                        value: true,
                        message: 'Nombre del medicamento es requerido',
                      },
                    })}
                    defaultValue={field.nameMedicine}
                    // isDisabled={mode === 'update' ? true : false}
                  />
                  <label
                    className="input-style-label"
                    htmlFor={`medicine.${index}.nameMedicine`}
                  >
                    Nombre*
                  </label>
                  {errors.medicine?.[index]?.nameMedicine && (
                    <p className="text-sm text-red-600 mt-2">
                      {errors.medicine?.[index]?.nameMedicine?.message}
                    </p>
                  )}
                </div>

                <div className="w-full lg:w-64 h-full relative">
                  <input
                    id={`medicine.${index}.dosage`}
                    type="text"
                    placeholder="Dosis"
                    className="input-style peer"
                    {...register(`medicine.${index}.dosage`)}
                    defaultValue={field.dosage}
                  />
                  <label
                    className="input-style-label"
                    htmlFor={`medicine.${index}.dosage`}
                  >
                    Dosis
                  </label>
                </div>

                <div className="w-full lg:w-64 h-full relative">
                  <Controller
                    control={control}
                    name={`medicine.${index}.measurementUnit`}
                    render={({
                      field: { onChange, onBlur, value, ref },
                    }: any) => (
                      <Select
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        className="w-full"
                        placeholder="Unidad de medida..."
                        styles={customStyles}
                        onBlur={onBlur}
                        onChange={onChange}
                        options={options?.measurementUnitOptions}
                        value={value}
                      />
                    )}
                  />
                </div>

                <div className="w-full lg:w-64 h-full relative">
                  <input
                    id={`medicine.${index}.frequency`}
                    type="text"
                    placeholder="Frecuencia"
                    className="input-style peer"
                    {...register(`medicine.${index}.frequency`)}
                    defaultValue={field.frequency}
                  />
                  <label
                    className="input-style-label"
                    htmlFor={`medicine.${index}.frequency`}
                  >
                    Frecuencia
                  </label>
                </div>

                <div className="w-full lg:w-64 h-full relative">
                  <input
                    id={`medicine.${index}.rxNumber`}
                    type="text"
                    placeholder="rxNumber"
                    className="input-style peer"
                    {...register(`medicine.${index}.rxNumber`)}
                    defaultValue={field.rxNumber}
                  />
                  <label
                    className="input-style-label"
                    htmlFor={`medicine.${index}.rxNumber`}
                  >
                    rxNumber
                  </label>
                </div>

                <div className="w-full lg:w-64 h-full relative">
                  <textarea
                    id={`medicine.${index}.indications`}
                    className="input-style peer"
                    placeholder="Indicaciones"
                    {...register(`medicine.${index}.indications`)}
                  />
                  <label
                    className="input-style-label"
                    htmlFor={`medicine.${index}.indications`}
                  >
                    Indicaciones
                  </label>
                </div>

                <input
                  type="button"
                  className={`${
                    mode === 'update' || mode === 'add' ? 'hidden' : ''
                  } w-full lg:w-64 h-10 bg-red-500 rounded-lg text-white`}
                  onClick={() => medicineRemove(index)}
                  value="Eliminar"
                />
              </div>
            </div>
          )
        })}
        <div className="w-full text-center">
          <input
            className={`${
              mode === 'update' || mode === 'add' ? 'hidden' : ''
            } mx-auto w-11/12 lg:w-64 rounded-lg px-4 py-2 bg-lime-600 shadow-md duration-300 font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700`}
            type="button"
            value={`Adicionar medicamento`}
            onClick={() =>
              medicineAppend({
                nameMedicine: '',
                dosage: '',
                frequency: '',
                rxNumber: '',
              })
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default StepFive
