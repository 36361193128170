/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import Select, { components } from 'react-select'
import UpdatePatientTutor from '../../services/updatePatientTutor'
import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline'
import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer'
import Swal from 'sweetalert2'
import useGetAllergyConditionSurgery from '../../hooks/useGetAllergyConditionSurgery'
import { customStyles } from '../customStyleNewLabel'
import SumaryAllergyRegister from '../Modals/SumaryAllergyRegister'
import AllergyForm from '../RegisterPatient/ConditionAllergySurgery/Allergy'

const AddAllergyForm = () => {
  const [stateData, setStateData] = useState<any>()
  const location: any = useLocation()
  //const [isRequired, setIsLoading] = useState(false);
  const [user, initUser] = useState<any>(null)

  const [isLoadingState, setIsLoading] = useState<any>(true)
  const [modalOn, setModalOn] = useState<any>(false)
  const [formData, setFormData] = useState<any>(null)
  const [choice, setChoice] = useState<any>(false)
  const navigate = useNavigate()

  // let {
  //   listData: acsListData,
  //   error: acsError,
  //   isLoading: acsIsLoading,
  // }: any = useGetAllergyConditionSurgery()
  const myInitialData: any = {
    item: {},
    description: '',
  }
  const {
    register,
    watch,
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  })

  const {
    fields: allergyFields,
    append: allergyAppend,
    remove: allergyRemove,
  } = useFieldArray({
    name: 'allergy',
    control,
  })

  useEffect(() => {
    reset({ allergy: [user] })
  }, [user])

  const registerUser = async () => {
    formData.userId = location?.state?.patient

    let data: any = {
      afection: [
        {
          description: formData?.allergy[0]?.description,
          item: {
            value: formData?.allergy[0]?.item?.value,
            label: formData?.allergy[0]?.item?.label,
            type: formData?.allergy[0]?.item?.type,
          },
        },
      ],
      userId: formData?.userId,
    }

    let result = await UpdatePatientTutor.addPatientConAllSur(data)

    if (result?.code >= 200 && result?.code < 300) {
      reset(myInitialData)

      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Confirmación',
        text: `¡Ha sido registrado correctamente!`,
        icon: 'success',
        // icon: "error",
        //cancelButtonText: 'Volver al formulario',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
        confirmButtonColor: 'rgb(14 165 233)',
        cancelButtonColor: 'rgb(14 165 233)',
      }).then((res) => {
        setIsLoading(true)
        if (res.isConfirmed) {
          navigate(`/patientprofile/${location?.state?.patient}/`)
        } else {
          setIsLoading(true)
        }
      })
      navigate(`/patientprofile/${location?.state?.patient}/`)
    } else {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      setIsLoading(true)
    }
    setIsLoading(true)
  }

  const onSubmit = async (data: any) => {
    setModalOn(true)
    setFormData(data)
    setIsLoading(false)
  }
  /**
   *
   */

  return (
    <>
      {modalOn && (
        <SumaryAllergyRegister
          formData={formData}
          setChoice={setChoice}
          setModalOn={setModalOn}
          registerUser={registerUser}
          setIsLoading={setIsLoading}
        />
      )}
      <div className="w-full space-y-4 ">
        <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
          <PencilAltIcon strokeWidth={1.3} color="#294fa9" className="w-20" />
          <h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
            Agregando Responsable
          </h2>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          //defaultValue={myInitialData}
        >
          <div className="lg:w-fit mx-auto place-items-center flex flex-col space-y-7">
            <AllergyForm
              Controller={Controller}
              control={control}
              Select={Select}
              register={register}
              allergyFields={allergyFields}
              allergyAppend={allergyAppend}
              allergyRemove={allergyRemove}
              // acsError={acsError}
              // acsIsLoading={acsIsLoading}
              customStyles={customStyles}
              errors={errors}
              acsListData={location?.state?.acsListData}
              CustomValueContainer={CustomValueContainer}
              mode={'add'}
            />
            <div className="lg:col-span-2 w-full xl:col-span-3 justify-center place-items-center center flex md:flex-row flex-col gap-6">
              {isLoadingState ? (
                <>
                  <input
                    type={'button'}
                    value="Volver"
                    className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                    onClick={() => {
                      navigate(`/patientprofile/${location?.state?.patient}/`)
                    }}
                  />
                  <div id="next" className="has-tooltip w-fit h-fit">
                    {!isValid ? (
                      <span className="tooltip rounded shadow-lg p-1 bg-gray-800 text-white -mt-12">
                        Para guardar, debe completar los campos requeridos
                        señalados con *
                      </span>
                    ) : null}
                    <input
                      type="submit"
                      disabled={!isValid}
                      value="Registrar"
                      className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                    />
                  </div>
                </>
              ) : (
                <input
                  type="submit"
                  disabled
                  value="Guardar"
                  className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddAllergyForm
