/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */

import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer'
import { emailValidation, npiValidation } from '../../utils/regexExpresion'
import UpdateUserService from '../../services/updateUserProfile'
import { clearUpdateProfileUserForm } from '../../actions'
import { PencilAltIcon } from '@heroicons/react/outline'
import { CalendarIcon } from '@heroicons/react/outline'
import useGetEntityII from '../../hooks/useGetEntityII'
import { Controller, useForm } from 'react-hook-form'
import { customStyles } from '../customStyleNewLabel'
import React, { useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import options from '../../data/selectOptions'
import NumberFormat from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import Switch from 'react-switch'
import FormUser from './Form'
import Swal from 'sweetalert2'
import { ROLEID, ROLES } from '../../utils/enums/Roles'

const UpdateUser = ({ ...props }: any) => {
  const [stateData, setStateData] = useState<any>(null)
  const [isRequiredNPI, setIsRequiredNPI] = useState(false)
  const [onlySpecialty, setOnlySpecialty] = useState(false)
  const [withoutInstitution, setWithoutInstitution] = useState(false)
  const [photoURL, setPhotoURL] = useState<any>(null)

  const [onlyClinic, setOnlyClinic] = useState<any>(null)
  const [onlyHome, setOnlyHome] = useState<any>(null)

  const navigate = useNavigate()

  const [isLoadingState, setIsLoading] = useState<any>(true)

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({ mode: 'onChange' })

  console.log(`props update user`, props)

  const userTypeSelected = (value: any) => {
    console.log(`Llego un perico: `, value)

    // let flag:any = watch('user_type').isArray() ? watch('user_type'): [watch('user_type')]
    // console.log(`value in: `,flag);

    const NPIREQUIRED = [ROLEID.DOCTOR, ROLEID.NURSE]
    const SPECIALTYREQUIRED = [ROLEID.DOCTOR, ROLEID.ADMIN_CLINIC]
    const DAE = [
      ROLEID.PROJECT_MANAGER,
      ROLEID.PROJECT_ASSISTANT,
      ROLEID.PROJECT_EVALUATOR,
    ]
    const ONLYCLINIC = [ROLEID.DOCTOR, ROLEID.SECRETARY, ROLEID.ADMIN_CLINIC]
    const ONLYHOME = [ROLEID.NURSE, ROLEID.CARER, ROLEID.ADMIN_NURSING_HOME]

    if (NPIREQUIRED.includes(value?.value)) {
      setIsRequiredNPI(true)
    } else {
      setIsRequiredNPI(false)
    }

    if (SPECIALTYREQUIRED.includes(value?.value)) {
      setOnlySpecialty(true)
    } else {
      setOnlySpecialty(false)
    }

    if (DAE.includes(value?.value)) {
      setWithoutInstitution(true)
    } else {
      setWithoutInstitution(false)
    }

    if (ONLYCLINIC.includes(value?.value)) {
      setOnlyClinic(true)
      console.log(`out value onlyClinic: `, onlyClinic)
    } else {
      setOnlyClinic(false)
    }

    if (ONLYHOME.includes(value?.value)) {
      setOnlyHome(true)
      console.log(`out value onlyHome: `, onlyHome)
    } else {
      setOnlyHome(false)
    }

    return value
  }

  // const userTypeSelected = (value: any) => {
  //   const DAEADS = [1, 6, 7, 8]
  //   const ADS = [1, 8]
  //   const DAE = [9, 10, 11]

  //   if (DAEADS.includes(value?.value)) {
  //     setIsRequiredNPI(true)
  //   } else {
  //     setIsRequiredNPI(false)
  //   }

  //   if (ADS.includes(value?.value)) {
  //     setOnlySpecialty(true)
  //   } else {
  //     setOnlySpecialty(false)
  //   }

  //   if (DAE.includes(value?.value)) {
  //     setWithoutInstitution(true)
  //   } else {
  //     setWithoutInstitution(false)
  //   }
  //   return value
  // }
  console.log(
    `props?.updateProfileUserForm`,
    props?.state?.updateProfileUserForm,
  )

  const onSubmit = async (data: any) => {
    console.log(`data\n`,data);
    let phones: any[] = [];
    setIsLoading(false)
    data.id = props?.updateProfileUserForm?.id
    data.clinic_id = props?.updateProfileUserForm?.roles[0]?.clinicId
    data.nursinghome_id = props?.updateProfileUserForm?.roles[0]?.nursingHomeId
    phones.push(
      {
        phoneId: props?.updateProfileUserForm?.phones[0]?.id,
        number: data.phoneMobile,
        isMobile: true,
        extension: null,
      }
    )
    if (data.phone!== null && data.phone !== undefined) {
      phones.push(
        {
          phoneId: props?.updateProfileUserForm?.phones[1]?.id ? props?.updateProfileUserForm?.phones[1]?.id : null,
          number: data.phone,
          isMobile: false,
          extension: data.phoneExt,
        }
      )
    }
    console.log(`phones\n`,phones);
    
    data.phone = phones;
    data.userEntityId = props?.updateProfileUserForm?.roles[0].id
    const responseData = await UpdateUserService.UpdateUserProfile(data)
    console.log(`responseData`, responseData)
    console.log(`\ncode`, responseData?.data?.code)
    console.log(
      `\nrespondfsdfsdfseData`,
      responseData?.data?.data[0]?.infoUser?.id,
    )
    if (responseData?.data?.code >= 200 && responseData?.data?.code <= 299) {
      clearUpdateProfileUserForm(null)
      reset({
        user_type: {},
        firstname: '',
        middlename: '',
        surname: '',
        second_surname: '',
        email: '',
        gender: {},
        birthday: '',
        phone: '',
        phoneExt: '',
        isMobilePatient: '',
        specialty: '',
        countryOrigin: {},
        municipalState: {},
      })
      setStateData({})
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Confirmación',
        text: `Ha sido registrado correctamente`,
        icon: 'success',
        // icon: "error",
        cancelButtonText: 'Volver al formulario',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
        confirmButtonColor: 'rgb(14 165 233)',
        cancelButtonColor: 'rgb(14 165 233)',
      }).then((res) => {
        setIsLoading(true)
        console.log(`\nres`, res)
        if (res.isConfirmed) {
          navigate('/profile/user/', {
            state: { userId: responseData?.data?.data[0]?.infoUser?.id },
          })
          // console.log(result?.data[0].user_id);
        } else {
          setIsLoading(true)
        }
      })
      navigate('/profile/user/', {
        state: { userId: responseData?.data?.data[0]?.infoUser?.id },
      })
    } else {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      setIsLoading(true)
    }
  }

  useEffect(() => {
    if (stateData === null) {
      setTimeout(() => {
        setStateData({
          user_type: options.roleList.filter((item: any) => {
            if (
              item?.value ===
              props?.state?.updateProfileUserForm?.roles[0].roleId
            ) {
              // userTypeSelected(item[0])
              return item
            }
          })[0],
          firstname: props?.state?.updateProfileUserForm?.firstname,
          middlename: props?.state?.updateProfileUserForm?.middlename,
          surname: props?.state?.updateProfileUserForm?.surname,
          second_surname: props?.state?.updateProfileUserForm?.secondSurname,
          email: props?.state?.updateProfileUserForm?.email,
          gender: options.genderOptions
            .filter((c: any) => {
              if (c.value === props?.state?.updateProfileUserForm?.genderId) {
                return c
              }
            })
            .map((c: any) => {
              return c
            }),
          birthday: new Date(props?.updateProfileUserForm?.birthday),
          phoneMobile: props?.updateProfileUserForm?.phones[0]?.number,
          phone: props?.updateProfileUserForm?.phones[1]?.number,
          phoneExt: props?.updateProfileUserForm?.phones[1]?.extension,
          isMobilePatient: props?.updateProfileUserForm?.phones[0]?.isMobile,
          specialty: props?.updateProfileUserForm?.specialty,
          npi: props?.updateProfileUserForm?.npi,
          // countryOrigin: props?.updateProfileUserForm?.country,
          countryOrigin: options.country
            .filter((c: any) => {
              if (c.value === parseInt(props?.updateProfileUserForm?.country)) {
                // console.log(c);
                return c
              }
            })
            .map((c: any) => {
              return c
            }),
          // municipalState:props?.state?.updateProfileUserForm?.city,
          municipalState: options.city
            .filter((c: any) => {
              // console.log(c);
              if (c.value === parseInt(props?.updateProfileUserForm?.city)) {
                return c
              }
            })
            .map((c: any) => {
              // console.log(c);
              return c
            }),
          nursinghome_id: homeList.filter((nh: any) => {
            if (
              nh.value ===
              props?.state?.updateProfileUserForm?.roles[0]?.nursingHomeId
            ) {
              return nh
            }
          }),
          clinic_id: clinicList.filter((c: any) => {
            if (
              c.value ===
              props?.state?.updateProfileUserForm?.roles[0]?.clinicId
            ) {
              return c
            }
          }),
        })
      }, 1000)
    }
  })

  useEffect(() => {
    reset(stateData)
  }, [stateData])
  console.log(`isValid`, isValid)
  console.log(`isLoadingState`, isLoadingState)

  const { homeList, clinicList, error, loading } = useGetEntityII()
  return (
    <div className="w-full space-y-7 ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
          <PencilAltIcon strokeWidth={1.3} color="#294fa9" className="w-20" />
          <h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
            Editando Perfil de Usuario
          </h2>
        </div>
        <div className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-3 place-items-center flex flex-col p-7">
          <FormUser
            optionsRole={options.roleList
              ?.sort((itemA: any, itemB: any) => {
                if (itemA > itemB) {
                  return 1
                }
                if (itemA < itemB) {
                  return -1
                }
                return 0
              })
              .filter((item: any) => {
                if (
                  props?.state?.currentUser?.roles[0].roleId ===
                  ROLEID.PROJECT_MANAGER
                ) {
                  return (
                    [
                      ROLEID.PROJECT_ASSISTANT,
                      ROLEID.PROJECT_EVALUATOR,
                      ROLEID.ADMIN_CLINIC,
                      ROLEID.ADMIN_NURSING_HOME,
                      ROLEID.DOCTOR,
                      ROLEID.SECRETARY,
                      ROLEID.NURSE,
                      ROLEID.CARER,
                    ].includes(item.value) && item
                  )
                }
                if (
                  props?.state?.currentUser?.roles[0].roleId ===
                  ROLEID.ADMIN_CLINIC
                ) {
                  return (
                    [ROLEID.DOCTOR, ROLEID.SECRETARY].includes(item.value) &&
                    item
                  )
                }
                if (
                  props?.state?.currentUser?.roles[0].roleId === ROLEID.DOCTOR
                ) {
                  return [ROLEID.SECRETARY].includes(item.value) && item
                }
                if (
                  props?.state?.currentUser?.roles[0].roleId ===
                  ROLEID.ADMIN_NURSING_HOME
                ) {
                  return (
                    [ROLEID.NURSE, ROLEID.CARER].includes(item.value) && item
                  )
                }
                if (
                  props?.state?.currentUser?.roles[0].roleId === ROLEID.NURSE
                ) {
                  return [ROLEID.CARER].includes(item.value) && item
                }
                if (
                  props?.state?.currentUser?.roles[0].roleId === ROLEID.ADMIN
                ) {
                  return (
                    [
                      ROLEID.DOCTOR,
                      ROLEID.SECRETARY,
                      ROLEID.NURSE,
                      ROLEID.PATIENT,
                      ROLEID.ADMIN,
                      ROLEID.CARER,
                      ROLEID.ADMIN_NURSING_HOME,
                      ROLEID.ADMIN_CLINIC,
                      ROLEID.PROJECT_MANAGER,
                      ROLEID.PROJECT_ASSISTANT,
                      ROLEID.PROJECT_EVALUATOR,
                    ].includes(item.value) && item
                  )
                }
                if (
                  props?.state?.currentUser?.roles[0].roleId ===
                  ROLEID.PROJECT_ASSISTANT
                ) {
                  return (
                    [
                      ROLEID.PROJECT_EVALUATOR,
                      ROLEID.ADMIN_CLINIC,
                      ROLEID.ADMIN_NURSING_HOME,
                      ROLEID.DOCTOR,
                      ROLEID.SECRETARY,
                      ROLEID.NURSE,
                      ROLEID.CARER,
                    ].includes(item.value) && item
                  )
                }
              })}
            Controller={Controller}
            DatePicker={DatePicker}
            CalendarIcon={CalendarIcon}
            Select={Select}
            customStyles={customStyles}
            options={options}
            NumberFormat={NumberFormat}
            emailValidation={emailValidation}
            npiValidation={npiValidation}
            Switch={Switch}
            userTypeSelected={userTypeSelected}
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            control={control}
            isRequiredNPI={isRequiredNPI}
            onlySpecialty={onlySpecialty}
            withoutInstitution={withoutInstitution}
            onSubmit={onSubmit}
            CustomValueContainer={CustomValueContainer}
            mode={'update'}
            homeList={homeList}
            clinicList={clinicList}
            loading={loading}
            watch={watch}
            isValid={isValid}
            onlyClinic={onlyClinic}
            onlyHome={onlyHome}
            photoURL={photoURL}
            setPhotoURL={setPhotoURL}
            currentHome={
              props?.state?.currentUser?.roles[0]?.roleId === ROLEID.ADMIN ||
              props?.state?.currentUser?.roles[0]?.roleId ===
                ROLEID.PROJECT_MANAGER ||
              props?.state?.currentUser?.roles[0]?.roleId ===
                ROLEID.PROJECT_ASSISTANT ||
              props?.state?.currentUser?.roles[0]?.roleId ===
                ROLEID.PROJECT_EVALUATOR
                ? null
                : props?.state?.currentEntitySelected?.nursing_home_id
            }
          />
          <div className="lg:col-span-2 w-full xl:col-span-3 justify-center place-items-center">
            {console.log(
              `\nUPDATEentityUser\n`,
              props?.state?.updateProfileUserForm?.entityUser,
            )}
            {isLoadingState ? (
              <>
                <div className="flex md:flex-row flex-col items-center justify-center gap-6">
                  <input
                    type="button"
                    value="Volver"
                    className="md:w-auto h-10 w-full rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                    onClick={() => {
                      navigate(
                        props?.state?.updateProfileUserForm?.return
                          ? props?.state?.updateProfileUserForm?.return
                          : `/profile/user/`,
                        {
                          state: {
                            userId: props?.state?.updateProfileUserForm?.id,
                            return: props?.state?.updateProfileUserForm?.entity
                              ? null
                              : '/users',
                            searchUser: props?.state?.updateProfileUserForm
                              ?.searchUser
                              ? '/users'
                              : null,
                            entityUser: props?.state?.updateProfileUserForm
                              ?.entityUser
                              ? '/entities/profile'
                              : null,
                          },
                        },
                      )
                    }}
                  />
                  <div id="next" className="has-tooltip w-fit h-fit ">
                    {!isValid ? (
                      <span className="tooltip rounded shadow-lg p-1 bg-gray-800 text-white -mt-12">
                        Para continuar, debe completar los campos requeridos
                        señalados con *
                      </span>
                    ) : null}
                    <input
                      type="submit"
                      disabled={!isValid}
                      value="Actualizar"
                      className="md:w-auto h-10 w-full disabled:bg-gray-300 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                    />
                  </div>
                </div>
              </>
            ) : (
              <input
                type="submit"
                disabled
                value="Cargando"
                className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
              />
            )}
            {/* <input
                type="submit"
                value="Actualizar Usuario"
                className="mx-2 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
              /> */}
          </div>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    state: {
      updateProfileUserForm: state.updateProfileUserForm,
      currentEntitySelected: state.currentEntitySelected,
      currentUser: state.currentUser,
    },
  }
}
const mapDispatchToProps = {
  clearUpdateProfileUserForm,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser)
