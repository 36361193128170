/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import Allergy from '../../../assets/svg/PatientItems/alergia.svg'

const AllergyForm = (props: any) => {
  const {
    Controller,
    control,
    Select,
    register,
    allergyFields,
    allergyAppend,
    allergyRemove,
    acsListData,
    errors,
    acsError,
    acsIsLoading,
    customStyles,
    mode,
    CustomValueContainer,
  } = props
  return (
    <div className="w-full h-full space-y-5 border-b-2">
      {mode !== 'update' ? (
        // <h2 className="text-center text-2xl my-5">Alergias</h2>
        <>
          <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
            <img src={Allergy} className={`w-12`} alt="" />
            <h1 className="text-xl">{`Alergias`}</h1>
          </div>
          <div className="my-1 flex w-full flex-col lg:col-span-2 lg:block xl:col-span-3  text-slate-500">
            <p>Lista de alergias y detalle que afectan al residente.</p>
          </div>
        </>
      ) : null}
      {allergyFields.map((field: any, index: any) => {
        return (
          <div className="w-full space-y-5" key={field.id}>
            <div
              className={`w-full grid gap-2 grid-cols-1 lg:grid-cols-2  ${
                mode !== 'update' ? 'xl:grid-cols-3' : 'xl:grid-cols-2'
              }`}
            >
              <div className="w-full lg:w-64 h-full ">
                <Controller
                  control={control}
                  name={`allergy.${index}.item`}
                  rules={{
                    required: {
                      value: true,
                      message: 'Seleccione una alergia',
                    },
                  }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                  }: any) => (
                    <Select
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      isClearable
                      className="w-full"
                      placeholder="Alergia"
                      styles={customStyles}
                      options={acsListData?.allergies}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      isDisabled={mode === 'update' ? true : false}
                    />
                  )}
                />
                {errors.allergy?.[index]?.item && (
                  <p className="text-sm text-red-600 mt-2">
                    {errors.allergy?.[index]?.item?.message}
                  </p>
                )}
              </div>
              <div className="w-full lg:w-64 h-full relative">
                <textarea
                  id={`allergy.${index}.description`}
                  className="input-style peer"
                  placeholder="Detalles"
                  {...register(`allergy.${index}.description`)}
                />
                <label
                  className="input-style-label"
                  htmlFor={`allergy.${index}.description`}
                >
                  Detalles
                </label>
              </div>
              <input
                type="button"
                className={`${
                  mode === 'update' || mode === 'add' ? 'hidden' : ''
                } w-full h-10 bg-red-500 rounded-lg text-white`}
                onClick={() => allergyRemove(index)}
                value="Eliminar"
              />
            </div>
          </div>
        )
      })}
      <div className="w-full text-center">
        <input
          type="button"
          className={`${
            mode === 'update' || mode === 'add' ? 'hidden' : ''
          } mx-auto w-11/12 lg:w-64 rounded-lg px-4 mb-5 py-2 bg-lime-600 shadow-md duration-300 font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700`}
          value={`Adicionar Alergia`}
          onClick={() => allergyAppend({})}
        />
      </div>
    </div>
  )
}

export default AllergyForm
