import InformationPersonal from '../../assets/svg/PatientItems/informacionPersonal.svg'

const SumaryUserRegisterData = ({
  setModalOn,
  setChoice,
  registerUser,
  setIsLoading,
  formData,
}: any) => {
  const handleOKClick = () => {
    registerUser()
    setChoice(true)
    setModalOn(false)
  }
  const handleCancelClick = () => {
    setIsLoading(true)
    setChoice(false)
    setModalOn(false)
  }

  return (
    <div className=" opacity-100 fixed inset-0 z-50">
      <div className="flex h-screen justify-center items-center ">
        <div className="flex-col justify-center rounded-md bg-white py-10 px-16 grid gap-5 border border-gray-200">
          <div className="flex flex-col text-lg text-zinc-600 max-w-xl space-y-5">
            <p className="font-bold text-2xl text-centerr">
              ¿Esta seguro de registrar este usuario?
            </p>
            <div className="flex flex-col">
              <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
                <img src={InformationPersonal} className={`w-7`} alt="" />
                <h1 className="text-xl">{`Información usuario`}</h1>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Tipo de usuario</b>
                <p>{formData?.user_type?.label}</p>
              </div>
              {formData?.specialty?.length > 0 && (
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Especialidad</b>
                  <p>{formData?.specialty?.label}</p>
                </div>
              )}
              <div className="flex flex-row gap-1">
                <b className="font-bold">Primer nombre: </b>
                <p>{formData?.firstname}</p>
              </div>
              {formData?.middlename && (
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Segundo nombre: </b>
                  <p>{formData?.middlename}</p>
                </div>
              )}
              <div className="flex flex-row gap-1">
                <b className="font-bold">Primer apellido: </b>
                <p>{formData?.surname}</p>
              </div>
              {formData?.second_surname && (
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Segundo apellido: </b>
                  <p>{formData?.second_surname}</p>
                </div>
              )}
              <div className="flex flex-row gap-1">
                <b className="font-bold">Dia de nacimiento: </b>
                <p>{formData?.birthday?.toISOString()?.substring(0, 10)}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Genero: </b>
                <p>{formData?.gender?.label}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Numero Celular: </b>
                <p>{formData?.phoneMobile}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Teléfono Fijo: </b>
                <p>{formData?.phone}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Extención: </b>
                <p>{formData?.phoneExt}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Correo electrónico</b>
                <p>{formData?.email}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Pueblo: </b>
                <p>{formData?.municipalState?.label}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">País: </b>
                <p>{formData?.countryOrigin?.label}</p>
              </div>
              {formData?.npi && (
                <div className="flex flex-row gap-1">
                  {formData?.user_type?.label === 'Enfermero/a' ? (
                    <b className="font-bold">Licencia de enfermería: </b>
                  ) : (
                    <b className="font-bold">NPI: </b>
                  )}
                  <p>{formData?.npi}</p>
                </div>
              )}
              {formData?.clinic_id?.label?.length > 0 ||
              formData?.nursinghome_id?.label?.length > 0 ? (
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Entidad a la que pertenece: </b>
                  <p>
                    {formData?.clinic_id?.label ||
                      formData?.nursinghome_id?.label}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-row justify-center">
            <button
              onClick={handleOKClick}
              className="w-16 rounded px-4 py-2 text-white bg-green-400 "
            >
              Si
            </button>
            <button
              onClick={handleCancelClick}
              className="w-16 rounded px-4 py-2 ml-4 text-white bg-blue-500 "
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SumaryUserRegisterData
