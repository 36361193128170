/* eslint-disable react-hooks/exhaustive-deps */
import '../assets/styles/Sidebar.scss'
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
} from 'react-pro-sidebar'
import { ChartPieIcon, ChevronRightIcon } from '@heroicons/react/outline'
import useGetCurrentUSer from '../hooks/useCurrentUser'
import {
  setSidebarCurrentUser,
  setEntitySelectedByUser,
  setUpdateEntityForm,
  setAllowOptionsByRole,
  setEntitiesId,
  setClinicList,
  setHomeList,
} from '../actions'
import { HomeIcon, SearchIcon } from '@heroicons/react/outline'
import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Entities from '../assets/svg/entidades.svg'
import Calendar from '../assets/svg/calendario.svg'
import CerrarSesion from '../assets/svg/cerrarSesion.svg'
import Configuracion from '../assets/svg/configuracion.svg'
import Registro from '../assets/svg/registro.svg'
import Switch from '../assets/svg/Switch.svg'
import Usuario from '../assets/svg/usuario.svg'
import { rolesAccess } from '../utils/Interfaces/accessRoles'
import logicalRolSidebar from '../utils/MenuAccess/logicalRolSidebar'

const Sidebar = ({
  collapsed,
  toggled,
  handleToggleSidebar,
  logout,
  setModalEntityOn,
  modalEntityOn,
  listEntity,
  setListEntity,
  ...props
}: any) => {
  const [user, setUser] = useState<any>(null)
  const [currentEntity, setCurrentEntity] = useState<any>(null)
  const [access, setAccess] = useState<rolesAccess>()

  const {
    data,
    dataClinic,
    dataHome,
    isLoading,
    clinicIDList,
    homeIDList,
    listSelectClinic,
    listSelectNursingHome,
  }: any = useGetCurrentUSer(`${user === null ? 'get' : 'not'}`)

  const navigator = useNavigate()

  useEffect(() => {
    if (user === null) {
      setTimeout(() => {
        setUser(data)
      }, 500)
    }
  }, [data, user])

  useEffect(() => {
    if (currentEntity === null) {
      setTimeout(() => {
        let flag: any = null

        if (dataClinic !== null && dataHome !== null) {
          flag = dataClinic
        } else if (dataHome !== null) {
          flag = dataHome
        } else if (dataClinic !== null) {
          flag = dataClinic
        }
        setCurrentEntity(flag)
      }, 500)
    }
  }, [currentEntity, setCurrentEntity, dataClinic, dataHome])

  useEffect(() => {
    props?.setClinicList(clinicIDList)
    props?.setHomeList(homeIDList)
  }, [clinicIDList, homeIDList])

  useEffect(() => {
    props?.setSidebarCurrentUser(user)
  }, [user])

  useEffect(() => {
    props?.setEntitySelectedByUser(currentEntity)
  }, [currentEntity])

  useEffect(() => {
    props?.setAllowOptionsByRole(access)
  }, [access])

  useEffect(() => {
    const clinics = Array.isArray(listSelectClinic)
      ? listSelectClinic
      : [listSelectClinic]
    const homes = Array.isArray(listSelectNursingHome)
      ? listSelectNursingHome
      : [listSelectNursingHome]
    setListEntity(homes.concat(clinics))
  }, [listSelectClinic, listSelectNursingHome, setListEntity])

  useEffect(() => {
    setAccess(logicalRolSidebar(data))
  }, [data])

  return (
    <React.Fragment>
      {!modalEntityOn && (
        <ProSidebar
          className="bg-white min-h-screen"
          collapsed={collapsed}
          toggled={toggled}
          breakPoint="md"
          onToggle={handleToggleSidebar}
        >
          <SidebarHeader className="shadow-md bg-[#044382]">
            <Link to="/userprofile">
              <div className="text-white flex flex-col p-5">
                <div className="flex flex-row items-center justify-center gap-x-3">
                  <img
                    src={Usuario}
                    className={`w-[4rem] h-[4rem] rounded-full bg-slate-200`}
                    alt=""
                  />
                  <div id="menu-profile-info" className="w-full">
                    <h1 className="font-bold text-lg">
                      {`${user?.firstname || ''} ${
                        user?.surname || 'Cargando...'
                      }`}
                    </h1>
                    <p className="text-base text-[#ffffff]">
                      {user?.email || 'Cargando...'}
                    </p>
                    <b className="text-sm text-[#ffffff]">
                      {user?.roles[0]?.roleName || 'Cargando...'}
                    </b>
                  </div>
                </div>
                <div className="flex flex-row-reverse m-[-10px] h-4">
                  <div className="flex text-center items-center content-center hover:bg-white hover:text-black hover:p-1 hover:rounded-full">
                    <p className="">Perfil</p>
                    <ChevronRightIcon className="h-4 w-4 mr-[-5px]" />
                  </div>
                </div>
              </div>
            </Link>
          </SidebarHeader>
          <Menu
            // hidden={isLoading}
            className="text-[#294fa9] bg-white h-full"
            iconShape="square"
          >
            <MenuItem
              className="font-bold text-lg "
              icon={<HomeIcon strokeWidth={1.3} />}
              onClick={() => {
                handleToggleSidebar(false)
              }}
            >
              Menu Principal <Link to="/home" />
            </MenuItem>

            {access?.entityOption && (
              <MenuItem
                className="font-bold text-lg "
                onClick={() => {
                  let profile: any
                  handleToggleSidebar(false)
                  profile = currentEntity
                  if (currentEntity?.clinic_comercialName) {
                    profile.type = 'C'
                    profile.preview = true
                    navigator('/entities/profile')
                  } else if (currentEntity?.nursing_home_comercialName) {
                    profile.type = 'HN'
                    profile.preview = true
                    navigator('/entities/profile')
                  }
                  props.setUpdateEntityForm(profile)
                }}
                icon={<img src={Entities} alt="" />}
              >
                {`${
                  currentEntity?.clinic_comercialName ||
                  currentEntity?.nursing_home_comercialName ||
                  'Entidad no asignada'
                }`}
                {currentEntity?.clinic_comercialName ||
                currentEntity?.nursing_home_comercialName ? (
                  <Link to="/user/linkuser" />
                ) : null}
              </MenuItem>
            )}

            <SubMenu
              title="Buscar"
              className="font-bold text-lg"
              icon={<SearchIcon className="w-6" />}
            >
              {access?.searchOption.entity && (
                <MenuItem className="ml-16 font-bold text-lg ">
                  Entidades <Link to="/entities" />
                </MenuItem>
              )}
              {access?.searchOption.resident && (
                <MenuItem
                  className="ml-16"
                  onClick={() => handleToggleSidebar(false)}
                >
                  Residentes <Link to="/patients" />
                </MenuItem>
              )}
              <MenuItem className="ml-16 font-bold text-lg ">
                Usuarios <Link to="/users" />
              </MenuItem>
            </SubMenu>

            {(access?.registerOption.entity ||
              access?.registerOption.resident ||
              access?.registerOption.user) && (
              <SubMenu
                title="Registrar"
                className="font-bold text-lg"
                icon={<img src={Registro} alt="" />}
              >
                {access?.registerOption.entity && (
                  <MenuItem
                    className="ml-16"
                    onClick={() => handleToggleSidebar(false)}
                  >
                    Entidad <Link to="/register/registerentity" />
                  </MenuItem>
                )}
                {access?.registerOption.resident && (
                  <MenuItem
                    className="ml-16"
                    onClick={() => handleToggleSidebar(false)}
                  >
                    Residente <Link to="/registerpatient" />
                  </MenuItem>
                )}
                {access?.registerOption.user && (
                  <MenuItem
                    className="ml-16"
                    onClick={() => handleToggleSidebar(false)}
                  >
                    Usuario <Link to="/register/user" />
                  </MenuItem>
                )}
              </SubMenu>
            )}

            {access?.calendarView && (
              <SubMenu
                title="Calendario"
                className="font-bold text-lg"
                icon={<img src={Calendar} className={`w-12`} alt="" />}
              >
                <MenuItem
                  className="ml-16"
                  onClick={() => handleToggleSidebar(false)}
                >
                  Calendario <Link to="/calendar" />
                </MenuItem>
                <MenuItem
                  className="ml-16"
                  onClick={() => handleToggleSidebar(false)}
                >
                  Crear Reserva <Link to="/newreservation" />
                </MenuItem>
                {/* <MenuItem
                  className="ml-16"
                  onClick={() => handleToggleSidebar(false)}
                >
                  Crear Restriccion <Link to="/newrestriction" />
                </MenuItem> */}
                {/* <MenuItem
                  className="ml-16"
                  onClick={() => handleToggleSidebar(false)}
                >
                  Crear Cita <Link to="/appointments/create" />
                </MenuItem> */}
                <MenuItem
                  className="ml-16"
                  onClick={() => handleToggleSidebar(false)}
                >
                  Listado de Citas <Link to="/appointments" />
                </MenuItem>
              </SubMenu>
            )}

            {access?.statics && (
              <MenuItem
                className="font-bold text-lg"
                onClick={() => {
                  navigator('/metrics')
                }}
                icon={<ChartPieIcon className={`w-6 text-[#294fa9]`} />}
              >
                Estadisticas
              </MenuItem>
            )}

            <SubMenu
              title="Configuracion"
              className="font-bold text-lg"
              icon={<img src={Configuracion} className={`w-12`} alt="" />}
            >
              <MenuItem
                className="ml-16"
                onClick={() => handleToggleSidebar(false)}
              >
                Perfil <Link to="/userprofile" />
              </MenuItem>
            </SubMenu>

            {access?.changeEntity && (
              <MenuItem
                className="font-bold text-lg"
                onClick={() => {
                  const clinics = Array.isArray(listSelectClinic)
                    ? listSelectClinic
                    : listSelectClinic !== null
                    ? [listSelectClinic]
                    : null
                  const homes = Array.isArray(listSelectNursingHome)
                    ? listSelectNursingHome
                    : listSelectNursingHome !== null
                    ? [listSelectNursingHome]
                    : null
                  setListEntity({ clinics, homes })
                  setModalEntityOn(!modalEntityOn)
                }}
                icon={
                  <img src={Switch} className={`w-6 text-[#294fa9]`} alt="" />
                }
              >
                Cambio de entidad
              </MenuItem>
            )}

            <MenuItem
              icon={<img src={CerrarSesion} className={`w-12`} alt="" />}
              className="font-bold text-lg"
              onClick={logout}
            >
              Cerrar Sesion
            </MenuItem>
          </Menu>
        </ProSidebar>
      )}
    </React.Fragment>
  )
}

//Pendiente poder almacenar un usuario en el stado de redux
const mapStateToProps = (state: any) => {
  return {
    state: {
      currentUser: state.currentUser,
      currentEntitySelected: state.currentEntitySelected,
      entitiesId: state.entitiesId,
    },
  }
}

const mapDispatchToProps = {
  setAllowOptionsByRole,
  setSidebarCurrentUser,
  setEntitySelectedByUser,
  setUpdateEntityForm,
  setEntitiesId,
  setClinicList,
  setHomeList,
}

// export default Sidebar;
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
