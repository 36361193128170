let allergies: any[] = []
let conditions: any[] = []
let surgeries: any[] = []

export const FilterDataACS = (data: any) => {
  if (
    !(allergies.length > 0) &&
    !(conditions.length > 0) &&
    !(surgeries.length > 0)
  ) {
    for (const iterator of data) {
      if (iterator.type === 'Allergy') {
        allergies.push(iterator)
      } else if (iterator.type === 'Condition') {
        conditions.push(iterator)
      } else if (iterator.type === 'Surgery') {
        surgeries.push(iterator)
      }
    }
  }

  return { allergies, conditions, surgeries }
}
