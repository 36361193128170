import { UserCircleIcon } from '@heroicons/react/outline'
import React from 'react'
import { Link } from 'react-router-dom'
import { getAge } from '../../utils/getAge'
import Usuario from '../../assets/svg/usuarioSearch.svg'

const PatientItem = ({
  value,
  label,
  gender,
  Profile_birthday,
  Profile_insurance_carrier,
  access,
}: any) => {
  const buttons: any[] = [
    {
      allowed: access?.residentActions?.createAppoinment,
      to: `/appointments/create/${value}`,
      label: 'Crear cita',
      state: {
        value,
        label,
        insurance: Profile_insurance_carrier,
      },
      classlink: 'w-full sm:w-fit',
      classbutton:
        'w-full sm:w-fit rounded-lg border-[1px] border-[#1E93C6] bg-[#1E93C6] px-2 py-2 font-semibold text-white shadow-md duration-300 hover:cursor-pointer hover:border-sky-700 hover:bg-sky-500',
    },
    {
      allowed: access?.residentActions?.createNote,
      to: `/patient/listnote/${value}`,
      label: 'Crear notas',
      state: {
        completeName: label,
      },
      classlink: 'w-full sm:w-fit',
      classbutton:
        'w-full sm:w-fit rounded-lg border-[1px] border-[#0561A0] bg-[#0561A0] px-2 py-2 font-semibold text-white shadow-md duration-300 hover:cursor-pointer hover:border-sky-700 hover:bg-sky-500',
    },
    {
      allowed: access?.residentActions?.watchProfile,
      to: `/patientprofile/${value}`,
      label: 'Ver perfil',
      classlink: 'w-full sm:w-fit',
      classbutton:
        'w-full sm:w-fit rounded-lg border-[1px] border-[#044382] bg-[#044382] px-2 py-2 font-semibold text-white shadow-md duration-300 hover:cursor-pointer hover:border-sky-700 hover:bg-sky-500',
    },
  ]

  return (
    <div className="flex flex-col gap-3 p-4 border-[1px] md:max-w-md shadow-sm rounded-md shadow-slate-400 border-slate-300 bg-white">
      <div className="gap-3 flex flex-row">
        <img
          src={Usuario}
          className={`h-20 w-20 p-1 self-center rounded-full bg-slate-500`}
          alt=""
        />

        <div className="w-full h-full flex flex-col">
          <div className="flex flex-wrap gap-1">
            <p>{label}</p>
          </div>
          <p>{gender}</p>
          <div className="flex gap-1">
            <p>{new Date(Profile_birthday).toISOString().substring(0, 10)}</p>
            <p>{getAge(Profile_birthday)}</p>
          </div>
          <p>{Profile_insurance_carrier}</p>
        </div>
      </div>

      {/* <div className="flex-1 flex-col" /> */}

      <div className="w-full flex flex-col sm:flex-row gap-1 items-center">
        <div className="flex-1" />
        {buttons?.map((option: any) => {
          return (
            <Link
              className={option.classlink}
              hidden={!option?.allowed}
              to={option.to}
              state={option?.state}
            >
              <button className={option.classbutton}>{option.label}</button>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default PatientItem
