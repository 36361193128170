/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import DatePicker from 'react-datepicker'
import Select, { components } from 'react-select'
import StepThree from '../RegisterPatient/TutorInfo'
import TutorInfo from '../RegisterPatient/TutorInfo'
import { connect } from 'react-redux'
import { clearUpdatePatientTutorForm } from '../../actions'
import options from '../../data/selectOptions'
import UpdatePatientTutor from '../../services/updatePatientTutor'
import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline'
import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer'
import Swal from 'sweetalert2'
import SumaryTutorRegisterData from '../Modals/SumaryTutorRegister'

const UpdateTutorForm = (props: any) => {
  const [stateData, setStateData] = useState<any>()
  const location: any = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const [user, initUser] = useState<any>(null)

  const [modalOn, setModalOn] = useState<any>(false)
  const [formData, setFormData] = useState<any>(null)
  const [choice, setChoice] = useState<any>(false)

  const navigate = useNavigate()
  ////console.log(`perico state:\n${JSON.stringify(location, null, 4)}`);
  const {
    register,
    watch,
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty, isSubmitSuccessful },
  } = useForm({
    mode: 'onChange',
  })

  const {
    fields: tutorFields,
    append: tutorAppend,
    remove: tutorRemove,
  } = useFieldArray({
    name: 'tutores',
    control,
  })
  //console.log(`props:\n${JSON.stringify(props, null, 4)}`);

  useEffect(() => {
    if (user === null) {
      setTimeout(() => {
        initUser({
          tutorFirstname: props?.updatePatientTutorForm?.firstname,
          tutorMiddlemame: props?.updatePatientTutorForm?.middlemame,
          tutorSurname: props?.updatePatientTutorForm?.surname,
          tutorSecondSurname: props?.updatePatientTutorForm?.secondSurname,
          tutorProfession: props?.updatePatientTutorForm?.profession,
          tutorEmail: props?.updatePatientTutorForm?.email,

          tutorPhone: props?.updatePatientTutorForm?.phone?.number,
          isMobilePersonalTutor: props?.updatePatientTutorForm?.phone?.isMobile,
          tutorExt: props?.updatePatientTutorForm?.phone?.extension,

          workPlace: props?.updatePatientTutorForm?.workPlace,
          jopOptPhone: props?.updatePatientTutorForm?.tutorLaborPhone?.number,
          jopOptPhoneExt:
            props?.updatePatientTutorForm?.tutorLaborPhone?.extension,

          tutorHomeAddressLineOne: props?.updatePatientTutorForm?.homeAddress,
          tutorHomeAddressLineTwo: props?.updatePatientTutorForm?.homeAddressII,
          tutorZipCode: props?.updatePatientTutorForm?.zipCode,

          tutorOptionalPhone:
            props?.updatePatientTutorForm?.optionalPhone?.number,
          isMobileHomeTutor:
            props?.updatePatientTutorForm?.optionalPhone?.isMobile,
          tutorOptionalExt:
            props?.updatePatientTutorForm?.optionalPhone?.extension,

          tutorJobAddressLineOne: props?.updatePatientTutorForm?.jobAddress,
          tutorJobAddressLineTwo: props?.updatePatientTutorForm?.jobAddressII,
          tutorJobZipCode: props?.updatePatientTutorForm?.jobZipCode,

          tutorLaborPhone:
            props?.updatePatientTutorForm?.optionalPhoneII?.number,
          isMobileLaborTutor:
            props?.updatePatientTutorForm?.optionalPhoneII?.isMobile,
          tutorLaborExt:
            props?.updatePatientTutorForm?.optionalPhoneII?.extension,

          tutorMaritalStatus: options.maritalOptions
            .filter((item: any) => {
              if (
                item?.label ===
                props?.updatePatientTutorForm?.maritalStatus?.value
              ) {
                return item
              }
            })
            .map((item: any) => {
              return item
            })[0],
          tutorRelationship: options.relationshipOptions
            .filter((item: any) => {
              if (item?.label === props?.updatePatientTutorForm?.relationship) {
                return item
              }
            })
            .map((item: any) => {
              return item
            })[0],
          tutorHomeCountry: options.state
            .filter((item: any) => {
              if (item?.label === props?.updatePatientTutorForm?.homeCountry) {
                return item
              }
            })
            .map((item: any) => {
              return item
            })[0],
          tutorHomeState: options.city
            .filter((item: any) => {
              if (item?.label === props?.updatePatientTutorForm?.homeState) {
                return item
              }
            })
            .map((item: any) => {
              return item
            })[0],
          tutorJobState: options.city
            .filter((item: any) => {
              if (item?.label === props?.updatePatientTutorForm?.jobState) {
                return item
              }
            })
            .map((item: any) => {
              return item
            })[0],
          tutorJobCountry: options.state
            .filter((item: any) => {
              if (item?.label === props?.updatePatientTutorForm?.jobCountry) {
                return item
              }
            })
            .map((item: any) => {
              return item
            })[0],
        })
      }, 1000)
    }
  })

  //console.log(`user: \n${JSON.stringify(user)}`);
  useEffect(() => {
    reset({ tutores: [user] })
  }, [user])

  const registerUser = async () => {
    setIsLoading(false)
    formData.tutores[0].id = props?.updatePatientTutorForm?.patient
    formData.tutores[0].tutorId = props?.updatePatientTutorForm?.id
    //console.log(`valores data: \n ${JSON.stringify(formData.tutores[0], null, 4)}`,);
    const dataUpdate: any = await UpdatePatientTutor.UpdatePatientTutor(
      formData.tutores[0],
    )
    if (dataUpdate.code === 200) {
      //alert(JSON.stringify(formData, null, 4));
      clearUpdatePatientTutorForm(null)
      reset({
        tutorFirstname: '',
        tutorMiddlemame: '',
        tutorSurname: '',
        tutorSecondSurname: '',
        tutorProfession: '',
        tutorEmail: '',
        tutorPhone: '',
        isMobilePersonalTutor: false,
        tutorExt: '',
        tutorHomeAddressLineOne: '',
        tutorHomeAddressLineTwo: '',
        tutorZipCode: '',
        tutorOptionalPhone: '',
        isMobileHomeTutor: false,
        tutorOptionalExt: '',
        tutorJobAddressLineOne: '',
        tutorJobAddressLineTwo: '',
        tutorJobZipCode: '',
        tutorLaborPhone: '',
        isMobileLaborTutor: false,
        tutorLaborExt: '',
        workPlace: '',
        jopOptPhone: '',
        jopOptPhoneExt: '',
        tutorMaritalStatus: {},
        tutorRelationship: {},
        tutorHomeCountry: {},
        tutorHomeState: {},
        tutorJobState: {},
        tutorJobCountry: {},
      })
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Confirmación',
        text: `¡Ha sido registrado correctamente!`,
        icon: 'success',
        // icon: "error",
        //cancelButtonText: 'Volver al formulario',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
        confirmButtonColor: 'rgb(14 165 233)',
        cancelButtonColor: 'rgb(14 165 233)',
      })
        .then((res) => {
        if (res.isConfirmed) {
          // console.log(result?.data[0])
          navigate(`/patientprofile/${props?.updatePatientTutorForm?.patient}/`)
        } else {
          setIsLoading(true)
        }
      })
      initUser({})
      navigate(`/patientprofile/${props?.updatePatientTutorForm?.patient}/`)
    } else {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      setIsLoading(true)
      // navigate(`/patientprofile/${props?.updatePatientTutorForm?.patient}/`)
    }
    setIsLoading(true)
  }

  const onSubmit = async (data: any) => {
    // console.log(`onSubmit`, data);
    setModalOn(true)
    setFormData(data)
  }

  return (
    <>
    {modalOn && (
        <SumaryTutorRegisterData
          formData={formData}
          setChoice={setChoice}
          setModalOn={setModalOn}
          registerUser={registerUser}
          setIsLoading={setIsLoading}
        />
      )}
      <div className="w-full space-y-4 ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
            <PencilAltIcon strokeWidth={1.3} color="#294fa9" className="w-20" />
            <h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
              Editando Responsable
            </h2>
          </div>
          <div className="lg:w-fit mx-auto place-items-center flex flex-col space-y-7 p-7">
            <TutorInfo
              Controller={Controller}
              Select={Select}
              control={control}
              NumberFormat={NumberFormat}
              register={register}
              tutorAppend={tutorAppend}
              tutorFields={tutorFields}
              tutorRemove={tutorRemove}
              errors={errors}
              CustomValueContainer={CustomValueContainer}
              mode={'update'}
              watch={watch}
            />
            <div className="lg:col-span-2 w-full xl:col-span-3 justify-center place-items-center center flex md:flex-row flex-col gap-6">
              {isLoading ? (
                <>
                  <input
                    type={'button'}
                    value="Volver"
                    className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                    onClick={() => {
                      navigate(
                        `/patientprofile/${props?.updatePatientTutorForm?.patient}/`,
                      )
                    }}
                  />
                  <input
                    type="submit"
                    value="Guardar"
                    className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  />
                </>
              ) : (
                <>
                  <input
                    type="submit"
                    disabled
                    value="Cargando..."
                    className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
                  />
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
const mapStateToProps = (state: any) => {
  return {
    updatePatientTutorForm: state.updatePatientTutorForm,
  }
}
const mapDispatchToProps = {
  clearUpdatePatientTutorForm,
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateTutorForm)
