import React, { useCallback, useState, useEffect } from 'react'
import {
  Calendar,
  momentLocalizer,
  Views,
  //DateLocalizer,
} from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import { Controller, useForm } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import { customStyles } from '../customStyleNewLabel'
import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer'
import Select from 'react-select'
import useGetUserTypeI from '../../hooks/useGetUserTypeI'
import logicalRolSidebar from '../../utils/MenuAccess/logicalRolSidebar'
import options from '../../data/selectOptions'
import Calendario from '../../assets/svg/calendario.svg'
import CreateAppointmenCalendar from './Appointment/CreateAppointmenCalendar'
import 'moment/locale/es-us'
import { CreateEventCalendar } from '../../services/CreateCalendar'
import * as EventTransformer from '../../utils/DataTransformer/events/dataTransformer'
import Swal from 'sweetalert2'
import useGetEntityRelations from '../../hooks/useGetEntityRelations'
import UpdateAppointmentCalendar from './Appointment/UpdateAppointmentCalendar'

import api from '../../services/Api'
import ModalShowEvent from '../Modals/ModalShowEvent'
import useListLimitEntity from '../../hooks/useListLimitEntity'
import { ROLEID } from '../../utils/enums/Roles'
import { UpdateCalendar } from '../../services/UpdateEvent'

const localizer = momentLocalizer(moment)
// const DragAndDropCalendar: any = withDragAndDrop(Calendar as any)

const formatName = (name: any, count: any) => `${name} ID ${count}`

const ReactBigCalendar = ({ ...props }: any) => {
  const [myEvents, setMyEvents] = useState<any>([])
  const [draggedEvent, setDraggedEvent] = useState<any>()
  const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true)
  const [CalendarCreateAppointment, setCalendarCreateAppointment] =
    useState(true)
  const [CalendarViewMain, setCalendarViewMain] = useState(true)
  const [CalendarUpdateAppointment, setCalendarUpdateAppointment] =
    useState(true)
  const [access, setAccess] = useState<any>(false)
  const [user, setUser] = useState<any>(null)
  const [dataCalendarNH, setDataCalendarNH] = useState<any>([])
  const [error, setError] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [loadingCalendar, setLoadingCalendar] = useState(true)
  const [nursingHomeId, setNursingHomeId] = useState()
  const [calendarIds, setCalendarIds] = useState<any>()
  const [currentEventUpdateSelected, setCurrentEventUpdateSelected] =
    useState<any>(null)
  const [showDataEventModal, setShowDataEventModal] = useState<boolean>(true)
  const [entityListOptions, setEntityListOptions] = useState<any>()
  const [updateMode, setUpdateMode] = useState<boolean>(false)

  const {
    // register: registerUser,
    handleSubmit: handleSubmitUser,
    // reset: resetUser,
    control: controlUser,
    // setValue: setValueUser,
    watch: watchUser,
    formState: { errors: errorsUser },
  } = useForm()

  const {
    register: registerAppointment,
    handleSubmit: handleSubmitAppointment,
    reset: resetAppointment,
    formState: {
      errors: errorsAppointment,
      // isValid: isValidAppointment,
      // isDirty: isDirtyAppointment,
      // isSubmitSuccessful: isSubmitSuccessfulAppointment,
    },
    control: controlAppointment,
    watch: watchAppointment,
  } = useForm()

  // const access: rolesAccess = logicalRolSidebar(user);

  const fetchdata = useCallback(
    async (nursingHomeId?: any) => {
      let user: any = localStorage.getItem('user')
      setLoadingCalendar(!loadingCalendar)
      await api.instanceEvents
        .post(
          `/calendar/calendar-by-NH`,
          {
            nursingHomeId: nursingHomeId,
          },
          {
            headers: {
              Authorization: `${JSON.parse(user).TokenType} ${
                JSON.parse(user).IdToken
              }`,
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          },
        )
        .then((res: any) => {
          setDataCalendarNH(res.data.data[0])
          setLoadingCalendar(true)
        })
        .catch((err: any) => {
          setError(err)
          if ([401].includes(err?.response?.status)) {
            fetchdata(nursingHomeId)
            setLoadingCalendar(true)
          }
        })
    },
    [setLoadingCalendar, setDataCalendarNH, loadingCalendar],
  )

  // useEffect(() => {
  //   fetchdata()
  // }, [fetchdata])

  const { entityList, isloading } = useGetEntityRelations({
    nursingHomeId: props?.state?.currentEntitySelected?.nursing_home_id,
    clinicId: props?.state?.currentEntitySelected?.clinic_id,
  })

  const {
    dataHomes,
    dataClinics,
    isLoading: isLoadingLimitList,
  }: any = useListLimitEntity({
    clinics: Array.isArray(props?.state?.listClinicFromUser)
      ? props?.state?.listClinicFromUse
      : [props?.state?.listClinicFromUse],
    nursingHomes: Array.isArray(props?.state?.listHomeFromUser)
      ? props?.state?.listHomeFromUser
      : [props?.state?.listHomeFromUser],
  })
  // useEffect(() => {
  //   setDataHomes(homeData)
  // }, [dataHomes])

  // console.log('Resultados datahomes', dataHomes)

  const {
    data: dataUserType,
    error: errorUserType,
    isLoading: isLoadingUserType,
  }: any = useGetUserTypeI([4, 1])

  // useEffect(() => {
  //   console.log('Por esta sisas', dataHomes, props?.state?.currentUser)
  //   if (props?.state?.currentUser && dataHomes) {
  //     if (
  //       props?.state?.currentUser?.roles[0]?.roleid === ROLEID.NURSE &&
  //       dataHomes !== undefined
  //     ) {
  //       console.log(
  //         'Entre a setear solo homes del rol:',
  //         props?.state?.currentUser?.roles[0]?.roleid,
  //       )
  //       setEntityListOptions(dataHomes)
  //     }
  //   } else if (
  //     [
  //       ROLEID.ADMIN,
  //       ROLEID.ADMIN_CLINIC,
  //       ROLEID.ADMIN_NURSING_HOME,
  //       ROLEID.CARER,
  //       ROLEID.DOCTOR,
  //       ROLEID.PATIENT,
  //       ROLEID.PROJECT_ASSISTANT,
  //       ROLEID.PROJECT_EVALUATOR,
  //       ROLEID.PROJECT_MANAGER,
  //       ROLEID.SECRETARY,
  //     ].includes(props?.state?.currentUser?.roles[0]?.roleid)
  //   ) {
  //     console.log(
  //       'Entre a setear solo homes del rol:',
  //       props?.state?.currentUser?.roles[0]?.roleid,
  //     )
  //     setEntityListOptions(entityList)
  //   }
  // }, [
  //   user,
  //   dataHomes,
  //   entityList,
  //   dataClinics,
  //   isLoadingLimitList,
  //   props?.state?.currentUser,
  // ])
  // let rezuls = EventTransformer.dataTransformer(
  //   dataCalendarNH.length > 0 ? dataCalendarNH[0].event : null,
  // )

  //cuando la variable user sea null, busque en el estado el usuario actual y asignelo a user
  useEffect(() => {
    if (user === null) {
      setTimeout(() => {
        setUser(props?.state?.currentUser)
      }, 500)
    }
  }, [props?.state?.currentUser, user])

  //cuando user no sea null, asigne los roles al estado local
  useEffect(() => {
    if (user !== null) {
      setTimeout(() => {
        setAccess(logicalRolSidebar(user))
      }, 500)
    }
  }, [user, dataHomes])

  useEffect(() => {
    let calendar: any[] = EventTransformer.dataTransformer(dataCalendarNH)
    let events: any[] = []
    if (calendar?.length > 0) {
      calendar?.map((item: any) => (events = events.concat(item.event)))
    }

    let calendars: any[] = []

    if (calendar?.length > 0) {
      calendar?.map(
        (item: any) =>
          (calendars = calendars.concat({
            value: item.id,
            label: `${item.id} | ${item.dateBeginAt}-${item.timeBeginAt}`,
            timeEndAt: item.timeEndAt,
            dateFinishAt: item.dateFinishAt,
          })),
      )
    }

    setTimeout(() => {
      setCalendarIds(calendars)
      setMyEvents(events)
    }, 1500)

    // setTimeout(() => {
    //   setMyEvents(
    //     EventTransformer.dataTransformer(
    //       dataCalendarNH.length > 0 ? dataCalendarNH : null,
    //     ),
    //   )
    // }, 1500)
  }, [dataCalendarNH, dataHomes])

  useEffect(() => {
    if (currentEventUpdateSelected !== null) {
      resetAppointment({
        calendarId: calendarIds
          ?.filter((item: any) =>
            currentEventUpdateSelected?.calendarId === item?.value
              ? item
              : null,
          )
          ?.map((item: any) => item),
        id: currentEventUpdateSelected?.id,
        statusAssistanceTypeId: options?.assistance
          ?.filter((item: any) =>
            currentEventUpdateSelected?.statusAssistanceTypeIdId === item?.value
              ? item
              : null,
          )
          ?.map((item: any) => item),
        statusPriorityId: options?.priorities
          ?.filter((item: any) =>
            currentEventUpdateSelected?.statusPriorityIdId === item?.value
              ? item
              : null,
          )
          ?.map((item: any) => item),
        insuranceCarrier: currentEventUpdateSelected?.insuranceCarrier,
        statusStatusIdId: currentEventUpdateSelected?.statusStatusIdId,
        orderAttention: currentEventUpdateSelected?.orderAttention,
        beginAt: new Date(currentEventUpdateSelected?.start),
        description: currentEventUpdateSelected?.description,
        endAt: new Date(currentEventUpdateSelected?.end),
        tytocareId: currentEventUpdateSelected?.tytocareId,
        created_by: currentEventUpdateSelected?.created_by,
        // calendarId: currentEventUpdateSelected?.calendarId,
        statusAssistanceTypeIdId:
          currentEventUpdateSelected?.statusAssistanceTypeIdId,
        doctorUserEntityId: dataUserType
          ?.filter((item: any) =>
            currentEventUpdateSelected?.doctorUserEntityId === item?.value &&
            item?.type === 1
              ? item
              : null,
          )
          ?.map((item: any) => item)[0],
        patientUserEntityId: dataUserType
          ?.filter((item: any) =>
            currentEventUpdateSelected?.patientUserEntityId === item?.value &&
            item?.type === 4
              ? item
              : null,
          )
          ?.map((item: any) => item),
        appointmentType: options.appointmentType
          ?.filter((item: any) => {
            let temp
            if (
              currentEventUpdateSelected?.orderAttention === 0 &&
              item.label === 'Cita'
            ) {
              temp = item
            } else if (
              currentEventUpdateSelected?.orderAttention !== 0 &&
              item.label === 'Turno'
            ) {
              temp = item
            }
            return temp
          })
          ?.map((item: any) => item),
      })
    }
  }, [
    currentEventUpdateSelected,
    dataUserType,
    resetAppointment,
    dataHomes,
    calendarIds,
  ])
  // const {
  //   dataPatients: dataUser,
  //   dataPhysician: dataPhysicianList,
  //   error: errorUser,
  //   isLoading: isLoadingUser,
  // }: any = useGetUserTypeI({ types: [{ id: 0 }, { id: 1 }] })

  const returnView = () => {
    setCalendarViewMain(!CalendarViewMain)
    setCalendarCreateAppointment(!CalendarCreateAppointment)
  }

  const returnViewTwo = () => {
    setCalendarViewMain(!CalendarViewMain)
    setCalendarUpdateAppointment(!CalendarUpdateAppointment)
  }
  // const clickedShowData = (event: any) => {}

  // const handleDragStart: any = useCallback(
  //   (event: any) => setDraggedEvent(event),
  //   [],
  // )

  // const handleDisplayDragItemInCell = useCallback(
  //   () => setDisplayDragItemInCell((prev) => !prev),
  //   [],
  // )

  // const eventPropGetter = useCallback((event: any) => {
  //   return {
  //     ...(event.isDraggable
  //       ? { className: 'isDraggable' }
  //       : { className: 'nonDraggable' }),
  //   }
  // }, [])

  // const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent])

  // const customOnDragOver = useCallback(
  //   (dragEvent: any) => {
  //     // check for undroppable is specific to this example
  //     // and not part of API. This just demonstrates that
  //     // onDragOver can optionally be passed to conditionally
  //     // allow draggable items to be dropped on cal, based on
  //     // whether event.preventDefault is called
  //     if (draggedEvent !== 'undroppable') {
  //       dragEvent.preventDefault()
  //     }
  //   },
  //   [draggedEvent],
  // )

  //Movimiento de los eventos entre columnas
  // const moveEvent = useCallback(
  //   ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }: any) => {
  //     const { allDay } = event
  //     if (!allDay && droppedOnAllDaySlot) {
  //       event.allDay = true
  //     }
  //     setMyEvents((prev: any) => {
  //       const existing = prev.find((ev: any) => ev === event)
  //       const filtered = prev.filter((ev: any) => ev !== event)
  //       return [...filtered, { ...existing, start, end, allDay }]
  //     })
  //   },
  //   [setMyEvents],
  // )

  //Al hacer click va a crear un nuevo evento y si ya hay existentes los va a añadir tambien
  const createNewEvent = useCallback(
    async ({
      beginAt,
      endAt,
      description,
      orderAttention,
      tytocareId,
      insuranceCarrier,
      appointmentType,
      patientUserEntityId,
      doctorUserEntityId,
      statusPriorityId,
      statusAssistanceTypeId,
      calendarId,
    }: any) => {
      // const title = window.prompt('New Event name');
      setIsLoading(!isLoading)
      if (
        description &&
        patientUserEntityId &&
        doctorUserEntityId &&
        statusPriorityId &&
        statusAssistanceTypeId
      ) {
        const result = await CreateEventCalendar({
          start: beginAt,
          end: endAt,
          beginAt,
          endAt,
          title: 'Evento',
          details: {
            orderAttention,
            tytocareId,
            insuranceCarrier,
            appointmentType,
            patientUserEntityId,
            doctorUserEntityId,
            statusPriorityId,
            statusAssistanceTypeId,
            description,
            created_by: props?.state?.currentUser.id,
            calendarId: calendarId.value,
          },
        })

        if (result.data.code >= 200 && result.data.code <= 290) {
          setIsLoading(!isLoading)
          setCalendarViewMain(!CalendarViewMain)
          setCalendarCreateAppointment(!CalendarCreateAppointment)
          fetchdata(nursingHomeId)
        }

        //setear evento nuevo conservando los ya existentes
        // setMyEvents((prev: any) => {
        //   return [
        //     ...prev,
        //     {
        //       start: beginAt,
        //       end: endAt,
        //       beginAt,
        //       endAt,
        //       title: 'Evento',
        //       details: {
        //         orderAttention,
        //         tytocareId,
        //         insuranceCarrier,
        //         appointmentType,
        //         patientUserEntityId,
        //         doctorUserEntityId,
        //         statusPriorityId,
        //         statusAssistanceTypeId,
        //       },
        //     },
        //   ]
        // })
      }
    },
    [
      fetchdata,
      CalendarCreateAppointment,
      setIsLoading,
      CalendarViewMain,
      isLoading,
      nursingHomeId,
      props?.state?.currentUser?.id,
    ],
    // [CalendarCreateAppointment, setMyEvents],
  )

  const updateEvent = useCallback(
    async (params: any) => {
      const result = await UpdateCalendar(params)
      if (result?.data?.code === 200) {
        setCalendarViewMain(!CalendarViewMain)
        setCalendarUpdateAppointment(!CalendarUpdateAppointment)
      }
      // console.log('EventUpdate', result)
    },
    [CalendarUpdateAppointment, CalendarViewMain],
  )

  const newEvent = useCallback(
    ({ start, end }: any) => {
      resetAppointment({
        beginAt: start,
        endAt: end,
      })
      // console.log('nursingHomeId', nursingHomeId)
      // console.log('nursingHomeIdWatch', watchUser('nursingHomeId'))
      if (new Date(start) >= new Date() && nursingHomeId !== undefined) {
        setCurrentEventUpdateSelected(null)
        setCalendarViewMain(!CalendarViewMain)
        setCalendarCreateAppointment(!CalendarCreateAppointment)
      } else if (new Date(start) <= new Date()) {
        Swal.fire({
          customClass: {
            confirmButton: 'bg-yellow-500 text-black',
          },
          title: '¡Accion no permitida!',
          text: 'No puedes crear eventos en el pasado',
          icon: 'info',
          confirmButtonText: 'Aceptar',
        })
      } else if (new Date(start) >= new Date() && nursingHomeId === undefined) {
        Swal.fire({
          customClass: {
            confirmButton: 'bg-yellow-500 text-black',
          },
          title: '¡Accion no permitida!',
          text: 'Selecciona un Nursing Home para poder añadir una cita/evento',
          icon: 'info',
          confirmButtonText: 'Aceptar',
        })
      }
      // const title = window.prompt('New Event name');
    },
    [
      resetAppointment,
      nursingHomeId,
      watchUser,
      CalendarViewMain,
      CalendarCreateAppointment,
    ],
    // (event: any) => {
    //   setMyEvents((prev: any) => {
    //     const idList = prev.map((item: any) => item.id);
    //     const newId = Math.max(...idList) + 1;
    //     return [...prev, { ...event, id: newId }];
    //   });
    // },
    // [setMyEvents]
  )

  // se usa cuando arrastro un elemento en el calendario y lo suelto en otra caja
  // const onDropFromOutside = useCallback(
  //   ({ start, end, allDay: isAllDay }: any) => {
  //     if (draggedEvent === 'undroppable') {
  //       setDraggedEvent(null)
  //       return
  //     }

  //     const { name }: any = draggedEvent
  //     const event = {
  //       title: formatName(name, counters[name]),
  //       start,
  //       end,
  //       isAllDay,
  //     }
  //     setDraggedEvent(null)
  //     setCounters((prev: any) => {
  //       const { [name]: count }: any = prev
  //       return {
  //         ...prev,
  //         [name]: count + 1,
  //       }
  //     })
  //     newEvent(event)
  //   },
  //   [draggedEvent, counters, setDraggedEvent, setCounters, newEvent],
  // )

  //Permite la redimension del tiempo de un evento
  // const resizeEvent = useCallback(
  //   ({ event, start, end }: any) => {
  //     setMyEvents((prev: any) => {
  //       const existing = prev.find((ev: any) => ev === event) ?? {}
  //       const filtered = prev.filter((ev: any) => ev !== event)
  //       return [...filtered, { ...existing, start, end }]
  //     })
  //   },
  //   [setMyEvents],
  // )

  const rescheduleEventAction = useCallback(() => {
    if (nursingHomeId !== undefined) {
      if (currentEventUpdateSelected !== null) {
        setUpdateMode(true)
        setCalendarViewMain(!CalendarViewMain)
        setCalendarUpdateAppointment(!CalendarUpdateAppointment)
        setShowDataEventModal(!showDataEventModal)
        setCurrentEventUpdateSelected((prev: any) => {
          return { formRescheduleMode: true, ...prev }
        })
      }
    }
  }, [
    CalendarUpdateAppointment,
    CalendarViewMain,
    currentEventUpdateSelected,
    nursingHomeId,
    showDataEventModal,
  ])

  const updateEventAction = useCallback(() => {
    if (nursingHomeId !== undefined) {
      if (currentEventUpdateSelected !== null) {
        setUpdateMode(false)
        setCalendarViewMain(!CalendarViewMain)
        setCalendarUpdateAppointment(!CalendarUpdateAppointment)
        setShowDataEventModal(!showDataEventModal)
        setCurrentEventUpdateSelected((prev: any) => {
          return { formUpdateMode: true, ...prev }
        })
      }
    }
  }, [
    CalendarUpdateAppointment,
    CalendarViewMain,
    currentEventUpdateSelected,
    nursingHomeId,
    showDataEventModal,
  ])

  const handleSelectEvent = useCallback(
    (event: any) => {
      setCurrentEventUpdateSelected(null)
      let daticos: any = JSON.parse(JSON.stringify(event))
      daticos = {
        end: new Date(daticos?.end),
        start: new Date(daticos?.start),
        doctorName: dataUserType
          ?.filter((item: any) =>
            daticos?.doctorUserEntityId === item?.value && item?.type === 1
              ? item
              : null,
          )
          ?.map((item: any) => item)[0]?.label,
        patientName: dataUserType
          ?.filter((item: any) =>
            daticos?.patientUserEntityId === item?.value && item?.type === 4
              ? item
              : null,
          )
          ?.map((item: any) => item)[0]?.label,
        ...event,
      }
      setCurrentEventUpdateSelected(daticos)
      setShowDataEventModal(!showDataEventModal)
    },
    [dataUserType, showDataEventModal],
  )

  // const defaultDate = useMemo(() => new Date(), []) //Fecha predeterminada a mostrar

  const onSubmitDoctor = useCallback(
    (data: any) => {
      // console.log(
      //   'onsubmit',
      //   data.nursingHomeId.nursingHomeId === undefined
      //     ? data.nursingHomeId.value
      //     : data.nursingHomeId.nursingHomeId,
      // )
      setNursingHomeId(
        data.nursingHomeId.nursingHomeId === undefined
          ? data.nursingHomeId.value
          : data.nursingHomeId.nursingHomeId,
      )
      fetchdata(
        data.nursingHomeId.nursingHomeId === undefined
          ? data.nursingHomeId.value
          : data.nursingHomeId.nursingHomeId,
      )
    },
    [setNursingHomeId, fetchdata],
  )

  // const customDayPropGetter = (date: any) => {
  //   if (date.getDate() === 7 || date.getDate() === 15)
  //     return {
  //       className: 'specialDay',
  //       style: {
  //         border: 'solid 3px ' + (date.getDate() === 7 ? '#faa' : '#afa'),
  //       },
  //     }
  //   else return {}
  // }

  // const customSlotPropGetter = (date: any) => {
  // // if (date.getDate() === 7 || date.getDate() === 15)
  // // 	return {
  // // 		className: 'specialDay',
  // // 	};
  // // else return {};
  // const currentSlot: Date = new Date(date);
  // let restriccion: {
  // 	start: Date;
  // 	end: Date;
  // }[] = [
  // 	{
  // 		start: new Date('2022-08-21T08:30:00'),
  // 		end: new Date('2022-08-27T17:00:00'),
  // 	},
  // ];
  // for (let index = 0; index <= restriccion.length; index++) {
  // 	if (
  // 		restriccion[index].start.getHours() <= currentSlot.getHours() &&
  // 		restriccion[index].end.getHours() - 1 >= currentSlot.getHours() &&
  // 		restriccion[index].start.getDay() <= currentSlot.getDay()
  // 	) {
  // 		return {
  // 			className: 'h-5 bg-green-900/30',
  // 		};
  // 	} else {
  // 		return { className: 'h-5 bg-black/5' };
  // 	}
  // }
  // }

  const event = (props: any) => {
    return <div>{props.children}</div>
  }

  const timeSlotWrapper = (props: any) => {
    // const currentSlot: Date = new Date(props.value);

    // let restriccion: {
    // 	start: Date;
    // 	end: Date;
    // }[] = [
    // 	{
    // 		start: new Date('2022-08-22T08:30:00'),
    // 		end: new Date('2022-08-22T17:00:00'),
    // 	},
    // 	{
    // 		start: new Date('2022-08-23T10:30:00'),
    // 		end: new Date('2022-08-23T17:00:00'),
    // 	},
    // ];

    // for (let index = 0; index < restriccion.length; index++) {

    // 	if (
    // 		restriccion[index].start.getTime() <= currentSlot.getTime() &&
    // 		restriccion[index].end.getTime() - 30 >= currentSlot.getTime() &&
    // 		restriccion[index].start.getDay() === currentSlot.getDay()
    // 	) {
    // 		return (
    // 			<div className="h-5 bg-green-900/30">
    // 				{/* <div className="" style={{ backgroundColor: "#000" }}> */}
    // 				{props.children}
    // 			</div>
    // 		);
    // 	} else {
    // 		return (
    // 			<div className="h-5 bg-black/5">
    // 				{/* <div className="" style={{ backgroundColor: "#000" }}> */}
    // 				{props.children}
    // 			</div>
    // 		);
    // 	}
    // }
    return (
      <div
        className={`h-[40px] ${
          props.value >= new Date() ? 'bg-[#33ff4438]' : 'bg-[#2727270c]'
        }`}
      >
        {props.children}
      </div>
    )
    //debe cumplirse la condicion tanto para el dia como la hora para poder marcar el espacio a utilizar, funciona como el plano cartesiano
    // if (
    // 	dias.includes(props.value.getDate()) &&
    // 	perica.includes(props.value.getHours())
    // ) {
    // 	return (
    // 		<div className="h-5 bg-slate-500">
    // 			{/* <div className="" style={{ backgroundColor: "#000" }}> */}
    // 			{props.children}
    // 		</div>
    // 	);
    // 	// ){
    // 	//   className: "specialDay",
    // 	// };
    // } else
    // 	return (
    // 		<div className="h-5">
    // 			{/* <div className="" style={{ backgroundColor: "#000" }}> */}
    // 			{props.children}
    // 		</div>
    // 	);

    // return (
    //   <div className="h-5">
    //     {/* <div className="" style={{ backgroundColor: "#000" }}> */}
    //     {props.children}
    //   </div>
    // );
  }

  // const showCalendar = (onChange: any) => {
  //   setAccess(true)
  //   return onChange
  // }

  // console.log('Muestra de homes', dataHomes)
  // console.log('Muestra de clinicas', entityList)
  // console.log('muestra de props', props?.state?.currentUser?.roles[0]?.roleId)

  return (
    <>
      {!showDataEventModal && (
        <ModalShowEvent
          currentEventUpdateSelected={currentEventUpdateSelected}
          showDataEventModal={showDataEventModal}
          setShowDataEventModal={setShowDataEventModal}
          updateEventAction={updateEventAction}
          rescheduleEventAction={rescheduleEventAction}
        />
      )}
      <div className="w-full h-screen space-y-7 ">
        <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
          <img src={Calendario} className={`w-20`} alt="" />
          <h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#294fa9]">
            Calendario
          </h2>
        </div>
        <div className={`w-full h-2/3`}>
          {/* {access?.calendarOption && ( */}

          {/* // )} */}
          {access?.calendarView && (
            <>
              {CalendarViewMain && (
                <>
                  {/* {isLoading ? (
                  <div>Cargando...</div>
                ) : ( */}
                  <>
                    <div className="w-full p-4 flex flex-col gap-3">
                      <p className="font-bold">
                        Seleccione un Hogar para visualizar su calendario con
                        las citas y reservas correspondientes
                      </p>
                      <form
                        className="w-full flex flex-row gap-3"
                        onSubmit={handleSubmitUser(onSubmitDoctor)}
                      >
                        <div className="w-full h-full">
                          <Controller
                            control={controlUser}
                            name="nursingHomeId"
                            rules={{
                              required: {
                                value: true,
                                message:
                                  'Seleccione un doctor para poder visualizar el calendario',
                              },
                            }}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }: any) => (
                              <Select
                                name="nursingHomeId"
                                id="nursingHomeId"
                                ref={ref}
                                isClearable
                                isDisabled={isloading ? true : false}
                                className="w-full"
                                placeholder="Nursing Home...*"
                                styles={customStyles}
                                onBlur={onBlur}
                                onChange={onChange}
                                components={{
                                  ValueContainer: CustomValueContainer,
                                }}
                                options={
                                  props?.state?.currentUser?.roles[0]
                                    ?.roleId === ROLEID.NURSE
                                    ? dataHomes
                                    : entityList
                                }
                                value={value}
                              />
                            )}
                          />
                          {errorsUser.calendarDoctor && (
                            <p className="text-sm text-red-600 mt-2">
                              {errorsUser.calendarDoctor.message}
                            </p>
                          )}
                        </div>
                        <input
                          type="submit"
                          value={
                            !loadingCalendar ? 'Cargando' : 'Mostrar Calendario'
                          }
                          disabled={!loadingCalendar}
                          className="h-10 px-4 rounded-lg border-[1px] border-[#044382] bg-[#044382] font-semibold text-white shadow-md duration-300 hover:cursor-pointer hover:border-sky-700 hover:bg-sky-500"
                        />
                      </form>
                    </div>
                    <div className={`w-full h-full p-4 `}>
                      <Calendar
                        // <DragAndDropCalendar
                        defaultDate={new Date()}
                        defaultView={Views.WEEK}
                        min={new Date(2022, 12, 19, 7, 0, 0)}
                        max={new Date(202, 12, 23, 17, 0, 0)}
                        // dragFromOutsideItem={
                        //   displayDragItemInCell ? dragFromOutsideItem : undefined
                        // }
                        // draggableAccessor={() => {
                        //   return true
                        // }}
                        // eventPropGetter={eventPropGetter}
                        culture="es"
                        localizer={localizer} //Localizador para obtener el calendario
                        events={myEvents} //eventos que vienen de algun lado
                        // onDropFromOutside={onDropFromOutside} //Soltar el evento en otro espacio
                        // onDragOver={customOnDragOver} //funcion de arrastrar
                        // onEventDrop={moveEvent} //cuando se suenta un evento a otro cuadro
                        // onEventResize={resizeEvent} //cambiar el rango de un evento, ya sea con fechas u horas
                        onSelectSlot={newEvent} //Permite la creacion de un nuevo evento cuando se arrastra y se suelta en cajas y cuando hacemos clic en las cajas
                        onSelectEvent={handleSelectEvent}
                        // resizable //Permitir redimensionar por medio de la accion del usuario
                        selectable //Poder hacer clic en los eventos
                        // dayPropGetter={customDayPropGetter}
                        // slotPropGetter={customSlotPropGetter} //Asigna color a los dias
                        step={15}
                        timeslots={1}
                        // formats={{
                        //   timeGutterFormat: (
                        //     date: any,
                        //     culture: any,
                        //     localizer: any,
                        //   ) => localizer.format(date, 'H:mm', culture),
                        // }}
                        messages={{
                          next: 'Siguiente',
                          previous: 'Atrás',
                          today: 'Hoy',
                          day: 'Dia',
                          week: 'Semana',
                          month: 'Mes',
                          agenda: 'Agenda',
                        }}
                        components={{
                          timeSlotWrapper,
                          event,
                          //   timeSlotWrapper,
                        }}
                      />
                    </div>
                  </>
                  {/* )*/}
                </>
              )}
              {!CalendarUpdateAppointment && (
                <>
                  <UpdateAppointmentCalendar
                    returnViewTwo={returnViewTwo}
                    Controller={Controller}
                    control={controlAppointment}
                    Select={Select}
                    errors={errorsAppointment}
                    DatePicker={DatePicker}
                    register={registerAppointment}
                    watch={watchAppointment}
                    handleSubmit={handleSubmitAppointment}
                    reset={resetAppointment}
                    updateEvent={updateEvent}
                    calendars={calendarIds}
                    loading={isLoading}
                    isLoadingUserType={isLoadingUserType}
                    errorUserType={errorUserType}
                    dataUserType={dataUserType}
                    options={options}
                    updateMode={updateMode}
                  />
                </>
              )}
              {!CalendarCreateAppointment && (
                <div>
                  {/* {JSON.stringify(myEvents, null, 4)} */}
                  <CreateAppointmenCalendar
                    returnView={returnView}
                    Controller={Controller}
                    control={controlAppointment}
                    Select={Select}
                    errors={errorsAppointment}
                    DatePicker={DatePicker}
                    register={registerAppointment}
                    watch={watchAppointment}
                    handleSubmit={handleSubmitAppointment}
                    reset={resetAppointment}
                    createNewEvent={createNewEvent}
                    calendars={calendarIds}
                    loading={isLoading}
                    isLoadingUserType={isLoadingUserType}
                    errorUserType={errorUserType}
                    dataUserType={dataUserType}
                    options={options}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    state: {
      currentUser: state.currentUser,
      currentEntitySelected: state.currentEntitySelected,
      listClinicFromUser: state.listClinicFromUser,
      listHomeFromUser: state.listHomeFromUser,
    },
  }
}

const mapDispatchToProps = {}

// export default Sidebar;
export default connect(mapStateToProps, mapDispatchToProps)(ReactBigCalendar)
