//Define el ancho y el alto de la pantalla con el menu incluido
const FullContent = ({ children }: any) => {
  return (
    <div className="w-full min-h-screen bg-backgroundSVG bg-center">
      {children}
    </div>
  )
}

export default FullContent
