import api from './Api'
import Swal from 'sweetalert2'

const UpdatePatientDoctor = async (obj: any) => {
  let user: any = localStorage.getItem('user')
  // console.log(`obj: \n${JSON.stringify(obj, null, 4)}`);

  const {
    doctorFirstname,
    doctorSurname,
    clinicalName,
    doctorJobAddress,
    doctorJobAddressAlt,
    doctorZipCode,
    doctorPhone,
    extDoctorPhone,
    doctorEmail,
    clinicalMedicalRecord,
    doctorJobCountry,
    doctorJobState,
    doctorSpecialty,
    id,
    doctorId,
    doctorLocalPhone,
    extDoctorLocalPhone,
  } = obj
  // console.log(`comentario obj: ${JSON.stringify(obj, null, 4)} `);

  const data = await api.instanceUsers
    .post(
      `/user/update-doctor/`,
      {
        doctorFirstname,
        doctorSurname,
        clinicalName,
        doctorJobAddress,
        doctorJobAddressAlt,
        doctorZipCode,
        doctorPhone,
        extDoctorPhone,
        doctorEmail,
        clinicalMedicalRecord,
        doctorJobCountry,
        doctorJobState,
        doctorSpecialty,
        id,
        doctorId,
        doctorLocalPhone,
        extDoctorLocalPhone,
      },
      {
        headers: {
          Authorization: `${JSON.parse(user).TokenType} ${
            JSON.parse(user).IdToken
          }`,
          USER_ENTITY_ID: `${localStorage.getItem('role')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    )
    .then((response) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmación',
        text: 'Se realizó actualización con éxito!',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return response.data
    })
    .catch((error) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return error
    })
  return data
}
const DeletePatientDoctor = async (obj: any) => {
  let user: any = localStorage.getItem('user')
  // console.log(`obj: \n${JSON.stringify(obj, null, 4)}`)
  // console.log(`obj.id: \n${JSON.stringify(obj.id, null, 4)}`)

  const { userId } = obj.id

  const data = await api.instanceUsers
    .post(
      `/user/delete-specialty-doctor`,
      {
        userId: obj.id,
      },
      {
        headers: {
          Authorization: `${JSON.parse(user).TokenType} ${
            JSON.parse(user).IdToken
          }`,
          USER_ENTITY_ID: `${localStorage.getItem('role')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    )
    .then((response) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmacion',
        text: 'Se elimino con éxito!',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return response.data
    })
    .catch((error) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return error
    })
  return data
}

const UpdateDoctor = {
  UpdatePatientDoctor,
  DeletePatientDoctor,
}

export default UpdateDoctor
