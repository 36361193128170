import { PhoneIcon } from '@heroicons/react/outline'
import IconoContacto from '../../assets/svg/iconoContacto.svg'

const Info = ({ dataProfile, ...props }: any) => {
  //console.log(`dataProfile \n`,dataProfile);
  //console.log(`dataProfile?.roles \n`,dataProfile?.roles);
  
  let shownRoles: any = {}
  return (
    <>
      <div className="flex flex-row items-center justify-start gap-1.5 border-b-2">
        <img src={IconoContacto} className={`w-11`} alt="" />
        <h1 className="text-black text-xl font-bold first-line:capitalize">
          {'Información Personal'}
        </h1>
      </div>
      <div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5 ">
        <div className="w-fit md:ml-24 min-w-sm	 ">
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Estado de usuario: </p>
            <p>{dataProfile?.statusName ? dataProfile?.statusName : 'N/A'}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Rol/es asignados: </p>
            <p>
              {dataProfile?.roles?.map((item: any, idx: any) => {
                if (!shownRoles[item.roleName]) {
                  shownRoles[item.roleName] = true
                  return (
                    <p key={item?.id}>
                      {item?.roleName}
                      {dataProfile?.length > 0 ? ', ' : ''}
                    </p>
                  )
                }
              })}
            </p>
          </div>
          {dataProfile?.roles[0]?.roleName === 'Doctor' && (
            <div className="md:gap-1.5 md:flex md:flex-row">
              <p className="text-black font-bold">NPI: </p>
              <p>{dataProfile?.npi ? dataProfile?.npi : 'N/A'}</p>
            </div>
          )}
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Fecha de nacimiento: </p>
            <p>
              {dataProfile?.birthday
                ? new Date(dataProfile?.birthday).toISOString().substring(0, 10)
                : 'No encontrado'}
            </p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Ocupacion/Especialidad: </p>
            <p>{dataProfile?.specialty ? dataProfile?.specialty : 'N/A'}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Genero: </p>
            <p>{dataProfile?.genderName ? dataProfile?.genderName : 'N/A'}</p>
          </div>
        </div>
      </div>

      <div className="flex flex-row items-center justify-start border-b-2 gap-1.5 ">
        <PhoneIcon strokeWidth={1.3} color="#294fa9" className={`w-11 `} />
        <h1 className="text-black text-xl font-bold first-line:capitalize">
          {'Información de contacto'}
        </h1>
      </div>
      <div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5 ">
        <div className="w-fit md:ml-24 min-w-sm	 ">
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Correo electrónico: </p>
            <p>{dataProfile?.email ? dataProfile?.email : 'N/A'}</p>
          </div>

          {dataProfile?.phones && (
            <div className="md:gap-1.5 md:flex md:flex-row">
              <p className="text-black font-bold">Número Celular:</p>
              <p>
                {dataProfile?.phones?.map((item: any) =>
                  item?.isMobile === true ? (
                    <p key={item.id}>{item?.number}</p>
                  ) : (
                    ''
                  ),
                )}
              </p>
            </div>
          )}

          {dataProfile?.phones && (
            <div className="md:gap-1.5 md:flex md:flex-row">
              <p className="text-black font-bold">Teléfono Fijo: </p>
              <p>
                {dataProfile?.phones?.map((item: any) =>
                  item?.isMobile === false ? (
                    <p>
                      {`${item?.number ? item?.number : 'N/A'} `}
                      <b className="text-black font-bold ">Ext: </b>
                      {item?.extension ? ` ${item?.extension}` : 'N/A'}
                    </p>
                  ) : (
                    ''
                  ),
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Info
