/* eslint-disable @typescript-eslint/no-unused-vars */
import { clearSpace } from '../utils/regexExpresion'
import TokenService from './Token'
import Swal from 'sweetalert2'
import api from './Api'

const login = async (data: any) => {
  return await api.instanceUsers
    .post('/user/login', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: any) => {
      if (response.data.data[0].AccessToken) {
        TokenService.setUser(response.data.data[0])
      }
      return response.data
    })
}

const changepass = async (data: any) => {
  return await api.instanceUsers
    .post('/user/change-password-first-login', data, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    .then((response: any) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

const register = async (data: any) => {
  console.group()
  console.log('Register Raw Data: ', data)

  let user: any = localStorage.getItem('user')

  let loading: boolean = true

  let {
    user_type,
    firstname,
    middlename,
    surname,
    second_surname,
    specialty,
    phone,
    birthday,
    gender,
    email,
    phoneMobile,
    npi,
    countryOrigin,
    municipalState,
    clinic_id,
    nursinghome_id,
    phoneExt,
  } = data
  middlename = middlename === null ? '' : middlename
  second_surname = second_surname === null ? '' : second_surname
  const obj = {
    user_type: user_type?.value,
    firstname,
    middlename: middlename ? middlename : '',
    surname,
    second_surname: second_surname ? second_surname : '',
    email,
    npi,
    specialty: specialty?.label,
    birthday: new Date(`${birthday}`).toISOString().substring(0, 10),
    phone: [
      {
        isMobile: true,
        telephone: phoneMobile?.replace(clearSpace, ''),
      },
      {
        isMobile: false,
        telephone: phone?.replace(clearSpace, ''),
        extension: phoneExt,
      },
    ],
    gender: gender.value,
    clinic_id: clinic_id?.value || 0,
    nursinghome_id: nursinghome_id?.value || 0,
    Country: countryOrigin?.value,
    city: municipalState?.value,
    city_id: municipalState?.value,
  }

  console.log('Register Formated Data: ', obj)
  console.groupEnd()
  loading = false

  let result = await api.instanceUsers
    .post(`/user/create`, obj, {
      headers: {
        Authorization: `${JSON.parse(user).TokenType} ${
          JSON.parse(user).IdToken
        }`,
        USER_ENTITY_ID: `${localStorage.getItem('role')}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if ([409].includes(error.response.status)) {
        console.log(`data response\n`,error.response.data);
        return error.response.data
      }else {
        return error
      }      
    })

  return { result, loading }
}

const authService = {
  login,
  changepass,
  register,
  // currentUser,
}

export default authService
