/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CalendarIcon, PlusCircleIcon } from '@heroicons/react/outline'
import React from 'react'
import DatePicker from 'react-datepicker'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import ServiceNotes from '../../../services/ListNote'
import { Link, useLocation } from 'react-router-dom'
import ItemNote from './ItemNote'
import AppointmenItem from '../../Appointments/AppointmentItem'
import NotesIcon from '../../../assets/svg/notes-svgrepo-com.svg'

const ListNote = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = React.useState(false)
  const [notes, setNotes] = React.useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const { completeName }: any = location.state

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
  })

  const createNote = () => {
    navigate(`/patient/newnote/${id}`, {
      state: { id: id, completeName },
    })
  }

  const onSubmit = async (data: any) => {
    setIsLoading(true)
    const response = await ServiceNotes.ListNotes({ id, ...data })
    setIsLoading(false)
    // console.log(response);

    setNotes(response)
  }

  return (
    <div className="max-h-max w-full flex flex-col">
      <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
        <div className="flex flex-col md:flex-row gap-1">
          <div className="flex flex-row gap-3">
            <img src={NotesIcon} className={`w-14 my-3`} alt="" />
            <h1 className="w-full self-center text-[#96c952] text-4xl font-extrabold ">
              {completeName}
            </h1>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col p-4 gap-3 lg:flex-row">
        <form
          className="w-full gap-3 flex flex-col lg:flex-row"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full gap-3 grid grid-cols-1 sm:grid-cols-2">
            <Controller
              control={control}
              name="noteDate"
              rules={{
                required: {
                  value: true,
                  message:
                    'No ha seleccionado un dia para encontrar las notas del residente',
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }: any) => (
                <label
                  htmlFor={`noteDate`}
                  className="w-full rounded-md border-[1px] px-3 py-2 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
                >
                  <CalendarIcon className="w-6 h-6 mr-2" />
                  <DatePicker
                    id="noteDate"
                    name="noteDate"
                    className="w-full focus:outline-none bg-transparent"
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Seleccione dia inicial..."
                    onBlur={onBlur}
                    selected={value}
                    onChange={onChange}
                    showYearDropdown
                    autoComplete="off"
                    yearDropdownItemNumber={1}
                    scrollableYearDropdown
                    value={value}
                  />
                </label>
              )}
            />

            <Controller
              control={control}
              name="noteEndDate"
              rules={{
                required: {
                  value: true,
                  message:
                    'No ha seleccionado un dia para encontrar las notas del residente',
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }: any) => (
                <label
                  htmlFor={`noteEndDate`}
                  className="w-full rounded-md border-[1px] px-3 py-2 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
                >
                  <CalendarIcon className="w-6 h-6 mr-2" />
                  <DatePicker
                    id="noteEndDate"
                    name="noteEndDate"
                    className="w-full focus:outline-none bg-transparent"
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Seleccione dia final..."
                    onBlur={onBlur}
                    selected={value}
                    onChange={onChange}
                    showYearDropdown
                    autoComplete="off"
                    yearDropdownItemNumber={1}
                    scrollableYearDropdown
                    value={value}
                  />
                </label>
              )}
            />
          </div>

          <input
            value="Buscar"
            type={'submit'}
            className="rounded-lg border-[1px] px-3 py-2 font-medium shadow-sm hover:cursor-pointer"
          />
        </form>
      </div>
      <div className="grid gap-4 px-4">
        {!isLoading ? (
          <>
            {notes.length > 0 ? (
              notes?.map((notes: any) => {
                // console.log(notes);
                if (notes.note_text) {
                  return <ItemNote key={notes.id} {...notes} />
                } else if (notes.orderAttention === 0) {
                  return <AppointmenItem key={notes.id} {...notes} />
                } else if (notes.orderAttention > 0) {
                  return <AppointmenItem key={notes.id} {...notes} />
                }
              })
            ) : (
              <div className="py-4">
                Seleccione una fecha para poder mostrar la lista de notas del
                residente
              </div>
            )}
          </>
        ) : (
          <>
            <p>No se encontrarón coincidencias con la fecha seleccionada.</p>
          </>
        )}
      </div>
      <div className="w-full md:flex py-4 hidden md:block justify-center gap-3">
        <Link to={`/patients`}>
          <input
            type="button"
            value="Volver a la lista"
            className="w-full rounded-lg border-[1px] px-3 py-2 font-bold bg-[#F6A01E] shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700  duration-300"
          />
        </Link>
        <input
          type="submit"
          value="Adicionar Nota"
          onClick={createNote}
          className="rounded-lg border-[1px] px-3 py-2 shadow-sm hover:cursor-pointer bg-lime-600 font-bold hover:bg-lime-500 text-white hover:border-lime-700  duration-300"
        />
      </div>
      <div className="flex-1 flex-col"></div>

      <div className="md:hidden sticky bottom-0 z-50 flex w-full flex-row-reverse">
        <PlusCircleIcon className="h-12 w-12 rounded-full m-5 border-[1px]" />
      </div>
    </div>
  )
}

export default ListNote
