import api from './Api'
import Swal from 'sweetalert2'

const UpdatePatientVaccine = async (obj: any) => {
  let user: any = localStorage.getItem('user')
  // console.log(`obj obejota: \n${JSON.stringify(obj, null, 4)}`);

  const {
    id,
    vaccineId,
    vaccineName,
    dosage,
    doseDate,
    frequency,
    pharmaceuticalCompany,
  } = obj

  const data = await api.instanceUsers
    .post(
      `/user/update-vaccine/`,
      {
        id,
        vaccineId,
        vaccineName,
        dosage,
        doseDate,
        frequency,
        pharmaceuticalCompany,
      },
      {
        headers: {
          Authorization: `${JSON.parse(user).TokenType} ${
            JSON.parse(user).IdToken
          }`,
          USER_ENTITY_ID: `${localStorage.getItem('role')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    )
    .then((response) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmación',
        text: 'Se realizó actualización con éxito!',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return response.data
    })
    .catch((error) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return error
    })
  return data
}
const DeletePatientVaccine = async (obj: any) => {
  let user: any = localStorage.getItem('user')
  // console.log(`obj obejota: \n${JSON.stringify(obj, null, 4)}`);

  const { id } = obj

  const data = await api.instanceUsers
    .post(
      `/user/delete-vaccine/`,
      {
        vaccineId: id,
      },
      {
        headers: {
          Authorization: `${JSON.parse(user).TokenType} ${
            JSON.parse(user).IdToken
          }`,
          USER_ENTITY_ID: `${localStorage.getItem('role')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    )
    .then((response) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmación',
        text: 'Se realizó actualización con éxito!',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return response.data
    })
    .catch((error) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return error
    })
  return data
}

const UpdateVacines = {
  UpdatePatientVaccine,
  DeletePatientVaccine,
}
export default UpdateVacines
