import Medicine from '../../assets/svg/PatientItems/Medicine.svg'
const SumaryMedicineRegister = ({
  setModalOn,
  setChoice,
  registerUser,
  setIsLoading,
  formData,
}: any) => {
  const handleOKClick = () => {
    registerUser()
    setChoice(true)
    setModalOn(false)
  }

  const handleCancelClick = () => {
    setIsLoading(true)
    setChoice(false)
    setModalOn(false)
  }
  console.log(`formData`, formData)

  return (
    <div className=" opacity-100 fixed inset-0 z-50">
      <div className="flex h-screen justify-center items-center ">
        <div className="flex-col justify-center rounded-md bg-white py-10 px-16 grid gap-5 border border-gray-200">
          <div className="flex flex-col text-lg text-zinc-600 max-w-xl max-h-[30rem] space-y-4 overflow-y-auto">
            <p className="font-bold text-2xl text-center">
              ¿Esta seguro de registrar este Medicamento?
            </p>
            <div className="flex flex-col">
              <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
                <img src={Medicine} className={`w-7`} alt="" />
                <h1 className="text-xl">{`Información de Medicamento`}</h1>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Nombre: </b>
                <p>{formData?.medicine[0]?.nameMedicine}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Dosis: </b>
                <p>{formData?.medicine[0]?.dosage}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Unidad de medida: </b>
                <p>{formData?.medicine[0]?.measurementUnit?.value}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Frecuencia: </b>
                <p>{formData?.medicine[0]?.frequency}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">rxNumber: </b>
                <p>{formData?.medicine[0]?.rxNumber}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Indicaciones: </b>
                <p>{formData?.medicine[0]?.indications}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center">
            <button
              onClick={handleOKClick}
              className=" rounded px-4 py-2 text-white bg-green-400 "
            >
              Si
            </button>
            <button
              onClick={handleCancelClick}
              className="rounded px-4 py-2 ml-4 text-white bg-blue-500 "
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SumaryMedicineRegister
