/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import DoctorInfo from '../RegisterPatient/DoctorInfo'
import NumberFormat from 'react-number-format'
import { clearUpdatePatientDoctorForm } from '../../actions'
import { connect } from 'react-redux'
import options from '../../data/selectOptions'
import UpdatePatientDoctor from '../../services/updatePatientDoctor'
import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline'
import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer'
import SumaryConAllSugRegister from '../Modals/SumaryConditionRegister'
import SumaryDoctorRegister from '../Modals/SumaryDoctorRegister'

const UpdateDoctorForm = (props: any) => {
  // const [stateData, setStateData] = useState<any>();
  // const location: any = useLocation();
  const [isLoading, setIsLoading] = useState(true)
  const [user, initUser] = useState<any>(null)

  const [modalOn, setModalOn] = useState<any>(false)
  const [formData, setFormData] = useState<any>(null)
  const [choice, setChoice] = useState<any>(false)

  const navigate = useNavigate()
  //console.log(`perico state:\n${JSON.stringify(location, null, 4)}`);
  const {
    register,
    watch,
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty, isSubmitSuccessful },
  } = useForm({
    mode: 'onChange',
  })

  const {
    fields: specialtyDoctorFields,
    remove: specialtyDoctorRemove,
    append: specialtyDoctorAppend,
  } = useFieldArray({
    name: 'specialtyDoctor',
    control,
  })
  //console.log(`props:\n${JSON.stringify(props, null, 4)}`);

  useEffect(() => {
    if (user === null) {
      setTimeout(() => {
        initUser({
          doctorFirstname: props?.updatePatientDoctorForm?.firstname,
          doctorSurname: props?.updatePatientDoctorForm?.surname,
          clinicalName: props?.updatePatientDoctorForm?.clinicalName,
          doctorJobAddress: props?.updatePatientDoctorForm?.jobAddress,
          doctorJobAddressAlt: props?.updatePatientDoctorForm?.JobAddressAlt,
          doctorZipCode: props?.updatePatientDoctorForm?.zipCode,
          doctorPhone: props?.updatePatientDoctorForm?.phone?.number,
          extDoctorPhone: props?.updatePatientDoctorForm?.phone?.extension,

          doctorLocalPhone:
            props?.updatePatientDoctorForm?.doctorLocalPhone?.number,
          extDoctorLocalPhone:
            props?.updatePatientDoctorForm?.doctorLocalPhone?.extension,

          doctorEmail: props?.updatePatientDoctorForm?.email,
          clinicalMedicalRecord:
            props?.updatePatientDoctorForm?.clinicalMedicalRecord,

          doctorJobCountry: options.state
            .filter((item: any) => {
              if (item?.label === props?.updatePatientDoctorForm?.JobCountry) {
                return item
              }
            })
            .map((item: any) => {
              return item
            })[0],
          doctorJobState: options.city
            .filter((item: any) => {
              if (item?.label === props?.updatePatientDoctorForm?.JobState) {
                return item
              }
            })
            .map((item: any) => {
              return item
            })[0],
          doctorSpecialty: options.specialtyOptions
            .filter((item: any) => {
              if (item?.label === props?.updatePatientDoctorForm?.specialty) {
                return item
              }
            })
            .map((item: any) => {
              return item
            })[0],
        })
      }, 1000)
    }
  })

  //console.log(`user: \n${JSON.stringify(user)}`);
  useEffect(() => {
    reset({ specialtyDoctor: [user] })
  }, [user])

  const registerUser = async () => {
    setIsLoading(false)
    formData.specialtyDoctor[0].id = props?.updatePatientDoctorForm?.patient
    formData.specialtyDoctor[0].doctorId = props?.updatePatientDoctorForm?.id

    //console.log(`valores data: \n ${JSON.stringify(formData.specialtyDoctor[0], null, 4)}`,);
    const dataUpdate: any = await UpdatePatientDoctor.UpdatePatientDoctor(
      formData.specialtyDoctor[0],
    )
    if (dataUpdate.code >= 200 && dataUpdate.code < 300) {
      //alert(JSON.stringify(formData, null, 4));
      clearUpdatePatientDoctorForm(null)
      reset({
        ctorFirstname: '',
        doctorSurname: '',
        clinicalName: '',
        doctorJobAddress: '',
        doctorJobAddressAlt: '',
        doctorZipCode: '',
        doctorPhone: '',
        extDoctorPhone: '',
        doctorEmail: '',
        clinicalMedicalRecord: '',
        doctorLocalPhone: '',
        extDoctorLocalPhone: '',
        doctorJobCountry: {},
        doctorJobState: {},
        doctorSpecialty: {},
      })

      initUser({})
      navigate(`/patientprofile/${props?.updatePatientDoctorForm?.patient}/`)
    } else {
      setIsLoading(true)
      // navigate(`/patientprofile/${props?.updatePatientDoctorForm?.patient}/`)
    }
    setIsLoading(true)
  }
  const trashSubmit = async (values: any) => {
    //console.log(`values ${JSON.stringify(values, null, 4)}`);
    //values.specialtyDoctor[0].userId = props?.updatePatientDoctorForm?.id;
    const dataUpdate: any = await UpdatePatientDoctor.DeletePatientDoctor(
      values,
    )
    if (dataUpdate.code === 200) {
      navigate(`/patientprofile/${props?.updatePatientDoctorForm?.patient}/`)
    } else {
      navigate(`/patientprofile/${props?.updatePatientDoctorForm?.patient}/`)
    }
  }
  const onSubmit = async (data: any) => {
    // console.log(`onSubmit`, data);
    setModalOn(true)
    setFormData(data)
    setIsLoading(false)
  }
  return (
    <>
      {modalOn && (
        <SumaryDoctorRegister
          formData={formData}
          setChoice={setChoice}
          setModalOn={setModalOn}
          registerUser={registerUser}
          setIsLoading={setIsLoading}
        />
      )}
    <div className="w-full space-y-4 ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
          <PencilAltIcon strokeWidth={1.3} color="#294fa9" className="w-20" />
          <h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
            Editando Doctor
          </h2>
        </div>
        <div className="lg:w-fit mx-auto place-items-center flex flex-col space-y-7 p-7">
          <DoctorInfo
            Controller={Controller}
            NumberFormat={NumberFormat}
            Select={Select}
            control={control}
            errors={errors}
            register={register}
            specialtyDoctorFields={specialtyDoctorFields}
            specialtyDoctorRemove={specialtyDoctorRemove}
            specialtyDoctorAppend={specialtyDoctorAppend}
            CustomValueContainer={CustomValueContainer}
            mode={'update'}
          />

          <div className="lg:col-span-2 w-full xl:col-span-3 justify-center place-items-center center flex md:flex-row flex-col gap-6">
            {isLoading ? (
              <>
                <input
                  type={'button'}
                  value="Volver"
                  className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  onClick={() => {
                    navigate(
                      `/patientprofile/${props?.updatePatientDoctorForm?.patient}/`,
                    )
                  }}
                />
                <input
                  type="submit"
                  value="Guardar"
                  disabled={!isValid}
                  className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                />
              </>
            ) : (
              <>
                <input
                  type="submit"
                  disabled
                  value="Cargando..."
                  className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
                />
              </>
            )}
          </div>
        </div>
      </form>
    </div>
    </>
  )
}
const mapStateToProps = (state: any) => {
  return {
    updatePatientDoctorForm: state.updatePatientDoctorForm,
  }
}
const mapDispatchToProps = {
  clearUpdatePatientDoctorForm,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDoctorForm)
