import InformationGeneral from '../../assets/svg/PatientItems/informacionRegistro.svg'
import InformationPersonal from '../../assets/svg/iconoContacto.svg'
import Physicians from '../../assets/svg/PatientItems/Physicians.svg'

const GenericModalUserResident = ({
  setModalOn,
  setChoice,
  setIsLoading,
  registerUser,
  formData,
}: any) => {
  const handleOKClick = () => {
    registerUser()
    setChoice(true)
    setModalOn(false)
  }
  const handleCancelClick = () => {
    setIsLoading(true)
    setChoice(false)
    setModalOn(false)
  }
  console.log(`formData`,formData);
  

  return (
    <div className=" opacity-100 fixed inset-0 z-50">
      <div className="flex h-screen justify-center items-center ">
        <div className="flex-col justify-center rounded-md bg-white py-10 px-16 grid gap-5 border border-gray-200">
          <div className="flex flex-col text-lg text-zinc-600 max-w-xl max-h-[30rem] space-y-4 overflow-y-auto">
            <p className="font-bold text-2xl text-center">
              ¿Está seguro de realizar esta acción?
            </p>
            <div className="flex flex-col">

              <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
                <img src={InformationGeneral} className={`w-12`} alt="" />
                <h1 className="text-xl first-line:capitalize">{`Registro`}</h1>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Nursing Home: </b>
                <p>{formData?.nursingHomeId?.label}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Número de residente: </b>
                <p>{formData?.clinicalMedicalNH}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Fecha de ingreso: </b>
                <p>{formData?.entryDate
                      ? formData?.entryDate
                          ?.toISOString()
                          ?.substring(0, 10)
                      : null}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Hogar de procedencia: </b>
                <p>{formData?.nursingHomeSourceId?.value !== 'other' ? formData?.nursingHomeSourceId?.label : formData?.otherSource}</p>
              </div>

              <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
                <img src={InformationPersonal} className={`w-10`} alt="" />
                <h1 className="text-xl first-line:capitalize">{`Personal`}</h1>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Primer nombre: </b>
                <p>{formData?.firstname}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Segundo nombre: </b>
                <p>{formData?.middlename}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Primer apellido: </b>
                <p>{formData?.surname}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Segundo apellido: </b>
                <p>{formData?.second_surname}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Fecha de nacimiento: </b>
                <p>{formData?.birthday
                      ? formData?.birthday
                          ?.toISOString()
                          ?.substring(0, 10)
                      : null}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Seguro social: </b>
                <p>{formData?.socialSecurityId}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">País de origen: </b>
                <p>{formData?.countryOrigin?.label}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Género: </b>
                <p>{formData?.gender?.label}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Sexo: </b>
                <p>{formData?.statusSex?.label}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Estado civil: </b>
                <p>{formData?.maritalStatus?.label}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Número Celular: </b>
                <p>{formData?.phone}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Correo electrónico: </b>
                <p>{formData?.email}</p>
              </div>


              <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
                <img src={Physicians} className={`w-12`} alt="" />
                <h1 className="text-xl first-line:capitalize">{`Médica`}</h1>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Grupo sanguíneo: </b>
                <p>{formData?.bloodtype?.label}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Plan Médico: </b>
                <p>{formData?.insuranceCarrier}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Plan Médico 2: </b>
                <p>{formData?.insuranceCarrierII}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Clinica de preferencia: </b>
                <p>{formData?.preferenceHospital}</p>
              </div>
              <div className="flex flex-row gap-1">
                <b className="font-bold">Identificación Tytocare: </b>
                <p>{formData?.tytocareId}</p>
              </div>
              
            </div>
          </div>
          <div className="flex flex-row justify-center">
            <button
              onClick={handleOKClick}
              className=" rounded px-4 py-2 text-white bg-green-400 "
            >
              Yes
            </button>
            <button
              onClick={handleCancelClick}
              className="rounded px-4 py-2 ml-4 text-white bg-blue-500 "
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GenericModalUserResident
