/* eslint-disable @typescript-eslint/no-unused-vars */
import api from './Api'
import Swal from 'sweetalert2'
import TokenService from './Token'

const UpdateUserProfile = async (data: any) => {
  const user = TokenService.getUser()
  console.log('Raw data: ', data)

  const {
    user_type,
    id,
    firstname,
    middlename,
    surname,
    second_surname,
    phone,
    gender,
    countryOrigin,
    municipalState,
    birthday,
    isMobilePatient,
    phoneExt,
    npi,
    specialty,
    nursinghome_id,
    clinic_id,
    userEntityId,
  } = data

  let formatedData = {
    user_type: user_type?.value,
    id,
    firstname,
    middlename,
    surname,
    second_surname,
    specialty:specialty?.label,
    phone,
    npi,
    birthday: birthday.toISOString().substring(0, 10),
    gender: gender[0]?.value,
    clinic_id,
    nursinghome_id,
    Country: countryOrigin?.value,
    city_id: municipalState?.value,
    userEntityId,
  }

  console.log('Formated Data', formatedData)

  return await api.instanceUsers
    .put('/user/updateUser', formatedData, {
      headers: {
        Authorization: `${user.TokenType} ${user.IdToken}`,
        USER_ENTITY_ID: `${TokenService.getRole()}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    .then((response: any) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        icon: 'success',
        title: 'Confirmación',
        text: 'Su usuario fue actualizado correctamente',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      console.log(`response: `,response);
      
      return response
    })
    .catch((err: any) => {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Vuelva a intertarlo nuevamente',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      return err
    })
}

const UpdateUserService = {
  UpdateUserProfile,
}

export default UpdateUserService
