export const customStyles = {
  // container: (provided:any, state:any) => ({
  //   ...provided,
  //   marginTop: 50,
  // }),
  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      overflow: 'visible',
    }
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      // borderBottom: "1px dotted pink",
      // color: state.isSelected
      //   ? "red"
      //   : "rgb(14 165 233 / var(1))",
      // padding: 20,
      width: '100%',
    }
  },
  control: (base: any, state: any) => {
    return {
      // none of react-select's styles are passed to <Control />
      ...base,
      borderColor: 'rgb(0 0 0 / 0.07)',
      border: '1',
      boxShadow: '0.8px 0.8px #00000010',
      borderRadius: '0.375rem',
      heigth: '2.5rem',
      // width: "100%",
    }
  },
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return {
      ...provided,
      opacity,
      transition,
    }
  },
  placeholder: (provided: any, state: any) => {
    return {
      ...provided,
      backgroundColor: 'white',
      padding: '0px 5px',
      position: 'absolute',
      top: state.hasValue || state.selectProps.inputValue ? -13 : '13%',
      transition: 'top 0.1s, font-size 0.1s',
      fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
    }
  },
}
