/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

const MedicalConditionsItem = (props: any) => {
  const { conditionAllergy, id, medicine, profile } = props

  return (
    <div>
      {medicine ? (
        <div className="p-3">
          <label className="flex flex-wrap gap-1" htmlFor="">
            <b>Medicamento:</b>
            <p>{medicine.name}</p>
          </label>
          <label className="flex flex-wrap gap-1" htmlFor="">
            <b>Dosis:</b>
            <p>{`${medicine.dosage} ${medicine.measurementUnit}`}</p>
          </label>
          <label className="flex flex-wrap gap-1" htmlFor="">
            <b>Frecuencia:</b>
            <p>{medicine.frequency}</p>
          </label>
        </div>
      ) : (
        ''
      )}
      {conditionAllergy ? (
        <div className="p-3">
          <label className="flex flex-wrap gap-1" htmlFor="">
            <b>
              {conditionAllergy.type === 'Allergy'
                ? 'Elemento Causante de la alergia'
                : 'Condición de salud'}
              :
            </b>
            <p>{conditionAllergy.name}</p>
          </label>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default MedicalConditionsItem
