/* eslint-disable @typescript-eslint/no-unused-vars */
import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer'
import { emailValidation, npiValidation } from '../../utils/regexExpresion'
import SumaryUserRegisterData from '../Modals/SumaryUserRegisterData'
import { clearUpdateProfileUserForm } from '../../actions'
import { CalendarIcon } from '@heroicons/react/outline'
import authService from '../../services/Authentication'
import useGetEntityII from '../../hooks/useGetEntityII'
import { UserAddIcon, UserIcon } from '@heroicons/react/outline'
import { Controller, useForm } from 'react-hook-form'
import { customStyles } from '../customStyleNewLabel'
import Usuario from '../../assets/svg/UserSVG.svg'
import Select, { components } from 'react-select'
import { ROLEID, ROLES } from '../../utils/enums/Roles'
import NumberFormat from 'react-number-format'
import options from '../../data/selectOptions'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import Switch from 'react-switch'
import Swal from 'sweetalert2'
import FormUser from './Form'

const FinishSectionButton: React.FC<{
  onClick: () => void
  isDisabled: boolean
}> = ({ onClick, isDisabled, children }) => {
  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      type="button"
      className="w-28 mx-5 rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[1] border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
    >
      {children}
    </button>
  )
}

const RegisterUser = ({ ...props }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    watch,
    control,
  } = useForm({ mode: 'onChange' })
  const [isRequiredNPI, setIsRequiredNPI] = useState(false)
  const [onlySpecialty, setOnlySpecialty] = useState(false)
  const [withoutInstitution, setWithoutInstitution] = useState(false)
  const [onlyClinic, setOnlyClinic] = useState<any>(null)
  const [onlyHome, setOnlyHome] = useState<any>(null)

  const [isLoadingState, setIsLoading] = useState<any>(true)
  const [choice, setChoice] = useState<any>(false)
  const [modalOn, setModalOn] = useState<any>(false)
  const [formData, setFormData] = useState<any>(null)
  const [photoURL, setPhotoURL] = useState<any>(null)

  const navigate = useNavigate()

  const onSubmit = (data: any) => {
    setModalOn(true)
    setFormData(data)
    setIsLoading(false)
  }
  console.log(`currentEntitySelected:`, props?.state?.currentEntitySelected)

  const registerUser = async () => {
    let { result } = await authService.register(formData)

    if (result?.code >= 200 && result?.code < 300) {
      reset({
        municipalState: null,
        countryOrigin: null,
        user_type: null,
        specialty: null,
        firstname: null,
        middlename: null,
        surname: null,
        second_surname: null,
        gender: null,
        email: null,
        birthday: null,
        phoneMobile: '',
        phone: '',
        phoneExt: '',
        isMobilePatient: null,
      })

      clearUpdateProfileUserForm(null)
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Confirmación',
        text: `${formData?.user_type?.label} ha sido registrado correctamente`,
        icon: 'success',
        // icon: "error",
        cancelButtonText: 'Volver al formulario',
        confirmButtonText: 'Ver perfil',
        showCancelButton: true,
        confirmButtonColor: 'rgb(14 165 233)',
        cancelButtonColor: 'rgb(14 165 233)',
      }).then((res) => {
        //console.log(`res::`, res)

        setIsLoading(true)
        if (res.isConfirmed) {
          navigate('/profile/user/', {
            state: { userId: result?.data[0].user_id },
          })
          // console.log(result?.data[0].user_id);
        } else {
          setIsLoading(true)
        }
      })
    } else if (result?.code === 409) {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: `Se ha presentado un error, el correo ${result?.data[0]?.data} ya se encuentra registrado. Comuníquese con el administrador del sistema para vincular el usuario a su entidad.`,
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      setIsLoading(true)
    } else {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      setIsLoading(true)
    }
    setIsLoading(true)
  }

  const userTypeSelected = (value: any) => {
    const NPIREQUIRED = [ROLEID.DOCTOR, ROLEID.NURSE]
    const SPECIALTYREQUIRED = [ROLEID.DOCTOR, ROLEID.ADMIN_CLINIC]
    const DAE = [
      ROLEID.PROJECT_MANAGER,
      ROLEID.PROJECT_ASSISTANT,
      ROLEID.PROJECT_EVALUATOR,
    ]
    const ONLYCLINIC = [ROLEID.DOCTOR, ROLEID.SECRETARY, ROLEID.ADMIN_CLINIC]
    const ONLYHOME = [ROLEID.NURSE, ROLEID.CARER, ROLEID.ADMIN_NURSING_HOME]

    if (NPIREQUIRED.includes(value?.value)) {
      setIsRequiredNPI(true)
    } else {
      setIsRequiredNPI(false)
    }

    if (SPECIALTYREQUIRED.includes(value?.value)) {
      setOnlySpecialty(true)
    } else {
      setOnlySpecialty(false)
    }

    if (DAE.includes(value?.value)) {
      setWithoutInstitution(true)
    } else {
      setWithoutInstitution(false)
    }

    if (ONLYCLINIC.includes(value?.value)) {
      setOnlyClinic(true)
    } else {
      setOnlyClinic(false)
    }

    if (ONLYHOME.includes(value?.value)) {
      setOnlyHome(true)
    } else {
      setOnlyHome(false)
    }
    return value
  }

  const { homeList, clinicList, error, loading } = useGetEntityII()

  return (
    <>
      {modalOn && (
        <SumaryUserRegisterData
          formData={formData}
          setChoice={setChoice}
          setModalOn={setModalOn}
          registerUser={registerUser}
          setIsLoading={setIsLoading}
        />
      )}
      <div className="w-full space-y-7">
        <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
          {/* <img src={Usuario} className={`h-20 w-20 p-1 self-center bg-slate-500`} alt="" /> */}
          <UserIcon strokeWidth={1.3} color="#96c952" className="h-20 w-20" />
          <h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
            Usuario
          </h2>
          <div>
            <h1 className="w-full self-center text-[#aaaaaa] text-2xl md:text-4xl font-light ">
              / Registro
            </h1>
          </div>
        </div>
        <div className="h-full w-full space-y-7 px-7">
          <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
            <section className="block">
              <div className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-3 place-items-center flex flex-col py-8">
                <FormUser
                  optionsRole={options.roleList
                    ?.sort((itemA: any, itemB: any) => {
                      if (itemA > itemB) {
                        return 1
                      }
                      if (itemA < itemB) {
                        return -1
                      }
                      return 0
                    })
                    .filter((item: any) => {
                      if (
                        props?.state?.currentUser?.roles[0].roleId ===
                        ROLEID.PROJECT_MANAGER
                      ) {
                        return (
                          [
                            ROLEID.PROJECT_ASSISTANT,
                            ROLEID.PROJECT_EVALUATOR,
                            ROLEID.ADMIN_CLINIC,
                            ROLEID.ADMIN_NURSING_HOME,
                            ROLEID.DOCTOR,
                            ROLEID.SECRETARY,
                            ROLEID.NURSE,
                            ROLEID.CARER,
                          ].includes(item.value) && item
                        )
                      }
                      if (
                        props?.state?.currentUser?.roles[0].roleId ===
                        ROLEID.ADMIN_CLINIC
                      ) {
                        return (
                          [ROLEID.DOCTOR, ROLEID.SECRETARY].includes(
                            item.value,
                          ) && item
                        )
                      }
                      if (
                        props?.state?.currentUser?.roles[0].roleId ===
                        ROLEID.DOCTOR
                      ) {
                        return [ROLEID.SECRETARY].includes(item.value) && item
                      }
                      if (
                        props?.state?.currentUser?.roles[0].roleId ===
                        ROLEID.ADMIN_NURSING_HOME
                      ) {
                        return (
                          [ROLEID.NURSE, ROLEID.CARER].includes(item.value) &&
                          item
                        )
                      }
                      if (
                        props?.state?.currentUser?.roles[0].roleId ===
                        ROLEID.NURSE
                      ) {
                        return [ROLEID.CARER].includes(item.value) && item
                      }
                      if (
                        props?.state?.currentUser?.roles[0].roleId ===
                        ROLEID.ADMIN
                      ) {
                        return (
                          [
                            ROLEID.DOCTOR,
                            ROLEID.SECRETARY,
                            ROLEID.NURSE,
                            ROLEID.PATIENT,
                            ROLEID.ADMIN,
                            ROLEID.CARER,
                            ROLEID.ADMIN_NURSING_HOME,
                            ROLEID.ADMIN_CLINIC,
                            ROLEID.PROJECT_MANAGER,
                            ROLEID.PROJECT_ASSISTANT,
                            ROLEID.PROJECT_EVALUATOR,
                          ].includes(item.value) && item
                        )
                      }
                      if (
                        props?.state?.currentUser?.roles[0].roleId ===
                        ROLEID.PROJECT_ASSISTANT
                      ) {
                        return (
                          [
                            ROLEID.PROJECT_EVALUATOR,
                            ROLEID.ADMIN_CLINIC,
                            ROLEID.ADMIN_NURSING_HOME,
                            ROLEID.DOCTOR,
                            ROLEID.SECRETARY,
                            ROLEID.NURSE,
                            ROLEID.CARER,
                          ].includes(item.value) && item
                        )
                      }
                    })}
                  Controller={Controller}
                  DatePicker={DatePicker}
                  CalendarIcon={CalendarIcon}
                  Select={Select}
                  customStyles={customStyles}
                  options={options}
                  NumberFormat={NumberFormat}
                  emailValidation={emailValidation}
                  npiValidation={npiValidation}
                  Switch={Switch}
                  userTypeSelected={userTypeSelected}
                  register={register}
                  handleSubmit={handleSubmit}
                  errors={errors}
                  control={control}
                  isRequiredNPI={isRequiredNPI}
                  onlySpecialty={onlySpecialty}
                  withoutInstitution={withoutInstitution}
                  onSubmit={onSubmit}
                  CustomValueContainer={CustomValueContainer}
                  watch={watch}
                  onlyClinic={onlyClinic}
                  onlyHome={onlyHome}
                  homeList={homeList}
                  clinicList={clinicList}
                  loading={loading}
                  setPhotoURL={setPhotoURL}
                  photoURL={photoURL}
                  currentHome={
                    props?.state?.currentUser?.roles[0]?.roleId ===
                      ROLEID.ADMIN ||
                    props?.state?.currentUser?.roles[0]?.roleId ===
                      ROLEID.PROJECT_MANAGER ||
                    props?.state?.currentUser?.roles[0]?.roleId ===
                      ROLEID.PROJECT_ASSISTANT ||
                    props?.state?.currentUser?.roles[0]?.roleId ===
                      ROLEID.PROJECT_EVALUATOR
                      ? null
                      : props?.state?.currentEntitySelected?.nursing_home_id
                  }
                />
                <div className="lg:col-span-2 xl:col-span-3 my-5">
                  {isLoadingState ? (
                    <div id="next" className="has-tooltip w-fit h-fit">
                      {!isValid ? (
                        <span className="tooltip rounded shadow-lg p-1 bg-gray-800 text-white -mt-12">
                          Para continuar, debe completar los campos requeridos
                          señalados con *
                        </span>
                      ) : null}
                      <input
                        type="submit"
                        disabled={!isValid}
                        value="Registrar Usuario"
                        className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                      />
                    </div>
                  ) : (
                    <input
                      type="submit"
                      disabled
                      value="Cargando"
                      className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                    />
                  )}
                </div>
              </div>
            </section>
          </form>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    state: {
      updateProfileUserForm: state.updateProfileUserForm,
      currentEntitySelected: state.currentEntitySelected,
      currentUser: state.currentUser,
    },
  }
}
const mapDispatchToProps = {
  clearUpdateProfileUserForm,
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUser)
