import { connect } from 'react-redux'

const UnLinkConfirmation = ({ setShowModal, unlinkUser, ...props }: any) => {
  //console.log(`props\n`, props);
  
  const handleOKClick = () => {
    setShowModal(false)
    unlinkUser()
  }
  const handleCancelClick = () => {
    setShowModal(false)
  }
  console.log(`handleOKClick\n`, handleOKClick)
  console.log(`handleCancelClick\n`, handleCancelClick)

  return (
    <div className=" opacity-100 fixed inset-0 z-50">
      <div className="flex h-screen justify-center items-center ">
        <div className="flex-col justify-center rounded-md bg-white py-10 px-16 grid gap-5 border border-gray-200">
          <div className="flex flex-col text-lg text-zinc-600 max-w-xl max-h-[30rem] space-y-4 overflow-y-auto">
            <p>
              Esta seguro de Desvincular (usuario/usuario) de (usuario/entidad)
            </p>
          </div>
          <div className="flex flex-row justify-center">
            <button
              onClick={handleOKClick}
              className=" rounded px-4 py-2 text-white bg-green-400 "
            >
              Yes
            </button>
            <button
              onClick={handleCancelClick}
              className="rounded px-4 py-2 ml-4 text-white bg-blue-500 "
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    state: {},
  }
}

const mapDispatchToProps = {}

// export default Sidebar;
export default connect(mapStateToProps, mapDispatchToProps)(UnLinkConfirmation)
