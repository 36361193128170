import Entities from '../../assets/svg/entidades.svg'
import {
  IdentificationIcon,
  InboxInIcon,
  MapIcon,
} from '@heroicons/react/outline'
const SumaryEntityRegisterData = ({
  setModalOn,
  setChoice,
  onSubmit,
  setIsLoading,
  formData,
}: any) => {
  const handleOKClick = () => {
    onSubmit()
    setChoice(true)
    setModalOn(false)
  }
  const handleCancelClick = () => {
    setIsLoading(true)
    setChoice(false)
    setModalOn(false)
  }

  return (
    <div className="opacity-100 fixed inset-0 z-50">
      <div className="flex h-screen w-screen justify-center items-center ">
        <div className="flex-col justify-center rounded-md bg-white py-10 px-16 grid gap-5 border border-gray-200">
          <div className="flex flex-col text-lg text-zinc-600 max-w-xl max-h-[30rem] space-y-4 overflow-y-auto">
            <p className="font-bold text-2xl text-center">
              ¿Esta seguro de registrar esta entidad?
            </p>
            <div className="flex flex-col">
              <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 gap-1">
                <img src={Entities} className={`w-7`} alt="" />
                <h1 className="text-xl first-line:capitalize">
                  Información Comercial
                </h1>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">Tipo de entidad:</p>
                <p>{formData?.entityType?.label}</p>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">Nombre Legal:</p>
                <p>{formData?.legalName}</p>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">Nombre Comercial:</p>
                <p>{formData?.comercialName}</p>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">EIN:</p>
                <p>{formData?.ein}</p>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">NPI:</p>
                <p>{formData?.npi}</p>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">Sitio Web:</p>
                <p>{formData?.webPage}</p>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">Correo:</p>
                <p>{formData?.email}</p>
              </div>

              <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 gap-1">
                {/* <img src={`${}`} className={`w-7`} alt="" /> */}
                <IdentificationIcon
                  strokeWidth={1.3}
                  color="#294fa9"
                  className={`w-7 `}
                />
                <h1 className="text-xl first-line:capitalize">Contacto</h1>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">Número Celular:</p>
                <p>{formData?.phoneTwo}</p>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">Teléfono Fijo:</p>
                <p>{formData?.phone}</p>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">Extensión:</p>
                <p>{formData?.phoneExt}</p>
              </div>
              <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 gap-1">
                <MapIcon strokeWidth={1.3} className={`w-7 text-[#294fa9] `} />
                <h1 className="text-xl first-line:capitalize">
                  Dirección física
                </h1>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">Dirección Linea 1:</p>
                <p>{formData?.firstLline}</p>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">Dirección Linea 2:</p>
                <p>{formData?.secondLline}</p>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">Pueblo/Municipio:</p>
                <p>{formData?.state?.label}</p>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">Codigo Postal:</p>
                <p>{formData?.zipCode}</p>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">País:</p>
                <p>{formData?.country?.label}</p>
              </div>
              <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 gap-1">
                <InboxInIcon
                  strokeWidth={1.3}
                  className={`w-7 text-[#294fa9] `}
                />
                <h1 className="text-xl first-line:capitalize">PO box</h1>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">Número:</p>
                <p>{formData?.pobox}</p>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">Pueblo/Municipio:</p>
                <p>{formData?.poboxState?.label}</p>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">Codigo Postal:</p>
                <p>{formData?.poboxZipCode}</p>
              </div>
              <div className="flex flex-row gap-1">
                <p className="font-bold">País:</p>
                <p>{formData?.poboxCountry?.label}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center">
            <button
              onClick={handleOKClick}
              className="w-16 rounded px-4 py-2 text-white bg-green-400 "
            >
              Si
            </button>
            <button
              onClick={handleCancelClick}
              className="w-16 rounded px-4 py-2 ml-4 text-white bg-blue-500 "
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SumaryEntityRegisterData
