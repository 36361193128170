import { TrashIcon } from '@heroicons/react/outline'
import PencilAltIcon from '@heroicons/react/outline/PencilAltIcon'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { setUpdatePatientAllerProfCondForm } from '../../actions'
import Allergy from '../../assets/svg/PatientItems/alergia.svg'
import updatePatientASC from '../../services/updatePatientASC'
import SumaryAllergyRegister from '../Modals/SumaryAllergyRegister'

const AllergyPatient = (props: any) => {
  const { mane, type, description, id, pcmid, patient, acsListData } = props
  const [actionBotton, setActionBotton] = useState<string>('')
  const navigate = useNavigate()

  const [modalOn, setModalOn] = useState<any>(false)
  const [formData, setFormData] = useState<any>(null)
  const [choice, setChoice] = useState<any>(false)
  //console.log(`props`, props)
  
  const trashSubmit = async () => {
    setActionBotton('delete');
    setModalOn((prev: any) => {
      //console.log(`prev`, prev)
      return !prev
    })
  }

  const deleteAction = async () => {
    navigate(`/patientprofile/${props?.patient}/`,);
    let data: any = {
      pcmid: props?.pcmid,
    }
    const dataUpdate: any = await updatePatientASC.DeletePatientASC(data)
    if (dataUpdate?.data?.code >= 200 && dataUpdate?.data?.code < 300) {
      console.log(`entre if (dataUpdate)`)
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Confirmación',
        text: `¡Ha sido eliminado con éxito!`,
        icon: 'success',
        // icon: "error",
        //cancelButtonText: 'Volver al formulario',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
        confirmButtonColor: 'rgb(14 165 233)',
        cancelButtonColor: 'rgb(14 165 233)',
      }).then((res) => {
        // console.log(`res`, res)
        // console.log(`dataUpdate?.data`,dataUpdate?.data);
        // console.log(`props?.patient`,props?.patient);
        navigate(`/patientprofile/${props?.patient}`,);
      })
    } else {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        confirmButtonText: 'Aceptar',
      }).then((res) => {
        console.log(`res`, res)
        navigate(`/patientprofile/${props?.patient}`,)
      })
    }
    navigate(`/patients`,);
    navigate(`/patientprofile/${props?.patient}`)
  }
  return (
    <>
      {modalOn && (
        <SumaryAllergyRegister
          setChoice={setChoice}
          setModalOn={setModalOn}
          actionBotton={actionBotton}
          deleteAction={deleteAction}
        />
      )}
      <div className="w-full text-left md:px-20 space-y-2 text-lg py-2 border-y-2 ">
        <div className="flex flex-row items-center justify-start gap-1.5 border-b-2">
          <img src={Allergy} className={`w-12`} alt="" />
          <h1 className="text-xl font-bold">
            <b className="text-black font-bold first-line:capitalize">{mane}</b>
          </h1>
        </div>
        <div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5  ">
          <div className="w-fit md:ml-24 min-w-sm	 ">
            <div className="md:gap-1.5 md:flex md:flex-row max-w-md	">
              <b className="text-black font-bold">Descripcción:</b>
              <p>{description ? description : 'N/A'}</p>
            </div>
          </div>
        </div>

        <div className="flex flex-row ">
          <div className={`lg:flex-1 flex flex-row`} />
          <div
            className="w-full sm:w-fit flex items-center gap-1 bg-[#f61e1e]  p-2 text-white rounded-lg cursor-pointer m-2"
            onClick={() => trashSubmit()}
          >
            <TrashIcon strokeWidth={1.3} className="w-4 border-b-2" />
            Eliminar
          </div>
          <div
            className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
            onClick={() => {
              navigate('/allergyupdate')
              props.setUpdatePatientAllerProfCondForm({
                mane,
                type,
                description,
                id,
                pcmid,
                patient,
                acsListData,
              })
            }}
          >
            <PencilAltIcon className="w-8 h-8 border-b-2" />
            <div>Editar datos</div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = {
  setUpdatePatientAllerProfCondForm,
}
export default connect(null, mapDispatchToProps)(AllergyPatient)
