const ModalPhotoViewer = ({
  showPhotoViewer,
  setShowPhotoViewer,
  tempPhoto,
  ...props
}: any) => {
  return (
    <div className="bg-zinc-200 fixed inset-0 z-50">
      <div className="flex h-screen justify-center items-center ">
        <div className="flex-col justify-center  bg-white border-4 p-2 border-sky-500 rounded-xl">
          <div className="flex justify-end">
            <button
              onClick={() => {
                setShowPhotoViewer(!showPhotoViewer)
              }}
              className="rounded-full px-4 py-2 text-white bg-red-600 "
            >
              X
            </button>
          </div>
          <div className="flex-col justify-center bg-white py-12 px-24 ">
            <img src={tempPhoto} className="  w-full" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalPhotoViewer
