import { deserialize } from 'class-transformer'
import { FormatSelect } from './FormatSelect'

export const dataTransformer = (data: any) => {
  let counter: number = 0
  let dataArray = data.map((item: any) => {
    return {
      value: (counter += 1),
      label: item.cliniclegalname || item?.nhlegalname,
      nhlegalname: item?.nhlegalname,
      cliniclegalname: item?.cliniclegalname,
      id: item.id,
      state: item?.state,
      nursingHomeId: item?.nursingHomeId,
      firstLine: item?.firstLine,
      secondLine: item?.secondLine,
      clinicId: item?.clinicId,
    }
  })

  let processData = deserialize(FormatSelect, JSON.stringify(dataArray))

  // console.log(processData);

  return processData
}
