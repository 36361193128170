import Surgery from '../../assets/svg/PatientItems/Surgery.svg'
const SumarySurgeryRegister = ({
  setModalOn,
  setChoice,
  registerUser,
  setIsLoading,
  formData,
  
  actionBotton,
  deleteAction,
}: any) => {
  const handleOKClick = () => {
    if (actionBotton !== 'delete') {
      registerUser();
    }else{
      deleteAction();
    }
    setChoice(true)
    setModalOn(false)
  }

  const handleCancelClick = () => {
    if (actionBotton !== 'delete') {
      setIsLoading(true)
    }
    setChoice(false)
    setModalOn(false)
  }
  // console.log(`formData`, formData)

  return (
    <div className=" opacity-100 fixed inset-0 z-50">
      <div className="flex h-screen justify-center items-center ">
        <div className="flex-col justify-center rounded-md bg-white py-10 px-16 grid gap-5 border border-gray-200">
          {actionBotton !== 'delete' ? (
            <div className="flex flex-col text-lg text-zinc-600 max-w-xl max-h-[30rem] space-y-4 overflow-y-auto">
              <p className="font-bold text-2xl text-center">
                ¿Esta seguro de registrar esta Cirugía?
              </p>
              <div className="flex flex-col">
                <div className=" flex flex-row w-full lg:col-span-2 xl:col-span-3 my-1 items-center justify-start border-b-2 ">
                  <img src={Surgery} className={`w-12`} alt="" />
                  <h2 className="text-xl">{`Información de Cirugía`}</h2>
                </div>
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Cirugía: </b>
                  <p>{formData?.surgery[0]?.item?.label}</p>
                </div>
                <div className="flex flex-row gap-1">
                  <b className="font-bold">Descripción: </b>
                  <p>{formData?.surgery[0]?.description}</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col text-lg text-zinc-600 max-w-xl max-h-[30rem] space-y-4 overflow-y-auto">
              <p className="font-bold text-2xl text-center">
                ¿Esta seguro de eliminar esta Cirugía?
              </p>
            </div>
          )}
          <div className="flex flex-row justify-center">
            <button
              onClick={handleOKClick}
              className=" rounded px-4 py-2 text-white bg-green-400 "
            >
              Si
            </button>
            <button
              onClick={handleCancelClick}
              className="rounded px-4 py-2 ml-4 text-white bg-blue-500 "
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SumarySurgeryRegister
