import {
  OfficeBuildingIcon,
  UserGroupIcon,
  MenuIcon,
} from '@heroicons/react/outline'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Entities from '../../assets/svg/entidades.svg'
const ProfileEntity = ({ ...props }: any) => {
  const navigate = useNavigate()

  return (
    <div className="w-full ">
      <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex flex-row md:gap-4">
        <img src={Entities} className={`w-20`} alt="" />
        <div className="">
          <h1 className="my-5 justify-start md:text-4xl text-2xl font-extrabold text-[#96c952]">
            {props?.state?.updateEntityForm?.nursing_home_comercialName ||
              props?.state?.updateEntityForm?.clinic_comercialName}
          </h1>
        </div>
      </div>
      <div className="flex flex-col px-3 py-7 sm:px-7">
        {/* <div className="mb-7 flex flex-row items-center justify-center gap-3"> */}
        <div className="flex flex-col items-center text-center gap-5">
          <div
            className="entity-button-profile"
            onClick={() => {
              navigate('/entities/profile/information')
            }}
          >
            <img src={Entities} className={`w-10`} alt="" />
            <p className="flex-1 text-center">Detalles de la entidad</p>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Ic_chevron_right_48px.svg/1200px-Ic_chevron_right_48px.svg.png"
              className="h-10 self-center"
              alt=""
            />
          </div>
          <div
            className="entity-button-profile"
            onClick={() => {
              navigate('/entities/manage/users')
            }}
          >
            <UserGroupIcon
              strokeWidth={1.6}
              color="#294fa9"
              className="w-10 h-10 p-1"
            />
            {/* <img
							src="http://simpleicon.com/wp-content/uploads/user1.svg"
							className="w-10 h-10 self-center rounded-full bg-white border-[1px] p-1"
							alt=""
						/> */}
            <p className="flex-1 text-center">Gestión de Usuarios</p>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Ic_chevron_right_48px.svg/1200px-Ic_chevron_right_48px.svg.png"
              className="h-10 self-center"
              alt=""
            />
          </div>
          <div
            className="entity-button-profile"
            onClick={() => {
              // navigate('/entity/create-relationation');
              navigate('/entities/manage/entity')
            }}
          >
            <MenuIcon
              color="#294fa9"
              className="h-10 self-center rounded-full bg-white border-[1px] p-1"
            />
            <p className="flex-1 text-center">
              {`Gestión ${
                props?.state?.updateEntityForm?.type === 'HN'
                  ? 'Clínica'
                  : 'Nursing Home'
              }`}
            </p>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Ic_chevron_right_48px.svg/1200px-Ic_chevron_right_48px.svg.png"
              className="h-10 self-center"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="w-full text-center">
        <button
          className={`w-fit px-4 py-2 rounded-xl border-[1px] font-bold bg-[#F6A01E] shadow-sm hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700 duration-300`}
          onClick={() => {
            if (props?.updateEntityForm?.preview) {
              navigate('/home')
            } else {
              navigate('/entities')
            }
          }}
        >
          Volver
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    state: {
      updateEntityForm: state.updateEntityForm,
    },
  }
}

export default connect(mapStateToProps, null)(ProfileEntity)
