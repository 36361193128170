/* eslint-disable @typescript-eslint/no-unused-vars */
import CustomValueContainer from '../ReactSelectComponents/CustomValueContainer'
import { emailValidation, websiteValidation } from '../../utils/regexExpresion'
import registerHomeService from '../../services/RegisterForms'
import { customStyles } from '../customStyleNewLabel'
import { Controller, useForm } from 'react-hook-form'
import Entities from '../../assets/svg/entidadesVerde.svg'
import Select, { components } from 'react-select'
import options from '../../data/selectOptions'
import NumberFormat from 'react-number-format'
import React, { useState } from 'react'
import EntityForm from './FormEntity'
import Switch from 'react-switch'
import Swal from 'sweetalert2'
import SumaryEntityRegisterData from '../Modals/SumaryEntityRegisterData'
import { useNavigate } from 'react-router-dom'
import List from '../../services/ListEntity'
import { setUpdateEntityForm } from '../../actions'
import { connect } from 'react-redux'

const RegisterEntity = ({ ...props }: any) => {
  const [isRequired, setIsRequired] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [formData, setFormdata] = useState<any>(null)
  const [choice, setChoice] = useState<any>(false)
  const [modalOn, setModalOn] = useState<any>(false)
  const [optional, setOptional] = useState<any>(true)
  const navigate = useNavigate()
  const numError: any[] = []

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
    control,
  } = useForm({
    mode: 'onChange',
  })

  const onSubmit = async () => {
    setIsLoading(false)
    const response = await registerHomeService.registerEntity(formData)

    if (response?.code >= 200 && response?.code <= 299) {
      reset({
        legalName: '',
        comercialName: '',
        webPage: '',
        email: '',
        firstLline: '',
        secondLline: '',
        entityType: null,
        ein: '',
        npi: '',
        phoneTwo: '',
        phone: '',
        phoneExt: '',
        state: null,
        zipCode: '',
        country: null,
        pobox: '',
        poboxState: null,
        poboxZipCode: '',
        poboxCountry: null,
      })
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Confirmación',
        text: 'Entidad registrada con éxito',
        icon: 'success',
        // icon: "error",
        cancelButtonText: 'Volver al formulario',
        confirmButtonText: 'Ver perfil',
        showCancelButton: true,
        confirmButtonColor: 'rgb(14 165 233)',
        cancelButtonColor: 'rgb(14 165 233)',
      }).then(async (result) => {
        //setIsLoading(true);
        let data: any
        if (result.isConfirmed) {
          if (formData?.entityType?.label === 'Clínica') {
            data = await List.clinic({ id: response?.data[0]?.id })
            data.type = 'C'
            props.setUpdateEntityForm(data)
            navigate('/entities/profile/information')
            // navigator('/update/entity');
          } else if (formData?.entityType?.label === 'Nursing Home') {
            data = await List.home({ id: response?.data[0]?.id })
            data.type = 'HN'
            props.setUpdateEntityForm(data)
            navigate('/entities/profile/information')
            // navigator('/update/entity');
          }
        } else {
          setIsLoading(true)
          reset({})
        }
      })
      setIsLoading(true)
    } else {
      Swal.fire({
        customClass: {
          confirmButton: 'bg-red-500 text-white',
        },
        title: 'Error Interno!',
        text: 'Se ha presentado un error interno',
        // icon: "error",
        confirmButtonText: 'Aceptar',
      })
      setIsLoading(true)
    }
    setIsLoading(true)
  }

  const beforeRegisterEntity = (data: any) => {
    setModalOn(true)
    setFormdata(data)
    setIsLoading(true)
  }

  return (
    <>
      {modalOn && (
        <SumaryEntityRegisterData
          formData={formData}
          setChoice={setChoice}
          setModalOn={setModalOn}
          onSubmit={onSubmit}
          setIsLoading={setIsLoading}
        />
      )}
      <div className="w-full space-y-7 ">
        <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
          <img src={Entities} className={`w-20`} alt="" />

          <h2 className="text-center sm:text-4xl font-extrabold text-2xl text-[#96c952]">
            Entidades
          </h2>
          <div>
            <h1 className="w-full self-center text-[#aaaaaa] text-2xl md:text-4xl font-light ">
              / Registro
            </h1>
          </div>
        </div>
        <div className="h-full w-full space-y-7 px-7">
          <form onSubmit={handleSubmit(beforeRegisterEntity)} onReset={reset}>
            <section className="lg:w-fit mx-auto lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-3 place-items-center flex flex-col py-4">
              <EntityForm
                Controller={Controller}
                Select={Select}
                options={options}
                customStyles={customStyles}
                NumberFormat={NumberFormat}
                emailValidation={emailValidation}
                websiteValidation={websiteValidation}
                Switch={Switch}
                register={register}
                errors={errors}
                isValid={isValid}
                isDirty={isDirty}
                control={control}
                isRequired={isRequired}
                setIsRequired={setIsRequired}
                CustomValueContainer={CustomValueContainer}
                mode={'register'}
                watch={watch}
                setOptional={setOptional}
                numError={numError}
              />
              <div className="lg:col-span-2 xl:col-span-3 my-5">
                {isLoading ? (
                  <div id="next" className="has-tooltip w-fit h-fit">
                    {!isValid || !optional ? (
                      <span className="tooltip rounded shadow-lg p-1 bg-gray-800 text-white -mt-12">
                        Para continuar, debe completar los campos requeridos
                        señalados con *
                      </span>
                    ) : null}
                    <input
                      type="submit"
                      disabled={!isValid || !optional}
                      value="Registrar Entidad"
                      className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                    />
                  </div>
                ) : (
                  <input
                    type="submit"
                    disabled
                    value="Registrando..."
                    className="md:w-auto h-10 w-full   rounded-lg px-4 py-2 bg-[#F6A01E] shadow-md duration-300 border-[#F6A01E] font-semibold hover:cursor-pointer hover:bg-sky-500 text-white hover:border-sky-700"
                  />
                )}
              </div>
            </section>
          </form>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = {
  setUpdateEntityForm,
}

export default connect(null, mapDispatchToProps)(RegisterEntity)

//export default RegisterEntity;

//labels en la parte superior colores de labels y inputs
