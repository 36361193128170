/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import api from './Api'
import Swal from 'sweetalert2'

const nursingHomeUpdate = async (data: any) => {
  let user: any = localStorage.getItem('user')

  console.log('raw: ', data)

  let {
    entityType,
    phoneTwo,
    phone,
    firstLline,
    secondLline,
    pobox,
    country,
    state,
    zipCode,
    legalName,
    comercialName,
    webPage,
    email,
    npi,
    ein,
    isMobilePatient,
    phoneExt,
    nursingHomeId,
    poboxState,
    poboxZipCode,
    poboxCountry,
  } = data

  npi = parseInt(npi)
  // ein = ein ? parseInt(ein.replace('-', '')) : ein
  phone = phone.replace(/(\D+)/g, '')
  phoneTwo = phoneTwo?.replace(/(\D+)/g, '')
  webPage = webPage === '' ? undefined : webPage
  //country = country.label;

  let obj: any = {
    nursingHomeId,
    comercialName,
    ein,
    npi,
    webPage,
    email,
    mobile: phoneTwo,
    local: phone,
    extension: phoneExt,
    address: null,
    state: state?.label,
    firstLline,
    secondLline,
    zipCode,
    pobox,
    statePoBox: poboxState?.label,
    zipCodePoBox: poboxZipCode,
    countryPoBox: poboxCountry?.label,
  }

  console.log('formated: ', obj)

  const response = await api.instanceEntities
    .put(`/nursing_home/update`, obj, {
      headers: {
        Authorization: `${JSON.parse(user).TokenType} ${
          JSON.parse(user).IdToken
        }`,
        // USER_ENTITY_ID: `${localStorage.getItem("role")}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    .then((response) => {
      // Swal.fire({
      // 	customClass: {
      // 		confirmButton: 'bg-red-500 text-white',
      // 	},
      // 	icon: 'success',
      // 	title: 'Confirmación',
      // 	text: 'Residente registrado con éxito',
      // 	// icon: "error",
      // 	confirmButtonText: 'Aceptar',
      // });
      return response.data
    })
    .catch((error) => {
      // Swal.fire({
      // 	customClass: {
      // 		confirmButton: 'bg-red-500 text-white',
      // 	},
      // 	title: 'Error Interno!',
      // 	text: 'Vuelva a intertarlo nuevamente',
      // 	// icon: "error",
      // 	confirmButtonText: 'Aceptar',
      // });
      return error
    })

  // console.log(response);

  return response
}

const clinicUpdate = async (data: any) => {
  let user: any = localStorage.getItem('user')

  console.log('raw clinic: ', data)

  let {
    clinic_id,
    legalName,
    comercialName,
    webPage,
    email,
    firstLline,
    secondLline,
    entityType,
    ein,
    npi,
    phoneTwo,
    phone,
    phoneExt,
    state,
    zipCode,
    country,
    pobox,
    poboxState,
    poboxZipCode,
    poboxCountry,
  } = data

  state = state.label
  npi = npi ? parseInt(npi) : npi
  // ein = ein ? parseInt(ein.replace('-', '')) : ein
  phone = phone.replace(/(\D+)/g, '')

  let obj: any = {
    clinicId: clinic_id,
    comercialName,
    ein,
    npi,
    webPage,
    email,
    mobile: phoneTwo,
    local: phone,
    extension: phoneExt,
    address: null,
    state,
    firstLline,
    secondLline,
    zipCode,
    pobox,
    phoneExt,
    statePoBox: poboxState?.label,
    zipCodePoBox: poboxZipCode,
    countryPoBox: poboxCountry?.label,
  }

  console.log('formated clinic: ', obj)

  const response = await api.instanceEntities
    .put(`/clinic/update`, obj, {
      headers: {
        Authorization: `${JSON.parse(user).TokenType} ${
          JSON.parse(user).IdToken
        }`,
        // USER_ENTITY_ID: `${localStorage.getItem("role")}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    .then((response) => {
      // Swal.fire({
      // 	customClass: {
      // 		confirmButton: 'bg-red-500 text-white',
      // 	},
      // 	icon: 'success',
      // 	title: 'Confirmación',
      // 	text: 'Residente registrado con éxito',
      // 	// icon: "error",
      // 	confirmButtonText: 'Aceptar',
      // });
      return response.data
    })
    .catch((error) => {
      // Swal.fire({
      // 	customClass: {
      // 		confirmButton: 'bg-red-500 text-white',
      // 	},
      // 	title: 'Error Interno!',
      // 	text: 'Vuelva a intertarlo nuevamente',
      // 	// icon: "error",
      // 	confirmButtonText: 'Aceptar',
      // });
      return error
    })

  // console.log(response);

  return response
}

export default {
  nursingHomeUpdate,
  clinicUpdate,
}
