/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { BanIcon, CheckCircleIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import { customStyles } from '../customStyle'
import { useNavigate } from 'react-router-dom'
import useGetEntity from '../../hooks/useGetEntity'
import { connect } from 'react-redux'
import Entities from '../../assets/svg/entidades.svg'
import updatePatientASC from '../../services/updatePatientASC'
import LinkEntitiesService from '../../services/LinkEntity'

const LinkEntity = ({ ...props }) => {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
    control,
  } = useForm({ mode: 'onChange' })

  const { dataHomeList, dataClinicList, error, isLoading } = useGetEntity(
    props?.state?.accessRole,
  )
  //director registro usuario entidad paciente actualizar y ver

  const onSubmit = async (values: any) => {
    let datalink: any = ''
    values.select_entity.clinicId = props?.state?.updateEntityForm?.clinic_id
    values.select_entity.nursing_homeId =
      props?.state?.updateEntityForm?.nursing_home_id

    if (props?.state?.updateEntityForm?.type === 'C') {
      datalink = await LinkEntitiesService.linkEntities(values.select_entity)
    } else {
      datalink = await LinkEntitiesService.linkEntitiesHN(values.select_entity)
    }
    if (datalink?.code >= 200 && datalink?.code <= 299) {
      //alert(JSON.stringify(values, null, 4));
      // clearUpdatePatientAllerProfCondForm(null);
      // reset({
      // 	allergy: [
      // 		{
      // 			description: '',
      // 			item: {},
      // 		},
      // 	],
      // });
      // initUser({});
      // navigate(`/entities/`);
    } else {
      // navigate(`/entities/`);
    }
  }

  return (
    <div className="w-full h-full">
      <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
        <div className="flex flex-col md:flex-row gap-1">
          <div className="flex flex-row">
            <img src={Entities} className={`w-20`} alt="" />
            <h1 className="w-full self-center text-[#96c952] sm:text-4xl font-extrabold text-xl ">
              Vincular / Desvincular Entidades
            </h1>
          </div>
        </div>
      </div>
      <div className="space-y-2 p-4">
        <div className="w-full justify-center  md:gap-1.5 md:flex md:flex-row sm:text-3xl text-xl text-sky-500 font-bold ">
          <p>
            {props?.state?.updateEntityForm?.type === 'C'
              ? 'Clínica '
              : 'Nursing Home '}
          </p>
          <p>
            {props?.state?.updateEntityForm?.clinic_comercialName ||
              props?.state?.updateEntityForm?.nursing_home_comercialName}
          </p>
        </div>
        {/*React hook form maneja un select mas dinamico y personalizable este es un ejemplo*/}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full h-full">
            <div>
              <p>
                {`Seleccione ${
                  props?.state?.updateEntityForm?.type === 'C'
                    ? 'el hogar'
                    : 'la clinica'
                } que desea vincular a ${
                  props?.state?.updateEntityForm?.type === 'C'
                    ? 'la clinica'
                    : 'el hogar'
                } 
								${
                  props?.state?.updateEntityForm?.type === 'C'
                    ? props?.state?.updateEntityForm?.clinic_comercialName
                    : props?.state?.updateEntityForm?.nursing_home_comercialName
                }
								`}
              </p>
            </div>
            <label
              className="h-10 rounded-md border-[1px] px-1 py-4 shadow-sm flex flex-row items-center bg-white hover:border-sky-500 hover:ring-sky-500 hover:ring-[1px]"
              htmlFor="select_entity"
            >
              <Controller
                control={control}
                name="select_entity"
                rules={{
                  required: {
                    value: false,
                    message: 'Tipo de usuario es requerido',
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <Select
                    ref={ref}
                    className="w-full"
                    placeholder="Seleccione entidad..."
                    styles={customStyles}
                    onBlur={onBlur}
                    isClearable
                    onChange={onChange}
                    options={
                      props?.state?.updateEntityForm?.type === 'C'
                        ? dataHomeList?.filter((item: any) => {
                            if (
                              item?.type !==
                              props?.state?.updateEntityForm?.type
                            ) {
                              return item
                            }
                          })
                        : dataClinicList?.filter((item: any) => {
                            if (
                              item?.type !==
                              props?.state?.updateEntityForm?.type
                            ) {
                              return item
                            }
                          })
                    }
                    value={value}
                  />
                )}
              />
            </label>
            {errors.select_entity && (
              <p className="text-sm text-red-600 mt-2">
                {errors.select_entity.message}
              </p>
            )}
          </div>
          <div className="my-5 w-full text-center">
            <input
              type="submit"
              value="Guardar Cambios"
              className="center mb-5 rounded-lg border-[1px] px-4 py-2 font-medium shadow-md duration-300 hover:cursor-pointer"
            />
          </div>
        </form>
      </div>
      <div className="w-full text-center">
        <button
          onClick={() => {
            navigate('/entities/manage/entity')
          }}
          className="center mb-5 rounded-lg border-[1px] px-4 py-2 font-medium shadow-md duration-300 hover:cursor-pointer"
        >
          Volver
        </button>
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => {
  return {
    state: {
      accessRole: state.accessRole,
      updateEntityForm: state.updateEntityForm,
    },
  }
}
export default connect(mapStateToProps, null)(LinkEntity)
