import { Expose } from 'class-transformer'

export class FormatSelect {
  @Expose({ name: 'id' })
  value: string | undefined | null
  @Expose({ name: 'legalName' })
  legalname: string | undefined | null
  @Expose({ name: 'comercialName' })
  label: string | undefined | null
  @Expose({ name: 'npi' })
  npi: string | undefined | null
  @Expose({ name: 'ein' })
  ein: string | undefined | null
  @Expose({ name: 'webPage' })
  webpage: string | undefined | null
  @Expose({ name: 'email' })
  email: string | undefined | null
}
