import { Expose } from 'class-transformer'

export class FormatSelect {
  @Expose({ name: 'value' })
  value: string | undefined | null

  @Expose({ name: 'label' })
  label: string | undefined | null

  @Expose({ name: 'id' })
  id: number | undefined | null

  @Expose({ name: 'state' })
  state: number | undefined | null

  @Expose({ name: 'nursingHomeId' })
  nursingHomeId: number | undefined | null

  @Expose({ name: 'firstLine' })
  firstLine: number | undefined | null

  @Expose({ name: 'secondLine' })
  secondLine: number | undefined | null

  @Expose({ name: 'clinicId' })
  clinicId: number | undefined | null
}
