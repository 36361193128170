/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLocation, useNavigate } from 'react-router-dom'
import useGetProfile from '../../hooks/useGetProfile'
import { PencilAltIcon } from '@heroicons/react/outline'
import { connect } from 'react-redux'
import { setUpdateProfileUserForm } from '../../actions'
import Usuario from '../../assets/svg/usuarioSearch.svg'
import Info from './Info'
import { useEffect, useState } from 'react'
import logicalRolSidebar from '../../utils/MenuAccess/logicalRolSidebar'

const Profile = ({ ...props }: any) => {
  const navigate = useNavigate()
  const [access, setAccess] = useState<any>(false)
  const [user, setUser] = useState<any>(null)

  const { dataProfile, isLoading }: any = useGetProfile()

  //cuando la variable user sea null, busque en el estado el usuario actual y asignelo a user
  useEffect(() => {
    if (user === null) {
      setTimeout(() => {
        setUser(props?.state?.currentUser)
      }, 500)
    }
  }, [props?.state?.currentUser, user])

  //cuando user no sea null, asigne los roles al estado local
  useEffect(() => {
    if (user !== null) {
      setTimeout(() => {
        setAccess(logicalRolSidebar(user))
      }, 500)
    }
  }, [user])

  return isLoading ? (
    <div className="w-full h-full p-4">Cargando...</div>
  ) : (
    <div className="w-full h-full ">
      <div className="w-full shadow-lg shadow-slate-20 pt-1 pb-2 md:py-4 border-b-2 bg-white justify-center items-center text-center flex sm:flex-row flex-col md:gap-4">
        <img
          //src={Usuario}
          src={dataProfile?.photoURL ? dataProfile?.photoURL : Usuario}
          className={`w-20 p-1 self-center rounded-full bg-slate-500`}
          alt=""
        />
        <div className="">
          <h1 className="text-2xl text-[#96c952] font-semibold my-5 justify-start">
            {dataProfile?.firstname
              ? dataProfile?.firstname
              : props?.state?.currentUser?.firstname}{' '}
            {dataProfile?.middlename
              ? dataProfile?.middlename
              : props?.state?.currentUser?.middlename}{' '}
            {dataProfile?.surname
              ? dataProfile?.surname
              : props?.state?.currentUser?.surname}{' '}
            {dataProfile?.secondSurname
              ? dataProfile?.secondSurname
              : props?.state?.currentUser?.secondSurname}
          </h1>
        </div>
      </div>

      <div className="w-full text-left md:px-20 space-y-2 text-lg py-2 border-y-2 p-3	">
        <Info
          dataProfile={dataProfile ? dataProfile : props?.state?.currentUser}
        />
        {/* <div className="lg:col-span-2 xl:col-span-3 my-5"> */}
        {access?.profileAcceseModifyData && (
          <div className={`lg:col-span-2 xl:col-span-3 my-5 `}>
            <div className="flex flex-row gap-10">
              <div className={`sm:flex-1 flex flex-row`} />
              <div
                className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
                onClick={() => {
                  props.setUpdateProfileUserForm(dataProfile)
                  navigate('/profile/update')
                }}
              >
                <PencilAltIcon className="w-8 h-8 " />
                <div>Editar datos</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    state: {
      currentUser: state.currentUser,
    },
  }
}

const mapDispatchToProps = {
  setUpdateProfileUserForm,
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
