import { deserialize } from 'class-transformer'
import { FormatSelect } from './FormatSelect'

export const dataTransformer = (data: any) => {
  let dataArray = data.map((item: any) => {
    return {
      id: item.id,
      fullname: `${item.firstname ? `${item.firstname} ` : ''}${
        item.middlename ? `${item.middlename} ` : ''
      }${item.surname ? `${item.surname} ` : ''}${
        item.secondSurname ? `${item.secondSurname} ` : ''
      }`,
      type: item.typeid,
    }
  })

  let processData = deserialize(FormatSelect, JSON.stringify(dataArray))

  return processData
}
