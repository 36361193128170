/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import api from '../services/Api'

const useGetProfile = (userId?: any) => {
  const [dataProfile, setDataProfile] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState<any>()

  useEffect(() => {
    const fetchdata = async () => {
      setIsLoading(true)
      let user: any = localStorage.getItem('user')
      if (userId === null || userId === undefined) {
        await api.instanceUsers
          .get('/user/full-info/', {
            headers: {
              Authorization: `${JSON.parse(user).TokenType} ${
                JSON.parse(user).IdToken
              }`,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            setDataProfile(response.data.data[0])
          })
          .catch((err) => {
            //console.log(err.response);
            //console.log(err?.response?.status);
            if ([401].includes(err?.response?.status)) {
              fetchdata()
            }
          })
      } else {
        await api.instanceUsers
          .post(
            '/user/get-profile-info/',
            { id: userId },
            {
              headers: {
                USER_ENTITY_ID: `${localStorage.getItem('role')}`,
                Authorization: `${JSON.parse(user).TokenType} ${
                  JSON.parse(user).IdToken
                }`,
                'Content-Type': 'application/json',
              },
            },
          )
          .then((response) => {
            setDataProfile(response.data.data[0])
          })
          .catch((err) => {
            //console.log(err.response);
            //console.log(err?.response?.status);
            if ([401].includes(err?.response?.status)) {
              fetchdata()
            }
          })
      }
      setIsLoading(false)
    }

    fetchdata()
  }, [])

  // console.log(data);
  return { dataProfile, isError, isLoading }
}

export default useGetProfile
