//Define el ancho y el alto de la pantalla con el menu incluido
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from './Navbar'
import Sidebar from '../components/Sidebar'
import SwitchEntity from '../components/Modals/SwitchEntity'
import { setEntitySelectedByUser } from '../actions'
import { connect } from 'react-redux'

const Layout = ({ container, ...props }: any) => {
  const [collapsed, setCollapsed] = useState(false)
  const [toggled, setToggled] = useState(false)
  const [modalEntityOn, setModalEntityOn] = useState(false)
  const [listEntity, setListEntity] = useState<any>(null)

  const navigate = useNavigate()

  const handleCollapsedChange = (checked: boolean) => {
    setCollapsed(checked)
  }

  const handleToggleSidebar = (value: boolean) => {
    setToggled(value)
  }

  const logout = () => {
    navigate('/login', { replace: true })
    localStorage.removeItem('user')
    window.location.reload()
  }

  const entitySelected = (data: any) => {
    props.setEntitySelectedByUser(data)
  }

  return (
    <>
      {modalEntityOn && (
        <SwitchEntity
          setModalEntityOn={setModalEntityOn}
          modalEntityOn={modalEntityOn}
          entitySelected={entitySelected}
          listEntity={listEntity}
        />
      )}
      <div className="w-full min-h-screen flex flex-col">
        <Navbar
          toggled={toggled}
          collapsed={collapsed}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        />
        <div className="flex flex-row w-full min-h-full">
          {/**{!modalEntityOn && ( */}
          <div className="shadow-lg border-r z-20">
            <Sidebar
              collapsed={collapsed}
              toggled={toggled}
              handleToggleSidebar={handleToggleSidebar}
              logout={logout}
              setModalEntityOn={setModalEntityOn}
              modalEntityOn={modalEntityOn}
              currentEntitySelected={props?.currentEntitySelected}
              listEntity={listEntity}
              setListEntity={setListEntity}
            />
          </div>
          {/**)} */}
          {container}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    state: {
      currentEntitySelected: state.currentEntitySelected,
    },
  }
}
const mapDispatchToProps = {
  setEntitySelectedByUser,
}

// export default Sidebar;
export default connect(mapStateToProps, mapDispatchToProps)(Layout)
