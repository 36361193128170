import PencilAltIcon from '@heroicons/react/outline/PencilAltIcon'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { setUpdatePatientMedicationForm } from '../../actions'
import Medicine from '../../assets/svg/PatientItems/Medicine.svg'

const MedicationPatient = (props: any) => {
  const {
    medicine,
    medicineId,
    dosage,
    measurementUnit,
    frequency,
    rxNumber,
    patient,
    indications,
  } = props
  const navigate = useNavigate()
  return (
    <div className="w-full text-left md:px-20 space-y-2 text-lg py-2 border-y-2 ">
      <div className="flex flex-row items-center justify-start gap-1.5 border-b-2">
        <img src={Medicine} className={`w-12`} alt="" />
        <h1 className="text-xl font-bold">
          <p className="text-black font-bold first-line:capitalize">
            {medicine ? medicine : 'N/A'}
          </p>
        </h1>
      </div>
      <div className="md:text-left md:w-full md:justify-self-center md:space-y-2 md:gap-y-1 md:gap-x-1.5  ">
        <div className="w-fit md:ml-24 min-w-sm	 ">
          {/* <div className="md:gap-1.5 md:flex md:flex-row">
						<p className="text-black font-bold">Nombre: </p>
						<p>{medicine}</p>
					</div> */}
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Dosis: </p>
            <p>
              {dosage ? dosage : 'N/A'}{' '}
              {measurementUnit ? measurementUnit : 'N/A'}
            </p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Frecuencia: </p>
            <p>{frequency ? frequency : 'N/A'}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">RXNumber: </p>
            <p>{rxNumber ? rxNumber : 'N/A'}</p>
          </div>
          <div className="md:gap-1.5 md:flex md:flex-row">
            <p className="text-black font-bold">Indicaciones: </p>
            <p>{indications ? indications : 'N/A'}</p>
          </div>
        </div>
      </div>

      <div className="flex flex-row ">
        <div className={`lg:flex-1 flex flex-row`} />
        <div
          className="w-full sm:w-fit flex items-center gap-1 bg-sky-500 p-2 text-white rounded-lg cursor-pointer my-2"
          onClick={() => {
            navigate('/medication/update')
            props.setUpdatePatientMedicationForm({
              medicine,
              medicineId,
              dosage,
              measurementUnit,
              frequency,
              rxNumber,
              patient,
              indications,
            })
          }}
        >
          <PencilAltIcon className="w-8 h-8 border-b-2" />
          <div>Editar datos</div>
        </div>
      </div>
    </div>
  )
}
const mapDispatchToProps = {
  setUpdatePatientMedicationForm,
}
export default connect(null, mapDispatchToProps)(MedicationPatient)
